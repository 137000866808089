import { createFileRoute, useParams } from "@tanstack/react-router";
import ProductConsumptionTab from "../../../../../../pages/projectDetails/projectProducts/ProductConsumptionTab";
import PageLayout from "../../../../../../components/PageLayout";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout/product"
)({
  component: OrderProductPage
});

function OrderProductPage() {
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/_layout/product"
  });

  return (
    <PageLayout>
      <ProductConsumptionTab resourceId={orderId} resourceType={"order"} />
    </PageLayout>
  );
}
