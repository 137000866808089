import { t } from "i18next";
import {
  ActivityActivityTypeEnum,
  ActivityTypeEnum,
  ActivityValueTypeEnum,
  TimeEntry
} from "../../.generated/api";

export function getHoursAndMinutesOnTimeSubmit(timeSubmit: TimeEntry) {
  const [hours, minutes] = timeSubmit.value.split(":");

  return { hours: +hours, minutes: +minutes };
}

const MINUTES_IN_HOUR = 60;

export function getFormattedHoursAndMinutesOnTimeSubmits(
  timeEntries: TimeEntry[]
) {
  const { hours, minutes } = getHoursAndMinutesOnTimeSubmits(timeEntries);
  return `${hours}${t("h")} ${minutes}${t("m")}`;
}

export function getMinutesOnTimeSubmit(timeSubmit: TimeEntry) {
  const { hours, minutes } = getHoursAndMinutesOnTimeSubmit(timeSubmit);

  return hours * MINUTES_IN_HOUR + minutes;
}

export function getMinutesOnTimeSubmits(timeSubmits: TimeEntry[]) {
  const totalMinutes = timeSubmits.reduce((sum, timeSubmit) => {
    if (timeSubmit.valueType !== "Hours") return sum;

    const minutes = getMinutesOnTimeSubmit(timeSubmit);
    return (sum += minutes);
  }, 0);

  return totalMinutes;
}

export function getHoursAndMinutesOnTimeSubmits(timeSubmits: TimeEntry[]) {
  const minutes = getMinutesOnTimeSubmits(timeSubmits);

  return {
    hours: Math.floor(minutes / MINUTES_IN_HOUR),
    minutes: minutes % MINUTES_IN_HOUR
  };
}

export function getKilometersFromTimeSubmits(timeSubmits: TimeEntry[]) {
  const kilometeres = timeSubmits.reduce((sum, { value, valueType }) => {
    if (valueType === "Hours") return sum;

    return (sum += +value);
  }, 0);

  return kilometeres;
}

export function getTypeLabel(type: ActivityTypeEnum) {
  switch (type) {
    case "Company":
      return "internal";
    case "Project":
      return "project";
    case "Order":
      return "order";
    case "Task":
      return "task";
  }
}

export function getActivityTypeLabel(type: ActivityActivityTypeEnum) {
  switch (type) {
    case "Absence":
      return "Fravær";
    case "Billable":
      return "Fakturerbart";
    case "NonBillable":
      return "Ikke-fakturerbart";
    case "Other":
      return "Annet";
  }
}

export function getValueTypeLabel(valueType: ActivityValueTypeEnum) {
  switch (valueType) {
    case "Hours":
      return "hours";
    case "Kilometers":
      return "kilometers";
  }
}
