import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import CardNew from "../../../../../../../components/ds/CardNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";
import React from "react";
import { formatFullNumericDateString } from "../../../../../../../utils/DateTimeFormatter";
import { formatCurrency } from "../../../../../../../utils/currencyFormatter";
import { useGetInvoicesByProjectId } from "../../../../../../../service/api/InvoiceApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { projectId }
  }) => {
    return await queryClient.fetchQuery(
      useGetInvoicesByProjectId.getOptions({ companyId, projectId })
    );
  },
  component: ProjectEconomyInvoicePage
});

function ProjectEconomyInvoicePage() {
  const { t } = useTranslation();
  const invoices = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
  });

  return (
    <>
      <CardNew title={t("outgoingInvoices")} inset>
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>{t("title")}</TableHeaderCell>
              <TableHeaderCell>{t("to")}</TableHeaderCell>
              <TableHeaderCell>{t("deliveryDate")}</TableHeaderCell>
              <TableHeaderCell>{t("dueDate")}</TableHeaderCell>
              <TableHeaderCell>{t("netAmount")}</TableHeaderCell>
              <TableHeaderCell>{t("totalAmount")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices.totalElements === 0 && (
              <TableRow>
                <TableCell>{t("noOutgoingInvoices")}</TableCell>
              </TableRow>
            )}
            {invoices.data.map((invoice) => (
              <TableRow key={invoice.invoiceId}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.title}</TableCell>
                <TableCell>{invoice.customer?.formattedCustomerName}</TableCell>
                <TableCell>
                  {formatFullNumericDateString(invoice.deliveryDate)}
                </TableCell>
                <TableCell>
                  {formatFullNumericDateString(invoice.dueDate)}
                </TableCell>
                <TableCell>{formatCurrency(invoice.netAmount)}</TableCell>
                <TableCell>{formatCurrency(invoice.totalAmount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </>
  );
}
