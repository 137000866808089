import { differenceInDays } from "date-fns";
import { formatFullNumericDate } from "./DateTimeFormatter";
import { t } from "i18next";

export type DateRange = {
  start: Date;
  end: Date;
};

export type Period = "week" | "month" | "day" | "year" | "custom";

export const toUTC = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

export function getDayOfWeek(date: Date) {
  const day = date.getDay();
  return day === 0 ? 6 : day - 1;
}

export function getDaysAgo(dateString: string) {
  try {
    const now = new Date();
    const date = new Date(dateString);
    const difference = differenceInDays(now, date);
    if (difference === 0) {
      return t("I dag");
    } else if (difference === 1) {
      return t("1 dag siden");
    } else if (difference < 30) {
      return `${difference} ${t("dager siden")}`;
    } else {
      return formatFullNumericDate(date);
    }
  } catch (_) {
    return "";
  }
}
