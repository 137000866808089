import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import ChildProjectTab from "../../../../../../pages/projectDetails/ChildProjectTab";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/sub-project"
)({
  component: ProjectSubProjectPage
});

function ProjectSubProjectPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <div className={"m-5"}>
      <ChildProjectTab project={project} />
    </div>
  );
}
