import {
  ResourceRelationApi,
  ResourceRelationResourceTypeEnum
} from "../../.generated/api";
import environment from "../../environment";
import { customerInstance } from "./CustomerHttpBase";
import { createQuery } from "react-query-kit";

const relationApi = new ResourceRelationApi(
  undefined,
  environment.skwApiNewBaseUrl,
  customerInstance
);

export const useGetRelationIdsByCustomerId = createQuery({
  queryKey: ["getRelationsIdsByCustomerId"],
  fetcher: async (variables: {
    companyId: string;
    customerId: string;
    resourceType: "Project" | "Task" | "Order" | "Facility" | "ServiceContract";
  }) => {
    const response = await relationApi.getResourceIdsByCustomerId(
      variables.companyId,
      variables.customerId,
      variables.resourceType
    );
    return response.data;
  }
});

//TODO: Fetcher?
export async function getResourceRelationsByCustomerId(
  companyId: string,
  customerId: string,
  resourceType: ResourceRelationResourceTypeEnum
) {
  const response = await relationApi.getResourceIdsByCustomerId(
    companyId,
    customerId,
    resourceType
  );
  return response.data;
}

export async function createCustomerRelation(
  companyId: string,
  customerId: string,
  resourceId: string,
  resourceType: ResourceRelationResourceTypeEnum,
  contactPersonId?: string
) {
  await relationApi.createResourceRelation(
    companyId,
    customerId,
    resourceId,
    resourceType,
    contactPersonId
  );
}

export async function deleteCustomerRelation(
  companyId: string,
  customerId: string,
  resourceId: string
) {
  await relationApi.deleteResourceRelation(companyId, customerId, resourceId);
}
