import { Select as RadixSelect } from "@radix-ui/themes";
import React, { ReactNode, Ref } from "react";
import InputWrapperNew, { InputWrapperProps } from "./InputWrapperNew";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import classNames from "classnames";
import { pickInputWrapperProps } from "./utils";

export type SelectOption<T extends string | undefined> = {
  value: T;
  label: string;
};

type ControlledSelectProps<
  T extends string | undefined,
  U extends FieldValues
> = Omit<FormSelectProps<T>, "onChange"> & {
  control: Control<U>;
  fieldName: Path<U>;
};

type FormSelectProps<T extends string | undefined> = InputWrapperProps &
  SelectProps<T>;

type SelectProps<T extends string | undefined> = RadixSelect.RootProps & {
  options: SelectOption<T>[];
  onChange: (value: undefined extends T ? T | undefined : T) => void;
  value?: string;
  customRef?: Ref<HTMLButtonElement>;
  className?: string;
  placeholder?: string;
  trailing?: ReactNode;
  triggerProps?: RadixSelect.TriggerProps;
};

export function ControlledSelect<
  T extends string | undefined,
  U extends FieldValues
>({ fieldName, control, ...props }: ControlledSelectProps<T, U>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value, ref, name } }) => (
        <FormSelect
          {...props}
          value={value}
          onChange={onChange}
          customRef={ref}
          name={name}
        />
      )}
    />
  );
}

export function FormSelect<T extends string | undefined>({
  ...props
}: FormSelectProps<T>) {
  return (
    <InputWrapperNew {...pickInputWrapperProps(props)}>
      {(style) => (
        <Select {...props} className={classNames(style, props.className)} />
      )}
    </InputWrapperNew>
  );
}

export default function Select<T extends string | undefined>({
  size = "3",
  customRef,
  onChange,
  value,
  trailing,
  triggerProps,
  ...props
}: SelectProps<T>) {
  function handleChange(selectedValue: string) {
    const value =
      selectedValue === "" && props.options.some((o) => o.value === undefined)
        ? undefined
        : (selectedValue as T);

    onChange(value as undefined extends T ? T | undefined : T);
  }

  return (
    <RadixSelect.Root
      size={size}
      disabled={props.disabled}
      onValueChange={handleChange}
      value={value ?? ""}
      {...props}
    >
      <RadixSelect.Trigger {...triggerProps} {...props} ref={customRef} />
      <RadixSelect.Content position={"popper"} variant={"soft"}>
        {props.options.map((option, index) => (
          //@ts-expect-error undefined should be allowed as a value
          <RadixSelect.Item key={option.value + index} value={option.value}>
            {option.label}
          </RadixSelect.Item>
        ))}
        {trailing && (
          <>
            <RadixSelect.Separator />
            {trailing}
          </>
        )}
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
}
