import { InvoiceLineData } from "../invoice.new";
import {
  OfferSection,
  OfferSubsectionVarelinjer,
  OfferSubsectionVarelinjervalg
} from "../../../../../.generated/api";

function getVat(vat: number) {
  switch (vat) {
    case 0:
      return "0";
    case 12:
      return "12";
    case 15:
      return "15";
    case 25:
      return "25";
    default:
      return "25";
  }
}

export function mapOfferLines(sections?: OfferSection[]): InvoiceLineData[] {
  if (!sections) return [];
  const lines: InvoiceLineData[] = [];

  sections.forEach((section) => {
    section.subsections.forEach((subsection) => {
      if (subsection.type === "varelinjer") {
        const section = subsection as OfferSubsectionVarelinjer;
        section.package.items.forEach((item) => {
          lines.push({
            productNumber: "999",
            price: item.price,
            count: item.count,
            description: item.description,
            unit: "stk.",
            vat: getVat(item.mva),
            discount: item.rebate,
            isFreeText: false
          });
        });
      }

      if (subsection.type === "varelinjevalg") {
        const section = subsection as OfferSubsectionVarelinjervalg;

        section.packages.forEach((packagedItems) => {
          if (section.selectedPackageId === packagedItems.id) {
            packagedItems.items.forEach((item) => {
              lines.push({
                productNumber: item.description,
                price: item.price,
                count: item.count,
                description: "",
                unit: "stk.",
                vat: getVat(item.mva),
                discount: item.rebate,
                isFreeText: false
              });
            });
          }
        });
      }
    });
  });

  return lines;
}
