/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ResourceRelation } from '../model';
/**
 * ResourceRelationApi - axios parameter creator
 * @export
 */
export const ResourceRelationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create order relation on automatically generated orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderRelationByPubSub: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/pubsub/order/recurring/relation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [contactPersonId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceRelation: async (companyId: string, customerId: string, resourceId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', contactPersonId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createResourceRelation', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createResourceRelation', 'customerId', customerId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('createResourceRelation', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('createResourceRelation', 'resourceType', resourceType)
            const localVarPath = `/v2/company/{companyId}/customer/{customerId}/resource/{resourceId}/{resourceType}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactPersonId !== undefined) {
                localVarQueryParameter['contactPersonId'] = contactPersonId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceRelation: async (companyId: string, customerId: string, resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteResourceRelation', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteResourceRelation', 'customerId', customerId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteResourceRelation', 'resourceId', resourceId)
            const localVarPath = `/v2/company/{companyId}/customer/{customerId}/resource/{resourceId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get resource ids by type and customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceIdsByCustomerId: async (companyId: string, customerId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getResourceIdsByCustomerId', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getResourceIdsByCustomerId', 'customerId', customerId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getResourceIdsByCustomerId', 'resourceType', resourceType)
            const localVarPath = `/v2/company/{companyId}/customer/{customerId}/resource/{resourceType}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceRelationApi - functional programming interface
 * @export
 */
export const ResourceRelationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceRelationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create order relation on automatically generated orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderRelationByPubSub(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderRelationByPubSub(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [contactPersonId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResourceRelation(companyId: string, customerId: string, resourceId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', contactPersonId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceRelation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResourceRelation(companyId, customerId, resourceId, resourceType, contactPersonId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResourceRelation(companyId: string, customerId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResourceRelation(companyId, customerId, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get resource ids by type and customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceIdsByCustomerId(companyId: string, customerId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceIdsByCustomerId(companyId, customerId, resourceType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResourceRelationApi - factory interface
 * @export
 */
export const ResourceRelationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceRelationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create order relation on automatically generated orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderRelationByPubSub(options?: any): AxiosPromise<object> {
            return localVarFp.createOrderRelationByPubSub(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [contactPersonId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceRelation(companyId: string, customerId: string, resourceId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', contactPersonId?: string, authorization?: string, options?: any): AxiosPromise<ResourceRelation> {
            return localVarFp.createResourceRelation(companyId, customerId, resourceId, resourceType, contactPersonId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete resource relation
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceRelation(companyId: string, customerId: string, resourceId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteResourceRelation(companyId, customerId, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get resource ids by type and customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceIdsByCustomerId(companyId: string, customerId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', authorization?: string, options?: any): AxiosPromise<Set<string>> {
            return localVarFp.getResourceIdsByCustomerId(companyId, customerId, resourceType, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResourceRelationApi - object-oriented interface
 * @export
 * @class ResourceRelationApi
 * @extends {BaseAPI}
 */
export class ResourceRelationApi extends BaseAPI {
    /**
     * 
     * @summary Create order relation on automatically generated orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceRelationApi
     */
    public createOrderRelationByPubSub(options?: AxiosRequestConfig) {
        return ResourceRelationApiFp(this.configuration).createOrderRelationByPubSub(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create resource relation
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} resourceId 
     * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
     * @param {string} [contactPersonId] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceRelationApi
     */
    public createResourceRelation(companyId: string, customerId: string, resourceId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', contactPersonId?: string, authorization?: string, options?: AxiosRequestConfig) {
        return ResourceRelationApiFp(this.configuration).createResourceRelation(companyId, customerId, resourceId, resourceType, contactPersonId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete resource relation
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceRelationApi
     */
    public deleteResourceRelation(companyId: string, customerId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ResourceRelationApiFp(this.configuration).deleteResourceRelation(companyId, customerId, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get resource ids by type and customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract'} resourceType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceRelationApi
     */
    public getResourceIdsByCustomerId(companyId: string, customerId: string, resourceType: 'Project' | 'Task' | 'Order' | 'Facility' | 'ServiceContract', authorization?: string, options?: AxiosRequestConfig) {
        return ResourceRelationApiFp(this.configuration).getResourceIdsByCustomerId(companyId, customerId, resourceType, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
