import {
  createFileRoute,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import React from "react";
import { UpsertLocationPage } from "../-components/UpsertLocationPage";
import { useGetLocationByResourceId } from "../../../../../service/api/LocationApi";

export const Route = createFileRoute(
  "/_protected/dashboard/location/$resourceId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({ context: { queryClient }, params: { resourceId } }) => {
    return await queryClient.fetchQuery({
      ...useGetLocationByResourceId.getOptions({ resourceId })
    });
  },
  component: UpdateLocationPage
});

function UpdateLocationPage() {
  const { resourceId } = useParams({
    from: "/_protected/dashboard/location/$resourceId/update"
  });
  const location = useLoaderData({
    from: "/_protected/dashboard/location/$resourceId/update"
  });

  return <UpsertLocationPage resourceId={resourceId} location={location} />;
}
