import {
  ChecklistFromTemplateRequest,
  ChecklistItem,
  ChecklistItemPutRequest,
  ChecklistItemTypeEnum,
  ChecklistPostRequest,
  ChecklistPutRequest,
  ChecklistV2,
  ChecklistV2Api,
  FileResponse
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { Control, UseFormRegister, UseFormWatch } from "react-hook-form";
import { t } from "i18next";
import { createQuery } from "react-query-kit";
import { BadgeProps as RadixBadgeProps } from "@radix-ui/themes/dist/cjs/components/badge";
import { ChecklistFormData } from "../../routes/_protected/dashboard/checklist/-components/UpsertChecklistPage";

export type ChecklistFormInputs = {
  taskId?: string;
  title: string;
  description?: string;
  isRequired: boolean;
  recipients: string[];
  items: ChecklistItem[];
  saveAsTemplate: boolean;
};

export type ChecklistRawItem = {
  id?: string;
  index: number;
  title: string;
  type: ChecklistItemTypeEnum;
  possibleValues?: string[];
  files?: File[];
  uploadedFiles?: FileResponse[];
  description?: string;
  value?: string;
  completedAt?: string;
  completedBy?: string;
  comment?: string;
  required?: boolean;
};

export type ChecklistItemProps = {
  control: Control<ChecklistFormInputs>;
  register: UseFormRegister<ChecklistFormInputs>;
  watch: UseFormWatch<ChecklistFormInputs>;
  checklistItems?: ChecklistRawItem[];
};

export type ChecklistSortField = "Title" | "UpdatedAt" | "ChecklistNumber";
export type ChecklistStatusFilter = "All" | "Completed" | "NotCompleted";

export function getChecklistDefaultValues(
  checklist?: ChecklistV2
): ChecklistFormData {
  if (checklist) {
    checklist.items.sort((a, b) => a.index - b.index);

    return {
      title: checklist.title,
      description: checklist.description,
      recipient: "",
      recipients: checklist.recipients.map((r) => ({ email: r })),
      saveAsTemplate: false,
      required: checklist.required,
      items: checklist.items.map((item) => ({
        id: item.id,
        title: item.title,
        required: item.required,
        value: item.value ?? undefined,
        description: item.description,
        type: item.type,
        possibleValues: item.possibleValues
          ? [...item.possibleValues.map((v) => ({ option: v })), { option: "" }]
          : []
      }))
    };
  } else {
    return {
      title: "",
      description: "",
      recipient: "",
      recipients: [],
      saveAsTemplate: false,
      required: false,
      items: [
        {
          title: "",
          required: false,
          value: undefined,
          type: "checkbox",
          description: "",
          possibleValues: []
        }
      ]
    };
  }
}

export function mapChecklistItems(items: ChecklistFormData["items"]) {
  return items.map((item, index) => {
    item.possibleValues.pop();
    return {
      id: item.id,
      value: item.value,
      description: item.description,
      index: index,
      title: item.title,
      type: item.type,
      possibleValues: item.possibleValues.map((value) => value.option),
      required: item.required
    };
  });
}

const checklistApi = new ChecklistV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetPublicChecklistByResourceId = createQuery({
  queryKey: ["getPublicChecklistByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await checklistApi.getAllPublicChecklistsByResourceId(
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetChecklistsByResourceId = createQuery({
  queryKey: ["getChecklistsByResourceId"],
  fetcher: async (variables: {
    resourceId: string;
    withChildren?: boolean;
    categoryId?: string;
    status?: ChecklistStatusFilter;
    page?: number;
    sortDirection?: "ASC" | "DESC";
    query?: string;
    sortField: ChecklistSortField;
  }) => {
    const response = await checklistApi.getPaginatedChecklistsByResourceId(
      variables.resourceId,
      variables.withChildren ?? false,
      variables.categoryId,
      variables.status,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query
    );
    return response.data;
  }
});

export const useGetChecklistByProjectId = createQuery({
  queryKey: ["getChecklistByProjectId"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await checklistApi.getChecklistByProjectId(
      variables.projectId
    );
    return response.data;
  }
});

export const useGetChecklistByResourceId = createQuery({
  queryKey: ["getChecklistByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await checklistApi.getChecklistByResourceId(
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetChecklistById = createQuery({
  queryKey: ["getChecklistById"],
  fetcher: async (variables: { checklistId: string }) => {
    const response = await checklistApi.getChecklistById(variables.checklistId);
    return response.data;
  }
});

export async function createChecklist(request: ChecklistPostRequest) {
  const response = await checklistApi.createChecklist(request);
  return response.data;
}

export async function createChecklistFromTemplate(
  request: ChecklistFromTemplateRequest
) {
  const response = await checklistApi.createFromTemplate(request);
  return response.data;
}

export async function deleteChecklist(checklistId: string) {
  await checklistApi.deleteChecklist(checklistId);
}

export async function updateChecklist(request: ChecklistPutRequest) {
  const res = await checklistApi.updateChecklist(request);
  return res.data;
}

export async function updateChecklistItemInputValueData(
  checklistId: string,
  request: Array<ChecklistItemPutRequest>
): Promise<void> {
  await checklistApi.updateChecklistItems(checklistId, request);
}

export function getChecklistStatusText(checklist: ChecklistV2) {
  if (checklist.itemsCompleted === 0) {
    return t("notStarted");
  } else if (checklist.completed) {
    return t("completed");
  } else {
    return t("started");
  }
}

export function getChecklistStatusColor(
  checklist: ChecklistV2
): RadixBadgeProps["color"] {
  if (checklist.itemsCompleted === 0) {
    return "gray";
  } else if (checklist.completed) {
    return "green";
  } else {
    return "yellow";
  }
}
