import { z } from "zod";
import {
  customerFormSchema,
  handleCustomer
} from "../../../../../components/customer/types";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { Ref, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useGetProductsByCompanyId } from "../../../../../service/api/ProductApi";
import { keepPreviousData } from "@tanstack/react-query";
import { CustomerFormSections } from "../../../../../components/customer/CustomerForm";
import CardNew from "../../../../../components/ds/CardNew";
import { FormCombobox } from "../../../../../components/ds/Combobox/Combobox";
import { FormTextField } from "../../../../../components/ds/TextFieldNew";
import { ControlledSelect } from "../../../../../components/ds/NewSelect";
import LineItemSum from "./-components/DirectSaleLineItem";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import { FormTextArea } from "../../../../../components/ds/TextArea";
import { ControlledDatePicker } from "../../../../../components/ds/DatePicker/DatePickerNew";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import FormPage from "../../../../../components/FormPage";
import { Text } from "@radix-ui/themes";
import DirectSaleSummary from "./-components/DirectSaleSummary";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../../../../../hooks/useToast";
import { t } from "i18next";
import { createDirectSale } from "../../../../../service/api/DirectSaleApi";
import { ControlledSwitch } from "../../../../../components/ds/SwitchNew";
import { useAppStore } from "../../../../../state/app_store";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/direct-sale/new"
)({
  component: CreateDirectSalePage
});

const vatEnum = z.enum(["vat25", "vat15", "vat12", "vat0"]);

const directSaleProductSchema = z.object({
  title: z.string(),
  price: z.coerce.number(),
  quantity: z.coerce.number().min(1).max(999),
  vat: vatEnum,
  discount: z.coerce.number().min(0).max(100)
});

const directSaleSchema = z
  .object({
    customer: customerFormSchema,
    paymentMethod: z.enum(["cash", "invoice"]),
    date: z.coerce.date(),
    comment: z.string().optional(),
    sendReceipt: z.boolean(),
    customerReference: z.string(),
    sellerReference: z.string(),
    orderReference: z.string(),
    receiptEmail: z.string().email().optional(),
    products: z
      .array(directSaleProductSchema)
      .min(1, { message: t("atLeastOneProductMustBeAdded") })
  })
  .superRefine((schema, ctx) => {
    if (schema.sendReceipt && !schema.receiptEmail) {
      ctx.addIssue({
        code: "custom",
        path: ["receiptEmail"],
        message: t("fieldRequired")
      });
    }
  });

export type VatEnum = z.infer<typeof vatEnum>;
export type DirectSalesFormData = z.infer<typeof directSaleSchema>;

function CreateDirectSalePage() {
  const { t } = useTranslation();
  const user = useAppStore((s) => s.user);
  const companyId = useCompanyId();
  const [query, setQuery] = useState("");
  const { showErrorToast, showSuccessToast } = useToast();
  const navigate = useNavigate();

  const {
    watch,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<DirectSalesFormData>({
    resolver: zodResolver(directSaleSchema),
    defaultValues: {
      comment: "",
      date: new Date(),
      paymentMethod: "cash",
      products: [],
      sendReceipt: true,
      receiptEmail: "",
      customerReference: "",
      sellerReference: "",
      orderReference: "",
      customer: {
        isNewCustomer: false,
        customerType: "company"
      }
    }
  });

  const sendReceipt = watch("sendReceipt");
  const customerEmail = watch("customer.info.email");
  const contactPerson = watch("customer.info.contactPerson");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products"
  });

  useEffect(() => {
    setValue("receiptEmail", customerEmail);
  }, [customerEmail]);

  useEffect(() => {
    const ourReference = getValues("sellerReference");
    if (!ourReference && user) {
      setValue("sellerReference", user.name ?? "");
    }
  }, [user]);

  useEffect(() => {
    const customerReference = getValues("customerReference");
    if (!customerReference && contactPerson) {
      setValue("customerReference", contactPerson);
    }
  }, [contactPerson]);

  function addProduct(productId?: string) {
    if (!productId) return;
    const product = productsQuery.data?.products.find(
      (p) => p.id === productId
    );
    if (!product) return;

    append(
      {
        price: product.price,
        title: product.name,
        discount: 0,
        quantity: 1,
        vat: "vat25"
      },
      {
        shouldFocus: false
      }
    );
    setQuery("");
  }

  function addNewFreeTextLine() {
    append({
      quantity: 1,
      discount: 0,
      title: "",
      price: 0,
      vat: "vat25"
    });
  }

  const productsQuery = useGetProductsByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      query
    }
  });

  async function onSubmit(data: DirectSalesFormData) {
    const customer = await handleCustomer(data.customer, companyId);

    try {
      await createDirectSale(companyId, {
        date: data.date.toISOString(),
        comment: data.comment,
        paymentMethod: data.paymentMethod,
        customerId: customer.id,
        receiptEmail: data.receiptEmail ?? "",
        sendReceipt: data.sendReceipt,
        customerReference: data.customerReference,
        orderReference: data.orderReference,
        sellerReference: data.sellerReference,
        lines: data.products.map((p) => ({
          price: p.price,
          discount: p.discount,
          vat: p.vat,
          quantity: p.quantity,
          name: p.title
        }))
      });

      showSuccessToast(t("saleCreated"));
      navigate({
        to: "/dashboard/sales/direct-sale"
      });
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  function handleError() {
    if (errors.products?.type === "too_small") {
      showErrorToast(errors.products.message ?? "");
    }
  }

  return (
    <FormPage
      pageTitle={t("newSale")}
      onSubmit={handleSubmit(onSubmit, handleError)}
      sections={[
        ...CustomerFormSections({
          errors,
          control,
          register,
          setValue,
          watch
        }),
        {
          title: t("addProducts"),
          description: t("addProductsDescription"),
          content: (
            <CardNew
              inset
              headerContent={
                <FormCombobox
                  noBottomPadding
                  wrapperClassName={"grow"}
                  clearOnSelect
                  skipLocalFiltering
                  placeholder={t("addExistingProduct")}
                  loading={productsQuery.isLoading}
                  onValueChange={addProduct}
                  onInputChange={setQuery}
                  options={productsQuery.data?.products ?? []}
                  getLabel={(p) => `${p.name} (${p.item_number})`}
                  getValue={(p) => p.id}
                />
              }
            >
              <div className={"flex flex-col "}>
                {fields.map((f, index) => {
                  return (
                    <div className={"bg-radix-gray-2"} key={f.id}>
                      <div className={"px-4 py-4 "}>
                        <div className={"flex items-end"}>
                          <div className={"grow"}>
                            <div
                              className={
                                "flex  items-center justify-between mb-1"
                              }
                            >
                              <Text weight={"bold"} size={"3"} as={"p"}>
                                Produkt {index + 1}
                              </Text>
                              <IconButton
                                color={"gray"}
                                icon={"delete"}
                                onClick={() => remove(index)}
                              />
                            </div>
                            <div className={"flex items-start gap-4"}>
                              <FormTextField
                                wrapperClassName={"grow"}
                                label={t("productName")}
                                size={"2"}
                                {...register(`products.${index}.title`)}
                              />
                            </div>
                            <div className={"flex gap-4 items-end"}>
                              <FormTextField
                                noBottomPadding
                                type={"number"}
                                wrapperClassName={"w-full"}
                                label={t("price")}
                                size={"2"}
                                {...register(`products.${index}.price`)}
                              />
                              <FormTextField
                                noBottomPadding
                                type={"number"}
                                label={t("quantity")}
                                className={"w-[80px]"}
                                size={"2"}
                                {...register(`products.${index}.quantity`)}
                              />
                              <FormTextField
                                noBottomPadding
                                label={t("discount")}
                                className={"w-[80px]"}
                                size={"2"}
                                trailing={<Text>%</Text>}
                                {...register(`products.${index}.discount`)}
                              />
                              <ControlledSelect
                                noBottomPadding
                                control={control}
                                fieldName={`products.${index}.vat`}
                                label={t("vat")}
                                className={"w-[80px]"}
                                size={"2"}
                                options={[
                                  {
                                    label: "25%",
                                    value: "vat25"
                                  },
                                  {
                                    label: "15%",
                                    value: "vat15"
                                  },
                                  {
                                    label: "12%",
                                    value: "vat12"
                                  },
                                  {
                                    label: "0%",
                                    value: "vat0"
                                  }
                                ]}
                              />
                              <LineItemSum control={control} index={index} />
                            </div>
                          </div>
                        </div>
                        {errors.products?.[index]?.quantity?.message && (
                          <Text as={"p"} color={"red"} className={"mt-1"}>
                            {errors.products?.[index]?.quantity?.message}
                          </Text>
                        )}
                        {errors.products?.[index]?.discount?.message && (
                          <Text as={"p"} color={"red"} className={"mt-1"}>
                            {errors.products?.[index]?.discount?.message}
                          </Text>
                        )}
                      </div>
                      <hr className={"my-0 py-0"} />
                    </div>
                  );
                })}
              </div>
              <div className={"p-4 border-b border-b-radix-gray-6"}>
                <ButtonNew
                  icon={"add"}
                  color={"gray"}
                  onClick={addNewFreeTextLine}
                  variant={"soft"}
                  className={""}
                >
                  {t("newFreeTextLine")}
                </ButtonNew>
              </div>
              <DirectSaleSummary control={control} />
            </CardNew>
          )
        },
        {
          title: t("details"),
          description: t("directSaleDetailsDescription"),
          content: (
            <CardNew
              button={
                <ButtonNew type={"submit"} loading={isSubmitting}>
                  {t("save")}
                </ButtonNew>
              }
            >
              <FormTextField
                label={t("ourReference")}
                {...register("sellerReference")}
              />
              <FormTextField
                label={t("yourReference")}
                {...register("customerReference")}
              />
              <FormTextField
                label={t("orderReference")}
                {...register("orderReference")}
              />
              <ControlledSelect
                className={"w-[250px]"}
                label={t("paymentMethod")}
                control={control}
                fieldName={"paymentMethod"}
                options={[
                  {
                    label: t("cashOrInvoice"),
                    value: "cash"
                  },
                  {
                    label: t("invoice"),
                    value: "invoice"
                  }
                ]}
              />
              <ControlledDatePicker
                type={"single"}
                control={control}
                fieldName={"date"}
                trigger={(ref, date) => (
                  <FormTextField
                    label={t("date")}
                    value={date}
                    wrapperClassName={"w-[250px]"}
                    className={"withoutReadonlyStyles"}
                    ref={ref as Ref<HTMLInputElement>}
                  />
                )}
              />
              <ControlledSwitch
                control={control}
                fieldName={"sendReceipt"}
                className={"mb-2"}
                label={t("sendReceiptOnEmail")}
              />
              <FormTextField
                disabled={!sendReceipt}
                errorMessage={errors.receiptEmail?.message}
                placeholder={"ola@nordmann.no"}
                wrapperClassName={"max-w-[250px]"}
                {...register("receiptEmail")}
              />
              <FormTextArea label={t("comment")} />
            </CardNew>
          )
        }
      ]}
    />
  );
}
