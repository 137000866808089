import { createFileRoute, Link } from "@tanstack/react-router";
import { useToast } from "../../hooks/useToast";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  respondToInvitation,
  useGetPendingUserInvitations
} from "../../service/api/InvitationApi";
import { UserInvitation } from "../../.generated/api";
import { t } from "i18next";
import { useGetUserProfile } from "../../service/api/UserApi";
import LoginLayout from "../_unauthenticated/-components/LoginLayout";
import CardNew from "../../components/ds/CardNew";
import { Link as RadixLink, Text } from "@radix-ui/themes";
import ButtonNew from "../../components/ds/ButtonNew";

export const Route = createFileRoute("/_protected/invitations")({
  component: InvitationsPage
});

function InvitationsPage() {
  const { showErrorToast, showSuccessToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const invitationsQuery = useGetPendingUserInvitations();
  const invitations = invitationsQuery.data ?? [];

  const userQuery = useGetUserProfile();
  const canNavigate =
    (userQuery.data?.companyConnections?.length ?? 0) > 0 &&
    (invitationsQuery.data?.length ?? 0) === 0;

  async function respondToInvite(
    invitation: UserInvitation,
    accepted: boolean
  ) {
    setIsSubmitting(true);
    try {
      await respondToInvitation(invitation.id, accepted);
      await queryClient.invalidateQueries(useGetUserProfile.getOptions());
      await invitationsQuery.refetch();
      if (accepted) {
        showSuccessToast(t("Invitasjon godtatt"));
      }
    } catch (_) {
      showErrorToast(t("Feilet i å akseptere invitasjonen"));
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <LoginLayout
      title={t("invitations")}
      subtitle={t("yourPendingInvitations")}
    >
      {invitations.length === 0 && (
        <Text align={"center"}>{t("noPendingInvitations")}</Text>
      )}
      <div className={"flex flex-col gap-4"}>
        {invitations.map((invite) => (
          <CardNew
            key={invite.id}
            button={
              <>
                <ButtonNew
                  variant={"soft"}
                  color={"red"}
                  disabled={isSubmitting}
                  onClick={() => respondToInvite(invite, false)}
                >
                  {t("decline")}
                </ButtonNew>
                <ButtonNew
                  disabled={isSubmitting}
                  onClick={() => respondToInvite(invite, true)}
                >
                  {t("accept")}
                </ButtonNew>
              </>
            }
          >
            <Text>
              {t("invitationText", {
                user: invite.invitedBy,
                employmentType: t(invite.employmentType),
                company: invite.company
              })}
            </Text>
          </CardNew>
        ))}
      </div>
      {canNavigate && (
        <RadixLink asChild className={"mt-10 mx-auto"}>
          <Link to={"/dashboard/project/overview"}>
            {t("continueToAdminPortal")}
          </Link>
        </RadixLink>
      )}
    </LoginLayout>
  );
}
