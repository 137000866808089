import { createFileRoute, useParams } from "@tanstack/react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../../components/common/PageTitle";
import AddSingleProduct from "../../../../../../pages/projectDetails/projectProducts/components/AddSingleProduct";
import AddProductBundle from "../../../../../../pages/projectDetails/projectProducts/components/AddProductBundle";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/products/add"
)({
  component: AddProductToOrder
});

function AddProductToOrder() {
  const [isBundles, setIsBundles] = useState<"singleProduct" | "productGroups">(
    "singleProduct"
  );
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/products/add"
  });
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full justify-between">
        <PageTitle title={t("addFromProductCatalog")} />
      </div>
      {isBundles == "singleProduct" ? (
        <AddSingleProduct
          isBundles={isBundles}
          setIsBundles={setIsBundles}
          resourceId={orderId}
          resourceType="order"
        />
      ) : (
        <AddProductBundle
          isBundles={isBundles}
          setIsBundles={setIsBundles}
          orderId={orderId}
        />
      )}
    </>
  );
}
