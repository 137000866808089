import { createFileRoute } from "@tanstack/react-router";
import Navigation from "../../components/navigation/Navigation";
import { getCurrentUser } from "../../firebase";
import { resolveCompany } from "../../hooks/useCompanyResolver";

export const Route = createFileRoute("/_protected/dashboard")({
  beforeLoad: async ({ context: { queryClient } }) => {
    await getCurrentUser();
    const company = await resolveCompany(queryClient);
    return {
      companyId: company ?? ""
    };
  },
  component: Navigation
});
