import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { UpsertProductBundle } from "./-components/UpsertProductBundle";

export const Route = createFileRoute(
  "/_protected/dashboard/products/bundles/new"
)({
  component: NewProductBundlePage
});

function NewProductBundlePage() {
  return <UpsertProductBundle />;
}
