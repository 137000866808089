import { createFileRoute, useParams } from "@tanstack/react-router";
import ChecklistPage from "../../../../../../pages/projectDetails/checklist/ChecklistPage";
import PageLayout from "../../../../../../components/PageLayout";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout/checklist"
)({
  component: OrderChecklistPage
});

function OrderChecklistPage() {
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/_layout/checklist"
  });

  return (
    <PageLayout>
      <ChecklistPage resourceId={orderId} resourceType={"order"} />
    </PageLayout>
  );
}
