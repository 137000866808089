import { Popover as RadixPopover } from "@radix-ui/themes";
import React, { ReactNode, useEffect, useId, useState } from "react";

type PopoverProps = {
  trigger: ReactNode;
  children: ReactNode;
  className?: string;
};

type PopoverCloseProps = {
  children: ReactNode;
};

export function PopoverClose({ children }: PopoverCloseProps) {
  return <RadixPopover.Close>{children}</RadixPopover.Close>;
}

export default function Popover({
  trigger,
  children,
  className
}: PopoverProps) {
  const [open, setOpen] = useState(false);
  const id = useId();

  useEffect(() => {
    if (open) {
      const element = document.getElementById(id);
      element?.style.removeProperty("--radix-popover-trigger-width");
    }
  }, [open]);

  return (
    <RadixPopover.Root onOpenChange={setOpen}>
      <RadixPopover.Trigger>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Content id={id} className={className}>
        {children}
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
}
