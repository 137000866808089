import { createFileRoute, useParams } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import useDialog from "../../../../../../../hooks/useDialog";
import React, { useState } from "react";
import { FileResponse } from "../../../../../../../.generated/api";
import { useGetRootFoldersOnResource } from "../../../../../../../service/api/FolderApi";
import { useGetFilesByFolderId } from "../../../../../../../service/api/FileApi";
import PageLayout from "../../../../../../../components/PageLayout";
import ContractPreview from "./-components/ContractPreview";
import ContractDialog from "./-components/ContractDialog";
import CardNew from "../../../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract"
)({
  component: ProjectEconomyContractPage
});

function ProjectEconomyContractPage() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialog();
  const [selectedFile, setSelectedFile] = useState<FileResponse>();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  const folderQuery = useGetRootFoldersOnResource({
    variables: { resourceId: projectId }
  });

  const contractsFolder = folderQuery.data?.find(
    (f) => f.name === "Kontrakter"
  );

  const fileQuery = useGetFilesByFolderId({
    variables: { folderId: contractsFolder?.id ?? "", resourceId: projectId },
    enabled: contractsFolder !== undefined
  });

  return (
    <PageLayout
      previewOffset={"regularWithTabBar"}
      withoutPadding
      preview={
        selectedFile && (
          <ContractPreview
            file={selectedFile}
            onPreviewClose={() => setSelectedFile(undefined)}
            onUpdated={(file) => {
              setSelectedFile(file);
              fileQuery.refetch();
            }}
          />
        )
      }
    >
      <ContractDialog
        open={isOpen}
        onClose={onClose}
        projectId={projectId}
        contractFolderId={contractsFolder?.id}
        onUpdated={async () => {
          onClose();
          await folderQuery.refetch();
          await fileQuery.refetch();
        }}
      />
      <CardNew
        title={t("contracts")}
        trailing={
          <ButtonNew size={"1"} variant={"soft"} onClick={onOpen}>
            {t("add")}
          </ButtonNew>
        }
        inset
      >
        <Table variant={"ghost"}>
          <TableBody>
            {!fileQuery.data && !fileQuery.isLoading && (
              <TableRow>
                <TableCell>{t("noContractsUploaded")}</TableCell>
              </TableRow>
            )}
            {fileQuery.data?.map((file) => (
              <TableRow key={file.id}>
                <TableCell>
                  <ButtonNew
                    variant={"ghost"}
                    highContrast
                    color={"gray"}
                    onClick={() => {
                      setSelectedFile(file);
                    }}
                  >
                    {file.name}
                  </ButtonNew>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
