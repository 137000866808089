import {
  TimeApprovalRequest,
  TimeEntriesApi,
  TimeEntryPostRequest,
  TimeEntryPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { instance } from "./TimekeepingModuleHttpBase";
import { createQuery } from "react-query-kit";
import { SortDirection } from "../../hooks/useSort";

export type TimeEntrySortField = "userId" | "date" | "isSynced" | "isInvoiced";
export type TimeApprovalFilter =
  | "approved"
  | "notApproved"
  | "invoiced"
  | "notInvoiced"
  | "transferred"
  | "notTransferred";

const timeEntryApi = new TimeEntriesApi(
  undefined,
  environment.skwApiNewBaseUrl,
  instance
);

export const useGetPaginatedTimeEntriesByCompanyId = createQuery({
  queryKey: ["getPaginatedTimeEntriesByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page: number;
    userId?: string[];
    resourceId?: string;
    approved?: TimeApprovalFilter;
    from?: Date;
    to?: Date;
    sortField?: TimeEntrySortField;
    sortDirection?: SortDirection;
    pageSize?: number;
  }) => {
    const response = await timeEntryApi.getCompanyTimeEntriesPaginated(
      variables.companyId,
      variables.from?.toISOString(),
      variables.to?.toISOString(),
      variables.approved,
      variables.userId,
      variables.resourceId,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      undefined,
      variables.pageSize
    );
    return response.data;
  }
});

export const useGetTimeEntriesByResourceId = createQuery({
  queryKey: ["getTimeEntriesByResourceId"],
  fetcher: async (variables: {
    companyId: string;
    resourceId: string;
    from?: Date;
    to?: Date;
  }) => {
    const response = await timeEntryApi.getTimeEntriesByResourceId(
      variables.companyId,
      variables.resourceId,
      variables.from?.toISOString(),
      variables.to?.toISOString()
    );
    return response.data;
  }
});

export const useGetTotalTimeForProject = createQuery({
  queryKey: ["getTotalTimeForProject"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await timeEntryApi.getTotalTimeSubmitted(
      variables.projectId
    );
    return response.data;
  }
});

export async function getTimeEntriesByResourceId(
  companyId: string,
  resourceId: string,
  fromDate?: Date,
  toDate?: Date
) {
  const response = await timeEntryApi.getTimeEntriesByResourceId(
    companyId,
    resourceId,
    fromDate?.toISOString(),
    toDate?.toISOString()
  );
  return response.data;
}

export async function updateTimeEntry(
  companyId: string,
  timeEntryId: string,
  request: TimeEntryPutRequest
) {
  return timeEntryApi.updateTimeEntry(companyId, timeEntryId, request);
}

export async function postTimeEntryOnUser(
  companyId: string,
  userId: string,
  timeEntryPostRequest: TimeEntryPostRequest
) {
  return (
    await timeEntryApi.postTimeEntryOnUser(
      companyId,
      userId,
      timeEntryPostRequest
    )
  ).data;
}

export async function deleteTimeEntryOnUser(
  id: string,
  userId: string,
  companyId: string
) {
  await timeEntryApi.deleteTimeEntryOnUser(id, userId, companyId);
}

export async function updateTimeEntryApproval(
  companyId: string,
  request: TimeApprovalRequest
) {
  await timeEntryApi.updateTimeEntryApproval(companyId, request);
}

export async function markTimeEntriesAsInvoiced(
  companyId: string,
  ids: string[]
) {
  await timeEntryApi.updateTimeEntryInvoiced(companyId, {
    timeEntryIds: ids
  });
}

export async function syncTimeEntries(companyId: string) {
  await timeEntryApi.syncHours(companyId);
}
