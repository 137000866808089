import { createFileRoute } from "@tanstack/react-router";
import UpsertCustomerPage from "./-components/UpsertCustomerPage";

export const Route = createFileRoute("/_protected/dashboard/customers/new")({
  component: CreateCustomerPage
});

function CreateCustomerPage() {
  return <UpsertCustomerPage />;
}
