import { persist } from "zustand/middleware";
import { CompanyNameCurrencyEnum, User } from "../.generated/api";
import { createWithEqualityFn as create } from "zustand/traditional";

export type PageSize = 10 | 15 | 25 | 50;

export type CompanyInfo = {
  name: string;
  id: string;
  orgNumber: string;
  contactPerson: string;
  email?: string;
  phone?: string;
  freeTrialDaysRemaining: number;
  subscription?: "FREE_TRIAL" | "PAYED" | "EXPIRED";
  features: {
    sales: boolean;
    projects: boolean;
    orders: boolean;
    products: boolean;
    tasks: boolean;
    resourcePlanner: boolean;
    timekeeping: boolean;
  };
};

export type Theme = "dark" | "light";

type AppStore = {
  selectedCompany?: CompanyInfo;
  setSelectedCompany: (company: CompanyInfo) => void;
  pageSize: PageSize;
  setPageSize: (size: PageSize) => void;
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  user: User;
  setUser: (user: User) => void;
  currency: CompanyNameCurrencyEnum;
  setCurrency: (currency: CompanyNameCurrencyEnum) => void;
};

export const useAppStore = create<AppStore>()(
  persist(
    (set) => ({
      setSelectedCompany: (company) => {
        set(() => ({ selectedCompany: company }));
      },
      setCurrency: (currency) => {
        set(() => ({ currency }));
      },
      setIsLoading: (isLoading) => set(() => ({ isLoading })),
      setUser: (user) => set(() => ({ user: user })),
      setTheme: (theme) => set(() => ({ theme: theme })),
      setPageSize: (size) => set(() => ({ pageSize: size })),
      pageSize: 15,
      projectFilter: "all",
      theme: "light",
      selectedProjectView: "list",
      isLoading: false,
      menuState: "open",
      currency: "NOK",
      user: {
        id: "",
        email: "",
        loginOption: "google",
        status: "active"
      }
    }),
    {
      name: "company",
      partialize: (s) => ({
        selectedCompany: s.selectedCompany,
        pageSize: s.pageSize,
        currency: s.currency,
        user: s.user
      })
    }
  )
);
