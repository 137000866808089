import { useState } from "react";

export type UseSelectedReturn<T> = ReturnType<typeof useSelectedGeneric<T>>;

export function useSelectedGeneric<T>(key: keyof T & string) {
  const [selected, setSelected] = useState<Map<T[keyof T & string], T>>(
    new Map()
  );

  function clear() {
    setSelected(new Map());
  }

  function handleSelect(item: T) {
    if (selected.has(item[key])) {
      selected.delete(item[key]);
      setSelected(new Map(selected));
    } else {
      setSelected(new Map(selected.set(item[key], item)));
    }
  }

  function allSelected(items: T[]) {
    if (items.every((i) => selected.has(i[key])) && selected.size > 0) {
      return true;
    } else if (items.some((i) => selected.has(i[key]))) {
      return "indeterminate";
    } else {
      return false;
    }
  }

  function handleSelectAll(items: T[]) {
    const allSelected = items.every((i) => selected.has(i[key]));

    if (allSelected) {
      items.forEach((i) => selected.delete(i[key]));
      setSelected(new Map(selected));
    } else {
      items.forEach((i) => selected.set(i[key], i));
      setSelected(new Map(selected));
    }
  }

  function isSelected(item: T) {
    return selected.has(item[key]);
  }

  return {
    handleSelect,
    handleSelectAll,
    allSelected,
    isSelected,
    clear,
    selected: [...selected.values()]
  };
}

export function useSelected() {
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const anySelected = selected.size > 0;

  function handleSelect(item: string) {
    if (selected.has(item)) {
      selected.delete(item);
      setSelected(new Set(selected));
    } else {
      setSelected(new Set(selected.add(item)));
    }
  }

  function handleSelectAll(items: string[]) {
    const allSelected = items.every((i) => selected.has(i));
    if (allSelected) {
      setSelected(new Set());
    } else {
      setSelected(new Set([...selected, ...items]));
    }
  }

  function allSelected(items: string[]) {
    return items.every((i) => selected.has(i)) && selected.size > 0;
  }

  function resetSelected() {
    setSelected(new Set());
  }

  function isSelected(item: string) {
    return selected.has(item);
  }

  return {
    selected: [...selected],
    handleSelect,
    handleSelectAll,
    resetSelected,
    isSelected,
    allSelected,
    anySelected
  };
}
