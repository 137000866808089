import React, { useEffect, useState } from "react";
import { Text } from "@radix-ui/themes";

import Combobox from "./ds/Combobox/Combobox";
import useDialog from "../hooks/useDialog";
import RadixIcon from "./ds/RadixIcon";
import { useSearchCompanyProjects } from "../service/api/ProjectApiV2";
import { useCompanyId } from "../hooks/useCompanyId";
import { useNavigate } from "@tanstack/react-router";
import { keepPreviousData } from "@tanstack/react-query";

import { useGetCustomersByCompanyId } from "../service/api/CustomerApiV3";
import {
  SalesOpportunitySortFields,
  useGetSalesOpportunitiesByCompanyId
} from "../service/api/SalesOpportunityApi";
import { useSort } from "../hooks/useSort";

import { ComboboxItem } from "./ds/Combobox/ComboboxItem";
import Badge from "./ds/Badge";
import { useTranslation } from "react-i18next";
import SkyworkerChatDialog, {
  SkyworkerAIIcon
} from "./dialogs/SkyworkerChatDialog";
import { useGetOrdersByCompanyIdPaginated } from "../service/api/OrderApi";

const AI_ENABLED = localStorage.getItem("feature--ai-enabled") ?? false; // Will be enabled when the AI is ready

export default function GlobalSearchWidget() {
  const [loading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { isOpen, onOpen, onClose } = useDialog();

  const { result } = useGlobalSearch(searchQuery);
  const { t } = useTranslation();

  const { updateWithItem, items } = useRecentNavigationHistory();

  const navigate = useNavigate();
  const handleNavigate = (item: NavigationItem) => {
    updateWithItem(item);
    navigate({ to: item.link });
  };

  const resultGroups = searchQuery
    ? [
        { title: t("customers"), items: result.customers },
        { title: t("projects"), items: result.projects },
        { title: t("orders"), items: result.orders },
        { title: t("salesOpportunities"), items: result.sales }
      ]
    : [
        {
          title: t("Historikk"),
          items: items
        }
      ];

  return (
    <>
      <SkyworkerChatDialog isOpen={isOpen} onClose={onClose} />

      <Combobox
        skipLocalFiltering
        clearOnSelect
        loading={loading}
        getLabel={(option) => option.description}
        getValue={(option) => option.data}
        onInputChange={(value) => setSearchQuery(value.trim())}
        options={[{ description: "", data: "" }]} // Dummy data
        customRender={() => {
          return [
            AI_ENABLED && (
              <ComboboxItem value="" onClick={onOpen} key="ai">
                <SkyworkerAIIcon className={"mr-2"} />
                <Text size={"2"}>{"Skyworker AI"}</Text>
              </ComboboxItem>
            ),

            resultGroups
              .map((group) => {
                if (group.items.length === 0) {
                  return [];
                }
                return [
                  <Text
                    size="1"
                    as="div"
                    className={"pl-3 mt-3 mb-1 text-radix-gray-a9"}
                    key={group.title}
                  >
                    {group.title}
                  </Text>,
                  ...group.items.map((item) => (
                    <NavigationItemComponent
                      item={item}
                      query={searchQuery}
                      key={item.link}
                      onClick={() => handleNavigate(item)}
                    />
                  ))
                ];
              })
              .flat()
          ];
        }}
        maxHeight="calc(100vh - 100px)"
        value={searchQuery}
        placeholder={t("find_customers_projects_etc")}
        className={"w-[350px]"}
      />
    </>
  );
}

const NavigationItemComponent = ({
  item,
  query,
  onClick
}: {
  item: NavigationItem;
  query?: string;
  onClick: () => void;
}) => {
  return (
    <ComboboxItem onClick={onClick} value={item.link}>
      <RadixIcon icon={item.icon} />
      <Text size={"2"}>
        {formatString(item.title, query)}
        {item.titleExtra && " "}
        {item.titleExtra && formatString(item.titleExtra, query)}
      </Text>
      <div className="flex-1"></div>
      {item.badge && (
        <Badge color={"gray"}>
          <span>{formatString(item.badge, query)}</span>
        </Badge>
      )}
    </ComboboxItem>
  );
};

type NavigationItem = {
  icon: string;
  title: string;
  titleExtra?: string;
  link: string;
  badge?: string;
};

function useRecentNavigationHistory() {
  const storageKey = "globalSearch_recentHistory";

  const [items, setItems] = useState<NavigationItem[]>([]);

  useEffect(() => {
    try {
      const items = JSON.parse(localStorage.getItem(storageKey) ?? "[]");
      setItems(items);
    } catch (_) {}
  }, []);

  localStorage.getItem(storageKey);

  return {
    updateWithItem: (item: NavigationItem) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { titleExtra, ...itemWithoutTitleExtra } = item;
      const updateItems = [
        itemWithoutTitleExtra,
        ...items.filter((i) => i.link !== item.link)
      ].slice(0, 5);
      localStorage.setItem(storageKey, JSON.stringify(updateItems));

      setItems(updateItems);
    },
    items
  };
}

function useGlobalSearch(query: string): {
  loading: boolean;
  result: {
    projects: NavigationItem[];
    customers: NavigationItem[];
    orders: NavigationItem[];
    sales: NavigationItem[];
  };
} {
  const companyId = useCompanyId();

  const ignoreQuery = query.length < 2;

  const projectSearchQuery = useSearchCompanyProjects({
    variables: {
      companyId,
      query,
      page: 0
    },
    placeholderData: keepPreviousData,
    enabled: !ignoreQuery
  });

  const customerQuery = useGetCustomersByCompanyId({
    variables: {
      companyId,
      sortField: "CustomerNumber",
      sortDirection: "DESC",
      query
    },
    placeholderData: keepPreviousData,
    enabled: !ignoreQuery
  });

  const orderQuery = useGetOrdersByCompanyIdPaginated({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page: 0,
      sortDirection: "ASC",
      sortField: "Title",
      onlyCompleted: false,
      invoiced: false,
      onlyWithoutDate: false,
      query
    }
  });

  const { sortField, sortDirection, page } =
    useSort<SalesOpportunitySortFields>("Title", "salesOpportunity");
  const salesQuery = useGetSalesOpportunitiesByCompanyId({
    variables: {
      companyId,
      page,
      sortDirection,
      sortField,
      archived: false,
      query: query
    },
    placeholderData: keepPreviousData,
    enabled: !ignoreQuery
  });

  if (ignoreQuery) {
    return {
      loading: false,
      result: {
        projects: [],
        customers: [],
        orders: [],
        sales: []
      }
    };
  }

  return {
    loading: false,
    result: {
      projects:
        projectSearchQuery.data?.data?.map((project) => {
          return {
            icon: "layers",
            title: project.title,
            link: `/dashboard/project/${project.id}/details`,
            badge: `#${project.projectNumber}`
          };
        }) ?? [],
      customers:
        customerQuery.data?.data?.map((customer) => {
          const didMatchFormattedCustomerName = testMatch(
            customer.formattedCustomerName,
            query
          );
          const didMatchCustomerNumber = testMatch(
            `${customer.customerNumber}`,
            query
          );

          const titleExtra =
            !(didMatchFormattedCustomerName || didMatchCustomerNumber) &&
            customer.primaryContact
              ? `(${customer.primaryContact.firstName} ${customer.primaryContact.lastName})`
              : undefined;

          return {
            icon: customer.type === "Corporate" ? "business" : "group",
            title: customer.formattedCustomerName,
            titleExtra,
            link: `/dashboard/customers/${customer.id}`,
            badge: `#${customer.customerNumber}`
          };
        }) ?? [],
      orders:
        orderQuery.data?.data?.map((order) => {
          return {
            icon: "local_shipping",
            title: order.title,
            link: `/dashboard/orders/${order.id}/details`,
            badge: `#${order.orderNumber}`
          };
        }) ?? [],
      sales:
        salesQuery.data?.data?.map((sale) => {
          return {
            icon: "paid",
            title: sale.title,
            link: `/dashboard/sales/${sale.id}`,
            badge: ""
          };
        }) ?? []
    }
  };
}

function testMatch(str: string, query: string) {
  return str.toLowerCase().indexOf(query.toLowerCase()) !== -1;
}

function formatString(str: string, query: string | undefined = "") {
  const index = str.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) {
    return str;
  }

  return (
    <>
      {str.slice(0, index)}
      <strong>{str.slice(index, index + query.length)}</strong>
      {str.slice(index + query.length)}
    </>
  );
}
