import { createFileRoute, useParams } from "@tanstack/react-router";
import PageLayout from "../../../../../../components/PageLayout";
import DeviationPage from "../../../../../../components/common/deviation/DeviationPage";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout/deviation"
)({
  component: OrderDeviationPage
});

function OrderDeviationPage() {
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/_layout/deviation"
  });

  return (
    <PageLayout>
      <DeviationPage resourceId={orderId} resourceType={"order"} />
    </PageLayout>
  );
}
