import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import DocumentationTab from "../../../../../../pages/documentation/DocumentationTab";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/documentation"
)({
  component: ProjectDocumentationPage
});

function ProjectDocumentationPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <DocumentationTab
      resourceId={project.id}
      resourceName={project.title}
      resourceType={"project"}
      project={project}
    />
  );
}
