import i18n from "i18next";
import { useAppStore } from "../state/app_store";

export function formatCurrency(value?: number | string) {
  const currency = useAppStore.getState().currency;

  return Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: currency
  }).format(Number(value) ?? 0);
}

export function formatDecimal(value?: number, decimals?: number) {
  return Intl.NumberFormat(i18n.language, {
    ...(decimals !== undefined
      ? {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }
      : {})
  }).format(value ?? 0);
}
