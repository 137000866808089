import { createFileRoute, useParams } from "@tanstack/react-router";
import { EhsItemResourceTypeEnum } from "../../../../../../.generated/api";
import EhsView from "../../../../../../pages/ehs/EhsView";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/ehs"
)({
  component: ProjectEhsPage
});

function ProjectEhsPage() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <div className={"m-5"}>
      <EhsView
        resourceId={projectId}
        resourceType={EhsItemResourceTypeEnum.Project}
        displayBackButton={false}
      />
    </div>
  );
}
