import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useRouter,
  useSearch
} from "@tanstack/react-router";
import { customerDetailsTabs } from "../../../../../router/Routes";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import Button from "../../../../../components/ds/Button";
import { TypedTab } from "../../../../../components/ds/Tab";
import CustomerOverviewTab from "../../../../../pages/customers/customerOverview/CustomerOverviewTab";
import CustomerContactTab from "../../../../../pages/customers/customerContacts/CustomerContactTab";
import CustomerEconomyTab from "../../../../../pages/customers/customerEconomy/CustomerEconomyTab";
import CustomerSalesTab from "../../../../../pages/customers/customerSales/CustomerSalesTab";
import DocumentationTab from "../../../../../pages/documentation/DocumentationTab";
import CustomerProjectTab from "../../../../../pages/customers/customerProjects/CustomerProjectTab";
import CustomerOrderTab from "../../../../../pages/customers/customerOrders/CustomerOrderTab";
import FacilityTab from "../../../../../pages/employeesPage/facility/FacilityTab";
import React from "react";
import { useGetRelationIdsByCustomerId } from "../../../../../service/api/CustomerRelationApiV3";
import { z } from "zod";
import { useGetCustomerById } from "../../../../../service/api/CustomerApiV3";

const customerDetailsSchema = z.object({
  tab: customerDetailsTabs.catch("overview").optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/"
)({
  validateSearch: customerDetailsSchema,
  loader: ({ context: { queryClient, companyId }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerById.getOptions({ customerId, companyId })
    });
  },
  component: CustomerDetailsPage
});

function CustomerDetailsPage() {
  const { customerId } = useParams({
    from: "/_protected/dashboard/customers/$customerId/"
  });
  const { tab } = useSearch({
    from: "/_protected/dashboard/customers/$customerId/"
  });
  const customer = useLoaderData({
    from: "/_protected/dashboard/customers/$customerId/"
  });

  const companyId = useCompanyId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const router = useRouter();

  const projectIdsQuery = useGetRelationIdsByCustomerId({
    variables: {
      companyId,
      customerId: customerId,
      resourceType: "Project"
    }
  });

  const orderIdsQuery = useGetRelationIdsByCustomerId({
    variables: {
      companyId,
      customerId,
      resourceType: "Order"
    }
  });

  return (
    <div className={"p-5"}>
      <div className="mb-6">
        <div className="flex items-center justify-between w-full gap-2 min-h-12">
          <div className="flex items-center">
            {/*TODO: PageTitle*/}
            <Link to={"/dashboard/customers"}>
              <IconButton
                icon="arrow_back_ios_new"
                variant={"ghost"}
                size={"3"}
              />
            </Link>
            <h1 className="font-semibold ml-4">
              {customer?.companyName
                ? customer.companyName
                : customer.formattedCustomerName}
            </h1>
            {customer?.orgNumber && (
              <h1 className="font-semibold text-system-neutral-90 pl-4">{`(Org nr: ${customer.orgNumber})`}</h1>
            )}
          </div>
          {tab === "projects" && (
            <Link
              to={"/dashboard/customers/$customerId/project/new"}
              params={{ customerId: customer.id }}
            >
              <Button icon="add">{t("createProject")}</Button>
            </Link>
          )}
          {tab === "orders" && (
            <Button icon="add">
              <Link
                to={"/dashboard/customers/$customerId/order/new"}
                params={{
                  customerId: customer.id
                }}
              >
                {t("createOrder")}
              </Link>
            </Button>
          )}
        </div>
      </div>
      <div className="pb-8">
        <TypedTab
          tabs={customerDetailsTabs.options}
          getLabel={(tab) => t(tab)}
          selected={tab}
          onChange={async (tab) => {
            await navigate({
              to: "/dashboard/customers/$customerId",
              search: { tab: tab },
              params: {
                customerId: customer.id
              }
            });
          }}
        >
          <CustomerOverviewTab key={0} customer={customer} />

          <CustomerContactTab
            key={1}
            customer={customer}
            refetch={router.invalidate}
          />
          <CustomerEconomyTab key={2} customer={customer} />
          <CustomerSalesTab key={3} customer={customer} />
          <DocumentationTab resourceId={customer.id} resourceName="" />
          <CustomerProjectTab
            key={4}
            customer={customer}
            projectIds={Array.from(projectIdsQuery.data ?? [])}
          />
          <CustomerOrderTab
            key={5}
            customerId={customer.id}
            orderIds={Array.from(orderIdsQuery.data ?? [])}
          />
          <FacilityTab key={6} customerId={customer.id} />
        </TypedTab>
      </div>
    </div>
  );
}
