import { createFileRoute, useParams } from "@tanstack/react-router";
import PageTitle from "../../../../../../components/common/PageTitle";
import OfferDetailsView from "../../../../../../pages/sales/salesOppertunity/details/offer/OfferDetailsView";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/offer/$offerId/additional-work"
)({
  component: AdditionalWorkOfferPage
});

function AdditionalWorkOfferPage() {
  const { offerId } = useParams({
    from: "/_protected/dashboard/sales/offer/$offerId/additional-work"
  });

  return (
    <>
      <PageTitle title={"Tilleggsarbeid"} hasBackFunction />
      <OfferDetailsView offerId={offerId} canBeEdited={false} />
    </>
  );
}
