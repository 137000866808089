import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { UpsertEhsPage } from "../-components/UpsertEhsPage";
import { z } from "zod";

const ehsTemplateSchema = z.object({
  templateId: z.string().optional(),
  isFromProject: z.boolean().optional()
});

export const Route = createFileRoute("/_protected/dashboard/ehs/template/new")({
  validateSearch: ehsTemplateSchema,
  component: CreateEhsTemplatePage
});

function CreateEhsTemplatePage() {
  return <UpsertEhsPage templateEdit />;
}
