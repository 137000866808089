import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import React, { useState } from "react";
import { ProjectV2 } from "../../../../../.generated/api";
import { useSort } from "../../../../../hooks/useSort";
import { useSearchCompanyProjects } from "../../../../../service/api/ProjectApiV2";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { taskQueries } from "../../../../../service/api/TaskApiV2";
import { useTranslation } from "react-i18next";
import PageLayout from "../../../../../components/PageLayout";
import { ColumnLayout } from "../../../../../components/layouts/ColumnLayout";
import Card from "../../../../../components/ds/Card";
import Button from "../../../../../components/ds/Button";
import TextField from "../../../../../components/ds/TextField";
import AutoComplete from "../../../../../components/inputs/AutoComplete";
import TaskMultipleOptions from "../../../../../pages/project/components/TaskMultipleOptions";
import Pagination from "../../../../../components/ds/Pagination";
import TaskDialog from "../../../../../components/dialogs/task/TaskDialog";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";
import TaskTableContainer from "../../../../../pages/project/components/TaskTableContainer";

export const Route = createFileRoute(
  "/_protected/dashboard/project/_layout/task"
)({
  component: RouteComponent
});

function RouteComponent() {
  const companyId = useCompanyId();
  const { onClose, onOpen, isOpen } = useDialog();
  const [projectSearchQuery, setProjectSearchQuery] = useState("");
  const [selectedProject, setSelectedProject] = useState<ProjectV2>();
  const [selectedTasks, setSelectedTasks] = useState<Set<string>>(new Set());
  const {
    page,
    setPage,
    query,
    setQuery,
    sortField,
    sortDirection,
    registerHeader
  } = useSort<
    | "Created"
    | "Title"
    | "ProjectTitle"
    | "FromDateTime"
    | "ToDateTime"
    | "Status"
    | "PhaseTitle"
  >("Created", "tasks");

  const projectQuery = useSearchCompanyProjects({
    variables: {
      companyId,
      query: projectSearchQuery
    }
  });

  const taskQuery = useQuery({
    ...taskQueries.getByCompanyId(
      companyId,
      sortField,
      sortDirection,
      page,
      query,
      selectedProject?.id
    ),
    placeholderData: keepPreviousData
  });

  const { t } = useTranslation();

  return (
    <PageLayout>
      <ColumnLayout columns={1}>
        <Card
          title={t("allTasks")}
          trailing={
            <Button isSmall icon={"add"} onClick={onOpen}>
              {t("addTask")}
            </Button>
          }
          headerContent={
            <div>
              <div className={"flex gap-2"}>
                <TextField
                  icon={"search"}
                  placeholder={t("searchForTask")}
                  isBottomPadding={false}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <AutoComplete
                  className={"max-w-[300px]"}
                  isBottomPadding={false}
                  value={selectedProject}
                  options={projectQuery?.data?.data ?? []}
                  onInputChange={setProjectSearchQuery}
                  onChange={(project) => {
                    setSelectedProject(project);
                    setPage(0);
                  }}
                  getLabel={(v) => v.title}
                  getKey={(v) => v.id}
                  placeholder={t("allProjects")}
                />
              </div>
              <TaskMultipleOptions
                taskIds={Array.from(selectedTasks)}
                onUpdated={() => {
                  setSelectedTasks(new Set());
                  taskQuery.refetch();
                }}
              />
            </div>
          }
          footerContent={
            <Pagination
              page={page}
              totalPages={taskQuery.data?.totalPages ?? 0}
              setPage={setPage}
            />
          }
        >
          <TaskDialog
            onClose={onClose}
            open={isOpen}
            onTaskAdded={() => {
              taskQuery.refetch();
              onClose();
            }}
          />
          <NewDataFetcher
            query={taskQuery}
            onData={(tasks) => (
              <TaskTableContainer
                tasks={tasks}
                selectedTasks={selectedTasks}
                sortFn={registerHeader}
                onSelectedChange={setSelectedTasks}
                refetch={taskQuery.refetch}
                isInProject={false}
              />
            )}
          />
        </Card>
      </ColumnLayout>
    </PageLayout>
  );
}
