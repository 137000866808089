import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useGetCustomerById } from "../../../../../service/api/CustomerApiV3";
import UpsertProjectPage from "../../project/-components/UpsertProjectPage";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/project/new"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { customerId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetCustomerById.getOptions({ customerId, companyId })
    });
  },
  component: CustomerCreateProjectPage
});

function CustomerCreateProjectPage() {
  const customer = useLoaderData({
    from: "/_protected/dashboard/customers/$customerId/project/new"
  });

  return <UpsertProjectPage customer={customer} />;
}
