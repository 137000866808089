import { SegmentedControl, Separator, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeEntry } from "../../../../../.generated/api";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import Dialog from "../../../../../components/ds/Dialog";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useSelectedGeneric } from "../../../../../hooks/useSelected";
import { useToast } from "../../../../../hooks/useToast";
import { getNumericValue } from "../../../../../pages/timeInvoice/timekeeping/utils";
import { useGetHourlyRatesByCustomerId } from "../../../../../service/api/HourlyRateApi";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import { InvoiceLineData } from "../invoice.new";
import InvoiceDialogProducts, {
  GenericProductConsumption
} from "./InvoiceDialogProducts";
import InvoiceDialogTimeEntries from "./InvoiceDialogTimeEntries";

type InvoiceDialogProps = {
  open: boolean;
  onClose: () => void;
  resourceId: string;
  resourceType: "Order" | "Project" | "Other";
  customerId: string;
  onAdd: (lines: InvoiceLineData[]) => void;
};

export default function InvoiceDialog({
  open,
  onClose,
  customerId,
  resourceId,
  resourceType,
  onAdd
}: InvoiceDialogProps) {
  const [selectedTab, setSelectedTab] = useState("hours");
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const { showSuccessToast } = useToast();
  const [isAdding, setIsAdding] = useState(false);
  const queryClient = useQueryClient();

  const productSelection =
    useSelectedGeneric<GenericProductConsumption>("entryId");
  const timeSelection = useSelectedGeneric<TimeEntry>("id");
  const canAdd =
    productSelection.selected.length > 0 || timeSelection.selected.length > 0;

  useEffect(() => {
    if (!open) {
      productSelection.clear();
      timeSelection.clear();
    }
  }, [open]);

  async function handleAdd() {
    setIsAdding(true);
    const rate = await queryClient.ensureQueryData(
      useGetHourlyRatesByCustomerId.getOptions({ companyId, customerId })
    );

    const hourPrice = rate?.hourlyRate;
    const sortedProducts = productSelection.selected.sort((a, b) =>
      a.added.localeCompare(b.added)
    );
    const sortedTimeEntries = timeSelection.selected.sort((a, b) =>
      a.date.localeCompare(b.date)
    );
    const productLines = sortedProducts.map(
      (product) =>
        ({
          productNumber: product.productName,
          description: `${formatFullNumericDateString(product.added)} - ${product.productName}`,
          count: product.quantity,
          price: product.price,
          unit: product.unit,
          discount: 0,
          vat: "25",
          isFreeText: false
        }) satisfies InvoiceLineData
    );

    const timeEntryLines = sortedTimeEntries.map((entry) => {
      const value = getNumericValue(entry.valueType, [entry]);
      const unit = entry.valueType === "Kilometers" ? "Kilometer" : "Timer";
      const description = entry.description ? ` - ${entry.description}` : "";
      return {
        productNumber: entry.activityName,
        description: `${formatFullNumericDateString(entry.date)}${description}`,
        count: value,
        price: hourPrice ?? entry.hourlyRate,
        unit: unit,
        discount: 0,
        vat: "25",
        isFreeText: false,
        resource: {
          id: entry.id,
          type: "TimeEntry"
        }
      } satisfies InvoiceLineData;
    });

    onAdd([...productLines, ...timeEntryLines]);
    setIsAdding(false);
    showSuccessToast(t("linesAdded"));
    onClose();
  }

  return (
    <Dialog
      title={t("fetchInvoiceData")}
      onClose={onClose}
      open={open}
      maxWidth={"xl"}
      withCloseButton
      buttons={
        <ButtonNew disabled={!canAdd} onClick={handleAdd} loading={isAdding}>
          {t("addData")}
        </ButtonNew>
      }
    >
      <div className={"flex items-center gap-6 mb-4"}>
        <SegmentedControl.Root
          className={""}
          value={selectedTab}
          onValueChange={setSelectedTab}
        >
          <SegmentedControl.Item value={"hours"}>
            {t("timeEntries")}
          </SegmentedControl.Item>
          <SegmentedControl.Item value={"products"}>
            {t("products")}
          </SegmentedControl.Item>
        </SegmentedControl.Root>
        <div className={"flex gap-2 items-center"}>
          <Text color={"gray"} size={"2"}>
            {/*TODO: translation*/}
            {timeSelection.selected.length} timeføringer valgt
          </Text>
          <Separator orientation={"vertical"} />
          <Text color={"gray"} size={"2"}>
            {productSelection.selected.length} produkter valgt
          </Text>
        </div>
      </div>
      {selectedTab === "hours" && (
        <InvoiceDialogTimeEntries
          selection={timeSelection}
          resourceId={resourceId}
        />
      )}
      {selectedTab === "products" && (
        <InvoiceDialogProducts
          selection={productSelection}
          resourceId={resourceId}
          resourceType={resourceType}
        />
      )}
    </Dialog>
  );
}
