import { createFileRoute, useParams, useSearch } from "@tanstack/react-router";
import { DeviationResourceTypeEnum } from "../../../../../../.generated/api";
import DeviationPage from "../../../../../../components/common/deviation/DeviationPage";
import React from "react";
import { z } from "zod";

const projectDeviationPageSchema = z.object({
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/deviation"
)({
  validateSearch: projectDeviationPageSchema,
  component: ProjectDeviationPage
});

function ProjectDeviationPage() {
  const { selectedDeviationId } = useSearch({
    from: "/_protected/dashboard/project/$projectId/_layout/deviation"
  });
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <DeviationPage
      resourceId={projectId}
      resourceType={DeviationResourceTypeEnum.Project}
      selectedDeviationId={selectedDeviationId}
    />
  );
}
