import React, { forwardRef } from "react";
import {
  Checkbox as RadixCheckbox,
  CheckboxProps as RadixCheckboxProps,
  Text
} from "@radix-ui/themes";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type ControlledCheckboxProps<T extends FieldValues> = CheckboxProps & {
  control: Control<T>;
  fieldName: Path<T>;
};

type CheckboxProps = RadixCheckboxProps & {
  label?: string;
};

export function ControlledCheckbox<T extends FieldValues>({
  control,
  fieldName,
  ...props
}: ControlledCheckboxProps<T>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value, ...fieldProps } }) => {
        return (
          <CheckboxNew
            checked={value}
            onCheckedChange={(e) => onChange(e)}
            {...props}
            {...fieldProps}
          />
        );
      }}
    />
  );
}

export const CheckboxNew = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ size = "3", label, ...props }, ref) => {
    return (
      <Text as={"label"} size={"2"} color={props.disabled ? "gray" : undefined}>
        <span className={"flex gap-2"}>
          <RadixCheckbox {...props} ref={ref} size={size} />
          {label}
        </span>
      </Text>
    );
  }
);
