import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { useGetProjectById } from "../../../../../service/api/ProjectApiV2";
import UpsertProjectPage from "../-components/UpsertProjectPage";
import { useGetLocationByResourceId } from "../../../../../service/api/LocationApi";
import { useGetCustomerByResourceId } from "../../../../../service/api/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({ params: { projectId }, context: { queryClient } }) => {
    const project = await queryClient.fetchQuery({
      ...useGetProjectById.getOptions({ projectId })
    });

    const customer = await queryClient.fetchQuery({
      ...useGetCustomerByResourceId.getOptions({
        resourceId: project.id,
        companyId: project.companyId
      })
    });

    const location = await queryClient.fetchQuery({
      ...useGetLocationByResourceId.getOptions({
        resourceId: projectId
      })
    });

    return {
      project,
      location,
      customer
    };
  },
  component: UpdateProjectPage
});

function UpdateProjectPage() {
  const { location, project, customer } = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/update"
  });

  return (
    <UpsertProjectPage
      project={project}
      location={location}
      customer={customer}
    />
  );
}
