import {
  createFileRoute,
  useLoaderData,
  useParams,
  useRouter
} from "@tanstack/react-router";
import useDialog from "../../../../../../../hooks/useDialog";
import { useToast } from "../../../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { useGetProjectEconomyOverview } from "../../../../../../../service/api/ProjectEconomyApi";
import { useGetTotalTimeForProject } from "../../../../../../../service/api/TimeEntryApi";
import InvoiceDialog from "./-components/InvoiceDialog";
import CardNew from "../../../../../../../components/ds/CardNew";
import DetailsCard from "../../../../overview/-components/DetailsCard";
import { formatCurrency } from "../../../../../../../utils/currencyFormatter";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";
import { calculatePercentagePart } from "./-components/utils";
import { formatFullNumericDateString } from "../../../../../../../utils/DateTimeFormatter";
import DeleteBlock from "../../../../../../../components/buttons/DeleteBlock";
import IconButton from "../../../../../../../components/ds/buttons/IconButton";
import React from "react";
import Badge from "../../../../../../../components/ds/Badge";
import {
  deletePlannedInvoice,
  useGetPlannedInvoicesByProjectId
} from "../../../../../../../service/api/PlannedInvoiceApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
)({
  loader: async ({ context: { queryClient }, params: { projectId } }) => {
    return await queryClient.fetchQuery(
      useGetPlannedInvoicesByProjectId.getOptions({ projectId })
    );
  },
  component: ProjectEconomyPage
});

function ProjectEconomyPage() {
  const { isOpen, onOpen, onClose } = useDialog();
  const { showSuccessToast } = useToast();
  const { t } = useTranslation();
  const router = useRouter();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const plannedInvoices = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
  });

  const overviewQuery = useGetProjectEconomyOverview({
    variables: {
      projectId
    }
  });

  const timeEntryQuery = useGetTotalTimeForProject({
    variables: {
      projectId
    }
  });

  const overview = overviewQuery.data;
  const time = timeEntryQuery.data;

  return (
    <>
      <InvoiceDialog
        open={isOpen}
        onClose={onClose}
        projectId={projectId}
        contractValue={overview?.estimatedContractValue}
        onCreated={() => {
          onClose();
          router.invalidate();
          showSuccessToast(t("invoiceCreated"));
        }}
      />
      <CardNew title={t("overview")} inset>
        <div className={"grid grid-cols-3"}>
          <DetailsCard
            title={t("invoiced")}
            number={formatCurrency(overview?.invoiced)}
            loading={overviewQuery.isLoading}
            subtitle={`${t("contractValue")} ${formatCurrency(overview?.estimatedContractValue)}`}
          />
          <DetailsCard
            title={t("priceType")}
            number={overview?.isHourly ? t("hourly") : t("fixedPrice")}
            loading={overviewQuery.isLoading}
          />
          <DetailsCard
            title={t("hoursRegistered")}
            number={`${time?.hours}${t("h")} ${time?.minutes}${t("m")}`}
            subtitle={`${t("estimatedHours")} ${overview?.estimatedHours}`}
            loading={timeEntryQuery.isLoading}
          />
          <DetailsCard
            title={t("estimatedHoursAdditionalWork")}
            number={overview?.estimatedHoursAdditionalWork?.toString()}
            loading={overviewQuery.isLoading}
          />
          <DetailsCard
            title={t("estimatedMaterialCost")}
            number={formatCurrency(overview?.estimatedMaterialCost)}
            loading={overviewQuery.isLoading}
          />
          <DetailsCard
            title={t("estimatedSubcontractorCost")}
            number={formatCurrency(overview?.estimatedSubcontractorCost)}
            loading={overviewQuery.isLoading}
          />
        </div>
      </CardNew>
      <CardNew
        className={"mt-5"}
        title={t("invoicePlan")}
        inset
        trailing={
          <ButtonNew variant={"soft"} size={"1"} onClick={onOpen}>
            {t("newInvoice")}
          </ButtonNew>
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>{t("amount")} (%)</TableHeaderCell>
              <TableHeaderCell>{t("deliveryDate")}</TableHeaderCell>
              <TableHeaderCell>{t("dueDate")}</TableHeaderCell>
              <TableHeaderCell>{t("status")}</TableHeaderCell>
              <TableHeaderCell>{t("customerReference")}</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {plannedInvoices.length === 0 && (
              <TableRow>
                <TableCell>{t("noInvoicesFound")}</TableCell>
              </TableRow>
            )}
            {plannedInvoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>
                  {formatCurrency(invoice.amount)} (
                  {calculatePercentagePart(
                    invoice.amount,
                    overview?.estimatedContractValue
                  )}
                  )
                </TableCell>
                <TableCell>
                  {formatFullNumericDateString(invoice.deliveryDate)}
                </TableCell>
                <TableCell>
                  {formatFullNumericDateString(invoice.dueDate)}
                </TableCell>
                <TableCell>
                  <Badge color={invoice.sent ? "green" : undefined}>
                    {invoice.sent ? t("sent") : t("notSent")}
                  </Badge>
                </TableCell>
                <TableCell>{invoice.customerReference}</TableCell>
                <TableCell align={"right"}>
                  <DeleteBlock
                    title={t("deleteInvoice")}
                    deleteFunction={() =>
                      deletePlannedInvoice(projectId, invoice.id)
                    }
                    confirmationText={t("deleteInvoiceConfirmation")}
                    onDeleted={() => {
                      router.invalidate();
                      showSuccessToast(t("invoiceDeleted"));
                    }}
                    render={(onClick) => (
                      <IconButton
                        className={"invisible group-hover/tr:visible"}
                        icon={"delete"}
                        onClick={onClick}
                        variant={"ghost"}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </>
  );
}
