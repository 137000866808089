/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ProjectBundleRequest } from '../model';
// @ts-ignore
import { ProjectProductRequest } from '../model';
// @ts-ignore
import { ResponseDTOListProduct } from '../model';
// @ts-ignore
import { ResponseDTOPaginatedProductConsumption } from '../model';
// @ts-ignore
import { ResponseDTOPaginatedProductConsumptionV2 } from '../model';
// @ts-ignore
import { ResponseDTOString } from '../model';
/**
 * ProjectProductApi - axios parameter creator
 * @export
 */
export const ProjectProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add products and the quantity used to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectProducts: async (companyId: string, projectId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addProjectProducts', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectProducts', 'projectId', projectId)
            // verify required parameter 'projectProductRequest' is not null or undefined
            assertParamExists('addProjectProducts', 'projectProductRequest', projectProductRequest)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add bundles of products to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectProductsFromBundle: async (companyId: string, projectId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addProjectProductsFromBundle', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectProductsFromBundle', 'projectId', projectId)
            // verify required parameter 'projectBundleRequest' is not null or undefined
            assertParamExists('addProjectProductsFromBundle', 'projectBundleRequest', projectBundleRequest)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/bundle`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectBundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add products and the quantity used to a project and specific task
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskProducts: async (companyId: string, projectId: string, taskId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addTaskProducts', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addTaskProducts', 'projectId', projectId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('addTaskProducts', 'taskId', taskId)
            // verify required parameter 'projectProductRequest' is not null or undefined
            assertParamExists('addTaskProducts', 'projectProductRequest', projectProductRequest)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/task/{taskId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add bundles of products to a specific task of a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskProductsFromBundle: async (companyId: string, projectId: string, taskId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addTaskProductsFromBundle', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addTaskProductsFromBundle', 'projectId', projectId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('addTaskProductsFromBundle', 'taskId', taskId)
            // verify required parameter 'projectBundleRequest' is not null or undefined
            assertParamExists('addTaskProductsFromBundle', 'projectBundleRequest', projectBundleRequest)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/task/{taskId}/bundle`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectBundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a product from a project or task
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectProduct: async (companyId: string, projectProductId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteProjectProduct', 'companyId', companyId)
            // verify required parameter 'projectProductId' is not null or undefined
            assertParamExists('deleteProjectProduct', 'projectProductId', projectProductId)
            const localVarPath = `/v1/company/{companyId}/project/products/{projectProductId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectProductId"}}`, encodeURIComponent(String(projectProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectProducts: async (companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProjectProducts', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectProducts', 'projectId', projectId)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectProductsV2: async (companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProjectProductsV2', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectProductsV2', 'projectId', projectId)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/productsV2`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProducts: async (companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTaskProducts', 'companyId', companyId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTaskProducts', 'taskId', taskId)
            const localVarPath = `/v1/company/{companyId}/project/task/{taskId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProductsV2: async (companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTaskProductsV2', 'companyId', companyId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTaskProductsV2', 'taskId', taskId)
            const localVarPath = `/v1/company/{companyId}/project/task/{taskId}/productsV2`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific user\'s most or last used products
         * @param {string} companyId 
         * @param {string} sort 
         * @param {string} [authorization] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts: async (companyId: string, sort: string, authorization?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserProducts', 'companyId', companyId)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getUserProducts', 'sort', sort)
            const localVarPath = `/v1/company/{companyId}/products/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a project product
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {ProjectProductRequest} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectProduct: async (companyId: string, projectProductId: string, projectProductRequest: ProjectProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateProjectProduct', 'companyId', companyId)
            // verify required parameter 'projectProductId' is not null or undefined
            assertParamExists('updateProjectProduct', 'projectProductId', projectProductId)
            // verify required parameter 'projectProductRequest' is not null or undefined
            assertParamExists('updateProjectProduct', 'projectProductRequest', projectProductRequest)
            const localVarPath = `/v1/company/{companyId}/project/products/{projectProductId}/update`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectProductId"}}`, encodeURIComponent(String(projectProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectProductApi - functional programming interface
 * @export
 */
export const ProjectProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add products and the quantity used to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectProducts(companyId: string, projectId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectProducts(companyId, projectId, projectProductRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add bundles of products to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectProductsFromBundle(companyId: string, projectId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectProductsFromBundle(companyId, projectId, projectBundleRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add products and the quantity used to a project and specific task
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskProducts(companyId: string, projectId: string, taskId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskProducts(companyId, projectId, taskId, projectProductRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add bundles of products to a specific task of a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskProductsFromBundle(companyId: string, projectId: string, taskId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskProductsFromBundle(companyId, projectId, taskId, projectBundleRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a product from a project or task
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectProduct(companyId: string, projectProductId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectProduct(companyId, projectProductId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectProducts(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectProducts(companyId, projectId, authorization, search, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectProductsV2(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumptionV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectProductsV2(companyId, projectId, authorization, search, page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskProducts(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskProducts(companyId, taskId, authorization, search, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskProductsV2(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumptionV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskProductsV2(companyId, taskId, authorization, search, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific user\'s most or last used products
         * @param {string} companyId 
         * @param {string} sort 
         * @param {string} [authorization] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProducts(companyId: string, sort: string, authorization?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOListProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProducts(companyId, sort, authorization, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a project product
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {ProjectProductRequest} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectProduct(companyId: string, projectProductId: string, projectProductRequest: ProjectProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectProduct(companyId, projectProductId, projectProductRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectProductApi - factory interface
 * @export
 */
export const ProjectProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Add products and the quantity used to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectProducts(companyId: string, projectId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addProjectProducts(companyId, projectId, projectProductRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add bundles of products to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectProductsFromBundle(companyId: string, projectId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addProjectProductsFromBundle(companyId, projectId, projectBundleRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add products and the quantity used to a project and specific task
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectProductRequest>} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskProducts(companyId: string, projectId: string, taskId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addTaskProducts(companyId, projectId, taskId, projectProductRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add bundles of products to a specific task of a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} taskId 
         * @param {Array<ProjectBundleRequest>} projectBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskProductsFromBundle(companyId: string, projectId: string, taskId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addTaskProductsFromBundle(companyId, projectId, taskId, projectBundleRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a product from a project or task
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectProduct(companyId: string, projectProductId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.deleteProjectProduct(companyId, projectProductId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectProducts(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumption> {
            return localVarFp.getProjectProducts(companyId, projectId, authorization, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a project
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectProductsV2(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumptionV2> {
            return localVarFp.getProjectProductsV2(companyId, projectId, authorization, search, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProducts(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumption> {
            return localVarFp.getTaskProducts(companyId, taskId, authorization, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a task within a project
         * @param {string} companyId 
         * @param {string} taskId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProductsV2(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumptionV2> {
            return localVarFp.getTaskProductsV2(companyId, taskId, authorization, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific user\'s most or last used products
         * @param {string} companyId 
         * @param {string} sort 
         * @param {string} [authorization] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts(companyId: string, sort: string, authorization?: string, limit?: number, options?: any): AxiosPromise<ResponseDTOListProduct> {
            return localVarFp.getUserProducts(companyId, sort, authorization, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a project product
         * @param {string} companyId 
         * @param {string} projectProductId 
         * @param {ProjectProductRequest} projectProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectProduct(companyId: string, projectProductId: string, projectProductRequest: ProjectProductRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.updateProjectProduct(companyId, projectProductId, projectProductRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectProductApi - object-oriented interface
 * @export
 * @class ProjectProductApi
 * @extends {BaseAPI}
 */
export class ProjectProductApi extends BaseAPI {
    /**
     * 
     * @summary Add products and the quantity used to a project
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {Array<ProjectProductRequest>} projectProductRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public addProjectProducts(companyId: string, projectId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).addProjectProducts(companyId, projectId, projectProductRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add bundles of products to a project
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {Array<ProjectBundleRequest>} projectBundleRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public addProjectProductsFromBundle(companyId: string, projectId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).addProjectProductsFromBundle(companyId, projectId, projectBundleRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add products and the quantity used to a project and specific task
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {string} taskId 
     * @param {Array<ProjectProductRequest>} projectProductRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public addTaskProducts(companyId: string, projectId: string, taskId: string, projectProductRequest: Array<ProjectProductRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).addTaskProducts(companyId, projectId, taskId, projectProductRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add bundles of products to a specific task of a project
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {string} taskId 
     * @param {Array<ProjectBundleRequest>} projectBundleRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public addTaskProductsFromBundle(companyId: string, projectId: string, taskId: string, projectBundleRequest: Array<ProjectBundleRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).addTaskProductsFromBundle(companyId, projectId, taskId, projectBundleRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a product from a project or task
     * @param {string} companyId 
     * @param {string} projectProductId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public deleteProjectProduct(companyId: string, projectProductId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).deleteProjectProduct(companyId, projectProductId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a project
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public getProjectProducts(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).getProjectProducts(companyId, projectId, authorization, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a project
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public getProjectProductsV2(companyId: string, projectId: string, authorization?: string, search?: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).getProjectProductsV2(companyId, projectId, authorization, search, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a task within a project
     * @param {string} companyId 
     * @param {string} taskId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public getTaskProducts(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).getTaskProducts(companyId, taskId, authorization, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a task within a project
     * @param {string} companyId 
     * @param {string} taskId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public getTaskProductsV2(companyId: string, taskId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).getTaskProductsV2(companyId, taskId, authorization, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific user\'s most or last used products
     * @param {string} companyId 
     * @param {string} sort 
     * @param {string} [authorization] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public getUserProducts(companyId: string, sort: string, authorization?: string, limit?: number, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).getUserProducts(companyId, sort, authorization, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a project product
     * @param {string} companyId 
     * @param {string} projectProductId 
     * @param {ProjectProductRequest} projectProductRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectProductApi
     */
    public updateProjectProduct(companyId: string, projectProductId: string, projectProductRequest: ProjectProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectProductApiFp(this.configuration).updateProjectProduct(companyId, projectProductId, projectProductRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
