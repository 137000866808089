import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import UpsertChecklistPage from "../-components/UpsertChecklistPage";
import { useGetChecklistById } from "../../../../../service/api/ChecklistApiV2";

export const Route = createFileRoute(
  "/_protected/dashboard/checklist/$checklistId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({ context: { queryClient }, params: { checklistId } }) => {
    return queryClient.fetchQuery({
      ...useGetChecklistById.getOptions({ checklistId })
    });
  },
  component: UpdateChecklistPage
});

function UpdateChecklistPage() {
  const checklist = useLoaderData({
    from: "/_protected/dashboard/checklist/$checklistId/update"
  });

  return <UpsertChecklistPage checklist={checklist} />;
}
