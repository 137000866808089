import { createFileRoute, useParams } from "@tanstack/react-router";
import useDialog from "../../../../../../hooks/useDialog";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import { useToast } from "../../../../../../hooks/useToast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  addWeeks,
  differenceInCalendarWeeks,
  differenceInDays,
  getWeek,
  startOfWeek,
  subDays
} from "date-fns";
import { getDayOfWeek } from "../../../../../../utils/DateTimeFunctions";
import {
  createProjectPlanGroup,
  deleteProjectPlanGroup,
  updateProjectPlanGroup,
  useGetProgressPlanGroups
} from "../../../../../../service/api/progressPlanGroupApi";
import {
  createProjectPlanItem,
  deleteProgressPlanItems,
  updateProgressPlanItem,
  useGetProgressPlanByProjectId
} from "../../../../../../service/api/ProgressPlanApi";
import {
  createProgressPlanFromTemplate,
  createProgressPlanTemplate,
  deleteProgressPlanTemplate,
  useGetProgressPlanTemplates
} from "../../../../../../service/api/ProgressPlanTemplateApi";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { ProgressPlanItem } from "../../../../../../.generated/api";
import TextField from "../../../../../../components/ds/TextField";
import Button from "../../../../../../components/ds/Button";
import IconButton from "../../../../../../components/ds/buttons/IconButton";
import Icon from "../../../../../../components/ds/Icon";
import CardV3 from "../../../../../../components/ds/CardV3";
import DropDown from "../../../../../../components/ds/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/ds/Dropdown/DropdownItem";
import { HexColorPicker } from "react-colorful";
import Dialog from "../../../../../../components/ds/Dialog";
import Popover, { PopoverClose } from "../../../../../../components/ds/Popover";
import ButtonNew from "../../../../../../components/ds/ButtonNew";
import { IconButton as RadixButton, Select } from "@radix-ui/themes";
import { TextFieldNew } from "../../../../../../components/ds/TextFieldNew";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "../../../../../../components/ds/TableNew";
import SortableTableRow from "../../../../../../pages/projectDetails/progressPlanner/SortableTableRow";
import classNames from "classnames";
import NewSelect from "../../../../../../components/ds/NewSelect";

const colors = [
  "#6848C1",
  "#006666",
  "#9E2105",
  "#E5C207",
  "#044785",
  "#A15C07",
  "#404D4D",
  "#E59B8A",
  "#AFCBE5",
  "#B6A5F9",
  "#003636",
  "#9CCDA7"
];

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/progress-plan"
)({
  component: ProjectProgressPlanPage
});

function ProjectProgressPlanPage() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const { onClose, onOpen, isOpen } = useDialog();
  const createTemplateDialog = useDialog();
  const templateDialog = useDialog();
  const companyId = useCompanyId();
  const { showErrorToast, showSuccessToast } = useToast();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string[]>([]);
  const [isTextFieldActive, setIsTextFieldActive] = useState(false);

  const startDay = startOfWeek(subDays(new Date(), 365));
  const endDay = addWeeks(startDay, 156);
  const totalDays = differenceInDays(endDay, startDay);

  const currentDayOfWeek = getDayOfWeek(new Date());
  const todayMarkerOffset =
    differenceInCalendarWeeks(endDay, new Date()) * 90 -
    (90 / 7) * currentDayOfWeek;

  const diff = Math.floor(differenceInCalendarWeeks(new Date(), startDay));
  const scrollOffset = 90 * diff - 200;

  const [title, setTitle] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [groupId, setGroupId] = useState<string | undefined>();
  const [pickedColor, setPickedColor] = useState<string>("");

  const groupQuery = useGetProgressPlanGroups({
    variables: { companyId: companyId }
  });

  const progressPlanQuery = useGetProgressPlanByProjectId({
    variables: { projectId: projectId }
  });

  const templateQuery = useGetProgressPlanTemplates({
    variables: { companyId: companyId }
  });

  const [groupTitle, setGroupTitle] = useState("");

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollOffset;
    }
  }, []);

  async function submitProgressItem() {
    if (title && groupId) {
      try {
        await createProjectPlanItem(projectId, {
          groupId: groupId,
          title: title
        });

        showSuccessToast("");
        setTitle("");
        setGroupId(undefined);
        setIsTextFieldActive(false);
        await progressPlanQuery.refetch();
      } catch (_) {
        showErrorToast(t("anErrorOccurred"));
      }
    } else {
      showErrorToast(t("groupRequired"));
    }
  }

  useEffect(() => {
    if (groupId) submitProgressItem();
  }, [groupId]);

  async function submitGroup() {
    if (!groupTitle) return;
    try {
      await createProjectPlanGroup(companyId, {
        title: groupTitle
      });
      setGroupTitle("");
      await groupQuery.refetch();
      showSuccessToast(t("groupCreated"));
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  async function deleteItems() {
    try {
      await deleteProgressPlanItems(projectId, selected);
      showSuccessToast(t("itemsDeleted"));
      setSelected([]);
      progressPlanQuery.refetch();
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  async function updateGroup(groupId: string, color: string) {
    try {
      await updateProjectPlanGroup(companyId, groupId, { color: color });
      groupQuery.refetch();
      progressPlanQuery.refetch();
      showSuccessToast(t("groupUpdated"));
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  async function deleteGroup(groupId: string) {
    try {
      await deleteProjectPlanGroup(companyId, groupId);
      groupQuery.refetch();
      showSuccessToast(t("groupDeleted"));
    } catch (_) {
      showErrorToast(t("groupInUse"));
    }
  }

  function handleSelect(itemId: string, checked: boolean) {
    if (checked) {
      setSelected([...selected, itemId]);
    } else {
      setSelected(selected.filter((id) => id !== itemId));
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  async function update(
    item: ProgressPlanItem,
    newIndex?: number,
    title?: string,
    groupId?: string
  ) {
    await updateProgressPlanItem(
      item.id,
      progressPlanQuery.data?.projectId ?? "",
      undefined,
      undefined,
      newIndex,
      title,
      groupId
    );
    await progressPlanQuery.refetch();
  }

  function handleDrag(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setRows((rows) => {
        const oldIndex = rows.findIndex((row) => row.id === active.id);
        const newIndex = rows.findIndex((row) => row.id === over.id);

        const item = rows[oldIndex];
        item.index = newIndex;
        update(item, newIndex);

        const newRows = [...rows];
        const [removed] = newRows.splice(oldIndex, 1);
        newRows.splice(newIndex, 0, removed);

        return newRows.map((row, index) => ({
          ...row,
          index: index
        }));
      });
    }
  }

  const [rows, setRows] = useState<ProgressPlanItem[]>(
    progressPlanQuery.data?.items ?? []
  );

  async function handleTemplateSubmit() {
    if (!templateTitle) {
      showErrorToast(t("titleRequired"));
      return;
    }

    try {
      await createProgressPlanTemplate(
        companyId,
        progressPlanQuery.data?.planId ?? "",
        templateTitle
      );
      showSuccessToast(t("templateSaved"));
      setTemplateTitle("");
      createTemplateDialog.onClose();
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  useEffect(() => {
    setRows(progressPlanQuery.data?.items ?? []);
  }, [progressPlanQuery.data]);

  return (
    <>
      <Dialog
        title={t("saveAsTemplate")}
        onClose={createTemplateDialog.onClose}
        open={createTemplateDialog.isOpen}
      >
        <TextFieldNew
          placeholder={t("nameOfTemplate")}
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
        />
        <Button onClick={handleTemplateSubmit}>{t("save")}</Button>
      </Dialog>
      <Dialog
        title={t("createFromTemplate")}
        onClose={templateDialog.onClose}
        open={templateDialog.isOpen}
      >
        <div
          className={
            "border border-system-neutral-10 rounded flex flex-col divide-y divide-system-neutral-10"
          }
        >
          {templateQuery.data?.data?.map((template) => (
            <div
              className={"px-3 flex items-center justify-between h-12"}
              key={template.templateId}
            >
              <p className={"text-sm"}>{template.title}</p>
              <div className={"flex items-center gap-2"}>
                <IconButton
                  icon={"add"}
                  onClick={async () => {
                    await createProgressPlanFromTemplate(
                      projectId,
                      template.templateId
                    );
                    showSuccessToast(t("createdFromTemplate"));
                    progressPlanQuery.refetch();
                    templateDialog.onClose();
                  }}
                />
                <IconButton
                  icon={"delete"}
                  onClick={async () => {
                    await deleteProgressPlanTemplate(
                      companyId,
                      template.templateId
                    );
                    templateQuery.refetch();
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Dialog>
      <Dialog
        onClose={onClose}
        open={isOpen}
        title={t("editPerformingGroups")}
        buttons={<Button onClick={onClose}>{t("close")}</Button>}
      >
        <div
          className={
            "border border-system-neutral-10 rounded flex flex-col divide-y divide-system-neutral-10"
          }
        >
          {groupQuery.data?.data?.map((group) => (
            <div
              className={"px-3 flex items-center justify-between h-12"}
              key={group.id}
            >
              <p className={"text-sm"}>{group.title}</p>
              <div className={"flex items-center"}>
                <Popover
                  trigger={
                    <RadixButton className={"mr-2"} variant={"soft"}>
                      <div
                        className={"w-4 h-4 rounded-1 cursor-pointer"}
                        style={{ background: group.color }}
                      />
                    </RadixButton>
                  }
                >
                  <div className={"flex flex-col"}>
                    <HexColorPicker
                      color={pickedColor ?? group.color}
                      onChange={(color) => setPickedColor(color)}
                      className={"min-w-full mb-2"}
                    />
                    <div className={"grid grid-cols-6 gap-2"}>
                      {colors.map((color) => (
                        <RadixButton
                          key={color}
                          variant={"soft"}
                          onClick={() => setPickedColor(color)}
                        >
                          <div
                            className={"w-4 h-4 rounded-1 cursor-pointer"}
                            style={{ background: color }}
                          />
                        </RadixButton>
                      ))}
                      <div></div>
                    </div>
                  </div>
                  <PopoverClose>
                    <ButtonNew
                      color={"green"}
                      variant={"soft"}
                      className={"w-full"}
                      onClick={() => updateGroup(group.id, pickedColor)}
                    >
                      Velg farge
                    </ButtonNew>
                  </PopoverClose>
                </Popover>
                <IconButton
                  icon={"delete"}
                  onClick={() => deleteGroup(group.id)}
                />
              </div>
            </div>
          ))}
          <div className={"h-12 px-2 flex items-center"}>
            <Icon icon={"add"} className={"text-system-neutral-90 pr-2"} />
            <TextField
              className={"text-sm"}
              value={groupTitle}
              placeholder={t("addGroup")}
              isInTable
              onChange={(e) => setGroupTitle(e.target.value)}
              isBottomPadding={false}
              onEnter={submitGroup}
            />
            <IconButton
              icon={"check"}
              className={"ml-auto mr-1"}
              onClick={() => submitGroup()}
            />
          </div>
        </div>
      </Dialog>
      <CardV3
        className={"w-full m-5"}
        title={t("progressPlan")}
        trailing={
          <>
            <Button
              isSmall
              theme={"red"}
              icon={"delete"}
              isDisabled={selected.length === 0}
              onClick={deleteItems}
            >
              {t("deleteItems")}
            </Button>
            <DropDown
              button={<IconButton icon={"more_vert"} variant={"surface"} />}
            >
              {(progressPlanQuery.data?.items?.length ?? 0) > 0 && (
                <DropdownItem
                  label={t("saveAsTemplate")}
                  onClick={() => createTemplateDialog.onOpen()}
                />
              )}
              {progressPlanQuery.data?.items?.length === 0 && (
                <DropdownItem
                  label={t("createFromTemplate")}
                  onClick={() => templateDialog.onOpen()}
                />
              )}
            </DropDown>
          </>
        }
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDrag}
          modifiers={[restrictToVerticalAxis]}
          autoScroll={false}
        >
          <SortableContext
            items={rows.map((item) => item.id) ?? []}
            strategy={verticalListSortingStrategy}
          >
            <Table ref={scrollRef}>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell
                    className={"left-0 sticky min-w-[48px] max-w-[48px] z-10"}
                  />
                  <TableHeaderCell
                    className={"left-[48px] min-w-[48px] max-w-[48px] z-10"}
                  />
                  <TableHeaderCell className={"min-w-[48px] left-[96px] z-10"}>
                    #
                  </TableHeaderCell>
                  <TableHeaderCell
                    className={"min-w-[240px] left-[144px] z-10"}
                  >
                    {t("description")}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className={
                      "min-w-[160px] left-[374px] border-r border-r-radix-gray-8 z-10"
                    }
                  >
                    {t("performingGroup")}
                  </TableHeaderCell>
                  {Array.from(Array(156)).map((_, index) => (
                    <TableHeaderCell
                      className={
                        "min-w-[90px] max-w-[90px] border-r border-b border-radix-gray-8 z-0!"
                      }
                      key={index}
                    >
                      {t("week")} {getWeek(addWeeks(startDay, index))}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                <div
                  className={"absolute"}
                  style={{
                    zIndex: 1,
                    right: todayMarkerOffset - 8,
                    top: -8,
                    bottom: 0
                  }}
                >
                  <div className={"w-4 h-4 bg-[#E72A00] rounded-full"}></div>
                  <div
                    className={
                      "bg-[#E72A00] w-[2px] left-[7px] absolute top-0 bottom-0"
                    }
                  ></div>
                </div>
                {rows.map((item) => (
                  <SortableTableRow
                    key={item.id}
                    item={item}
                    projectId={progressPlanQuery.data?.projectId ?? ""}
                    startDay={startDay}
                    totalDays={totalDays}
                    groups={groupQuery.data?.data ?? []}
                    checked={selected.includes(item.id)}
                    onChange={(checked) => handleSelect(item.id, checked)}
                    onUpdated={() => progressPlanQuery.refetch()}
                    onGroupDialogOpen={() => onOpen()}
                  />
                ))}

                <TableRow>
                  <TableCell className={"left-0 sticky bg-white z-10"} />
                  <TableCell
                    className={"left-[48px] min-w-[48px] sticky bg-white z-10"}
                  >
                    <Icon icon={"add"} />
                  </TableCell>
                  <TableCell
                    className={"min-w-[48px] left-[96px] sticky bg-white z-10"}
                  />
                  <TableCell
                    className={
                      "min-w-[240px] left-[144px] sticky bg-white z-10 flex items-center gap-2"
                    }
                  >
                    <TextFieldNew
                      size={"2"}
                      className={"w-full"}
                      placeholder={t("addItem")}
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          submitProgressItem();
                        }
                      }}
                      onFocus={() => setIsTextFieldActive(true)}
                      onBlur={() => setIsTextFieldActive(false)}
                      maxLength={30}
                    />
                    <IconButton
                      icon={"check"}
                      className={classNames("ml-auto", {
                        "opacity-0 pointer-events-none": !isTextFieldActive,
                        "opacity-100 pointer-events-auto": isTextFieldActive
                      })}
                    />
                  </TableCell>
                  <TableCell
                    className={
                      "min-w-[160px] left-[374px] border-r border-r-radix-gray-8 sticky bg-white z-10"
                    }
                  >
                    <NewSelect
                      size={"2"}
                      triggerProps={{ variant: "ghost", color: "gray" }}
                      placeholder={t("selectPerformer")}
                      value={groupId}
                      options={
                        groupQuery?.data?.data.map((group) => ({
                          value: group.id,
                          label: group.title
                        })) ?? []
                      }
                      onChange={(value) => {
                        if (value === "edit") {
                          onOpen();
                          return;
                        }
                        setGroupId(value);
                      }}
                      trailing={
                        <Select.Item value={"edit"}>
                          {t("editPerformingGroups")}
                        </Select.Item>
                      }
                    />
                  </TableCell>
                  <TableCell colSpan={156} />
                </TableRow>
              </TableBody>
            </Table>
          </SortableContext>
        </DndContext>
      </CardV3>
    </>
  );
}
