/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BundleRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ResponseDTOBundle } from '../model';
// @ts-ignore
import { ResponseDTOListBundle } from '../model';
// @ts-ignore
import { ResponseDTOString } from '../model';
/**
 * BundleApi - axios parameter creator
 * @export
 */
export const BundleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create bundle
         * @param {string} companyId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBundle: async (companyId: string, bundleRequest: BundleRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createBundle', 'companyId', companyId)
            // verify required parameter 'bundleRequest' is not null or undefined
            assertParamExists('createBundle', 'bundleRequest', bundleRequest)
            const localVarPath = `/v1/company/{companyId}/products/bundles`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete/Archive bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBundle: async (companyId: string, bundleId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteBundle', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('deleteBundle', 'bundleId', bundleId)
            const localVarPath = `/v1/company/{companyId}/products/bundles/{bundleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all bundles
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyBundles: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyBundles', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/products/bundles`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single bundle by ID
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleById: async (companyId: string, bundleId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBundleById', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('getBundleById', 'bundleId', bundleId)
            const localVarPath = `/v1/company/{companyId}/products/bundles/{bundleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBundle: async (companyId: string, bundleId: string, bundleRequest: BundleRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateBundle', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('updateBundle', 'bundleId', bundleId)
            // verify required parameter 'bundleRequest' is not null or undefined
            assertParamExists('updateBundle', 'bundleRequest', bundleRequest)
            const localVarPath = `/v1/company/{companyId}/products/bundles/{bundleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundleId"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BundleApi - functional programming interface
 * @export
 */
export const BundleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BundleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create bundle
         * @param {string} companyId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBundle(companyId: string, bundleRequest: BundleRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOBundle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBundle(companyId, bundleRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete/Archive bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBundle(companyId, bundleId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all bundles
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyBundles(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOListBundle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyBundles(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single bundle by ID
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBundleById(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOBundle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBundleById(companyId, bundleId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBundle(companyId: string, bundleId: string, bundleRequest: BundleRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBundle(companyId, bundleId, bundleRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BundleApi - factory interface
 * @export
 */
export const BundleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BundleApiFp(configuration)
    return {
        /**
         * 
         * @summary Create bundle
         * @param {string} companyId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBundle(companyId: string, bundleRequest: BundleRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOBundle> {
            return localVarFp.createBundle(companyId, bundleRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete/Archive bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.deleteBundle(companyId, bundleId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all bundles
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyBundles(companyId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOListBundle> {
            return localVarFp.getAllCompanyBundles(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single bundle by ID
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleById(companyId: string, bundleId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOBundle> {
            return localVarFp.getBundleById(companyId, bundleId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a bundle
         * @param {string} companyId 
         * @param {string} bundleId 
         * @param {BundleRequest} bundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBundle(companyId: string, bundleId: string, bundleRequest: BundleRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.updateBundle(companyId, bundleId, bundleRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BundleApi - object-oriented interface
 * @export
 * @class BundleApi
 * @extends {BaseAPI}
 */
export class BundleApi extends BaseAPI {
    /**
     * 
     * @summary Create bundle
     * @param {string} companyId 
     * @param {BundleRequest} bundleRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleApi
     */
    public createBundle(companyId: string, bundleRequest: BundleRequest, authorization?: string, options?: AxiosRequestConfig) {
        return BundleApiFp(this.configuration).createBundle(companyId, bundleRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete/Archive bundle
     * @param {string} companyId 
     * @param {string} bundleId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleApi
     */
    public deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundleApiFp(this.configuration).deleteBundle(companyId, bundleId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all bundles
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleApi
     */
    public getAllCompanyBundles(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundleApiFp(this.configuration).getAllCompanyBundles(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single bundle by ID
     * @param {string} companyId 
     * @param {string} bundleId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleApi
     */
    public getBundleById(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundleApiFp(this.configuration).getBundleById(companyId, bundleId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a bundle
     * @param {string} companyId 
     * @param {string} bundleId 
     * @param {BundleRequest} bundleRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundleApi
     */
    public updateBundle(companyId: string, bundleId: string, bundleRequest: BundleRequest, authorization?: string, options?: AxiosRequestConfig) {
        return BundleApiFp(this.configuration).updateBundle(companyId, bundleId, bundleRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
