import React, { forwardRef, ReactNode } from "react";

type TableHeaderProps = {
  children: ReactNode;
  className?: string;
};

// eslint-disable-next-line react/display-name
const TableHeader = forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  ({ children, className }: TableHeaderProps, ref) => {
    return (
      <thead ref={ref} className={className}>
        {children}
      </thead>
    );
  }
);

export default TableHeader;
