import { useGetPaginatedTimeEntriesByCompanyId } from "../../../../../service/api/TimeEntryApi";
import { UseSelectedReturn } from "../../../../../hooks/useSelected";
import { TimeEntry } from "../../../../../.generated/api";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import { CheckboxNew } from "../../../../../components/ds/CheckboxNew";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { checkboxCellSize } from "../../../../../components/ds/sizes";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import { ScrollArea } from "@radix-ui/themes";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import { useSort } from "../../../../../hooks/useSort";
import { useState } from "react";
import RadixIcon from "../../../../../components/ds/RadixIcon";

type InvoiceDialogTimeEntriesProps = {
  selection: UseSelectedReturn<TimeEntry>;
  resourceId: string;
};

export default function InvoiceDialogTimeEntries({
  selection,
  resourceId
}: InvoiceDialogTimeEntriesProps) {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const [onlyApproved, setOnlyApproved] = useState(true);
  const { handleSelectAll, handleSelect, allSelected, isSelected } = selection;
  const { setPage, page } = useSort("", "");

  const timeEntryQuery = useGetPaginatedTimeEntriesByCompanyId({
    variables: {
      companyId,
      resourceId,
      sortField: "date",
      approved: onlyApproved ? "notInvoiced" : undefined,
      page
    }
  });

  const timeEntries = timeEntryQuery.data?.data ?? [];

  return (
    <>
      <div>
        <CheckboxNew
          className={"mb-4"}
          checked={onlyApproved}
          onClick={() => setOnlyApproved(!onlyApproved)}
          label={t("onlyShowNotInvoicedTimeEntries")}
        />
        <ScrollArea className={"h-[500px] mb-4"}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>
                  <CheckboxNew
                    checked={allSelected(timeEntries)}
                    onClick={() => handleSelectAll(timeEntries)}
                  />
                </TableHeaderCell>
                <TableHeaderCell>{t("user")}</TableHeaderCell>
                <TableHeaderCell>{t("date")}</TableHeaderCell>
                <TableHeaderCell>{t("value")}</TableHeaderCell>
                <TableHeaderCell>{t("activity")}</TableHeaderCell>
                <TableHeaderCell>{t("approved")}</TableHeaderCell>
                <TableHeaderCell>{t("invoiced")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {timeEntries.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>{t("noTimeEntriesAdded")}</TableCell>
                </TableRow>
              )}
              {timeEntries.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell className={checkboxCellSize}>
                    <CheckboxNew
                      checked={isSelected(entry)}
                      onClick={() => handleSelect(entry)}
                    />
                  </TableCell>
                  <TableCell>{entry.userName}</TableCell>
                  <TableCell>
                    {formatFullNumericDateString(entry.date)}
                  </TableCell>
                  <TableCell>{entry.value}</TableCell>
                  <TableCell>{entry.activityName}</TableCell>
                  <TableCell py={"0"} className={"align-middle"}>
                    {entry.approved && <RadixIcon icon={"check"} />}
                  </TableCell>
                  <TableCell py={"0"} className={"align-middle"}>
                    {entry.invoicedAt && <RadixIcon icon={"check"} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      <PaginationNew
        totalPages={timeEntryQuery.data?.totalPages}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
