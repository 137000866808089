import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import UpsertOrderPage from "../../../orders/-components/UpsertOrderPage";
import { useGetCustomerById } from "../../../../../../service/api/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/order/new"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { customerId }
  }) => {
    return await queryClient.fetchQuery(
      useGetCustomerById.getOptions({ customerId, companyId })
    );
  },
  component: CreateCustomerOrderPage
});

function CreateCustomerOrderPage() {
  const customer = useLoaderData({
    from: "/_protected/dashboard/customers/$customerId/order/new"
  });

  return <UpsertOrderPage existingCustomer={customer} />;
}
