import {
  createFileRoute,
  Link,
  useNavigate,
  useParams
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../../components/common/PageTitle";
import Button from "../../../../../../components/ds/Button";
import AddSingleProduct from "../../../../../../pages/projectDetails/projectProducts/components/AddSingleProduct";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/template/$templateId/product"
)({
  component: AddProductToOrderTemplate
});

function AddProductToOrderTemplate() {
  const { templateId } = useParams({
    from: "/_protected/dashboard/orders/template/$templateId/product"
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full justify-between">
        <PageTitle title={t("addFromProductCatalog")} />
        <Link
          to={"/dashboard/orders/template/$templateId"}
          search={{
            showDialog: false,
            tab: "products"
          }}
          params={{
            templateId: templateId
          }}
        >
          <Button theme="red" icon="close" isLeftIcon>
            {t("cancel")}
          </Button>
        </Link>
      </div>
      <AddSingleProduct
        resourceId={templateId}
        resourceType="order-template"
        onAdded={async () => {
          await navigate({
            to: "/dashboard/orders/template/$templateId",
            search: {
              showDialog: false,
              tab: "products"
            },
            params: {
              templateId: templateId
            }
          });
        }}
      />
    </>
  );
}
