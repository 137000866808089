import { createFileRoute, useParams, useSearch } from "@tanstack/react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../../components/common/PageTitle";
import AddSingleProduct from "../../../../../../pages/projectDetails/projectProducts/components/AddSingleProduct";
import AddProductBundle from "../../../../../../pages/projectDetails/projectProducts/components/AddProductBundle";
import { z } from "zod";

const addProductsToProjectSchema = z.object({
  taskId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/products/add"
)({
  validateSearch: addProductsToProjectSchema,
  component: AddProductToProject
});

function AddProductToProject() {
  const [isBundles, setIsBundles] = useState<"singleProduct" | "productGroups">(
    "singleProduct"
  );
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/products/add"
  });
  const { taskId } = useSearch({
    from: "/_protected/dashboard/project/$projectId/products/add"
  });
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full justify-between">
        <PageTitle title={t("addFromProductCatalog")} />
      </div>
      {isBundles == "singleProduct" ? (
        <AddSingleProduct
          isBundles={isBundles}
          setIsBundles={setIsBundles}
          resourceId={projectId}
          taskId={taskId}
          resourceType="project"
        />
      ) : (
        <AddProductBundle
          isBundles={isBundles}
          setIsBundles={setIsBundles}
          projectId={projectId}
          taskId={taskId}
        />
      )}
    </>
  );
}
