import {
  createFileRoute,
  useLoaderData,
  useNavigate,
  useParams,
  useRouter,
  useSearch
} from "@tanstack/react-router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDialog from "../../../../../../hooks/useDialog";
import { useGetChecklistByResourceId } from "../../../../../../service/api/ChecklistApiV2";
import {
  ChecklistV2,
  Deviation,
  DeviationDeviationStatusEnum,
  DeviationResourceTypeEnum,
  RoleAssignmentDeleteRequestResourceTypeEnum,
  RoleAssignmentUpsertRequestResourceTypeEnum
} from "../../../../../../.generated/api";
import { getDeviationsOnResource } from "../../../../../../service/api/DeviationApi";
import InvitationDialog from "../../../../../../pages/employeesPage/InvitationDialog";
import TaskDialog from "../../../../../../components/dialogs/task/TaskDialog";
import IconButton from "../../../../../../components/ds/buttons/IconButton";
import TaskStatusSelect from "../../../../../../components/common/TaskStatusSelect";
import DeleteBlock from "../../../../../../components/buttons/DeleteBlock";
import Button from "../../../../../../components/ds/Button";
import { TypedTab } from "../../../../../../components/ds/Tab";
import { taskDetailsTabs } from "../../../../../../router/Routes";
import Card from "../../../../../../components/ds/Card";
import ProgressCard from "../../../../../../components/ds/ProgressCard";
import Icon from "../../../../../../components/ds/Icon";
import TaskInformationDisplay from "../../../../../../pages/taskDetails/TaskInformationDisplay";
import TaskResourceView from "../../../../../../pages/taskDetails/taskUserResources/TaskResourceView";
import MessageView from "../../../../../../components/ds/MessageView";
import DocumentationTab from "../../../../../../pages/documentation/DocumentationTab";
import ChecklistPage from "../../../../../../pages/projectDetails/checklist/ChecklistPage";
import DeviationPage from "../../../../../../components/common/deviation/DeviationPage";
import ProductConsumptionTab from "../../../../../../pages/projectDetails/projectProducts/ProductConsumptionTab";
import {
  deleteTask,
  useGetTaskById
} from "../../../../../../service/api/TaskApiV2";
import { z } from "zod";

const taskDetailsSchema = z.object({
  isFromProject: z.boolean(),
  tab: taskDetailsTabs.catch("overview").optional(),
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/task/$taskId"
)({
  validateSearch: taskDetailsSchema,
  loader: async ({ context: { queryClient }, params: { taskId } }) => {
    return queryClient.fetchQuery({
      ...useGetTaskById.getOptions({ taskId })
    });
  },
  component: TaskDetailsPage
});

function TaskDetailsPage() {
  const navigate = useNavigate();
  const task = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/task/$taskId"
  });
  const { taskId, projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/task/$taskId"
  });
  const { tab, isFromProject, selectedDeviationId } = useSearch({
    from: "/_protected/dashboard/project/$projectId/task/$taskId"
  });
  const { isOpen, onOpen, onClose } = useDialog();
  const router = useRouter();
  const invitationDialog = useDialog();
  const [checklists, setChecklists] = useState<{
    completed: number;
    total: number;
    progress: number;
  }>({ completed: 0, total: 0, progress: 0 });
  const [deviations, setDeviations] = useState<{
    open: number;
    total: number;
  }>({ open: 0, total: 0 });

  const { data } = useGetChecklistByResourceId({
    variables: { resourceId: taskId }
  });

  const { t } = useTranslation();

  function parseDeviations(unparsedDeviations: Deviation[]) {
    const numDeviations = unparsedDeviations.length;
    const openDeviations = unparsedDeviations.filter(
      (dev) => dev.deviationStatus === DeviationDeviationStatusEnum.Open
    ).length;
    setDeviations({ open: openDeviations, total: numDeviations });
  }

  function parseChecklists(unparsedChecklists: ChecklistV2[]) {
    const numChecklists = unparsedChecklists.length ?? 0;
    const completed = unparsedChecklists.filter((c) => c.completed).length ?? 0;

    const progress =
      completed === 0 || numChecklists === 0 ? 0 : completed / numChecklists;
    setChecklists({
      completed: completed,
      total: numChecklists,
      progress: progress
    });
  }

  function handleTaskAdded() {
    onClose();
    router.invalidate();
  }

  useEffect(() => {
    getDeviationsOnResource(taskId, DeviationResourceTypeEnum.Task).then(
      parseDeviations
    );
    data && parseChecklists(data);
  }, [data, projectId]);

  async function handleNavigation() {
    if (isFromProject) {
      await navigate({
        to: "/dashboard/project/$projectId/overview",
        params: {
          projectId: projectId
        }
      });
    } else {
      await navigate({
        to: "/dashboard/project/task"
      });
    }
  }

  return (
    <div className={"p-5"}>
      <InvitationDialog
        onInvitationSent={invitationDialog.onClose}
        onClose={invitationDialog.onClose}
        open={invitationDialog.isOpen}
      />
      <div className={"flex h-full flex-col"}>
        <TaskDialog
          open={isOpen}
          projectId={task.projectId}
          taskDetails={task}
          onTaskAdded={handleTaskAdded}
          onClose={onClose}
        />
        <div
          className={
            "mb-6 flex flex-col items-start justify-between sm:flex-row lg:items-center"
          }
        >
          <div className={"flex items-baseline gap-4 mb-4 lg:mb-0"}>
            {/*TODO: Pagetitle*/}
            <IconButton
              icon={"arrow_back_ios_new"}
              size={"3"}
              variant={"ghost"}
              onClick={handleNavigation}
            />
            <h1 className={"grow font-bold"}>{task.title}</h1>
          </div>
          <span className="flex flex-row gap-4">
            <TaskStatusSelect
              status={task.progressStatus}
              taskIds={[task.id]}
              onUpdated={router.invalidate}
            />
            <DeleteBlock
              title={t("Arkiver oppgave")}
              deleteFunction={() => deleteTask(taskId)}
              confirmationText={t(
                "Er du sikker på at du vil arkivere oppgaven?"
              )}
              onDeleted={handleNavigation}
              render={(onOpen) => (
                <Button theme={"white"} icon={"archive"} onClick={onOpen}>
                  <p className="hidden sm:block">{t("Arkiver oppgave")}</p>
                </Button>
              )}
            />
          </span>
        </div>
        <TypedTab
          tabs={taskDetailsTabs.options}
          getLabel={(tab) => t(tab)}
          selected={tab}
          onChange={(tab) =>
            navigate({
              to: "/dashboard/project/$projectId/task/$taskId",
              params: { taskId: taskId, projectId: projectId },
              search: { tab: tab, isFromProject: isFromProject }
            })
          }
        >
          <div
            className={"mb-4 flex flex-col gap-4 overflow-visible lg:h-4/6 "}
          >
            <Card className="flex shrink-0 ">
              <div className="flex flex-col gap-4 lg:flex-row ">
                <button
                  className="w-full"
                  onClick={() =>
                    navigate({
                      to: "/dashboard/project/$projectId/task/$taskId",
                      params: {
                        projectId,
                        taskId
                      },
                      search: {
                        tab: "deviations",
                        isFromProject: isFromProject
                      }
                    })
                  }
                >
                  <ProgressCard
                    title={t("Åpne avvik")}
                    subtitle={`${deviations.total} ${t("avvik totalt")}`}
                    progressText={deviations.open.toString()}
                    theme="error"
                  />
                </button>
                <button
                  className="w-full"
                  onClick={() =>
                    navigate({
                      to: "/dashboard/project/$projectId/task/$taskId",
                      params: {
                        taskId: taskId,
                        projectId: task.projectId
                      },
                      search: {
                        isFromProject: false,
                        tab: "checklists"
                      }
                    })
                  }
                >
                  <ProgressCard
                    title={t("Sjekklister fullført")}
                    subtitle={`${checklists.total} ${t("sjekklister totalt")}`}
                    progressText={checklists.completed.toString()}
                    theme={"success"}
                    progress={checklists.progress}
                  />
                </button>
              </div>
            </Card>

            <div
              className={
                "grid h-fit grid-cols-1 gap-2 lg:h-full lg:grid-cols-3 lg:gap-4"
              }
            >
              <Card
                title={task.title}
                isFullHeight
                trailing={
                  <Icon
                    icon="edit"
                    className="cursor-pointer rounded-1 p-1 hover:bg-gray-5"
                    onClick={onOpen}
                  />
                }
              >
                <TaskInformationDisplay task={task} />
              </Card>
              <InvitationDialog
                onInvitationSent={invitationDialog.onClose}
                onClose={invitationDialog.onClose}
                open={invitationDialog.isOpen}
              />
              <Card
                title={t("resources")}
                isFullHeight
                trailing={
                  <Button
                    theme={"green"}
                    icon={"add"}
                    onClick={invitationDialog.onOpen}
                    isSmall
                  >
                    {t("Inviter ny bruker")}
                  </Button>
                }
              >
                <TaskResourceView
                  defaultRoleName={"Oppgavedeltaker"}
                  resourceId={taskId}
                  workAssignmentResourceType={"Task"}
                  fromDate={task.from}
                  toDate={task.to}
                  addResourceType={
                    RoleAssignmentUpsertRequestResourceTypeEnum.Task
                  }
                  deleteResourceType={
                    RoleAssignmentDeleteRequestResourceTypeEnum.Task
                  }
                />
              </Card>
              <MessageView
                title="Chat"
                resourceId={taskId}
                resourceType="task"
              />
            </div>
          </div>
          <DocumentationTab resourceId={taskId} resourceName={task.title} />
          <ChecklistPage
            resourceId={task.id}
            resourceType={"task"}
            parentId={task.projectId}
          />
          <DeviationPage
            resourceId={taskId}
            resourceType={DeviationResourceTypeEnum.Task}
            selectedDeviationId={selectedDeviationId}
            projectId={projectId}
          />
          <ProductConsumptionTab
            resourceId={projectId}
            taskId={taskId}
            resourceType={"project"}
          />
        </TypedTab>
      </div>
    </div>
  );
}
