import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { updateProfile, useGetUserProfile } from "../../service/api/UserApi";
import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { t } from "i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginLayout from "../_unauthenticated/-components/LoginLayout";
import { FormTextField } from "../../components/ds/TextFieldNew";
import ButtonNew from "../../components/ds/ButtonNew";

export const Route = createFileRoute("/_protected/user-info")({
  component: UserInfoPage
});

const userInfoSchema = z.object({
  name: z.string().min(1),
  phoneNumber: z.string().min(1),
  email: z.string().email()
});

type UserInfoFormInputs = z.infer<typeof userInfoSchema>;

function UserInfoPage() {
  const navigate = useNavigate();
  const userQuery = useGetUserProfile();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<UserInfoFormInputs>({
    resolver: zodResolver(userInfoSchema)
  });

  useEffect(() => {
    if (userQuery.data) {
      reset({
        name: userQuery.data.name ?? "",
        phoneNumber: userQuery.data.phoneNumber ?? "",
        email: userQuery.data.email ?? ""
      });
    }
  }, [userQuery.data]);

  async function onSubmit(data: UserInfoFormInputs) {
    await updateProfile({
      name: data.name,
      phoneNumber: data.phoneNumber
    });

    await userQuery.refetch();
    await navigate({
      replace: true,
      to: "/dashboard/project/overview"
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginLayout
        withHeader
        title={"Litt informasjon om deg"}
        subtitle={
          "Vi trenger litt informasjon om deg så vi kan sende deg nødvendiginformasjon om kontoen din."
        }
      >
        <FormTextField
          label={t("name")}
          placeholder={t("Hva heter du?")}
          {...register("name")}
          errorMessage={errors.name?.message}
        />
        <FormTextField
          label={t("email")}
          placeholder={t("email")}
          {...register("email")}
          errorMessage={errors.email?.message}
        />
        <FormTextField
          label={t("phone")}
          placeholder={t("Hva er telefonnummeret ditt?")}
          {...register("phoneNumber")}
          errorMessage={errors.phoneNumber?.message}
        />
        <ButtonNew type={"submit"} size={"3"} loading={isSubmitting}>
          {t("next")}
        </ButtonNew>
      </LoginLayout>
    </form>
  );
}
