import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import {
  createAccount,
  handleFirebaseError
} from "../../service/firebase/FirebaseService";
import { t } from "i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginLayout from "./-components/LoginLayout";
import { Text } from "@radix-ui/themes";
import { FormTextField } from "../../components/ds/TextFieldNew";
import ButtonNew from "../../components/ds/ButtonNew";
import { useToast } from "../../hooks/useToast";
import { Link as RadixLink } from "@radix-ui/themes/dist/cjs/components/link";

const signupSchema = z
  .object({
    email: z.string().email(),
    password1: z.string().min(1),
    password2: z.string().min(1)
  })
  .superRefine((schema, ctx) => {
    if (schema.password1 !== schema.password2) {
      ctx.addIssue({
        code: "custom",
        path: ["password2"],
        message: "Passordene må være like"
      });
    }
  });

type SignupFormData = z.infer<typeof signupSchema>;

export const Route = createFileRoute("/_unauthenticated/create-account")({
  component: CreateAccountPage
});

function CreateAccountPage() {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<SignupFormData>({
    resolver: zodResolver(signupSchema)
  });

  async function onSubmit(data: SignupFormData) {
    try {
      await createAccount(data.email.toLocaleLowerCase(), data.password1);
      await navigate({
        to: "/dashboard/project/overview"
      });
    } catch (e) {
      const error = handleFirebaseError(e as Record<string, unknown>);
      showErrorToast(error);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginLayout
        title={"Opprett konto"}
        subtitle={"Skriv inn e-post og passord for å opprette konto"}
      >
        <FormTextField
          label={t("email")}
          placeholder={t("eksempel@bedrift.no")}
          errorMessage={errors.email?.message}
          {...register("email")}
        />
        <FormTextField
          type={"password"}
          label={t("password")}
          placeholder={t("password")}
          errorMessage={errors.password1?.message}
          {...register("password1")}
        />
        <FormTextField
          type={"password"}
          label={t("repeatPassword")}
          placeholder={t("password")}
          errorMessage={errors.password2?.message}
          {...register("password2")}
        />
        <ButtonNew size={"3"} type={"submit"} loading={isSubmitting}>
          {t("createAccount")}
        </ButtonNew>
        <div className={"flex justify-center mt-6 gap-2"}>
          <Text weight={"medium"}>Har du allerede en konto?</Text>
          <RadixLink asChild>
            <Link to={"/login"}>{t("loginHere")}</Link>
          </RadixLink>
        </div>
      </LoginLayout>
    </form>
  );
}
