import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import { UpsertEhsPage } from "../../-components/UpsertEhsPage";
import { z } from "zod";
import { useGetEhsTemplateById } from "../../../../../../service/api/EhsApi";

const ehsTemplateSchema = z.object({
  templateId: z.string().optional(),
  isFromProject: z.boolean().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/ehs/template/$templateId/update"
)({
  validateSearch: ehsTemplateSchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { templateId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetEhsTemplateById.getOptions({ companyId, templateId })
    });
  },
  component: UpdateEhsTemplatePage
});

function UpdateEhsTemplatePage() {
  const ehsTemplateItem = useLoaderData({
    from: "/_protected/dashboard/ehs/template/$templateId/update"
  });

  return <UpsertEhsPage ehsTemplateItem={ehsTemplateItem} templateEdit />;
}
