import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import { UpsertProductBundle } from "../-components/UpsertProductBundle";
import { useGetProductBundleById } from "../../../../../../service/api/ProductBundleApi";

export const Route = createFileRoute(
  "/_protected/dashboard/products/bundles/$bundleId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { bundleId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetProductBundleById.getOptions({ companyId, bundleId })
    });
  },
  component: UpdateProductBundlePage
});

function UpdateProductBundlePage() {
  const bundle = useLoaderData({
    from: "/_protected/dashboard/products/bundles/$bundleId/update"
  });
  return <UpsertProductBundle bundle={bundle} />;
}
