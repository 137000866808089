import { createFileRoute, Outlet } from "@tanstack/react-router";
import TabNav from "../../../../components/TabNav";
import { TabLink } from "../../../../components/TabLink";
import { useTranslation } from "react-i18next";
import NewPageTitle from "../../../../components/common/NewPageTitle";

export const Route = createFileRoute("/_protected/dashboard/settings/_layout")({
  component: SettingsPageLayout
});

function SettingsPageLayout() {
  const { t } = useTranslation();
  return (
    <>
      <NewPageTitle title={t("settings")} />
      <TabNav>
        <TabLink replace to={"/dashboard/settings/company"}>
          {t("company")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/project"}>
          {t("project")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/order"}>
          {t("workOrders")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/equipment"}>
          {t("equipment")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/documentation-template"}>
          {t("documentationTemplates")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/checklist-template"}>
          {t("checklistTemplates")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/task-template"}>
          {t("taskTemplates")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/integration"}>
          {t("integrations")}
        </TabLink>
        <TabLink replace to={"/dashboard/settings/video-guide"}>
          {t("videoGuide")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
