import React, { ReactElement, RefCallback, useEffect, useState } from "react";
import {
  DateRange as DayPickerDateRange,
  DayPicker,
  DayPickerProps,
  DayProps,
  NavProps
} from "react-day-picker";
import Popover from "../Popover";
import IconButton from "../buttons/IconButton";
import { Text } from "@radix-ui/themes";
import {
  formatFullNumericDate,
  formatMonthAndYear,
  formatTime
} from "../../../utils/DateTimeFormatter";
import { TimeInputField } from "./TimeInputField";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import ButtonNew from "../ButtonNew";
import classnames from "classnames";
import {
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays
} from "date-fns";
import { useTranslation } from "react-i18next";
import { DateRange } from "../../../utils/DateTimeFunctions";

type DatePickerNewProps = DatePickerSingleProps | DatePickerRangeProps;

type DatePickerSingleProps = {
  type: "single";
  withTime?: boolean;
  value?: Date;
  onChange: (date: Date) => void;
  trigger: (
    ref?: RefCallback<HTMLElement>,
    value?: string
  ) => ReactElement<any>;
  customRef?: RefCallback<HTMLElement>;
};

type DatePickerRangeProps = {
  type: "range";
  value?: DateRange;
  onChange: (range: DateRange) => void;
  trigger: (
    ref?: RefCallback<HTMLElement>,
    value?: string
  ) => ReactElement<any>;
  customRef?: RefCallback<HTMLElement>;
};

type ControlledDatePickerProps<T extends FieldValues> = (
  | Omit<DatePickerSingleProps, "onChange">
  | Omit<DatePickerRangeProps, "onChange">
) & {
  control: Control<T>;
  fieldName: Path<T>;
};

export function ControlledDatePicker<T extends FieldValues>({
  fieldName,
  control,
  ...props
}: ControlledDatePickerProps<T>) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        const { ref, ...fieldRest } = field;
        return <DatePickerNew {...props} {...fieldRest} customRef={ref} />;
      }}
    />
  );
}

type PeriodButtonProps = {
  onClick: VoidFunction;
  label: string;
};

function PeriodButton({ label, onClick }: PeriodButtonProps) {
  return (
    <ButtonNew
      variant={"soft"}
      className={"w-full"}
      size={"1"}
      color={"gray"}
      onClick={onClick}
    >
      {label}
    </ButtonNew>
  );
}

export default function DatePickerNew({
  trigger,
  customRef,
  ...props
}: DatePickerNewProps) {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(
    (props.type === "single" ? props.value : props.value?.start) ?? new Date()
  );

  useEffect(() => {
    if (!props.value) return;
    if (props.type === "single") {
      setSelectedMonth(props.value);
    } else {
      setSelectedMonth(props.value.start);
    }
  }, [props.value]);

  function onDateRangeChange(range: DayPickerDateRange) {
    if (props.type === "range" && range.from) {
      props.onChange({
        start: range.from,
        end: range.to ?? range.from
      });
    }
  }

  function onDateChange(date: Date) {
    if (props.type === "single") {
      const newDate = props.value ? new Date(props.value) : new Date();
      newDate.setFullYear(date.getFullYear());
      newDate.setMonth(date.getMonth());
      newDate.setDate(date.getDate());
      props.onChange(newDate);
    }
  }

  function getDisplayValue() {
    if (!props.value) return;
    if (props.type === "single") {
      if (props.withTime) {
        return `${formatFullNumericDate(props.value)} ${formatTime(props.value?.toISOString())}`;
      }
      return formatFullNumericDate(props.value);
    } else {
      return `${formatFullNumericDate(props.value.start)} - ${formatFullNumericDate(props.value.end)}`;
    }
  }

  const commonProps = {
    autoFocus: true,
    required: true as const,
    showOutsideDays: false,
    weekStartsOn: 1,
    onMonthChange: setSelectedMonth,
    classNames: {
      caption_label: "hidden",
      weekday: "rt-Text rt-r-size-1 font-normal pt-2 pb-1",
      months: "grid",
      day: "p-0"
    },
    components: {
      Nav: ({ onPreviousClick, onNextClick }: NavProps) => (
        <>
          <div
            className={"relative flex items-center justify-around col-span-2"}
          >
            <IconButton
              className={"absolute left-0"}
              icon={"keyboard_arrow_left"}
              size={"1"}
              variant={"soft"}
              color={"gray"}
              onClick={onPreviousClick}
            />
            <Text weight={"medium"} size={"2"}>
              {formatMonthAndYear(selectedMonth?.toISOString())}
            </Text>
            {props.type === "range" && (
              <Text weight={"medium"} size={"2"}>
                {formatMonthAndYear(addMonths(selectedMonth, 1).toISOString())}
              </Text>
            )}
            <IconButton
              className={"absolute right-0"}
              icon={"keyboard_arrow_right"}
              size={"1"}
              color={"gray"}
              variant={"soft"}
              onClick={onNextClick}
            />
          </div>
        </>
      ),
      Day: ({ modifiers, ...restProps }: DayProps) => {
        const dayDiff = 0;
        let variant: "ghost" | "solid" | "soft" = "ghost";
        if (modifiers.selected && !modifiers.outside) variant = "solid";
        if (modifiers.selected && modifiers.outside) variant = "soft";
        if (modifiers.range_middle) variant = "soft";

        if (props.type === "range" && modifiers.outside) {
          return <td />;
        }

        return (
          <td {...restProps}>
            <ButtonNew
              asChild
              variant={variant}
              color={!modifiers.selected ? "gray" : undefined}
              className={classnames("w-8 h-8 m-0 p-0", {
                "text-radix-gray-8": modifiers.outside && !modifiers.selected,
                "rounded-r-[0px]": modifiers.range_start && dayDiff > 0,
                "rounded-l-[0px]": modifiers.range_end && dayDiff > 0,
                "rounded-[0px]": modifiers.range_middle
              })}
            >
              {restProps.children}
            </ButtonNew>
          </td>
        );
      }
    }
  } satisfies Omit<DayPickerProps, "mode">;

  return (
    <Popover
      trigger={trigger(customRef, getDisplayValue())}
      className={"max-w-[700px]"}
    >
      <div className={"inline-flex"}>
        {props.type === "range" && (
          <div className={"mr-2 w-[150px] flex flex-col gap-1"}>
            <PeriodButton
              label={t("last7Days")}
              onClick={() => {
                const end = startOfDay(new Date());
                props.onChange({
                  end: end,
                  start: subDays(end, 7)
                });
              }}
            />
            <PeriodButton
              label={t("last30Days")}
              onClick={() => {
                const end = startOfDay(new Date());
                props.onChange({
                  end: end,
                  start: subDays(end, 30)
                });
              }}
            />
            <PeriodButton
              label={t("thisWeek")}
              onClick={() => {
                props.onChange({
                  start: startOfWeek(new Date()),
                  end: endOfWeek(new Date())
                });
              }}
            />
            <PeriodButton
              label={t("thisMonth")}
              onClick={() => {
                props.onChange({
                  start: startOfMonth(new Date()),
                  end: endOfMonth(new Date())
                });
              }}
            />
          </div>
        )}
        {props.type === "single" && (
          <DayPicker
            {...commonProps}
            mode={"single"}
            selected={props.value}
            defaultMonth={props.value}
            onSelect={onDateChange}
            numberOfMonths={1}
            footer={
              props.withTime && (
                <TimeInputField
                  noBottomPadding
                  value={props.value}
                  onChange={props.onChange}
                  size={"2"}
                  className={"mt-2 w-[100px]"}
                />
              )
            }
          />
        )}
        {props.type === "range" && (
          <DayPicker
            {...commonProps}
            mode={"range"}
            selected={{
              from: props.value?.start,
              to: props.value?.end
            }}
            defaultMonth={props.value?.start}
            onSelect={onDateRangeChange}
            numberOfMonths={2}
            onMonthChange={setSelectedMonth}
          />
        )}
      </div>
    </Popover>
  );
}
