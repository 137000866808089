import { createFileRoute, useSearch } from "@tanstack/react-router";
import { UpsertFacilityPage } from "./-components/UpsertFacilityPage";
import { z } from "zod";

const createFacilitySchema = z.object({
  customerId: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/facility/new")({
  validateSearch: createFacilitySchema,
  component: CreateFacilityPage
});

function CreateFacilityPage() {
  const { customerId } = useSearch({
    from: "/_protected/dashboard/facility/new"
  });
  return <UpsertFacilityPage customerId={customerId} />;
}
