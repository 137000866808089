import { createFileRoute, useParams } from "@tanstack/react-router";
import React from "react";
import { UpsertFileOfferPage } from "./-components/UpsertFileOfferPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new"
)({
  component: CreateFileOffer
});

function CreateFileOffer() {
  const { salesOpportunityId } = useParams({
    from: "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new"
  });
  return <UpsertFileOfferPage salesOpportunityId={salesOpportunityId} />;
}
