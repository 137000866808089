import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classnames from "classnames";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { ControlledSelect } from "../../../../../components/ds/NewSelect";
import RadixIcon from "../../../../../components/ds/RadixIcon";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { TableRow } from "../../../../../components/ds/TableNew";
import { FormTextArea } from "../../../../../components/ds/TextArea";
import { FormTextField } from "../../../../../components/ds/TextFieldNew";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import { checkboxCellSize } from "../../../../../components/ds/sizes";
import { formatCurrency } from "../../../../../utils/currencyFormatter";
import { InvoiceFormData } from "../invoice.new";

type SortableInvoiceLineProps = {
  id: string;
  index: number;
  isFreeText: boolean;
  register: UseFormRegister<InvoiceFormData>;
  control: Control<InvoiceFormData>;
  errors: FieldErrors<InvoiceFormData>;
  onPriceChange: (price: number) => void;
  linePrice: number;
  onRemove: VoidFunction;
};

export default function SortableInvoiceLine({
  id,
  index,
  register,
  errors,
  control,
  onPriceChange,
  linePrice,
  onRemove,
  isFreeText
}: SortableInvoiceLineProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <TableRow ref={setNodeRef} style={style} className={"bg-radix-gray-2"}>
      <TableCell
        className={classnames(checkboxCellSize, "align-middle")}
        py={"0"}
      >
        <div
          className={"text-system-neutral-90 opacity-60 hover:opacity-100"}
          {...attributes}
          {...listeners}
        >
          <RadixIcon icon={"drag_indicator"} />
        </div>
      </TableCell>
      <TableCell className={"w-[40%]"}>
        <FormTextField
          noBottomPadding
          size={"2"}
          {...register(`lines.${index}.productNumber`)}
          disabled={isFreeText}
          hasError={Boolean(errors.lines?.[index]?.productNumber)}
        />
      </TableCell>
      <TableCell className={"w-[60%]"}>
        <FormTextArea
          noBottomPadding
          size={"2"}
          rows={1}
          {...register(`lines.${index}.description`)}
          hasError={Boolean(errors.lines?.[index]?.description)}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          noBottomPadding
          className={"w-[70px]"}
          size={"2"}
          type={"number"}
          step={".01"}
          {...register(`lines.${index}.count`)}
          hasError={Boolean(errors.lines?.[index]?.count)}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          noBottomPadding
          className={"w-[90px]"}
          size={"2"}
          {...register(`lines.${index}.unit`)}
          hasError={Boolean(errors.lines?.[index]?.unit)}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          noBottomPadding
          className={"w-[70px]"}
          size={"2"}
          type={"number"}
          step={".01"}
          {...register(`lines.${index}.price`, {
            onChange: (e) => onPriceChange(e.target.value)
          })}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          noBottomPadding
          className={"w-[70px]"}
          type={"number"}
          size={"2"}
          trailing={"%"}
          {...register(`lines.${index}.discount`)}
          hasError={Boolean(errors.lines?.[index]?.discount)}
        />
      </TableCell>
      <TableCell>
        <ControlledSelect
          className={"w-[70px]"}
          noBottomPadding
          control={control}
          size={"2"}
          fieldName={`lines.${index}.vat`}
          options={[
            { label: "25%", value: "25" },
            { label: "15%", value: "15" },
            { label: "12%", value: "12" },
            { label: "0%", value: "0" }
          ]}
        />
      </TableCell>
      <TableCell className={"align-middle"} align={"right"}>
        {formatCurrency(linePrice)}
      </TableCell>
      <TableCell>
        <IconButton icon={"delete"} variant={"soft"} onClick={onRemove} />
      </TableCell>
    </TableRow>
  );
}
