import { createFileRoute, Link } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSort } from "../../../../../hooks/useSort";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { exportSalesOpportunities } from "../../../../../utils/exportUtils";
import CardNew from "../../../../../components/ds/CardNew";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import PageLayout from "../../../../../components/PageLayout";
import {
  SalesOpportunitySortFields,
  useGetSalesOpportunitiesByCompanyId
} from "../../../../../service/api/SalesOpportunityApi";
import { searchBarSize } from "../../../../../components/ds/sizes";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import ComboboxMenu, { Item } from "../../../../../components/ds/ComboboxMenu";
import { SalesOpportunityStatusEnum } from "../../../../../.generated/api";
import Select from "../../../../../components/ds/NewSelect";
import { SegmentedControl } from "@radix-ui/themes";
import SalesOpportunityList from "../../../../../pages/sales/salesOppertunity/list/SalesOpportunityList";
import SalesOpportunityArchive from "../../../../../pages/sales/salesOppertunity/archive/SalesOpportunityArchive";
import SalesOpportunityCalendar from "../../../../../pages/sales/salesOppertunity/calendar/SalesOpportunityCalendar";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/_layout/sales-opportunity"
)({
  component: SalesOpportunityPage
});

type SalesFilter = "all" | "mine";
type View = "list" | "archive" | "calendar";

function SalesOpportunityPage() {
  const companyId = useCompanyId();
  const [filter, setFilter] = useState<SalesFilter>("all");
  const [selectedStatus, setSelectedStatus] =
    useState<Item<SalesOpportunityStatusEnum>>();
  const [selectedView, setSelectedView] = useState<View>("list");
  const [isExporting, setIsExporting] = useState(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const {
    sortField,
    sortDirection,
    registerHeader,
    page,
    setPage,
    setQuery,
    debouncedQuery,
    pageSize,
    setPageSize
  } = useSort<SalesOpportunitySortFields>("Title", "salesOpportunity");

  const salesQuery = useGetSalesOpportunitiesByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page,
      sortDirection,
      sortField,
      archived: selectedView === "archive",
      status: selectedStatus?.value,
      onlyUserAssigned: filter === "mine",
      query: debouncedQuery,
      pageSize
    }
  });

  const salesOpportunities = salesQuery.data?.data ?? [];

  async function exportToExcel() {
    if (isExporting) return;
    setIsExporting(true);
    await exportSalesOpportunities(companyId, queryClient);
    setIsExporting(false);
  }

  const optionsDisabled = selectedView === "calendar";

  return (
    <PageLayout>
      <CardNew
        inset
        title={t("salesOpportunities")}
        trailing={
          <>
            <IconButton
              loading={isExporting}
              onClick={exportToExcel}
              icon="download"
            />
            <ButtonNew icon="add" asChild>
              <Link to={"/dashboard/sales/sales-opportunity/new"}>
                {t("createSalesOpportunity")}
              </Link>
            </ButtonNew>
          </>
        }
        headerContent={
          <div className={"flex justify-between w-full"}>
            <div className={"flex gap-2"}>
              <TextFieldNew
                icon="search"
                name={"search"}
                size={"2"}
                disabled={optionsDisabled}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("searchForSalesOpportunity")}
                className={searchBarSize}
              />
              <ComboboxMenu<SalesOpportunityStatusEnum>
                label={t("status")}
                onChange={setSelectedStatus}
                value={selectedStatus}
                items={[
                  { label: t("identifiedSale"), value: "IdentifiedSale" },
                  { label: t("inProgress"), value: "InProgress" },
                  { label: t("lost"), value: "Lost" },
                  { label: t("offerSent"), value: "OfferSent" },
                  { label: t("won"), value: "Won" }
                ]}
              />
              {selectedStatus && (
                <ButtonNew
                  icon={"close"}
                  variant={"outline"}
                  color={"red"}
                  onClick={() => setSelectedStatus(undefined)}
                >
                  {t("removeFilter")}
                </ButtonNew>
              )}
              <Select
                size={"2"}
                onChange={setFilter}
                disabled={optionsDisabled}
                value={filter}
                options={[
                  { label: t("all"), value: "all" },
                  { label: t("mine"), value: "mine" }
                ]}
              />
            </div>
            <SegmentedControl.Root value={selectedView}>
              <SegmentedControl.Item
                value={"list"}
                onClick={() => setSelectedView("list")}
              >
                {t("list")}
              </SegmentedControl.Item>
              <SegmentedControl.Item
                value={"calendar"}
                onClick={() => setSelectedView("calendar")}
              >
                {t("calendar")}
              </SegmentedControl.Item>
              <SegmentedControl.Item
                value={"archive"}
                onClick={() => setSelectedView("archive")}
              >
                {t("archive")}
              </SegmentedControl.Item>
            </SegmentedControl.Root>
          </div>
        }
        footerContent={
          <PaginationNew
            page={page}
            totalPages={salesQuery.data?.totalPages ?? 0}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
        }
      >
        {selectedView === "list" && (
          <SalesOpportunityList
            data={salesOpportunities}
            onUpdated={() => salesQuery.refetch()}
            registerHeader={registerHeader}
          />
        )}
        {selectedView === "calendar" && <SalesOpportunityCalendar />}
        {selectedView === "archive" && (
          <SalesOpportunityArchive
            data={salesOpportunities}
            registerHeader={registerHeader}
          />
        )}
      </CardNew>
    </PageLayout>
  );
}
