import { createFileRoute, useSearch } from "@tanstack/react-router";
import React from "react";
import UpsertOrderPage from "../-components/UpsertOrderPage";
import { z } from "zod";

const createOrderSchema = z.object({
  facilityId: z.string().nullable().optional(),
  serviceContractId: z.string().nullable().optional(),
  initialStartDate: z.string().nullable().optional()
});

export const Route = createFileRoute("/_protected/dashboard/orders/new/")({
  validateSearch: createOrderSchema,
  component: CreateOrderPage
});

function CreateOrderPage() {
  const { facilityId, serviceContractId, initialStartDate } = useSearch({
    from: "/_protected/dashboard/orders/new/"
  });

  return (
    <UpsertOrderPage
      facilityId={facilityId !== null ? facilityId : undefined}
      serviceContractId={
        serviceContractId !== null ? serviceContractId : undefined
      }
      initialStartDate={
        initialStartDate ? new Date(initialStartDate) : undefined
      }
    />
  );
}
