import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export function useToast() {
  const { t } = useTranslation();

  return {
    showSuccessToast: (message: string) => {
      toast.success(message);
    },
    showErrorToast: (message: string) => {
      toast.error(message);
    },
    showLoadingToast: (
      promise: Promise<unknown>,
      loadingText: string,
      successText: string
    ) => {
      toast.promise(promise, {
        loading: loadingText,
        error: t("anErrorOccurred"),
        success: successText
      });
    }
  };
}
