import {
  createFileRoute,
  useNavigate,
  useSearch
} from "@tanstack/react-router";
import { resourceTabs } from "../../../../router/Routes";
import { useTranslation } from "react-i18next";
import { ColumnLayout } from "../../../../components/layouts/ColumnLayout";
import EmployeesTab from "../../../../pages/employeesPage/EmployeesTab";
import InvitationsTab from "../../../../pages/employeesPage/InvitationsTab";
import EquipmentTab from "../../../../pages/employeesPage/equipment/EquipmentTab";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TypedTabNew from "../../../../components/ds/TypedTabNew";
import ResourcePlannerTabNew from "../overview/-components/resourcePlanner/ResourcePlannerTabNew";
import { z } from "zod";

const employeeSchema = z.object({
  tab: resourceTabs.catch("users").optional()
});

export const Route = createFileRoute("/_protected/dashboard/employees/")({
  validateSearch: employeeSchema,
  component: EmployeesPage
});

function EmployeesPage() {
  const { tab } = useSearch({ from: "/_protected/dashboard/employees/" });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("resources")} />
      <TypedTabNew
        tabs={resourceTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={(tab) =>
          navigate({
            to: "/dashboard/employees",
            search: { tab: tab }
          })
        }
      >
        <ColumnLayout columns={1}>
          <EmployeesTab />
        </ColumnLayout>
        <ResourcePlannerTabNew />
        <ColumnLayout columns={1}>
          <InvitationsTab />
        </ColumnLayout>
        <ColumnLayout columns={1}>
          <EquipmentTab />
        </ColumnLayout>
      </TypedTabNew>
    </>
  );
}
