import {
  firebaseConfigDev,
  firebaseConfigStaging,
  firebaseConfigProd
} from "./service/firebase/firebaseconfig";

const environment = {
  skwApiNewBaseUrl: "http://localhost:8080/skyworker",
  exportModuleBaseUrl: "http://localhost:8026/",
  integrationsBaseUrl: "http://localhost:8087",
  crmBaseUrl: "http://localhost:8069",
  exportBaseUrl: "http://localhost:8008",
  aggregatorBaseUrl: "http://localhost:8090",
  firebaseConfig: firebaseConfigDev,
  offerBaseUrl: "http://localhost:3001/offer/"
};

if (import.meta.env.MODE === "dev") {
  environment.skwApiNewBaseUrl =
    "https://skyworkerapi-absp676whq-ey.a.run.app/skyworker";
  environment.exportModuleBaseUrl =
    "https://skyworkerexportmodule-absp676whq-ey.a.run.app";
  environment.integrationsBaseUrl =
    "https://skyworker-integrations-absp676whq-ey.a.run.app";
  environment.crmBaseUrl = "https://skyworker-crm-absp676whq-ey.a.run.app";
  environment.aggregatorBaseUrl =
    "https://skyworker-customer-aggregator-absp676whq-ey.a.run.app";
  environment.exportBaseUrl =
    "https://skyworker-export-pdf-absp676whq-ey.a.run.app";
  environment.offerBaseUrl = "https://skw-customer-dev.web.app/offer/";
}

if (import.meta.env.MODE === "staging") {
  environment.skwApiNewBaseUrl =
    "https://skyworkerapi-4c3stb56mq-ey.a.run.app/skyworker";
  environment.firebaseConfig = firebaseConfigStaging;
  environment.exportModuleBaseUrl =
    "https://skyworkerexportmodule-4c3stb56mq-ey.a.run.app";
  environment.integrationsBaseUrl =
    "https://skyworker-integrations-4c3stb56mq-ey.a.run.app";
  environment.crmBaseUrl = "https://skyworker-crm-4c3stb56mq-ey.a.run.app";
  environment.aggregatorBaseUrl =
    "https://skyworker-customer-aggregator-4c3stb56mq-ey.a.run.app";
  environment.exportBaseUrl =
    "https://skyworker-export-pdf-4c3stb56mq-ey.a.run.app";
  environment.offerBaseUrl = "https://skw-customer-staging.web.app/offer/";
}

if (import.meta.env.MODE === "prod") {
  environment.skwApiNewBaseUrl =
    "https://skyworkerapi-llme4cal6q-ey.a.run.app/skyworker";
  environment.firebaseConfig = firebaseConfigProd;
  environment.exportModuleBaseUrl =
    "https://skyworkerexportmodule-llme4cal6q-ey.a.run.app";
  environment.integrationsBaseUrl =
    "https://skyworker-integrations-llme4cal6q-ey.a.run.app";
  environment.crmBaseUrl = "https://skyworker-crm-llme4cal6q-ey.a.run.app";
  environment.aggregatorBaseUrl =
    "https://skyworker-customer-aggregator-llme4cal6q-ey.a.run.app";
  environment.exportBaseUrl =
    "https://skyworker-export-pdf-llme4cal6q-ey.a.run.app";
  environment.offerBaseUrl = "https://skw-customer.web.app/offer/";
}

export default environment;
