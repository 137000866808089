import { productsInstance } from "./ProductsModuleHttpBase";

import {
  ProductPriceRequest,
  ProductRequest,
  ProductApi
} from "../../.generated/api";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const productApi = new ProductApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export const useGetProductsByCompanyId = createQuery({
  queryKey: ["getProductsByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    sort?: string;
    query?: string;
    archived?: boolean;
    categoryIds?: string;
  }) => {
    const response = await productApi.getAllCompanyProducts(
      variables.companyId,
      undefined,
      variables.categoryIds ? variables.categoryIds.split(",") : undefined,
      variables.archived,
      variables.query,
      10,
      variables.page,
      variables.sort
    );

    return {
      products: response.data.body?.products ?? [],
      pagination: response.data.body!.pagination!
    };
  }
});

export const useGetProductById = createQuery({
  queryKey: ["getProductById"],
  fetcher: async (variables: { companyId: string; productId: string }) => {
    const response = await productApi.getProductById(
      variables.companyId,
      variables.productId
    );
    return response.data.body!;
  }
});

export async function createProduct(
  companyId: string,
  products: ProductRequest
) {
  return await productApi.createProduct(companyId, products);
}

export async function deleteProduct(companyId: string, request: string) {
  await productApi.deleteProduct(companyId, request);
}

export async function updateProduct(
  companyId: string,
  productId: string,
  product: ProductRequest
) {
  await productApi.updateProduct(companyId, productId, product);
}

export async function updateProductPrices(
  companyId: string,
  productPrice: ProductPriceRequest
) {
  await productApi.updateProductPrices(companyId, productPrice);
}
