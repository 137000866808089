import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useSearch
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import CardV2 from "../../../components/ds/CardV2";
import { useGetPublicProgressPlanByProjectId } from "../../../service/api/ProgressPlanApi";
import { ehsQueries } from "../../../service/api/EhsApi";
import { projectDetailsGuestTabs } from "../../../router/Routes";
import ProgressPlannerGuest from "../../../pages/guest/progressPlanner/ProgressPlannerGuest";
import DocumentationPageGuest from "../../../pages/guest/documentation/DocumentationPageGuest";
import ChecklistPageGuest from "../../../pages/guest/checklist/ChecklistPageGuest";
import EhsPageGuest from "../../../pages/guest/ehs/EhsPageGuest";
import DeviationPageGuest from "../../../pages/guest/deviation/DeviationPageGuest";
import LanguageSwitcher from "../../../components/common/LanguageSwitcher";
import { TypedTab } from "../../../components/ds/Tab";
import { useGetCustomerIdByResourceId } from "../../../service/api/CustomerApiV3";
import ButtonNew from "../../../components/ds/ButtonNew";
import { useGetPublicChecklistByResourceId } from "../../../service/api/ChecklistApiV2";
import { useGetPublicDeviationsByProjectId } from "../../../service/api/DeviationApi";
import { useGetPublicFilesAndFoldersByResourceId } from "../../../service/api/FolderApi";
import { z } from "zod";
import { useGetPublicProjectById } from "../../../service/api/ProjectApiV2";

const projectDetailsGuestTabSchema = z.object({
  tab: projectDetailsGuestTabs.default("progressPlan").optional(),
  checklistId: z.string().optional(),
  deviationId: z.string().optional()
});

export const Route = createFileRoute("/guest/project/$projectId")({
  validateSearch: projectDetailsGuestTabSchema,
  loader: ({ context: { queryClient }, params: { projectId } }) => {
    return queryClient.fetchQuery({
      ...useGetPublicProjectById.getOptions({ projectId })
    });
  },
  component: ProjectGuestPage
});

function ProjectGuestPage() {
  const projectInfo = useLoaderData({ from: "/guest/project/$projectId" });
  const { projectId } = useParams({ from: "/guest/project/$projectId" });
  const { checklistId, deviationId } = useSearch({
    from: "/guest/project/$projectId"
  });

  const { t } = useTranslation();
  const { tab } = useSearch({
    from: "/guest/project/$projectId"
  });
  const navigate = useNavigate();

  if (projectInfo.isPublic === false) {
    return (
      <>
        <div className={"p-4 mb-6 h-full"}>
          <CardV2 className={"shadow-custom items-center"}>
            <p className={"text-md"}>
              {`Kundeportal for dette prosjektet er for øyeblikket ikke aktiv. Kontakt ${projectInfo.companyTitle} for mer informasjon.`}
            </p>
          </CardV2>
        </div>
      </>
    );
  }

  const customerQuery = useGetCustomerIdByResourceId({
    variables: { resourceId: projectId }
  });
  const customerId = customerQuery.data;

  const progressPlanQuery = useGetPublicProgressPlanByProjectId({
    variables: { projectId: projectId }
  });
  const progressPlan = progressPlanQuery.data;

  const documentationQuery = useGetPublicFilesAndFoldersByResourceId({
    variables: { resourceId: projectId }
  });

  const documentation = documentationQuery.data;

  const checklistQuery = useGetPublicChecklistByResourceId({
    variables: { resourceId: projectId }
  });

  const checklists = checklistQuery.data;

  const ehsItemsQuery = useQuery({
    ...ehsQueries.getPublicByProjectId(projectId)
  });
  const ehsItems = ehsItemsQuery.data;

  const deviationQuery = useGetPublicDeviationsByProjectId({
    variables: { projectId }
  });

  const deviations = deviationQuery.data;

  const isProgressPlanAvailable = !!progressPlan;
  const isDocumentationAvailable =
    documentation?.files.length || documentation?.folders.length
      ? documentation.files.length > 0 || documentation.folders.length > 0
      : false;
  const isChecklistsAvailable = checklists?.length
    ? checklists.length > 0
    : false;
  const isEhsAvailable = ehsItems?.length ? ehsItems.length > 0 : false;
  const isDeviationsAvailable = deviations?.length
    ? deviations.length > 0
    : false;

  const availableTabs = projectDetailsGuestTabs.options.filter((tabOption) => {
    switch (tabOption) {
      case "progressPlan":
        return isProgressPlanAvailable;
      case "documentation":
        return isDocumentationAvailable;
      case "checklists":
        return isChecklistsAvailable;
      case "ehs":
        return isEhsAvailable;
      case "deviations":
        return isDeviationsAvailable;
      default:
        return false;
    }
  });

  const tabComponents = availableTabs.map((tabOption, index) => {
    switch (tabOption) {
      case "progressPlan":
        return isProgressPlanAvailable && progressPlan ? (
          <ProgressPlannerGuest key={index} progressPlan={progressPlan} />
        ) : null;
      case "documentation":
        return isDocumentationAvailable ? (
          <DocumentationPageGuest key={index} resourceId={projectId} />
        ) : null;
      case "checklists":
        return isChecklistsAvailable ? (
          <ChecklistPageGuest
            key={index}
            resourceType={"project"}
            resourceId={projectId}
            checklistId={checklistId}
          />
        ) : null;
      case "ehs":
        return isEhsAvailable ? (
          <EhsPageGuest
            key={index}
            resourceType={"project"}
            resourceId={projectId}
          />
        ) : null;
      case "deviations":
        return isDeviationsAvailable && deviations ? (
          <DeviationPageGuest
            key={index}
            resourceType={"project"}
            resourceId={projectId}
            deviationId={deviationId}
          />
        ) : null;
      default:
        return null;
    }
  });

  return (
    <div className={"flex flex-col p-2 sm:p-6"}>
      {customerId && (
        <ButtonNew
          icon={"arrow_back"}
          className={"mb-4 mr-auto"}
          size={"1"}
          color={"gray"}
        >
          <Link
            to={"/guest/$customerId"}
            params={{
              customerId: customerId
            }}
          >
            {"Tilbake til kundeportalen"}
          </Link>
        </ButtonNew>
      )}
      <div className={"flex flex-row gap-4 mb-8"}>
        {projectInfo.logoUrl && (
          <div className={"h-16"}>
            <img
              alt={"company logo"}
              src={projectInfo.logoUrl}
              className={"max-h-full"}
            />
          </div>
        )}
        <div>
          <div className={"flex flex-row flex-wrap items-end"}>
            <h1 className={"font-bold text-system-neutral-110"}>
              {t("project") + ": " + projectInfo.title}
            </h1>
            <div
              className={"text-system-neutral-100 text-md ml-2"}
            >{`#${projectInfo.number}`}</div>
          </div>
          <div className={"flex flex-row items-center"}>
            <h2 className={"text-system-neutral-110"}>
              {projectInfo.companyTitle}
            </h2>
          </div>
        </div>
        <div className={"ml-auto"}>
          <LanguageSwitcher isGuestView={true} />
        </div>
      </div>
      <TypedTab
        tabs={availableTabs}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={async (tab) => {
          await navigate({
            to: "/guest/project/$projectId",
            params: { projectId: projectId },
            search: { tab: tab }
          });
        }}
      >
        {tabComponents}
      </TypedTab>
    </div>
  );
}
