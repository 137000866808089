/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Equipment } from '../model';
// @ts-ignore
import { EquipmentCategory } from '../model';
// @ts-ignore
import { EquipmentCategoryPostRequest } from '../model';
// @ts-ignore
import { EquipmentCategoryPutRequest } from '../model';
// @ts-ignore
import { EquipmentExternalCheckoutRequest } from '../model';
// @ts-ignore
import { EquipmentHistory } from '../model';
// @ts-ignore
import { EquipmentPostRequest } from '../model';
// @ts-ignore
import { EquipmentPutRequest } from '../model';
// @ts-ignore
import { PaginatedResponseEquipment } from '../model';
/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check in equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinEquipment: async (companyId: string, equipmentId: string, notes?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkinEquipment', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('checkinEquipment', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkin`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check in equipment by admin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinEquipmentByAdmin: async (companyId: string, equipmentId: string, notes?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkinEquipmentByAdmin', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('checkinEquipmentByAdmin', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkin/admin`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check out equipment to user
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutEquipment: async (companyId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkoutEquipment', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('checkoutEquipment', 'equipmentId', equipmentId)
            // verify required parameter 'externalCheckout' is not null or undefined
            assertParamExists('checkoutEquipment', 'externalCheckout', externalCheckout)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkout`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expectedCheckinDateTime !== undefined) {
                localVarQueryParameter['expectedCheckinDateTime'] = (expectedCheckinDateTime as any instanceof Date) ?
                    (expectedCheckinDateTime as any).toISOString() :
                    expectedCheckinDateTime;
            }

            if (externalCheckout !== undefined) {
                localVarQueryParameter['externalCheckout'] = externalCheckout;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentExternalCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check out equipment to user by admin
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutEquipmentByAdmin: async (companyId: string, userId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkoutEquipmentByAdmin', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkoutEquipmentByAdmin', 'userId', userId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('checkoutEquipmentByAdmin', 'equipmentId', equipmentId)
            // verify required parameter 'externalCheckout' is not null or undefined
            assertParamExists('checkoutEquipmentByAdmin', 'externalCheckout', externalCheckout)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkout/{userId}/admin`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expectedCheckinDateTime !== undefined) {
                localVarQueryParameter['expectedCheckinDateTime'] = (expectedCheckinDateTime as any instanceof Date) ?
                    (expectedCheckinDateTime as any).toISOString() :
                    expectedCheckinDateTime;
            }

            if (externalCheckout !== undefined) {
                localVarQueryParameter['externalCheckout'] = externalCheckout;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentExternalCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create equipment
         * @param {string} companyId 
         * @param {EquipmentPostRequest} equipmentPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipment: async (companyId: string, equipmentPostRequest: EquipmentPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createEquipment', 'companyId', companyId)
            // verify required parameter 'equipmentPostRequest' is not null or undefined
            assertParamExists('createEquipment', 'equipmentPostRequest', equipmentPostRequest)
            const localVarPath = `/v1/company/{companyId}/equipment`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create equipment category
         * @param {string} companyId 
         * @param {EquipmentCategoryPostRequest} equipmentCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipmentCategory: async (companyId: string, equipmentCategoryPostRequest: EquipmentCategoryPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createEquipmentCategory', 'companyId', companyId)
            // verify required parameter 'equipmentCategoryPostRequest' is not null or undefined
            assertParamExists('createEquipmentCategory', 'equipmentCategoryPostRequest', equipmentCategoryPostRequest)
            const localVarPath = `/v1/company/{companyId}/equipment-category`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentCategoryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipment: async (companyId: string, equipmentId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteEquipment', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('deleteEquipment', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipmentCategory: async (companyId: string, equipmentCategoryId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteEquipmentCategory', 'companyId', companyId)
            // verify required parameter 'equipmentCategoryId' is not null or undefined
            assertParamExists('deleteEquipmentCategory', 'equipmentCategoryId', equipmentCategoryId)
            const localVarPath = `/v1/company/{companyId}/equipment-category/{equipmentCategoryId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentCategoryId"}}`, encodeURIComponent(String(equipmentCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment category list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentCategories: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllEquipmentCategories', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment-category`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all unique locations of equipments
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentLocations: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllEquipmentLocations', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment-locations`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEquipmentList: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyEquipmentList', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment by id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentById: async (companyId: string, equipmentId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEquipmentById', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('getEquipmentById', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment category by id
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentCategory: async (companyId: string, equipmentCategoryId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEquipmentCategory', 'companyId', companyId)
            // verify required parameter 'equipmentCategoryId' is not null or undefined
            assertParamExists('getEquipmentCategory', 'equipmentCategoryId', equipmentCategoryId)
            const localVarPath = `/v1/company/{companyId}/equipment-category/{equipmentCategoryId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentCategoryId"}}`, encodeURIComponent(String(equipmentCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment history by equipment id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentHistoryByEquipmentId: async (companyId: string, equipmentId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEquipmentHistoryByEquipmentId', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('getEquipmentHistoryByEquipmentId', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/history`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment list for user (rentals)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentRentalsForUser: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEquipmentRentalsForUser', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment/rentals`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unused equipment categories
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnusedEquipmentCategories: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUnusedEquipmentCategories', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment-category/unused`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize equipment checkin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeEquipmentCheckin: async (companyId: string, equipmentId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('initializeEquipmentCheckin', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('initializeEquipmentCheckin', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkin/initialize`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize equipment checkout
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeEquipmentCheckout: async (companyId: string, equipmentId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('initializeEquipmentCheckout', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('initializeEquipmentCheckout', 'equipmentId', equipmentId)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/checkout/initialize`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equipment list by company id, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Category' | 'Status' | 'Location'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} [status] 
         * @param {string} [categoryId] 
         * @param {string} [location] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyEquipment: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Category' | 'Status' | 'Location', query?: string, pageSize?: number, status?: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', categoryId?: string, location?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('searchCompanyEquipment', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/equipment/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set equipment category checklist templates
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} checkoutChecklistTemplateId 
         * @param {string} checkinChecklistTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEquipmentCategoryChecklistTemplates: async (companyId: string, equipmentCategoryId: string, checkoutChecklistTemplateId: string, checkinChecklistTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('setEquipmentCategoryChecklistTemplates', 'companyId', companyId)
            // verify required parameter 'equipmentCategoryId' is not null or undefined
            assertParamExists('setEquipmentCategoryChecklistTemplates', 'equipmentCategoryId', equipmentCategoryId)
            // verify required parameter 'checkoutChecklistTemplateId' is not null or undefined
            assertParamExists('setEquipmentCategoryChecklistTemplates', 'checkoutChecklistTemplateId', checkoutChecklistTemplateId)
            // verify required parameter 'checkinChecklistTemplateId' is not null or undefined
            assertParamExists('setEquipmentCategoryChecklistTemplates', 'checkinChecklistTemplateId', checkinChecklistTemplateId)
            const localVarPath = `/v1/company/{companyId}/equipment-category/{equipmentCategoryId}/checkout/{checkoutChecklistTemplateId}/checkin/{checkinChecklistTemplateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentCategoryId"}}`, encodeURIComponent(String(equipmentCategoryId)))
                .replace(`{${"checkoutChecklistTemplateId"}}`, encodeURIComponent(String(checkoutChecklistTemplateId)))
                .replace(`{${"checkinChecklistTemplateId"}}`, encodeURIComponent(String(checkinChecklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipment: async (companyId: string, equipmentId: string, equipmentPutRequest: EquipmentPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEquipment', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('updateEquipment', 'equipmentId', equipmentId)
            // verify required parameter 'equipmentPutRequest' is not null or undefined
            assertParamExists('updateEquipment', 'equipmentPutRequest', equipmentPutRequest)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {EquipmentCategoryPutRequest} equipmentCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentCategory: async (companyId: string, equipmentCategoryId: string, equipmentCategoryPutRequest: EquipmentCategoryPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEquipmentCategory', 'companyId', companyId)
            // verify required parameter 'equipmentCategoryId' is not null or undefined
            assertParamExists('updateEquipmentCategory', 'equipmentCategoryId', equipmentCategoryId)
            // verify required parameter 'equipmentCategoryPutRequest' is not null or undefined
            assertParamExists('updateEquipmentCategory', 'equipmentCategoryPutRequest', equipmentCategoryPutRequest)
            const localVarPath = `/v1/company/{companyId}/equipment-category/{equipmentCategoryId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentCategoryId"}}`, encodeURIComponent(String(equipmentCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentCategoryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update equipment status
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} status 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentStatus: async (companyId: string, equipmentId: string, status: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEquipmentStatus', 'companyId', companyId)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('updateEquipmentStatus', 'equipmentId', equipmentId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('updateEquipmentStatus', 'status', status)
            const localVarPath = `/v1/company/{companyId}/equipment/{equipmentId}/status`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"equipmentId"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check in equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinEquipment(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinEquipment(companyId, equipmentId, notes, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check in equipment by admin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinEquipmentByAdmin(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinEquipmentByAdmin(companyId, equipmentId, notes, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check out equipment to user
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutEquipment(companyId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutEquipment(companyId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check out equipment to user by admin
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutEquipmentByAdmin(companyId: string, userId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutEquipmentByAdmin(companyId, userId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create equipment
         * @param {string} companyId 
         * @param {EquipmentPostRequest} equipmentPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquipment(companyId: string, equipmentPostRequest: EquipmentPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Equipment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquipment(companyId, equipmentPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create equipment category
         * @param {string} companyId 
         * @param {EquipmentCategoryPostRequest} equipmentCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquipmentCategory(companyId: string, equipmentCategoryPostRequest: EquipmentCategoryPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquipmentCategory(companyId, equipmentCategoryPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEquipment(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEquipment(companyId, equipmentId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEquipmentCategory(companyId, equipmentCategoryId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment category list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEquipmentCategories(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEquipmentCategories(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all unique locations of equipments
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEquipmentLocations(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEquipmentLocations(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyEquipmentList(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Equipment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEquipmentList(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment by id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquipmentById(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Equipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipmentById(companyId, equipmentId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment category by id
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipmentCategory(companyId, equipmentCategoryId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment history by equipment id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquipmentHistoryByEquipmentId(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipmentHistoryByEquipmentId(companyId, equipmentId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment list for user (rentals)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquipmentRentalsForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Equipment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipmentRentalsForUser(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get unused equipment categories
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnusedEquipmentCategories(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnusedEquipmentCategories(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initialize equipment checkin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeEquipmentCheckin(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeEquipmentCheckin(companyId, equipmentId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initialize equipment checkout
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeEquipmentCheckout(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeEquipmentCheckout(companyId, equipmentId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equipment list by company id, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Category' | 'Status' | 'Location'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} [status] 
         * @param {string} [categoryId] 
         * @param {string} [location] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanyEquipment(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Category' | 'Status' | 'Location', query?: string, pageSize?: number, status?: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', categoryId?: string, location?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseEquipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanyEquipment(companyId, page, sortDirection, sortField, query, pageSize, status, categoryId, location, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set equipment category checklist templates
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} checkoutChecklistTemplateId 
         * @param {string} checkinChecklistTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEquipmentCategoryChecklistTemplates(companyId: string, equipmentCategoryId: string, checkoutChecklistTemplateId: string, checkinChecklistTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEquipmentCategoryChecklistTemplates(companyId, equipmentCategoryId, checkoutChecklistTemplateId, checkinChecklistTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEquipment(companyId: string, equipmentId: string, equipmentPutRequest: EquipmentPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Equipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEquipment(companyId, equipmentId, equipmentPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {EquipmentCategoryPutRequest} equipmentCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEquipmentCategory(companyId: string, equipmentCategoryId: string, equipmentCategoryPutRequest: EquipmentCategoryPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEquipmentCategory(companyId, equipmentCategoryId, equipmentCategoryPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update equipment status
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} status 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEquipmentStatus(companyId: string, equipmentId: string, status: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Equipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEquipmentStatus(companyId, equipmentId, status, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Check in equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinEquipment(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.checkinEquipment(companyId, equipmentId, notes, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check in equipment by admin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [notes] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinEquipmentByAdmin(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.checkinEquipmentByAdmin(companyId, equipmentId, notes, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check out equipment to user
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutEquipment(companyId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: any): AxiosPromise<object> {
            return localVarFp.checkoutEquipment(companyId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check out equipment to user by admin
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} equipmentId 
         * @param {boolean} externalCheckout 
         * @param {string} [expectedCheckinDateTime] 
         * @param {string} [authorization] 
         * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutEquipmentByAdmin(companyId: string, userId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: any): AxiosPromise<object> {
            return localVarFp.checkoutEquipmentByAdmin(companyId, userId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create equipment
         * @param {string} companyId 
         * @param {EquipmentPostRequest} equipmentPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipment(companyId: string, equipmentPostRequest: EquipmentPostRequest, authorization?: string, options?: any): AxiosPromise<Array<Equipment>> {
            return localVarFp.createEquipment(companyId, equipmentPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create equipment category
         * @param {string} companyId 
         * @param {EquipmentCategoryPostRequest} equipmentCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipmentCategory(companyId: string, equipmentCategoryPostRequest: EquipmentCategoryPostRequest, authorization?: string, options?: any): AxiosPromise<EquipmentCategory> {
            return localVarFp.createEquipmentCategory(companyId, equipmentCategoryPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipment(companyId: string, equipmentId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEquipment(companyId, equipmentId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEquipmentCategory(companyId, equipmentCategoryId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment category list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentCategories(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<EquipmentCategory>> {
            return localVarFp.getAllEquipmentCategories(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all unique locations of equipments
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentLocations(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllEquipmentLocations(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment list by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEquipmentList(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Equipment>> {
            return localVarFp.getCompanyEquipmentList(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment by id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentById(companyId: string, equipmentId: string, authorization?: string, options?: any): AxiosPromise<Equipment> {
            return localVarFp.getEquipmentById(companyId, equipmentId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment category by id
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: any): AxiosPromise<EquipmentCategory> {
            return localVarFp.getEquipmentCategory(companyId, equipmentCategoryId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment history by equipment id
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentHistoryByEquipmentId(companyId: string, equipmentId: string, authorization?: string, options?: any): AxiosPromise<Array<EquipmentHistory>> {
            return localVarFp.getEquipmentHistoryByEquipmentId(companyId, equipmentId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment list for user (rentals)
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentRentalsForUser(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Equipment>> {
            return localVarFp.getEquipmentRentalsForUser(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unused equipment categories
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnusedEquipmentCategories(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<EquipmentCategory>> {
            return localVarFp.getUnusedEquipmentCategories(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize equipment checkin
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeEquipmentCheckin(companyId: string, equipmentId: string, authorization?: string, options?: any): AxiosPromise<EquipmentHistory> {
            return localVarFp.initializeEquipmentCheckin(companyId, equipmentId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize equipment checkout
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeEquipmentCheckout(companyId: string, equipmentId: string, authorization?: string, options?: any): AxiosPromise<EquipmentHistory> {
            return localVarFp.initializeEquipmentCheckout(companyId, equipmentId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equipment list by company id, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Name' | 'Category' | 'Status' | 'Location'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} [status] 
         * @param {string} [categoryId] 
         * @param {string} [location] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyEquipment(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Category' | 'Status' | 'Location', query?: string, pageSize?: number, status?: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', categoryId?: string, location?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseEquipment> {
            return localVarFp.searchCompanyEquipment(companyId, page, sortDirection, sortField, query, pageSize, status, categoryId, location, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set equipment category checklist templates
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {string} checkoutChecklistTemplateId 
         * @param {string} checkinChecklistTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEquipmentCategoryChecklistTemplates(companyId: string, equipmentCategoryId: string, checkoutChecklistTemplateId: string, checkinChecklistTemplateId: string, authorization?: string, options?: any): AxiosPromise<EquipmentCategory> {
            return localVarFp.setEquipmentCategoryChecklistTemplates(companyId, equipmentCategoryId, checkoutChecklistTemplateId, checkinChecklistTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update equipment
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {EquipmentPutRequest} equipmentPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipment(companyId: string, equipmentId: string, equipmentPutRequest: EquipmentPutRequest, authorization?: string, options?: any): AxiosPromise<Equipment> {
            return localVarFp.updateEquipment(companyId, equipmentId, equipmentPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update equipment category
         * @param {string} companyId 
         * @param {string} equipmentCategoryId 
         * @param {EquipmentCategoryPutRequest} equipmentCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentCategory(companyId: string, equipmentCategoryId: string, equipmentCategoryPutRequest: EquipmentCategoryPutRequest, authorization?: string, options?: any): AxiosPromise<EquipmentCategory> {
            return localVarFp.updateEquipmentCategory(companyId, equipmentCategoryId, equipmentCategoryPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update equipment status
         * @param {string} companyId 
         * @param {string} equipmentId 
         * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} status 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentStatus(companyId: string, equipmentId: string, status: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', authorization?: string, options?: any): AxiosPromise<Equipment> {
            return localVarFp.updateEquipmentStatus(companyId, equipmentId, status, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @summary Check in equipment
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [notes] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public checkinEquipment(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).checkinEquipment(companyId, equipmentId, notes, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check in equipment by admin
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [notes] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public checkinEquipmentByAdmin(companyId: string, equipmentId: string, notes?: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).checkinEquipmentByAdmin(companyId, equipmentId, notes, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check out equipment to user
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {boolean} externalCheckout 
     * @param {string} [expectedCheckinDateTime] 
     * @param {string} [authorization] 
     * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public checkoutEquipment(companyId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).checkoutEquipment(companyId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check out equipment to user by admin
     * @param {string} companyId 
     * @param {string} userId 
     * @param {string} equipmentId 
     * @param {boolean} externalCheckout 
     * @param {string} [expectedCheckinDateTime] 
     * @param {string} [authorization] 
     * @param {EquipmentExternalCheckoutRequest} [equipmentExternalCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public checkoutEquipmentByAdmin(companyId: string, userId: string, equipmentId: string, externalCheckout: boolean, expectedCheckinDateTime?: string, authorization?: string, equipmentExternalCheckoutRequest?: EquipmentExternalCheckoutRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).checkoutEquipmentByAdmin(companyId, userId, equipmentId, externalCheckout, expectedCheckinDateTime, authorization, equipmentExternalCheckoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create equipment
     * @param {string} companyId 
     * @param {EquipmentPostRequest} equipmentPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public createEquipment(companyId: string, equipmentPostRequest: EquipmentPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).createEquipment(companyId, equipmentPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create equipment category
     * @param {string} companyId 
     * @param {EquipmentCategoryPostRequest} equipmentCategoryPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public createEquipmentCategory(companyId: string, equipmentCategoryPostRequest: EquipmentCategoryPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).createEquipmentCategory(companyId, equipmentCategoryPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete equipment
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public deleteEquipment(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).deleteEquipment(companyId, equipmentId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete equipment category
     * @param {string} companyId 
     * @param {string} equipmentCategoryId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public deleteEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).deleteEquipmentCategory(companyId, equipmentCategoryId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment category list by company id
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getAllEquipmentCategories(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getAllEquipmentCategories(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all unique locations of equipments
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getAllEquipmentLocations(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getAllEquipmentLocations(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment list by company id
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getCompanyEquipmentList(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getCompanyEquipmentList(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment by id
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getEquipmentById(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getEquipmentById(companyId, equipmentId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment category by id
     * @param {string} companyId 
     * @param {string} equipmentCategoryId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getEquipmentCategory(companyId: string, equipmentCategoryId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getEquipmentCategory(companyId, equipmentCategoryId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment history by equipment id
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getEquipmentHistoryByEquipmentId(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getEquipmentHistoryByEquipmentId(companyId, equipmentId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment list for user (rentals)
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getEquipmentRentalsForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getEquipmentRentalsForUser(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unused equipment categories
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getUnusedEquipmentCategories(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getUnusedEquipmentCategories(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize equipment checkin
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public initializeEquipmentCheckin(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).initializeEquipmentCheckin(companyId, equipmentId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize equipment checkout
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public initializeEquipmentCheckout(companyId: string, equipmentId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).initializeEquipmentCheckout(companyId, equipmentId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equipment list by company id, paginated
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Name' | 'Category' | 'Status' | 'Location'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} [status] 
     * @param {string} [categoryId] 
     * @param {string} [location] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public searchCompanyEquipment(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Name' | 'Category' | 'Status' | 'Location', query?: string, pageSize?: number, status?: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', categoryId?: string, location?: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).searchCompanyEquipment(companyId, page, sortDirection, sortField, query, pageSize, status, categoryId, location, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set equipment category checklist templates
     * @param {string} companyId 
     * @param {string} equipmentCategoryId 
     * @param {string} checkoutChecklistTemplateId 
     * @param {string} checkinChecklistTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public setEquipmentCategoryChecklistTemplates(companyId: string, equipmentCategoryId: string, checkoutChecklistTemplateId: string, checkinChecklistTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).setEquipmentCategoryChecklistTemplates(companyId, equipmentCategoryId, checkoutChecklistTemplateId, checkinChecklistTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update equipment
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {EquipmentPutRequest} equipmentPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public updateEquipment(companyId: string, equipmentId: string, equipmentPutRequest: EquipmentPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).updateEquipment(companyId, equipmentId, equipmentPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update equipment category
     * @param {string} companyId 
     * @param {string} equipmentCategoryId 
     * @param {EquipmentCategoryPutRequest} equipmentCategoryPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public updateEquipmentCategory(companyId: string, equipmentCategoryId: string, equipmentCategoryPutRequest: EquipmentCategoryPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).updateEquipmentCategory(companyId, equipmentCategoryId, equipmentCategoryPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update equipment status
     * @param {string} companyId 
     * @param {string} equipmentId 
     * @param {'Available' | 'Unavailable' | 'NeedsService' | 'InService'} status 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public updateEquipmentStatus(companyId: string, equipmentId: string, status: 'Available' | 'Unavailable' | 'NeedsService' | 'InService', authorization?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).updateEquipmentStatus(companyId, equipmentId, status, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
