import { createFileRoute, Link } from "@tanstack/react-router";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import React, { useState } from "react";
import PageLayout from "../../../../components/PageLayout";
import CardNew from "../../../../components/ds/CardNew";
import ButtonNew from "../../../../components/ds/ButtonNew";
import { Callout, Link as RadixLink, Text } from "@radix-ui/themes";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useGetDashboardOverview } from "../../../../service/api/DashboardApi";
import { useCompanyId } from "../../../../hooks/useCompanyId";
import { toUTC } from "../../../../utils/DateTimeFunctions";
import { useGetSalesDashboardData } from "../../../../service/api/CmDashboardApi";
import { useGetTimekeepingDashboardData } from "../../../../service/api/TkmDashboardApi";
import DetailsCard from "./-components/DetailsCard";
import { formatCurrency } from "../../../../utils/currencyFormatter";
import { formatMonth } from "../../../../utils/DateTimeFormatter";
import { subMonths } from "date-fns";
import ProgressPlanCard from "./-components/ProgressPlanCard";
import WorkAssignmentCard from "./-components/WorkAssignmentCard";
import NotificationComponent from "../../../../components/navigation/NotificationComponent";
import InspectionDialog from "./-components/resourcePlanner/InspectionDialog";
import { useGetActiveOrdersByCompanyId } from "../../../../service/api/OrderApi";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import useDialog from "../../../../hooks/useDialog";
import { useAppStore } from "../../../../state/app_store";
import { useTranslation } from "react-i18next";
import CalendarMonth from "../../../../components/ds/DatePicker/CalendarMonth";
import RadixIcon from "../../../../components/ds/RadixIcon";

export const Route = createFileRoute("/_protected/dashboard/overview/")({
  component: OverviewPage
});

function OverviewPage() {
  const companyId = useCompanyId();
  const company = useAppStore((s) => s.selectedCompany);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const queryClient = useQueryClient();
  const inspectionDialog = useDialog();
  const { t } = useTranslation();

  const apiOverviewQuery = useGetDashboardOverview({
    placeholderData: keepPreviousData,
    variables: { companyId, date: toUTC(selectedDate).toISOString() }
  });

  const salesOverviewQuery = useGetSalesDashboardData({
    placeholderData: keepPreviousData,
    variables: { companyId, date: toUTC(selectedDate).toISOString() }
  });

  const timekeepingOverviewQuery = useGetTimekeepingDashboardData({
    placeholderData: keepPreviousData,
    variables: { companyId, date: toUTC(selectedDate).toISOString() }
  });

  const salesData = salesOverviewQuery.data;
  const apiData = apiOverviewQuery.data;
  const timeData = timekeepingOverviewQuery.data;

  const invoiceData =
    apiData?.invoiceData?.map((sum, index) => ({
      name: formatMonth(subMonths(selectedDate, 3 - index), "short"),
      value: sum
    })) ?? [];

  return (
    <>
      <InspectionDialog
        isOpen={inspectionDialog.isOpen}
        onClose={inspectionDialog.onClose}
        onUpdated={() => {
          queryClient.invalidateQueries({
            queryKey: useGetActiveOrdersByCompanyId.getKey()
          });
        }}
      />
      <NewPageTitle title={"Dashboard"} />
      <PageLayout
        preview={
          <>
            <Text size={"3"} weight={"bold"} mb={"3"} as={"p"}>
              {t("createNew")}
            </Text>
            <div className={"grid grid-cols-2 gap-2"}>
              <ButtonNew
                asChild
                color={"green"}
                variant={"soft"}
                icon={"group"}
                size={"2"}
                className={"justify-start"}
              >
                <Link to={"/dashboard/customers/new"}>{t("customer")}</Link>
              </ButtonNew>
              {company?.features.sales && (
                <ButtonNew
                  color={"red"}
                  variant={"soft"}
                  icon={"paid"}
                  className={"justify-start"}
                  asChild
                >
                  <Link to={"/dashboard/sales/sales-opportunity/new"}>
                    {t("sale")}
                  </Link>
                </ButtonNew>
              )}
              <ButtonNew
                color={"yellow"}
                variant={"soft"}
                icon={"edit_square"}
                className={"justify-start"}
                onClick={inspectionDialog.onOpen}
              >
                {t("inspection")}
              </ButtonNew>
              {company?.features.projects && (
                <ButtonNew
                  asChild
                  color={"blue"}
                  variant={"soft"}
                  icon={"layers"}
                  className={"justify-start"}
                >
                  <Link to={"/dashboard/project/new"}>{t("project")}</Link>
                </ButtonNew>
              )}
              {company?.features?.orders && (
                <ButtonNew
                  color={"orange"}
                  variant={"soft"}
                  icon={"local_shipping"}
                  className={"justify-start"}
                  asChild
                >
                  <Link to={"/dashboard/orders/new"}>{t("workOrder")}</Link>
                </ButtonNew>
              )}
            </div>
            <hr className={"my-6"} />

            <NotificationComponent />
          </>
        }
      >
        {company?.subscription === "FREE_TRIAL" && (
          <Callout.Root color={"yellow"} className={"mb-5"}>
            <Callout.Icon>
              <RadixIcon icon={"info"} />
            </Callout.Icon>
            <Callout.Text>
              {t("freeTrialWarning1", {
                count: company?.freeTrialDaysRemaining
              })}{" "}
              <RadixLink asChild>
                <Link to={"/trial/expired"}>{t("here")}</Link>
              </RadixLink>{" "}
              {t("freeTrialWarning2")}
            </Callout.Text>
          </Callout.Root>
        )}
        <div className={"grid grid-cols-2 gap-6"}>
          <CardNew
            inset
            title={t("overview")}
            className={"col-span-2"}
            trailing={
              <CalendarMonth
                value={selectedDate}
                onChange={setSelectedDate}
                trigger={
                  <ButtonNew
                    variant={"soft"}
                    trailingIcon={"keyboard_arrow_down"}
                  >{`${formatMonth(selectedDate)} ${selectedDate.getFullYear()}`}</ButtonNew>
                }
              />
            }
          >
            <div className={"flex flex-col xl:flex-row"}>
              <div
                className={
                  "grid grid-cols-1 lg:grid-cols-2 3xl:grid-cols-3 grow"
                }
              >
                <DetailsCard
                  title={t("activeProjects")}
                  subtitle={`${t("totalContractValue")} ${formatCurrency(apiData?.totalContractValue)}`}
                  number={apiData?.activeProjects?.toString()}
                  loading={apiOverviewQuery.isLoading}
                />
                <DetailsCard
                  title={t("plannedWorkOrders")}
                  number={apiData?.activeOrders?.toString()}
                  loading={apiOverviewQuery.isLoading}
                />
                <DetailsCard
                  title={t("signedOffers")}
                  isPositiveChange={(salesData?.signedOffersChange ?? 0) >= 0}
                  change={`${Math.abs(salesData?.signedOffersChange ?? 0)}`}
                  number={salesData?.signedOffers?.toString()}
                  subtitle={`${salesOverviewQuery.data?.totalOffers?.toString()} ${t("total")}`}
                  loading={salesOverviewQuery.isLoading}
                />
                <DetailsCard
                  loading={salesOverviewQuery.isLoading}
                  title={t("valueSalesOpportunities")}
                  isPositiveChange={
                    (salesData?.salesOpportunityPercentChange ?? 0) >= 0
                  }
                  number={formatCurrency(salesData?.salesOpportunityValue ?? 0)}
                  change={`${Math.abs(salesData?.salesOpportunityPercentChange ?? 0)}%`}
                />
                <DetailsCard
                  loading={apiOverviewQuery.isLoading}
                  title={t("totalInvoiced")}
                  change={`${Math.abs(apiData?.totalInvoicedChange ?? 0)}%`}
                  isPositiveChange={(apiData?.totalInvoicedChange ?? 0) >= 0}
                  number={formatCurrency(apiData?.totalInvoiced ?? 0)}
                />
                <DetailsCard
                  loading={timekeepingOverviewQuery.isLoading}
                  title={t("hoursForApproval")}
                  number={timeData?.hoursForApproval?.toString()}
                  subtitle={`${timeData?.totalHoursRegistered} ${t("total")}`}
                />
              </div>
              <div
                className={
                  "p-6 flex flex-col justify-center border-t border-t-radix-gray-6 xl:border-t-0"
                }
              >
                <div className={"min-w-[280px]"}>
                  <Text
                    size={"1"}
                    color={"gray"}
                    weight={"medium"}
                    as={"p"}
                    mb={"3"}
                  >
                    {t("invoicing")}
                  </Text>

                  <ResponsiveContainer height={240}>
                    <BarChart
                      data={invoiceData}
                      margin={{
                        right: 0,
                        top: 0,
                        bottom: 0
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis
                        dataKey="name"
                        tickLine={{ stroke: "none" }}
                        axisLine={{ stroke: "none" }}
                        fontSize={"var(--font-size-1)"}
                        fontWeight={"var(--font-weight-medium)"}
                        color={"var(--accent-a11)"}
                      ></XAxis>
                      <YAxis
                        tickFormatter={(v) => formatCurrency(v)}
                        width={100}
                        axisLine={{ stroke: "none" }}
                        tickLine={{ stroke: "none" }}
                        fontSize={"var(--font-size-1)"}
                        fontWeight={"var(--font-weight-medium)"}
                        color={"var(--accent-a11)"}
                      />
                      <Tooltip
                        content={({ payload, label }) => {
                          return (
                            <div
                              className={
                                "rt-PopoverContent rt-PopperContent rt-r-size-1"
                              }
                            >
                              <Text as={"p"} weight={"bold"}>
                                {label}
                              </Text>
                              <Text as={"p"}>
                                {formatCurrency(
                                  payload?.[0]?.value?.toString()
                                )}
                              </Text>
                            </div>
                          );
                        }}
                        cursor={{ fill: "var(--accent-3)", radius: "20px" }}
                      />
                      {/*<Bar fill={"var(--gray-9)"} radius={[4, 4, 0, 0]} />*/}
                      <Bar
                        dataKey="value"
                        fill={"var(--green-9)"}
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>

                  <div className={"flex justify-center items-center"}>
                    <div className={"h-2 w-2 bg-radix-gray-9 mr-1 rounded"} />
                    <Text size={"1"} color={"gray"} weight={"medium"}>
                      {t("budget")}
                    </Text>
                    <div
                      className={"ml-4 h-2 w-2 bg-radix-success-9 mr-1 rounded"}
                    />
                    <Text size={"1"} color={"gray"} weight={"medium"}>
                      {t("actual")}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </CardNew>
          <CardNew
            title={t("today")}
            trailing={
              <ButtonNew
                asChild
                size={"1"}
                variant={"soft"}
                trailingIcon={"keyboard_arrow_right"}
              >
                <Link
                  to={"/dashboard/employees"}
                  search={{
                    tab: "resource-planner"
                  }}
                >
                  {t("resourcePlanner")}
                </Link>
              </ButtonNew>
            }
          >
            <div className={"flex flex-col gap-4"}>
              {apiData?.workAssignments?.map((wa) => (
                <WorkAssignmentCard workAssignment={wa} key={wa.assignmentId} />
              ))}
            </div>
          </CardNew>
          <CardNew
            title={t("progress")}
            trailing={
              <ButtonNew
                asChild
                variant={"soft"}
                size={"1"}
                trailingIcon={"keyboard_arrow_right"}
              >
                <Link to={"/dashboard/project/progress-plan"}>
                  {t("progressPlan")}
                </Link>
              </ButtonNew>
            }
          >
            <div className={"flex flex-col gap-4"}>
              {apiData?.progressPlans?.map((pp) => (
                <ProgressPlanCard item={pp} key={pp.planId} />
              ))}
            </div>
          </CardNew>
        </div>
      </PageLayout>
    </>
  );
}
