import {
  ActivityValueTypeEnum,
  TimeEntry,
  UserActivityValueTypeEnum
} from "../../../.generated/api";
import {
  getKilometersFromTimeSubmits,
  getMinutesOnTimeSubmits
} from "../../../service/api/utils";

export function getHours(timeSubmits?: TimeEntry[]) {
  const hours =
    Math.round((getMinutesOnTimeSubmits(timeSubmits ?? []) / 60) * 100) / 100;
  if (hours) return hours;
  return 0;
}

export function getKilometers(timeSubmits?: TimeEntry[]) {
  const kilometers =
    Math.round(getKilometersFromTimeSubmits(timeSubmits ?? []) * 100) / 100;
  if (kilometers) return kilometers;
  return 0;
}

export function getNumericValue(
  valueType: ActivityValueTypeEnum,
  timeEntries?: TimeEntry[]
) {
  return valueType === UserActivityValueTypeEnum.Hours
    ? getHours(timeEntries)
    : getKilometers(timeEntries);
}
