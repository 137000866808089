import React, { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";
import classNames from "classnames";

type TableRowProps = {
  children: ReactNode;
  onClick?: VoidFunction;
  className?: string;
  disabled?: boolean;
} & Omit<ComponentPropsWithoutRef<"tr">, "children">;

// eslint-disable-next-line react/display-name
const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ ...props }, ref) => (
    <tr
      onMouseDown={(e) => e.stopPropagation()}
      onDrag={(e) => e.stopPropagation()}
      ref={ref}
      onClick={props.onClick}
      className={classNames(
        props.className,
        "[&:last-child>td:first-child]:rounded-bl-1 [&:last-child>td:last-child]:rounded-br-1 group/tr",
        {
          "cursor-pointer hover:bg-system-neutral-5": props.onClick,
          "text-system-neutral-90": props.disabled
        }
      )}
      {...props}
    >
      {props.children}
    </tr>
  )
);

export default TableRow;
