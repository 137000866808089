import {
  createFileRoute,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import { UpsertEquipment } from "../-components/UpsertEquipment";
import { z } from "zod";
import { useGetEquipmentById } from "../../../../../service/api/EquipmentApi";

const updateEquipmentSchema = z.object({
  equipmentId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/equipment/$equipmentId/update"
)({
  validateSearch: updateEquipmentSchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { equipmentId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetEquipmentById.getOptions({ companyId, equipmentId })
    });
  },
  component: UpdateEquipment
});

function UpdateEquipment() {
  const { equipmentId } = useParams({
    from: "/_protected/dashboard/equipment/$equipmentId/update"
  });
  const equipment = useLoaderData({
    from: "/_protected/dashboard/equipment/$equipmentId/update"
  });

  return <UpsertEquipment equipmentId={equipmentId} equipment={equipment} />;
}
