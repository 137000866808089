import React, { forwardRef } from "react";
import classNames from "classnames";
import { Responsive } from "@radix-ui/themes/dist/esm/props";

type RadixIconProps = {
  icon: string;
  className?: string;
  size?: Responsive<"1" | "2" | "3" | "4">;
};

const RadixIcon = forwardRef<HTMLSpanElement, RadixIconProps>(
  ({ size = "2", icon, className }, ref) => {
    return (
      <span
        ref={ref}
        className={classNames("material-symbols-outlined", className, {
          "text-[15px]!": size === "1",
          "text-[18px]!": size === "2",
          "text-[22px]!": size === "3",
          "text-[24px]!": size === "4"
        })}
      >
        {icon}
      </span>
    );
  }
);

export default RadixIcon;
