import { FormTextField, FormTextFieldProps } from "../TextFieldNew";
import React, { useEffect, useState } from "react";

type TimeInputFieldProps = Omit<FormTextFieldProps, "value" | "onChange"> & {
  value?: Date;
  onChange: (date: Date) => void;
};

function getFormattedValue(value?: Date) {
  const dateVal = value ? new Date(value) : new Date();
  const hours = dateVal.getHours().toString().padStart(2, "0");
  const minutes = dateVal.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function TimeInputField({
  value,
  onChange,
  ...props
}: TimeInputFieldProps) {
  const [input, setInput] = useState(getFormattedValue(value));

  const handleChange = () => {
    const parts = input.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    if (!Number.isNaN(hours) && !Number.isNaN(minutes)) {
      const newDate = value ? new Date(value) : new Date();
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      onChange(newDate);
    }
  };

  useEffect(() => {
    setInput(getFormattedValue(value));
  }, [value]);

  return (
    <FormTextField
      icon="schedule"
      type="time"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      onBlur={handleChange}
      {...props}
    />
  );
}
