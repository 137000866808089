import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import React, { useState } from "react";
import {
  CompanyProjectPhaseEntity,
  ProjectNumberSeries
} from "../../../../../.generated/api";
import { useToast } from "../../../../../hooks/useToast";
import {
  deleteCompanyProjectPhase,
  useGetProjectPhasesByCompanyId
} from "../../../../../service/api/PhasesApi";
import {
  invertCompanyRestrictedPhaseAccess,
  useGetCompanyDetails
} from "../../../../../service/api/CompanyApi";
import {
  deleteProjectNumberSeries,
  updateProjectNumberSeries,
  useGetProjectNumbersByCompanyId
} from "../../../../../service/api/ProjectNumberApi";
import ProjectPhaseDialog from "./-components/ProjectPhaseDialog";
import ProjectNumberDialog from "./-components/ProjectNumberDialog";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { Text, Tooltip } from "@radix-ui/themes";
import Badge from "../../../../../components/ds/Badge";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import DeleteBlock from "../../../../../components/buttons/DeleteBlock";
import RadixIcon from "../../../../../components/ds/RadixIcon";
import SwitchNew from "../../../../../components/ds/SwitchNew";
import PageLayout from "../../../../../components/PageLayout";
import FormContainer from "../../../../../components/ds/layout/FormContainer";
import FormSection from "../../../../../components/ds/layout/FormSection";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/project"
)({
  component: ProjectSettingsPage
});

function ProjectSettingsPage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const projectNumberDialog = useDialog();
  const projectPhaseDialog = useDialog();
  const [selectedSeries, setSelectedSeries] = useState<ProjectNumberSeries>();
  const [selectedPhase, setSelectedPhase] =
    useState<CompanyProjectPhaseEntity>();
  const { showSuccessToast } = useToast();

  const companyProjectPhasesQuery = useGetProjectPhasesByCompanyId({
    variables: { companyId }
  });

  const companyDetailsQuery = useGetCompanyDetails({
    variables: { companyId }
  });

  const projectNumberQuery = useGetProjectNumbersByCompanyId({
    variables: { companyId }
  });

  const isRestrictedPhaseAccess =
    companyDetailsQuery.data?.isRestrictedPhaseAccess;

  const phases = companyProjectPhasesQuery.data ?? [];
  const projectNumbers = projectNumberQuery.data ?? [];

  async function handleRestrictedPhaseAccessChange() {
    await invertCompanyRestrictedPhaseAccess(companyId);
    await companyDetailsQuery.refetch();
    showSuccessToast(t("statusUpdated"));
  }

  async function toggleDefaultSeries(series: ProjectNumberSeries) {
    await updateProjectNumberSeries(series.id, companyId, {
      title: series.title,
      isDefault: !series.isDefault
    });

    await projectNumberQuery.refetch();
    showSuccessToast(t("statusUpdated"));
  }

  return (
    <PageLayout>
      <FormContainer>
        <ProjectPhaseDialog
          open={projectPhaseDialog.isOpen}
          onClose={projectPhaseDialog.onClose}
          selectedPhase={selectedPhase}
          onUpdated={() => {
            setSelectedPhase(undefined);
            companyProjectPhasesQuery.refetch();
          }}
        />
        <ProjectNumberDialog
          isOpen={projectNumberDialog.isOpen}
          onClose={projectNumberDialog.onClose}
          selectedSeries={selectedSeries}
          onSubmitted={() => {
            projectNumberQuery.refetch();
            setSelectedSeries(undefined);
          }}
        />
        <FormSection
          withSeparator
          title={t("projectPhases")}
          description={t("projectPhasesDescription")}
          content={
            <CardNew
              inset
              trailing={
                <ButtonNew
                  icon={"add"}
                  size={"1"}
                  onClick={() => {
                    setSelectedPhase(undefined);
                    projectPhaseDialog.onOpen();
                  }}
                >
                  {t("add")}
                </ButtonNew>
              }
            >
              <Table variant={"ghost"}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t("name")}</TableHeaderCell>
                    <TableHeaderCell>{t("notifications")}</TableHeaderCell>
                    <TableHeaderCell align={"right"}>
                      {t("estimatedAmountOfHours")}
                    </TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {phases.map((phase, index) => (
                    <TableRow key={phase.id}>
                      <TableCell>{`${index + 1}. ${phase.title}`}</TableCell>
                      <TableCell className={"flex gap-1"}>
                        {phase.recipients?.[0]}
                        {phase.recipients.length > 1 && (
                          <Tooltip
                            content={phase.recipients.slice(1).join("\n")}
                          >
                            <Text>+{phase.recipients.length - 1}</Text>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        align={"right"}
                        py={"0"}
                        className={"align-middle"}
                      >
                        {phase.hourEstimate !== undefined &&
                          phase.hourEstimate > 0 && (
                            <Badge>{phase.hourEstimate} timer</Badge>
                          )}
                      </TableCell>
                      <TableCell py={"0"} className={"align-middle"}>
                        <div
                          className={
                            "flex gap-4 justify-end items-center invisible group-hover/tr:visible"
                          }
                        >
                          {phase.title !== "Ikke påbegynt" && (
                            <>
                              <IconButton
                                variant={"ghost"}
                                icon={"edit"}
                                size={"2"}
                                onClick={() => {
                                  setSelectedPhase(phase);
                                  projectPhaseDialog.onOpen();
                                }}
                              />
                              <DeleteBlock
                                title={t("deleteProjectPhase")}
                                deleteFunction={() =>
                                  deleteCompanyProjectPhase(companyId, phase.id)
                                }
                                confirmationText={t(
                                  "deleteProjectPhaseConfirmation"
                                )}
                                onDeleted={companyProjectPhasesQuery.refetch}
                                render={(onClick) => (
                                  <IconButton
                                    onClick={onClick}
                                    variant={"ghost"}
                                    icon={"delete"}
                                    size={"2"}
                                  />
                                )}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardNew>
          }
        />
        <FormSection
          withSeparator
          title={t("projectNumberSeries")}
          description={t("projectNumberSeriesDescription")}
          content={
            <CardNew
              trailing={
                <ButtonNew
                  icon={"add"}
                  size={"1"}
                  onClick={() => {
                    setSelectedSeries(undefined);
                    projectNumberDialog.onOpen();
                  }}
                >
                  {t("add")}
                </ButtonNew>
              }
              inset
            >
              <Table variant={"ghost"}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t("name")}</TableHeaderCell>
                    <TableHeaderCell>{t("prefix")}</TableHeaderCell>
                    <TableHeaderCell>{t("start")}</TableHeaderCell>
                    <TableHeaderCell align={"right"}>
                      {t("standard")}
                    </TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {projectNumbers.length === 0 && (
                    <TableRow>
                      <TableCell>{t("noSeriesAdded")}</TableCell>
                    </TableRow>
                  )}
                  {projectNumbers.map((series) => (
                    <TableRow key={series.id}>
                      <TableCell>{series.title}</TableCell>
                      <TableCell>{series.prefix}</TableCell>
                      <TableCell>
                        {series.offset.toString().padStart(4, "0")}
                      </TableCell>
                      <TableCell
                        align={"right"}
                        className={"flex justify-end min-w-[160px]"}
                      >
                        <ButtonNew
                          variant={"ghost"}
                          className={"invisible group-hover/tr:visible"}
                          onClick={() => toggleDefaultSeries(series)}
                        >
                          {series.isDefault
                            ? t("removeAsDefault")
                            : t("setAsDefault")}
                        </ButtonNew>
                        {series.isDefault && (
                          <RadixIcon
                            icon={"check"}
                            className={"block group-hover/tr:hidden"}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align={"right"}
                        py={"0"}
                        className={"align-middle"}
                      >
                        <div
                          className={
                            "flex gap-4 invisible group-hover/tr:visible justify-end items-center"
                          }
                        >
                          <IconButton
                            icon={"edit"}
                            variant={"ghost"}
                            onClick={() => {
                              setSelectedSeries(series);
                              projectNumberDialog.onOpen();
                            }}
                          />
                          <DeleteBlock
                            title={t("deleteNumberSeries")}
                            deleteFunction={() =>
                              deleteProjectNumberSeries(companyId, series.id)
                            }
                            confirmationText={t(
                              "deleteNumberSeriesConfirmation"
                            )}
                            onDeleted={() => {
                              projectNumberQuery.refetch();
                              showSuccessToast(t("projectNumberSeriesDeleted"));
                            }}
                            render={(onClick) => (
                              <IconButton
                                icon={"delete"}
                                variant={"ghost"}
                                onClick={onClick}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardNew>
          }
        />
        <FormSection
          title={t("rightsManagement")}
          description={t("rightsManagementDescription")}
          content={
            <CardNew>
              <SwitchNew
                size={"1"}
                label={t("allowUsersToChangeProjectPhasesFromApp")}
                onClick={handleRestrictedPhaseAccessChange}
                checked={!isRestrictedPhaseAccess}
              />
            </CardNew>
          }
        />
      </FormContainer>
    </PageLayout>
  );
}
