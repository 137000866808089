import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import React from "react";
import { UpsertEhsPage } from "../-components/UpsertEhsPage";
import { z } from "zod";
import { ehsResourceType } from "../../../../../router/Routes";
import { useGetEhsTemplateById } from "../../../../../service/api/EhsApi";

const createEhsSchema = z.object({
  resourceType: ehsResourceType,
  resourceId: z.string()
});

export const Route = createFileRoute(
  "/_protected/dashboard/ehs/$templateId/new"
)({
  validateSearch: createEhsSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { templateId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetEhsTemplateById.getOptions({ companyId, templateId })
    });
  },
  component: CreateEhsPage
});

function CreateEhsPage() {
  const { resourceType, resourceId } = useSearch({
    from: "/_protected/dashboard/ehs/$templateId/new"
  });
  const ehsTemplateItem = useLoaderData({
    from: "/_protected/dashboard/ehs/$templateId/new"
  });

  return (
    <UpsertEhsPage
      resourceType={resourceType}
      resourceId={resourceId}
      initialTemplate={ehsTemplateItem}
    />
  );
}
