import { components } from "../../.generated/api-ts";
import { createQuery } from "react-query-kit";
import { client, Merged } from "./ApiClient";
import { VatEnum } from "../../routes/_protected/dashboard/sales/direct-sale/new";

export type DirectSale = components["schemas"]["DirectSale"];
export type VatRate = components["schemas"]["DirectSaleLineItem"]["vat"];
export type DirectSaleSortField =
  | "SaleNumber"
  | "Date"
  | "Created"
  | "PaymentMethod";

const vatMap: Record<VatEnum, number> = {
  vat0: 0,
  vat12: 0.12,
  vat15: 0.15,
  vat25: 0.25
};

export function calculateDiscountedPrice(price: number, discount: number) {
  return price * (1 - discount / 100);
}

export function calculateLineNetAmount(
  price: number,
  quantity: number,
  discount: number
) {
  return calculateDiscountedPrice(price, discount) * quantity;
}

export function calculateLineSum(
  price: number,
  quantity: number,
  discount: number,
  vat: VatRate
) {
  const netSum = calculateLineNetAmount(price, quantity, discount);
  return netSum + calculateLineVat(netSum, vat);
}

export function calculateLineVat(sum: number, vat: VatRate) {
  return sum * vatMap[vat];
}

export async function createDirectSale(
  companyId: string,
  request: components["schemas"]["DirectSalePostRequest"]
) {
  const response = await client.POST("/v1/company/{companyId}/sale", {
    body: request,
    params: {
      path: { companyId }
    }
  });

  return response.data;
}

export async function deleteSale(saleId: string, companyId: string) {
  await client.DELETE("/v1/company/{companyId}/sale/{saleId}", {
    params: {
      path: { companyId, saleId }
    }
  });
}

export async function sendDirectSaleReceipt(
  saleId: string,
  companyId: string,
  email: string
) {
  await client.POST("/v1/company/{companyId}/sale/{saleId}/receipt", {
    body: { email },
    params: {
      path: { companyId, saleId }
    }
  });
}

export const useGetDirectSalesByCompanyId = createQuery({
  queryKey: ["getDirectSalesByCompanyId"],
  fetcher: async (variables: Merged<"getSalesByCompanyId">) => {
    const response = await client.GET("/v1/company/{companyId}/sale", {
      params: variables
    });

    return response.data;
  }
});

export const useGetDirectSaleById = createQuery({
  queryKey: ["getDirectSaleById"],
  fetcher: async (variables: { companyId: string; saleId: string }) => {
    const response = await client.GET("/v1/company/{companyId}/sale/{saleId}", {
      params: {
        path: {
          companyId: variables.companyId,
          saleId: variables.saleId
        }
      }
    });
    return response.data!;
  }
});
