/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { FileCountResponse } from '../model';
// @ts-ignore
import { FilePostRequest } from '../model';
// @ts-ignore
import { FilePutRequest } from '../model';
// @ts-ignore
import { FileResponse } from '../model';
// @ts-ignore
import { SignedUrlRequest } from '../model';
// @ts-ignore
import { SignedUrlResponse } from '../model';
/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new file entry
         * @param {string} resourceId 
         * @param {FilePostRequest} filePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (resourceId: string, filePostRequest: FilePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('createFile', 'resourceId', resourceId)
            // verify required parameter 'filePostRequest' is not null or undefined
            assertParamExists('createFile', 'filePostRequest', filePostRequest)
            const localVarPath = `/v1/resource/{resourceId}/file`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: string, resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteFile', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch multiple files by parent ID
         * @param {string} parentId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFolder: async (parentId: string, resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getByFolder', 'parentId', parentId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getByFolder', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/parent/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (disposition !== undefined) {
                localVarQueryParameter['disposition'] = disposition;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a single file by id
         * @param {'INLINE' | 'ATTACHMENT'} disposition 
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (disposition: 'INLINE' | 'ATTACHMENT', id: string, resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disposition' is not null or undefined
            assertParamExists('getById', 'disposition', disposition)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getById', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (disposition !== undefined) {
                localVarQueryParameter['disposition'] = disposition;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get file count by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileCount: async (resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getFileCount', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/file/count`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByResourceId: async (resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getFilesByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/file`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch public files by parent ID
         * @param {string} resourceId 
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicFiles: async (resourceId: string, folderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getPublicFiles', 'resourceId', resourceId)
            const localVarPath = `/v1/public/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch multiple files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFiles: async (resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getRootFiles', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/file/root`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (disposition !== undefined) {
                localVarQueryParameter['disposition'] = disposition;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a signed URL for uploading files
         * @param {string} resourceId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl: async (resourceId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getUploadUrl', 'resourceId', resourceId)
            // verify required parameter 'signedUrlRequest' is not null or undefined
            assertParamExists('getUploadUrl', 'signedUrlRequest', signedUrlRequest)
            const localVarPath = `/v1/resource/{resourceId}/file/upload`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {FilePutRequest} filePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, resourceId: string, filePutRequest: FilePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('update', 'resourceId', resourceId)
            // verify required parameter 'filePutRequest' is not null or undefined
            assertParamExists('update', 'filePutRequest', filePutRequest)
            const localVarPath = `/v1/resource/{resourceId}/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new file entry
         * @param {string} resourceId 
         * @param {FilePostRequest} filePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(resourceId: string, filePostRequest: FilePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(resourceId, filePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch multiple files by parent ID
         * @param {string} parentId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByFolder(parentId: string, resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByFolder(parentId, resourceId, authorization, disposition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a single file by id
         * @param {'INLINE' | 'ATTACHMENT'} disposition 
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(disposition: 'INLINE' | 'ATTACHMENT', id: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(disposition, id, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get file count by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileCount(resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileCount(resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesByResourceId(resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesByResourceId(resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch public files by parent ID
         * @param {string} resourceId 
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicFiles(resourceId: string, folderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicFiles(resourceId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch multiple files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootFiles(resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootFiles(resourceId, authorization, disposition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a signed URL for uploading files
         * @param {string} resourceId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadUrl(resourceId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadUrl(resourceId, signedUrlRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {FilePutRequest} filePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, resourceId: string, filePutRequest: FilePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, resourceId, filePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new file entry
         * @param {string} resourceId 
         * @param {FilePostRequest} filePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(resourceId: string, filePostRequest: FilePostRequest, authorization?: string, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.createFile(resourceId, filePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: string, resourceId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFile(id, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch multiple files by parent ID
         * @param {string} parentId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFolder(parentId: string, resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: any): AxiosPromise<Array<FileResponse>> {
            return localVarFp.getByFolder(parentId, resourceId, authorization, disposition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a single file by id
         * @param {'INLINE' | 'ATTACHMENT'} disposition 
         * @param {string} id 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(disposition: 'INLINE' | 'ATTACHMENT', id: string, resourceId: string, authorization?: string, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.getById(disposition, id, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get file count by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileCount(resourceId: string, authorization?: string, options?: any): AxiosPromise<FileCountResponse> {
            return localVarFp.getFileCount(resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByResourceId(resourceId: string, authorization?: string, options?: any): AxiosPromise<Array<FileResponse>> {
            return localVarFp.getFilesByResourceId(resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch public files by parent ID
         * @param {string} resourceId 
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicFiles(resourceId: string, folderId?: string, options?: any): AxiosPromise<Array<FileResponse>> {
            return localVarFp.getPublicFiles(resourceId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch multiple files by resource ID
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFiles(resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: any): AxiosPromise<Array<FileResponse>> {
            return localVarFp.getRootFiles(resourceId, authorization, disposition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a signed URL for uploading files
         * @param {string} resourceId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl(resourceId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: any): AxiosPromise<SignedUrlResponse> {
            return localVarFp.getUploadUrl(resourceId, signedUrlRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a file
         * @param {string} id 
         * @param {string} resourceId 
         * @param {FilePutRequest} filePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, resourceId: string, filePutRequest: FilePutRequest, authorization?: string, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.update(id, resourceId, filePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Create a new file entry
     * @param {string} resourceId 
     * @param {FilePostRequest} filePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFile(resourceId: string, filePostRequest: FilePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).createFile(resourceId, filePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a file
     * @param {string} id 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(id: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFile(id, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch multiple files by parent ID
     * @param {string} parentId 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getByFolder(parentId: string, resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getByFolder(parentId, resourceId, authorization, disposition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a single file by id
     * @param {'INLINE' | 'ATTACHMENT'} disposition 
     * @param {string} id 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getById(disposition: 'INLINE' | 'ATTACHMENT', id: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getById(disposition, id, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get file count by resource ID
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileCount(resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFileCount(resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all files by resource ID
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFilesByResourceId(resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFilesByResourceId(resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch public files by parent ID
     * @param {string} resourceId 
     * @param {string} [folderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getPublicFiles(resourceId: string, folderId?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getPublicFiles(resourceId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch multiple files by resource ID
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {'INLINE' | 'ATTACHMENT'} [disposition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getRootFiles(resourceId: string, authorization?: string, disposition?: 'INLINE' | 'ATTACHMENT', options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getRootFiles(resourceId, authorization, disposition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a signed URL for uploading files
     * @param {string} resourceId 
     * @param {SignedUrlRequest} signedUrlRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getUploadUrl(resourceId: string, signedUrlRequest: SignedUrlRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getUploadUrl(resourceId, signedUrlRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a file
     * @param {string} id 
     * @param {string} resourceId 
     * @param {FilePutRequest} filePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public update(id: string, resourceId: string, filePutRequest: FilePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).update(id, resourceId, filePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
