import {
  createFileRoute,
  useNavigate,
  useSearch
} from "@tanstack/react-router";
import { orderTabs } from "../../../../router/Routes";
import { useTranslation } from "react-i18next";
import OrderTab from "../../../../pages/order/OrderTab";
import RecurringOrderTab from "../../../../pages/order/ordersPage/RecurringOrderTab";
import DeviationReportPage from "../../../../pages/timeInvoice/DeviationReportPage";
import OrderArchiveView from "../../../../pages/order/ordersPage/OrderArchiveView";
import React from "react";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TypedTabNew from "../../../../components/ds/TypedTabNew";
import { z } from "zod";

const orderTabSchema = z.object({
  tab: orderTabs.catch("overview").optional(),
  deviationStatus: z.enum(["open", "resolved"]).optional()
});

export const Route = createFileRoute("/_protected/dashboard/orders/")({
  validateSearch: orderTabSchema,
  component: OrderPage
});

function OrderPage() {
  const { tab } = useSearch({ from: "/_protected/dashboard/orders/" });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("workOrders")} />
      <TypedTabNew
        tabs={orderTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={async (tab) => {
          await navigate({
            to: "/dashboard/orders",
            search: { tab: tab }
          });
        }}
      >
        <OrderTab />
        <RecurringOrderTab />
        <DeviationReportPage resourceType={"order"} />
        <OrderArchiveView />
      </TypedTabNew>
    </>
  );
}
