import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import { UpsertDeviationPage } from "../-components/UpsertDeviationPage";
import { useGetDeviationById } from "../../../../../service/api/DeviationApi";

export const Route = createFileRoute(
  "/_protected/dashboard/deviation/$deviationId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({ context: { queryClient }, params: { deviationId } }) => {
    return queryClient.fetchQuery({
      ...useGetDeviationById.getOptions({ deviationId })
    });
  },
  component: UpdateDeviationPage
});

function UpdateDeviationPage() {
  const deviation = useLoaderData({
    from: "/_protected/dashboard/deviation/$deviationId/update"
  });

  return (
    <UpsertDeviationPage
      deviation={deviation}
      resourceType={"project"}
      resourceId={""}
    />
  );
}
