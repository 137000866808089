import { InvoiceApi, InvoicePostRequest } from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const invoiceApi = new InvoiceApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetInvoicesByProjectId = createQuery({
  queryKey: ["getInvoiceByProjectId"],
  fetcher: async (variables: {
    companyId: string;
    projectId: string;
    sortField?: "Title" | "DeliveryDate" | "DueDate" | "InvoiceNumber";
    page?: number;
    sortDirection?: "ASC" | "DESC";
    query?: string;
  }) => {
    const response = await invoiceApi.getInvoicesByProjectId(
      variables.companyId,
      variables.projectId,
      variables.sortField ?? "InvoiceNumber",
      variables.page,
      variables.sortDirection,
      variables.query
    );
    return response.data;
  }
});

export const useGetInvoiceById = createQuery({
  queryKey: ["getInvoiceById"],
  fetcher: async (variables: { invoiceId: string; companyId: string }) => {
    const response = await invoiceApi.getInvoiceById(
      variables.companyId,
      variables.invoiceId
    );
    return response.data;
  }
});

export const useGetInvoicesByCompanyId = createQuery({
  queryKey: ["getInvoicesByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    sortField: "Title" | "DeliveryDate" | "DueDate" | "InvoiceNumber";
    page?: number;
    sortDirection?: "ASC" | "DESC";
    query?: string;
  }) => {
    const response = await invoiceApi.getInvoicesForCompany(
      variables.companyId,
      variables.sortField,
      variables.page,
      variables.sortDirection,
      variables.query
    );
    return response.data;
  }
});

export async function deleteInvoice(companyId: string, invoiceId: string) {
  await invoiceApi.deleteInvoice(companyId, invoiceId);
}

export async function createInvoice(
  companyId: string,
  request: InvoicePostRequest
) {
  const result = await invoiceApi.createInvoice(companyId, request);
  return result.data;
}

export async function getInvoicesByOrderId(companyId: string, orderId: string) {
  const response = await invoiceApi.getInvoicesByOrderId(companyId, orderId);
  return response.data;
}

export async function syncInvoices(companyId: string) {
  await invoiceApi.syncInvoices(companyId);
}
