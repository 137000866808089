// import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useOfferStore } from "./-components/store";
import DetailsPage from "./-components/DetailsPage";
import { getOfferDataMock } from "./-components/templateUtils";
import { z } from "zod";

import { createFileRoute, useSearch } from "@tanstack/react-router";

const offerTemplateSchema = z.object({
  companyId: z.string()
  // offerData: z.string()
  // fromDate: z.string(),
  // toDate: z.string(),
  // tab: projectReportTabs.catch("hours").optional()
});

export const Route = createFileRoute("/_unauthenticated/offer/template")({
  validateSearch: offerTemplateSchema,
  component: OfferTemplatePage
});

function OfferTemplatePage() {
  //   const [searchParams] = useSearchParams();
  //   const companyId = searchParams.get("companyId");

  const { companyId } = useSearch({
    from: "/_unauthenticated/offer/template"
  });

  const offerData = getOfferDataMock();

  const initializeAsPreview = useOfferStore(
    (state) => state.initializeAsPreview
  );
  const offer = useOfferStore((state) => state.offer);
  const error = useOfferStore((state) => state.error);

  useEffect(() => {
    initializeAsPreview(offerData as string);
  }, [initializeAsPreview, offerData]);

  if (offer) {
    return <DetailsPage companyId={companyId ?? undefined} />;
  } else if (error) {
    return <p>{error.message}</p>;
  } else {
    return <p>En feil oppstod</p>;
  }
}
