import { useEffect, useRef, useState } from "react";
import { Dialog as RadixDialog, Inset, Spinner, Text } from "@radix-ui/themes";
import { getAuth } from "firebase/auth";

import { TextFieldNew } from "../ds/TextFieldNew";

import { useCompanyId } from "../../hooks/useCompanyId";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import axios from "axios";
import ButtonNew from "../ds/ButtonNew";

import Markdown from "markdown-to-jsx";

import Table from "../../components/ds/RadixTable/Table";
import TableHeader from "../../components/ds/RadixTable/TableHeader";
import TableHeaderCell from "../../components/ds/RadixTable/TableHeaderCell";
import TableCell from "../../components/ds/RadixTable/TableCell";
import TableRow from "../../components/ds/RadixTable/TableRow";
import TableBody from "../../components/ds/RadixTable/TableBody";
import { t } from "i18next";

export default function SkyworkerChatDialog({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [query, setQuery] = useState("");
  const [requestCount, setRequestCount] = useState(0); // to force re-fetch
  const suggestions = [
    // "Hvor mange prosjekter har vi neste måned?",
    // "Hvem er ledige fra 12:00 til 17:00 på mandag?",
    // "Har kunden på Frogner svart på tilbudet vi sendte?",
    // "Er det en ledig bil neste torsdag?"
    'Hvor mange timer har "Ansatt X" registrert i November',
    "Hva mange timer kan en ansatt jobbe hver uke?",
    'Hvor mange timer har "ansatt X" ført som fravær i 2024',
    "Hva sier arbeidsmiljøloven om overtid?",
    "Hva er TEK 17 standard for bad?"
  ];

  const { items: historyItems, updateWithItem: updateHistoryWithItem } =
    useRecentHistory();

  const [inputVal, setInputVal] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const { answer, loading } = useAiSearch(query, requestCount);

  const clear = () => {
    setInputVal("");
    setQuery("");
    inputRef.current?.focus();
  };
  const submit = () => {
    setRequestCount((prev) => prev + 1);
    setQuery(inputVal);
    updateHistoryWithItem(inputVal);
  };

  return (
    <RadixDialog.Root open={isOpen} onOpenChange={onClose}>
      <RadixDialog.Content
        width={"600px"}
        maxWidth={"600px"}
        maxHeight={"calc(100vh - 100px)"}
        minWidth={"400px"}
        className="flex flex-col"
      >
        <Inset side="all" m="0" className="flex flex-col flex-1">
          <VisuallyHidden.Root>
            <RadixDialog.Title>a title</RadixDialog.Title>
          </VisuallyHidden.Root>

          <div className="border-b border-b-radix-gray-a4 flex py-3 px-4 gap-2 items-center">
            <SkyworkerAIIcon size={"3"} />
            <TextFieldNew
              ref={inputRef}
              value={inputVal}
              variant="soft"
              placeholder="Still et spørsmål"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
              onChange={(e) => {
                setInputVal(e.target.value);
              }}
              className="flex-1"
            />
            <ButtonNew
              size={"1"}
              onClick={() => {
                submit();
              }}
            >
              Spør AI
            </ButtonNew>
          </div>

          {loading || answer ? (
            <div className="flex flex-col items-center px-5 py-7 gap-5 flex-1 overflow-y-auto">
              {loading ? (
                <Spinner />
              ) : (
                <Text size={"3"} as="div" className="self-start w-full">
                  <Markdown
                    options={{
                      overrides: {
                        p: {
                          component: Text,
                          props: {
                            as: "p",
                            className: "my-3",
                            style: {
                              fontSize: "inherit"
                            }
                          }
                        },
                        ol: {
                          component: "ol",
                          props: {
                            style: {
                              listStyleType: "decimal",
                              listStylePosition: "outside",
                              paddingLeft: "1rem"
                            }
                          }
                        },
                        ul: {
                          component: "ul",
                          props: {
                            className: "list-disc list-outside pl-[1rem]"
                          }
                        },
                        li: {
                          component: "li",
                          props: {
                            className: "my-1"
                          }
                        },
                        table: {
                          component: ({ children }) => (
                            <Table size={"1"}>{children}</Table>
                          )
                        },
                        thead: {
                          component: ({ children }) => (
                            <TableHeader>{children}</TableHeader>
                          )
                        },
                        tr: {
                          component: ({ children }) => (
                            <TableRow>{children}</TableRow>
                          )
                        },
                        th: {
                          component: ({ children }) => (
                            <TableHeaderCell>{children}</TableHeaderCell>
                          )
                        },
                        tbody: {
                          component: ({ children }) => (
                            <TableBody>{children}</TableBody>
                          )
                        },
                        td: {
                          component: ({ children }) => (
                            <TableCell>{children}</TableCell>
                          )
                        }
                      }
                    }}
                  >
                    {answer}
                  </Markdown>
                </Text>
              )}
              <ButtonNew
                color="gray"
                variant="soft"
                onClick={clear}
                className="self-center"
                style={{
                  opacity: loading ? 0 : 1,
                  transition: loading ? "opacity 0.3s" : "opacity 1s 0.5s"
                }}
              >
                Still et nytt spørsmål
              </ButtonNew>
            </div>
          ) : (
            <div className="p-2">
              {historyItems.length && (
                <>
                  <Text
                    as={"div"}
                    size={"1"}
                    weight={"medium"}
                    className="px-2 text-radix-gray-a9 mt-3 mb-1"
                  >
                    {t("Historikk")}
                  </Text>
                  {historyItems.map((item, i) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                      // role="button"
                      // tabIndex={0}
                      className="p-2 py-1.5 hover:bg-radix-gray-a4 flex items-center gap-2 cursor-pointer rounded-md"
                      key={i}
                      onClick={() => {
                        setInputVal(item);
                        inputRef.current?.focus();
                        // setQuery(suggestion);
                      }}
                    >
                      <SkyworkerAIIcon color="gray" />
                      <Text size={"2"}>{item}</Text>
                    </div>
                  ))}
                </>
              )}

              <Text
                as={"div"}
                size={"1"}
                weight={"medium"}
                className="px-2 text-radix-gray-a9 mt-3 mb-1"
              >
                {t("Forslag")}
              </Text>

              {suggestions.map((suggestion, i) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  // role="button"
                  // tabIndex={0}
                  className="p-2 py-1.5 hover:bg-radix-gray-a4 flex items-center gap-2 cursor-pointer rounded-md"
                  key={i}
                  onClick={() => {
                    setInputVal(suggestion);
                    inputRef.current?.focus();
                    // setQuery(suggestion);
                  }}
                >
                  <SkyworkerAIIcon color="gray" />
                  <Text size={"2"}>{suggestion}</Text>
                </div>
              ))}
            </div>
          )}
        </Inset>
      </RadixDialog.Content>
    </RadixDialog.Root>
  );
}

const aiInstance = axios.create({
  baseURL:
    "https://api-skyworker-copilot-prod-b4defrf7ezgha5da.eastus-01.azurewebsites.net/api/Proxy"
});

function useAiSearch(query: string, requestCount: number) {
  const companyId = useCompanyId();

  const auth = getAuth();

  const thequery = useQuery({
    queryKey: [companyId, query, requestCount],
    // gcTime: 0,
    queryFn: async ({ signal }) => {
      const response = await aiInstance.post(
        `/chat`,
        {
          content: query
        },
        {
          signal,
          headers: {
            "x-api-key": "f47b1b0d952847388bbfe3fa63a73170",
            "skyworker-token": await auth.currentUser?.getIdToken(),
            "company-id": companyId
          }
        }
      );
      return response.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!query
  });

  return {
    loading: thequery.isLoading,
    answer: query ? thequery.data?.response : ""
  };
}

export const SkyworkerAIIcon = ({
  size = "2",
  className,
  color = "#007760" // green
}: {
  className?: string;
  size?: "1" | "2" | "3" | "4";
  color?: "gray" | "#007760";
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, {
        "w-[15px]": size === "1",
        "w-[18px]": size === "2",
        "w-[22px]": size === "3",
        "w-[24px]": size === "4"
      })}
    >
      <g id="skyworker_ai">
        <path
          id="Star 1"
          d="M9.33398 3.33337C9.33398 3.33337 9.65533 6.43031 10.9462 7.72117C12.237 9.01203 15.334 9.33337 15.334 9.33337C15.334 9.33337 12.237 9.65472 10.9462 10.9456C9.65533 12.2364 9.33398 15.3334 9.33398 15.3334C9.33398 15.3334 9.01264 12.2364 7.72178 10.9456C6.43092 9.65472 3.33398 9.33337 3.33398 9.33337C3.33398 9.33337 6.43092 9.01203 7.72178 7.72117C9.01264 6.43031 9.33398 3.33337 9.33398 3.33337Z"
          fill={color}
        />
        <path
          id="Star 1_2"
          d="M3.99935 0.666626C3.99935 0.666626 4.17787 2.38715 4.89502 3.10429C5.61216 3.82143 7.33268 3.99996 7.33268 3.99996C7.33268 3.99996 5.61216 4.17849 4.89502 4.89563C4.17787 5.61277 3.99935 7.33329 3.99935 7.33329C3.99935 7.33329 3.82082 5.61277 3.10368 4.89563C2.38654 4.17849 0.666016 3.99996 0.666016 3.99996C0.666016 3.99996 2.38654 3.82143 3.10368 3.10429C3.82082 2.38715 3.99935 0.666626 3.99935 0.666626Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

function useRecentHistory() {
  const storageKey = "skyworkerAI_recentHistory";

  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    try {
      const items = JSON.parse(localStorage.getItem(storageKey) ?? "[]");
      setItems(items);
    } catch (_) {
      //
    }
  }, []);

  localStorage.getItem(storageKey);

  return {
    updateWithItem: (item: string) => {
      const updateItems = [item, ...items.filter((i) => i !== item)].slice(
        0,
        8
      );
      localStorage.setItem(storageKey, JSON.stringify(updateItems));

      setItems(updateItems);
    },
    items
  };
}
