import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import React, { useState } from "react";
import {
  updateTimeEntryApproval,
  useGetPaginatedTimeEntriesByCompanyId
} from "../../../../../service/api/TimeEntryApi";
import { keepPreviousData } from "@tanstack/react-query";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import { searchBarSize } from "../../../../../components/ds/sizes";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import { formatDateAndShortMonth } from "../../../../../utils/DateTimeFormatter";
import { addDays } from "date-fns";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import EmployeeTimeTableRow from "./-components/EmployeeTimeTableRow";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import { useTimeFilter } from "../../../../../hooks/useTimeFilter";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import TimeFilterSelector from "../../../../../components/ds/TimeFilterSelector";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import TimeEntryDialog from "../../../../../pages/timeInvoice/timeProject/TimeEntryDialog";
import useDialog from "../../../../../hooks/useDialog";
import { useSelectedGeneric } from "../../../../../hooks/useSelected";
import { CheckboxNew } from "../../../../../components/ds/CheckboxNew";
import { TimeEntry } from "../../../../../.generated/api";
import { useToast } from "../../../../../hooks/useToast";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/time-report"
)({
  component: TimeReport
});

function TimeReport() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const [userQuery, setUserQuery] = useState("");
  const { selectedRange, setSelectedRange } = useTimeFilter("week");
  const { onClose, onOpen, isOpen } = useDialog();
  const { showSuccessToast } = useToast();
  const { selected, handleSelect, isSelected, allSelected, handleSelectAll } =
    useSelectedGeneric<{ id: string }>("id");

  const timeEntryQuery = useGetPaginatedTimeEntriesByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page: -1,
      from: selectedRange.start,
      to: selectedRange.end
    }
  });

  const entries = timeEntryQuery.data?.data ?? [];
  entries.sort((a, b) => a.userId.localeCompare(b.userId));
  const groupedEntries = Object.groupBy(entries, (entry) => entry.userId);
  const entriesMap = Object.keys(groupedEntries);
  const userIds = entriesMap.map((e) => ({
    id: e
  }));

  async function approveHours() {
    const entries: TimeEntry[] = [];
    selected.forEach((s) => {
      const userEntries = groupedEntries[s.id];
      if (userEntries) {
        entries.push(...userEntries);
      }
    });
    await updateTimeEntryApproval(companyId, {
      timeEntryIds: entries.map((e) => e.id),
      approved: true
    });
    showSuccessToast(t("timeEntriesApproved"));
    timeEntryQuery.refetch();
  }

  return (
    <PageLayout>
      <TimeEntryDialog
        open={isOpen}
        onClose={onClose}
        onUpdated={timeEntryQuery.refetch}
      />
      <CardNew
        inset
        title={t("timeReport")}
        headerContent={
          <>
            <TextFieldNew
              placeholder={t("employee")}
              icon={"search"}
              size={"2"}
              className={searchBarSize}
              value={userQuery}
              onChange={(e) => setUserQuery(e.target.value)}
            />
            <div className={"grow"} />
            {selected.length > 0 && (
              <ButtonNew variant={"surface"} onClick={approveHours}>
                {t("approveHours")}
              </ButtonNew>
            )}
            <ButtonNew icon={"add"} onClick={onOpen}>
              {t("addHours")}
            </ButtonNew>
            <TimeFilterSelector
              dates={selectedRange}
              period={"week"}
              onChange={setSelectedRange}
              periods={["week"]}
            />
          </>
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>
                <CheckboxNew
                  checked={allSelected(userIds)}
                  onClick={() => handleSelectAll(userIds)}
                />
              </TableHeaderCell>
              <TableHeaderCell>{t("user")}</TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(selectedRange.start)}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 1))}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 2))}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 3))}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 4))}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 5))}
              </TableHeaderCell>
              <TableHeaderCell>
                {formatDateAndShortMonth(addDays(selectedRange.start, 6))}
              </TableHeaderCell>
              <TableHeaderCell>{t("sum")}</TableHeaderCell>
              <TableHeaderCell>{t("approved")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {entriesMap.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>{t("noTimeEntriesAdded")}</TableCell>
              </TableRow>
            )}
            {Object.keys(groupedEntries).map((userId) => (
              <EmployeeTimeTableRow
                key={userId}
                userId={userId}
                entries={groupedEntries[userId] ?? []}
                startDate={selectedRange.start}
                onRefetch={timeEntryQuery.refetch}
                userSearchQuery={userQuery}
                selected={isSelected({ id: userId })}
                onCheck={() => handleSelect({ id: userId })}
              />
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
