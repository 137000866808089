import {
  createFileRoute,
  useNavigate,
  useSearch
} from "@tanstack/react-router";
import { internalRoutinesAndEhsTabs } from "../../../../router/Routes";
import { useTranslation } from "react-i18next";
import InternalRoutinesView from "../../../../pages/internalRoutinesAndEhs/internalRoutines/InternalRoutinesView";
import EhsTemplateListPage from "../../../../pages/internalRoutinesAndEhs/EhsTemplateListPage";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TypedTabNew from "../../../../components/ds/TypedTabNew";
import { z } from "zod";

const ehsSchema = z.object({
  tab: internalRoutinesAndEhsTabs.optional().catch("internalRoutines")
});

export const Route = createFileRoute("/_protected/dashboard/ehs/")({
  validateSearch: ehsSchema,
  component: InternalRoutinesPage
});

function InternalRoutinesPage() {
  const { tab } = useSearch({ from: "/_protected/dashboard/ehs/" });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("HMS & Interne rutiner")} />
      <TypedTabNew
        tabs={internalRoutinesAndEhsTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={(tab) =>
          navigate({
            to: "/dashboard/ehs",
            search: {
              tab: tab
            }
          })
        }
      >
        <InternalRoutinesView />
        <EhsTemplateListPage />
      </TypedTabNew>
    </>
  );
}
