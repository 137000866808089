import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useAppStore } from "../../../../../state/app_store";
import { useQuery } from "@tanstack/react-query";
import {
  createPricingGroup,
  deletePricingGroup,
  pricingGroupQueries,
  updatePricingGroup
} from "../../../../../service/api/PricingGroupApi";
import {
  createHourlyRate,
  deleteHourlyRate,
  updateHourlyRate,
  useGetHourlyRatesByCompanyId
} from "../../../../../service/api/HourlyRateApi";
import { useToast } from "../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  PriceGroup,
  PriceGroupPostRequest
} from "../../../../../.generated/api";
import PageLayout from "../../../../../components/PageLayout";
import PricesTable from "../../../../../pages/prices/components/PricesTable";
import { formatCurrency } from "../../../../../utils/currencyFormatter";
import CardNew from "../../../../../components/ds/CardNew";
import { FormTextField } from "../../../../../components/ds/TextFieldNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/price-model"
)({
  component: PriceModelPage
});

function PriceModelPage() {
  const companyId = useCompanyId();
  const currency = useAppStore((state) => state.currency);
  const priceGroupQuery = useQuery(
    pricingGroupQueries(companyId).getAllByCompanyId()
  );

  const hourlyRateQuery = useGetHourlyRatesByCompanyId({
    variables: { companyId }
  });

  const priceData = priceGroupQuery.data ?? [];
  const hourlyRateData = hourlyRateQuery.data ?? [];

  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();
  const [newPriceGroup, setNewPriceGroup] = useState<{
    title?: string;
    discountPercent?: number;
  }>();

  const [hourlyRate, setHourlyRate] = useState({
    title: "",
    rate: 0
  });

  async function handleAddPriceGroup() {
    if (newPriceGroup?.title && newPriceGroup?.discountPercent) {
      if (priceGroupQuery.data?.find((p) => p.title === newPriceGroup.title)) {
        showErrorToast(
          t("Det eksisterer allered en prisgruppe med dette navnet")
        );
        return;
      }
      try {
        await createPricingGroup(
          companyId,
          newPriceGroup as PriceGroupPostRequest
        );
        priceGroupQuery.refetch();
        setNewPriceGroup(undefined);
        showSuccessToast(t("Prisgruppe lagt til"));
        setNewPriceGroup({
          title: "",
          discountPercent: 0
        });
      } catch {
        showErrorToast(t("errorCreatingPriceGroup"));
      }
    } else {
      showErrorToast(t("Fyll ut begge feltene"));
    }
  }

  async function handleDeletePriceGroup(group: PriceGroup) {
    try {
      await deletePricingGroup(companyId, group.id);
      priceGroupQuery.refetch();
      showSuccessToast(t("Prisgruppe ble slettet"));
    } catch {
      showErrorToast(t("Feil ved sletting av prisgruppe"));
    }
  }

  async function handelUpdatePriceGroup(
    groupId: string,
    name: string,
    value: number
  ) {
    try {
      await updatePricingGroup(companyId, groupId, {
        title: name,
        discountPercent: value
      });
      priceGroupQuery.refetch();
      showSuccessToast(t("Prisgruppe ble oppdatert"));
    } catch {
      showErrorToast(t("Feil ved oppdatering av prisgruppe"));
    }
  }

  async function handleRateUpdate(rateId: string, name: string, value: number) {
    if (value === 0 || name === "") {
      showErrorToast(t("Fyll ut begge feltene"));
      return;
    }

    try {
      await updateHourlyRate(companyId, rateId, {
        hourlyRate: value,
        title: name
      });
      showSuccessToast(t("Timepris oppdatert"));
      setHourlyRate({ rate: 0, title: "" });
      await hourlyRateQuery.refetch();
    } catch (_) {
      showErrorToast(t("Feil ved oppdatering av timepris"));
    }
  }

  async function handleRateDelete(rateId: string) {
    try {
      await deleteHourlyRate(companyId, rateId);
      showSuccessToast(t("Timepris slettet"));
      await hourlyRateQuery.refetch();
    } catch (_) {
      showErrorToast(t("Feil ved sletting av timepris"));
    }
  }

  async function handleCreateRate() {
    if (hourlyRate.rate === 0 || hourlyRate.title === "") {
      showErrorToast(t("Fyll ut begge feltene"));
      return;
    }

    try {
      await createHourlyRate(companyId, {
        hourlyRate: hourlyRate.rate,
        title: hourlyRate.title
      });
      showSuccessToast(t("Timepris opprettet"));
      setHourlyRate({ rate: 0, title: "" });
      await hourlyRateQuery.refetch();
    } catch (_) {
      showErrorToast(t("Feil ved opprettelse av timepris"));
    }
  }

  return (
    <PageLayout>
      <div className={"grid grid-cols-2 gap-4"}>
        <CardNew
          title={t("Legg til prisgruppe på produkt")}
          button={
            <ButtonNew onClick={() => handleAddPriceGroup()}>
              {t("add")}
            </ButtonNew>
          }
        >
          <FormTextField
            label={t("Navn på prisgruppe")}
            placeholder={t("Kunde B")}
            value={newPriceGroup?.title}
            onChange={(e) =>
              setNewPriceGroup({
                ...newPriceGroup,
                title: e.target.value
              })
            }
          />
          <FormTextField
            label={t("Produktrabatt % (legges til på utsalgspris)")}
            type="number"
            placeholder="5%"
            value={newPriceGroup?.discountPercent}
            onChange={(e) => {
              if (Number.isNaN(+e.target.value)) return;
              setNewPriceGroup({
                ...newPriceGroup,
                discountPercent: Number(e.target.value)
              });
            }}
          />
        </CardNew>
        <PricesTable
          title={t("Prisgrupper")}
          displayValue={(v) => v + "%"}
          onDelete={(index) => handleDeletePriceGroup(priceData[index])}
          onUpdate={(index, name, value) =>
            handelUpdatePriceGroup(priceData[index].id, name, value)
          }
          values={priceData.map((group) => ({
            name: group.title,
            value: group.discountPercent
          }))}
        />
        <CardNew
          title={t("Legg til timepriser")}
          button={<ButtonNew onClick={handleCreateRate}>{t("add")}</ButtonNew>}
        >
          <FormTextField
            label={t("Navn på timepris")}
            placeholder={t("Standard A")}
            value={hourlyRate.title}
            onChange={(e) =>
              setHourlyRate({ ...hourlyRate, title: e.target.value })
            }
          />
          <FormTextField
            label={`${t("Timepris i ")} ${currency} + t(" (eks. mva)")`}
            placeholder="950"
            value={hourlyRate.rate}
            onChange={(e) => {
              if (Number.isNaN(+e.target.value)) return;
              setHourlyRate({ ...hourlyRate, rate: Number(e.target.value) });
            }}
          />
        </CardNew>
        <PricesTable
          title={t("Timepriser")}
          displayValue={(v) => formatCurrency(v)}
          onDelete={(index) => handleRateDelete(hourlyRateData[index].id)}
          onUpdate={(index, name, value) =>
            handleRateUpdate(hourlyRateData[index].id, name, value)
          }
          values={hourlyRateData.map((rate) => ({
            value: rate.hourlyRate,
            name: rate.title
          }))}
        />
      </div>
    </PageLayout>
  );
}
