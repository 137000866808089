import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React, { useState } from "react";
import TaskTable from "../../../../../../pages/projectDetails/tasks/TaskTable";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/task"
)({
  component: ProjectTaskPage
});

function ProjectTaskPage() {
  const [key, setKey] = useState(new Date().getMilliseconds());
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <div className={"w-full m-5"}>
      <TaskTable
        key={(key - 1).toString()}
        toDate={project.to}
        fromDate={project.from}
        projectId={project.id}
        projectPhase={project.projectPhase}
        onUpdated={() => setKey(new Date().getMilliseconds())}
      />
      <TaskTable
        key={key.toString()}
        toDate={project.to}
        fromDate={project.from}
        projectId={project.id}
        onUpdated={() => setKey(new Date().getMilliseconds())}
      />
    </div>
  );
}
