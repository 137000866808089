/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { HourlyRate } from '../model';
// @ts-ignore
import { HourlyRatePostRequest } from '../model';
// @ts-ignore
import { HourlyRatePutRequest } from '../model';
/**
 * HourlyRateApi - axios parameter creator
 * @export
 */
export const HourlyRateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make an Hourly Rate the default for a customer
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerDefaultHourlyRate: async (companyId: string, hourlyRateId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addCustomerDefaultHourlyRate', 'companyId', companyId)
            // verify required parameter 'hourlyRateId' is not null or undefined
            assertParamExists('addCustomerDefaultHourlyRate', 'hourlyRateId', hourlyRateId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('addCustomerDefaultHourlyRate', 'customerId', customerId)
            const localVarPath = `/v1/company/{companyId}/hourly-rates/{hourlyRateId}/customer/{customerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"hourlyRateId"}}`, encodeURIComponent(String(hourlyRateId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an hourly rate
         * @param {string} companyId 
         * @param {HourlyRatePostRequest} hourlyRatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHourlyRate: async (companyId: string, hourlyRatePostRequest: HourlyRatePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createHourlyRate', 'companyId', companyId)
            // verify required parameter 'hourlyRatePostRequest' is not null or undefined
            assertParamExists('createHourlyRate', 'hourlyRatePostRequest', hourlyRatePostRequest)
            const localVarPath = `/v1/company/{companyId}/hourly-rates`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourlyRatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHourlyRate: async (companyId: string, hourlyRateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteHourlyRate', 'companyId', companyId)
            // verify required parameter 'hourlyRateId' is not null or undefined
            assertParamExists('deleteHourlyRate', 'hourlyRateId', hourlyRateId)
            const localVarPath = `/v1/company/{companyId}/hourly-rates/{hourlyRateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"hourlyRateId"}}`, encodeURIComponent(String(hourlyRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the default hourly rate for a customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRateByCustomerId: async (companyId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getHourlyRateByCustomerId', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getHourlyRateByCustomerId', 'customerId', customerId)
            const localVarPath = `/v1/company/{companyId}/hourly-rates/customer/{customerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an hourly rate by id
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRateById: async (companyId: string, hourlyRateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getHourlyRateById', 'companyId', companyId)
            // verify required parameter 'hourlyRateId' is not null or undefined
            assertParamExists('getHourlyRateById', 'hourlyRateId', hourlyRateId)
            const localVarPath = `/v1/company/{companyId}/hourly-rates/{hourlyRateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"hourlyRateId"}}`, encodeURIComponent(String(hourlyRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all hourly rates for a company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRatesByCompanyId: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getHourlyRatesByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/hourly-rates`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {HourlyRatePutRequest} hourlyRatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHourlyRate: async (companyId: string, hourlyRateId: string, hourlyRatePutRequest: HourlyRatePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateHourlyRate', 'companyId', companyId)
            // verify required parameter 'hourlyRateId' is not null or undefined
            assertParamExists('updateHourlyRate', 'hourlyRateId', hourlyRateId)
            // verify required parameter 'hourlyRatePutRequest' is not null or undefined
            assertParamExists('updateHourlyRate', 'hourlyRatePutRequest', hourlyRatePutRequest)
            const localVarPath = `/v1/company/{companyId}/hourly-rates/{hourlyRateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"hourlyRateId"}}`, encodeURIComponent(String(hourlyRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourlyRatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HourlyRateApi - functional programming interface
 * @export
 */
export const HourlyRateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HourlyRateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Make an Hourly Rate the default for a customer
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomerDefaultHourlyRate(companyId: string, hourlyRateId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCustomerDefaultHourlyRate(companyId, hourlyRateId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an hourly rate
         * @param {string} companyId 
         * @param {HourlyRatePostRequest} hourlyRatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHourlyRate(companyId: string, hourlyRatePostRequest: HourlyRatePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHourlyRate(companyId, hourlyRatePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHourlyRate(companyId: string, hourlyRateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHourlyRate(companyId, hourlyRateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the default hourly rate for a customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHourlyRateByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHourlyRateByCustomerId(companyId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an hourly rate by id
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHourlyRateById(companyId: string, hourlyRateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHourlyRateById(companyId, hourlyRateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all hourly rates for a company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHourlyRatesByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourlyRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHourlyRatesByCompanyId(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {HourlyRatePutRequest} hourlyRatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHourlyRate(companyId: string, hourlyRateId: string, hourlyRatePutRequest: HourlyRatePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHourlyRate(companyId, hourlyRateId, hourlyRatePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HourlyRateApi - factory interface
 * @export
 */
export const HourlyRateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HourlyRateApiFp(configuration)
    return {
        /**
         * 
         * @summary Make an Hourly Rate the default for a customer
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerDefaultHourlyRate(companyId: string, hourlyRateId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.addCustomerDefaultHourlyRate(companyId, hourlyRateId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an hourly rate
         * @param {string} companyId 
         * @param {HourlyRatePostRequest} hourlyRatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHourlyRate(companyId: string, hourlyRatePostRequest: HourlyRatePostRequest, authorization?: string, options?: any): AxiosPromise<HourlyRate> {
            return localVarFp.createHourlyRate(companyId, hourlyRatePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHourlyRate(companyId: string, hourlyRateId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteHourlyRate(companyId, hourlyRateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the default hourly rate for a customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRateByCustomerId(companyId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<HourlyRate> {
            return localVarFp.getHourlyRateByCustomerId(companyId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an hourly rate by id
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRateById(companyId: string, hourlyRateId: string, authorization?: string, options?: any): AxiosPromise<HourlyRate> {
            return localVarFp.getHourlyRateById(companyId, hourlyRateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all hourly rates for a company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourlyRatesByCompanyId(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<HourlyRate>> {
            return localVarFp.getHourlyRatesByCompanyId(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an hourly rate
         * @param {string} companyId 
         * @param {string} hourlyRateId 
         * @param {HourlyRatePutRequest} hourlyRatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHourlyRate(companyId: string, hourlyRateId: string, hourlyRatePutRequest: HourlyRatePutRequest, authorization?: string, options?: any): AxiosPromise<HourlyRate> {
            return localVarFp.updateHourlyRate(companyId, hourlyRateId, hourlyRatePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HourlyRateApi - object-oriented interface
 * @export
 * @class HourlyRateApi
 * @extends {BaseAPI}
 */
export class HourlyRateApi extends BaseAPI {
    /**
     * 
     * @summary Make an Hourly Rate the default for a customer
     * @param {string} companyId 
     * @param {string} hourlyRateId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public addCustomerDefaultHourlyRate(companyId: string, hourlyRateId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).addCustomerDefaultHourlyRate(companyId, hourlyRateId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an hourly rate
     * @param {string} companyId 
     * @param {HourlyRatePostRequest} hourlyRatePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public createHourlyRate(companyId: string, hourlyRatePostRequest: HourlyRatePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).createHourlyRate(companyId, hourlyRatePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an hourly rate
     * @param {string} companyId 
     * @param {string} hourlyRateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public deleteHourlyRate(companyId: string, hourlyRateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).deleteHourlyRate(companyId, hourlyRateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the default hourly rate for a customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public getHourlyRateByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).getHourlyRateByCustomerId(companyId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an hourly rate by id
     * @param {string} companyId 
     * @param {string} hourlyRateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public getHourlyRateById(companyId: string, hourlyRateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).getHourlyRateById(companyId, hourlyRateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all hourly rates for a company
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public getHourlyRatesByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).getHourlyRatesByCompanyId(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an hourly rate
     * @param {string} companyId 
     * @param {string} hourlyRateId 
     * @param {HourlyRatePutRequest} hourlyRatePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourlyRateApi
     */
    public updateHourlyRate(companyId: string, hourlyRateId: string, hourlyRatePutRequest: HourlyRatePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return HourlyRateApiFp(this.configuration).updateHourlyRate(companyId, hourlyRateId, hourlyRatePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
