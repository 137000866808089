import { createFileRoute } from "@tanstack/react-router";
import UpsertSalesOpportunityPage from "./-components/UpsertSalesOpportunityPage";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/sales-opportunity/new"
)({
  component: CreateSalesOpportunityPage
});

function CreateSalesOpportunityPage() {
  return <UpsertSalesOpportunityPage />;
}
