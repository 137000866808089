import { createFileRoute } from "@tanstack/react-router";
import { useTimeFilter } from "../../../../../hooks/useTimeFilter";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useIsMinScreenSizeListener } from "../../../../../hooks/useScreenListener";
import CardNew from "../../../../../components/ds/CardNew";
import TimeFilterSelector from "../../../../../components/ds/TimeFilterSelector";
import {
  Column,
  ColumnLayout
} from "../../../../../components/layouts/ColumnLayout";
import { DateRange } from "../../../../../utils/DateTimeFunctions";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";
import IconWithText from "../../../../../components/IconWithText";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { t } from "i18next";
import classNames from "classnames";
import { formatCurrency } from "../../../../../utils/currencyFormatter";
import { SimpleStatusCircle } from "../../../../../components/ds/StatusCircle";
import ProgressCard from "../../../../../components/ds/ProgressCard";
import PageLayout from "../../../../../components/PageLayout";
import {
  convertChartName,
  getAreaChartStatsForCompany,
  getFunnelStatsForCompany,
  getTopFiveSalesOpportunitiesStatsForCompany,
  useGetSalesOpportunityStatsByCompanyId
} from "../../../../../service/api/SalesReportApi";
import { SalesOpportunityStatusEnum } from "../../../../../.generated/api";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/_layout/report"
)({
  component: SalesReportPage
});

type OverallStatsProps = {
  companyId: string;
  yearRange: DateRange;
};

type AreaChartStatsProps = {
  companyId: string;
  yearRange: DateRange;
};

type TopFiveSalesOpportunitiesProps = {
  companyId: string;
  yearRange: DateRange;
};

type FunnelStatsProps = {
  companyId: string;
  yearRange: DateRange;
  isVisible: boolean;
};

function AreaChartStats({ companyId, yearRange }: AreaChartStatsProps) {
  const areaChartStatsQuery = useQuery({
    queryKey: ["areaChartStats", companyId, yearRange],
    queryFn: () => getAreaChartStatsForCompany(companyId, yearRange)
  });

  const { t } = useTranslation();

  return (
    <NewDataFetcher
      query={areaChartStatsQuery}
      onData={(areaChartStats) => (
        <>
          <CardNew>
            <div className="grid place-items-center">
              <p className="font-bold mb-6">{t("totalValueSignedOffers")}</p>
            </div>
            <div className="h-[28rem]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={areaChartStats.map((stat) => ({
                    ...stat,
                    name: t(convertChartName(stat.name))
                  }))}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" lang="aus" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    name={t("signedOffers")}
                    stackId="1"
                    stroke="#04856D"
                    fill="#04856D"
                    fillOpacity={0.2}
                    strokeWidth={4}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CardNew>
        </>
      )}
    />
  );
}

function OverallStats({ companyId, yearRange }: OverallStatsProps) {
  const overallStatsQuery = useGetSalesOpportunityStatsByCompanyId({
    variables: {
      companyId,
      period: yearRange
    }
  });

  return (
    <NewDataFetcher
      query={overallStatsQuery}
      onData={({
        openSalesOpportunities,
        sentOffers,
        signedOffers,
        valueOpenSalesOpportunities,
        valueWonSalesOpportunities
      }) => (
        <>
          <div
            className={"grid justify-between grid-cols-1 gap-6 xl:grid-cols-3"}
          >
            <ProgressCard
              title={t("salesOpportunities")}
              subtitle={t("openSalesOpportunities")}
              progressText={openSalesOpportunities.toString()}
              theme="primary"
            />
            <ProgressCard
              title={t("sentOffers")}
              subtitle={t("sentToCustomers")}
              progressText={sentOffers.toString()}
              theme="primary"
            />
            <ProgressCard
              title={t("signedOffers")}
              subtitle={t("completed")}
              progressText={signedOffers.toString()}
              theme="primary"
            />
          </div>
          <div className="flex justify-between gap-6">
            <CardNew
              title={t("totalValueOpenSalesOpportunities")}
              className={"w-full"}
            >
              <h3 className="text-primary-100">
                {formatCurrency(valueOpenSalesOpportunities)}
              </h3>
            </CardNew>
            <CardNew
              title={t("totalValueWonSalesOpportunities")}
              className={"w-full"}
            >
              <h3 className="text-primary-100">
                {formatCurrency(valueWonSalesOpportunities)}
              </h3>
            </CardNew>
          </div>
        </>
      )}
    />
  );
}

function TopFiveSalesOpportunities({
  companyId,
  yearRange
}: TopFiveSalesOpportunitiesProps) {
  const { t } = useTranslation();
  const [topFiveState, setTopFiveState] = useState<boolean>(false);
  const status = topFiveState ? SalesOpportunityStatusEnum.Won : undefined;

  const topFiveSalesOpportunitiesQuery = useQuery({
    queryKey: ["topFiveSalesOpportunities", companyId, yearRange, status],
    queryFn: () =>
      getTopFiveSalesOpportunitiesStatsForCompany(companyId, yearRange, status)
  });

  const handleButtonClick = () => {
    setTopFiveState((prevState) => !prevState);
    topFiveSalesOpportunitiesQuery.refetch();
  };

  return (
    <NewDataFetcher
      query={topFiveSalesOpportunitiesQuery}
      onData={(topFiveSalesOpportunities) => (
        <CardNew
          trailing={
            <div className={"ml-auto mr-auto"}>
              <button onClick={handleButtonClick}>
                <IconWithText
                  icon="repeat"
                  className={"mr-4 hover:bg-primary-10 p-1"}
                  text={t("changeView")}
                  textClassName={"text-system-neutral-100"}
                />
              </button>
            </div>
          }
        >
          <div className="h-5/6 relative">
            <div className="flex items-center justify-center">
              <p className="font-bold mb-6">
                {t("top") +
                  " 5 " +
                  (topFiveState
                    ? t("salesOpportunitiesWon")
                    : t("openSalesOpportunities"))}
              </p>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={450}
                height={250}
                data={topFiveSalesOpportunities}
                layout="horizontal"
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <YAxis dataKey="valueSalesOpportunity" type="number" />
                <XAxis dataKey="name" type="category" width={180} />
                <Tooltip />
                <Bar
                  dataKey="valueSalesOpportunity"
                  name={t("Verdi salgsmulighet")}
                  fill="#04856D"
                  barSize={20}
                  radius={[0, 5, 5, 0]}
                />
              </BarChart>
            </ResponsiveContainer>

            <div className="grid place-items-center">
              <p className="mb-6 text-system-neutral-90">
                {t("estimatedValue")}
              </p>
              <div></div>
            </div>
          </div>
        </CardNew>
      )}
    />
  );
}

function FunnelStats({ companyId, yearRange, isVisible }: FunnelStatsProps) {
  const funnelStatsQuery = useQuery({
    queryKey: ["funnelStats", companyId, yearRange],
    queryFn: () => getFunnelStatsForCompany(companyId, yearRange)
  });

  return (
    <NewDataFetcher
      query={funnelStatsQuery}
      onData={(funnelStats) => {
        const funnelOverview = [
          {
            label: t("identifiedSale"),
            color: "#9ccda7",
            value: funnelStats[0].value
          },
          {
            label: t("inProgress"),
            color: "#04856d",
            value: funnelStats[1].value
          },
          {
            label: t("offerSent"),
            color: "#006666",
            value: funnelStats[2].value
          },
          {
            label: t("won"),
            color: "#003333",
            value: funnelStats[3].value
          }
        ];

        return isVisible ? (
          <CardNew className={"h-full"}>
            <div className="grid place-items-center">
              <p className="font-bold mb-6">{t("salesOpportunities")}</p>
            </div>
            <div className="flex gap-4 h-5/6 justify-center">
              <div className="h-full flex justify-center">
                <div className="w-72 h-full relative">
                  {/* <ResponsiveFunnel
                  data={formattedFunnelStats}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  valueFormat=">-1.0s"
                  colors={funnelOverview.map(({ color }) => color)}
                  borderWidth={0}
                  borderOpacity={0}
                  labelColor="#ffffff"
                  beforeSeparatorLength={100}
                  shapeBlending={0.45}
                  beforeSeparatorOffset={20}
                  afterSeparatorLength={100}
                  afterSeparatorOffset={20}
                  currentPartSizeExtension={10}
                  enableAfterSeparators={false}
                  enableBeforeSeparators={false}
                  currentBorderWidth={40}
                  motionConfig="wobbly"
                /> */}
                  <div className="absolute z-50 w-full h-5/6 flex">
                    <div
                      style={{
                        width: 0,
                        height: 0,
                        borderLeft: "145px solid white",
                        borderRight: "145px solid white",
                        borderTop: "400px solid transparent"
                      }}
                    />
                    <div className="bg-white w-full h-full"></div>
                  </div>
                  <div className="absolute right-0 left-0 top-0 bottom-0 z-1">
                    <div className=" flex flex-col h-full">
                      <div className="bg-[#9ccda7] grow">
                        <div className="grid place-items-center h-full w-full text-white font-bold">
                          {funnelStats[0].amount}
                        </div>
                      </div>
                      <div className="bg-[#04856d] grow">
                        <div className="grid place-items-center h-full w-full text-white font-bold">
                          {funnelStats[1].amount}
                        </div>
                      </div>
                      <div className="bg-[#006666] grow">
                        <div className="grid place-items-center h-full w-full text-white font-bold">
                          {funnelStats[2].amount}
                        </div>
                      </div>
                      <div className="bg-[#003333] grow">
                        <div className="grid place-items-center h-full w-full text-white font-bold">
                          {funnelStats[3].amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between py-16">
                {funnelOverview.map(({ label, color, value }) => (
                  <div className="flex gap-4 items-center" key={color}>
                    <div
                      className={classNames(
                        "w-8 h-8 rounded-full text-white flex items-center justify-center",
                        {
                          "bg-[#9ccda7]": color === funnelOverview[0].color,
                          "bg-[#04856d]": color === funnelOverview[1].color,
                          "bg-[#006666]": color === funnelOverview[2].color,
                          "bg-[#003333]": color === funnelOverview[3].color
                        }
                      )}
                    />
                    <div>
                      <p>{label}</p>
                      <p className="rt-Text text-system-neutral-90">
                        {formatCurrency(value)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardNew>
        ) : (
          <></>
        );
      }}
    />
  );
}

function FunnelStatsMini({
  companyId,
  yearRange,
  isVisible
}: FunnelStatsProps) {
  const funnelStatsQuery = useQuery({
    queryKey: ["funnelStats", companyId, yearRange],
    queryFn: () => getFunnelStatsForCompany(companyId, yearRange)
  });

  return (
    <NewDataFetcher
      query={funnelStatsQuery}
      onData={(funnelStats) => {
        const funnelOverview = [
          {
            label: t("identifiedSale"),
            color: "#9ccda7",
            value: funnelStats[0].value
          },
          {
            label: t("inProgress"),
            color: "#04856d",
            value: funnelStats[1].value
          },
          {
            label: t("offerSent"),
            color: "#006666",
            value: funnelStats[2].value
          },
          {
            label: t("won"),
            color: "#003333",
            value: funnelStats[3].value
          }
        ];

        return isVisible ? (
          <CardNew>
            <div className="grid place-items-center gap-y-4">
              <h3>{t("salesOpportunities")}</h3>
              {/* Populate this cards with circles of funnelOverview values with colorcoding */}
              <div className="grid w-full grid-cols-2 md:grid-cols-4 gap-8">
                <div className="flex flex-col gap-4 items-center">
                  <SimpleStatusCircle
                    backgroundColor={funnelOverview[0].color}
                    isLarge={true}
                  >
                    {funnelStats[0].amount.toString()}
                  </SimpleStatusCircle>
                  <p className="text-system-neutral-110 font-bold">
                    {funnelOverview[0].label}
                  </p>
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <SimpleStatusCircle
                    backgroundColor={funnelOverview[1].color}
                    isLarge={true}
                  >
                    {funnelStats[1].amount.toString()}
                  </SimpleStatusCircle>
                  <p className="text-system-neutral-110 font-bold">
                    {funnelOverview[1].label}
                  </p>
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <SimpleStatusCircle
                    backgroundColor={funnelOverview[2].color}
                    isLarge={true}
                  >
                    {funnelStats[2].amount.toString()}
                  </SimpleStatusCircle>
                  <p className="text-system-neutral-110 font-bold">
                    {funnelOverview[2].label}
                  </p>
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <SimpleStatusCircle
                    backgroundColor={funnelOverview[3].color}
                    isLarge={true}
                  >
                    {funnelStats[3].amount.toString()}
                  </SimpleStatusCircle>
                  <p className="text-system-neutral-110 font-bold">
                    {funnelOverview[3].label}
                  </p>
                </div>
              </div>
            </div>
          </CardNew>
        ) : (
          <></>
        );
      }}
    />
  );
}

function SalesReportPage() {
  const { selectedRange, setSelectedRange, period } = useTimeFilter("year");
  const companyId = useCompanyId();
  const isAboveHorizontalTablet =
    useIsMinScreenSizeListener("horizontalTablet");

  return (
    <PageLayout>
      <CardNew
        title={selectedRange.start.getFullYear().toString()}
        trailing={
          <TimeFilterSelector
            onChange={setSelectedRange}
            periods={["year"]}
            dates={selectedRange}
            period={period}
          />
        }
      >
        <div className="flex flex-col gap-6">
          <OverallStats companyId={companyId} yearRange={selectedRange} />

          <ColumnLayout columns={2}>
            <Column span={isAboveHorizontalTablet ? 1 : 2}>
              <div className="h-full">
                <FunnelStatsMini
                  companyId={companyId}
                  yearRange={selectedRange}
                  isVisible={!isAboveHorizontalTablet}
                />
                <FunnelStats
                  companyId={companyId}
                  yearRange={selectedRange}
                  isVisible={isAboveHorizontalTablet}
                />
              </div>
            </Column>
            <Column span={isAboveHorizontalTablet ? 1 : 2}>
              <div className="h-[34rem]">
                <TopFiveSalesOpportunities
                  companyId={companyId}
                  yearRange={selectedRange}
                />
              </div>
            </Column>
          </ColumnLayout>

          <AreaChartStats companyId={companyId} yearRange={selectedRange} />
        </div>
      </CardNew>
    </PageLayout>
  );
}
