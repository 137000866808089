import React, { forwardRef, ReactNode } from "react";

type TableProps = {
  children: ReactNode;
};

// eslint-disable-next-line react/display-name
const Table = forwardRef<HTMLDivElement, TableProps>(
  ({ children }: TableProps, ref) => {
    return (
      <div className={"overflow-x-auto"} ref={ref}>
        <table
          className={
            "w-full border-separate border-spacing-0 shadow-[0_5px_20px_0_rgba(0,0,0,0.08)]"
          }
        >
          {children}
        </table>
      </div>
    );
  }
);

export default Table;
