import { createFileRoute, useParams } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Subcontractor } from "../../../../../../../.generated/api";
import useDialog from "../../../../../../../hooks/useDialog";
import { useGetSubcontractorByProjectId } from "../../../../../../../service/api/SubcontractorApi";
import PageLayout from "../../../../../../../components/PageLayout";
import SubcontractorPreview from "./-components/SubcontractorPreview";
import UpsertSubContractorDialog from "./-components/UpsertSubContractorDialog";
import CardNew from "../../../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor"
)({
  component: ProjectEconomySubcontractorPage
});

function ProjectEconomySubcontractorPage() {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const [selectedSubContractor, setSelectedSubContractor] =
    useState<Subcontractor>();
  const { onClose, onOpen, isOpen } = useDialog();

  const subcontractorQuery = useGetSubcontractorByProjectId({
    variables: {
      companyId: companyId,
      projectId: projectId
    }
  });

  useEffect(() => {
    if (subcontractorQuery.data && !selectedSubContractor) {
      setSelectedSubContractor(subcontractorQuery?.data?.[0]);
    }
  }, [subcontractorQuery.data]);

  return (
    <PageLayout
      previewOffset={"regularWithTabBar"}
      withoutPadding
      preview={
        selectedSubContractor && (
          <SubcontractorPreview
            key={subcontractorQuery.dataUpdatedAt}
            subcontractor={selectedSubContractor}
            onClear={() => setSelectedSubContractor(undefined)}
            onOpen={onOpen}
          />
        )
      }
    >
      <UpsertSubContractorDialog
        isOpen={isOpen}
        onClose={onClose}
        projectId={projectId}
        subcontractor={selectedSubContractor}
        onUpdated={(subcontractor) => {
          subcontractorQuery.refetch();
          setSelectedSubContractor(subcontractor);
          onClose();
        }}
      />
      <CardNew
        inset
        title={t("subcontractor")}
        trailing={
          <ButtonNew
            size={"1"}
            onClick={() => {
              setSelectedSubContractor(undefined);
              onOpen();
            }}
          >
            {t("add")}
          </ButtonNew>
        }
      >
        <Table variant={"ghost"}>
          <TableHeader className={"bg-radix-gray-a2"}>
            <TableRow>
              <TableHeaderCell>{t("companyName")}</TableHeaderCell>
              <TableHeaderCell>{t("contactPerson")}</TableHeaderCell>
              <TableHeaderCell>{t("phone")}</TableHeaderCell>
              <TableHeaderCell>{t("email")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {subcontractorQuery.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>{t("noSubcontractorsAdded")}</TableCell>
              </TableRow>
            )}
            {subcontractorQuery.data?.map((subcontractor) => {
              return (
                <TableRow key={subcontractor.id}>
                  <TableCell>
                    <ButtonNew
                      highContrast
                      color={"gray"}
                      variant={"ghost"}
                      onClick={() => setSelectedSubContractor(subcontractor)}
                      className={"underline"}
                    >
                      {subcontractor.companyName}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{subcontractor.contactPerson}</TableCell>
                  <TableCell>{subcontractor.phone}</TableCell>
                  <TableCell>{subcontractor.email}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
