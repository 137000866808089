import { createFileRoute, Outlet, useParams } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import {
  pageTitleSizes,
  tabBarSizes
} from "../../../../../../components/ds/sizes";
import React from "react";
import { SubmenuNavButton } from "./_layout.economy/-components/SubmenuNavButton";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout"
)({
  component: ProjectEconomyLayout
});

function ProjectEconomyLayout() {
  const { t } = useTranslation();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return (
    <>
      <div
        className={"w-[184px] border-r border-radix-gray-a6 fixed p-2"}
        style={{
          left: 84,
          top: tabBarSizes.regular + pageTitleSizes.regular,
          bottom: 0
        }}
      >
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy"}
          params={{ projectId }}
        >
          {t("overview")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/invoice"}
          params={{ projectId }}
        >
          {t("invoice")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/contract"}
          params={{ projectId }}
        >
          {t("contracts")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/budget"}
          params={{ projectId }}
        >
          {t("budget")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/time"}
          params={{ projectId }}
        >
          {t("hours")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/change-order"}
          params={{ projectId }}
        >
          {t("changeOrders")}
        </SubmenuNavButton>
        <SubmenuNavButton
          to={"/dashboard/project/$projectId/economy/subcontractor"}
          params={{ projectId }}
        >
          {t("subcontractors")}
        </SubmenuNavButton>
      </div>
      <div
        style={{
          marginLeft: 184
        }}
      >
        <div className={"m-5"}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
