import { createFileRoute, useSearch } from "@tanstack/react-router";
import React from "react";
import { UpsertServiceContract } from "./-components/UpsertServiceContactPage";
import { z } from "zod";

const createServiceContractSchema = z.object({
  facilityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/service-contract/new"
)({
  validateSearch: createServiceContractSchema,
  component: CreateServiceContractPage
});

function CreateServiceContractPage() {
  const { facilityId } = useSearch({
    from: "/_protected/dashboard/service-contract/new"
  });
  return <UpsertServiceContract facilityId={facilityId} />;
}
