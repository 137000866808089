import {
  InspectionApi,
  InspectionPostRequest,
  InspectionPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { customerInstance } from "../api/CustomerHttpBase";
import { createQuery } from "react-query-kit";

const inspectionApi = new InspectionApi(
  undefined,
  environment.skwApiNewBaseUrl,
  customerInstance
);

export const useGetInspectionById = createQuery({
  queryKey: ["getInspectionById"],
  fetcher: async (variables: { companyId: string; inspectionId: string }) => {
    const response = await inspectionApi.getInspectionById(
      variables.companyId,
      variables.inspectionId
    );
    return response.data;
  }
});

export const useGetInspectionsBySalesOpportunityId = createQuery({
  queryKey: ["getInspectionsBySalesOpportunityId"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId: string;
  }) => {
    const response = await inspectionApi.getInspectionsBySalesOpportunityId(
      variables.companyId,
      variables.salesOpportunityId
    );
    return response.data;
  }
});

export async function getInspectionById(
  companyId: string,
  inspectionId: string
) {
  const response = await inspectionApi.getInspectionById(
    companyId,
    inspectionId
  );
  return response.data;
}

export async function getInspectionsByCustomerId(
  companyId: string,
  customerId: string
) {
  const reseponse = await inspectionApi.getInspectionsByCustomerId(
    companyId,
    customerId
  );
  return reseponse.data;
}

export async function getInspectionsBySalesOpportunityId(
  companyId: string,
  salesOpportunityId: string
) {
  const response = await inspectionApi.getInspectionsBySalesOpportunityId(
    companyId,
    salesOpportunityId
  );
  return response.data;
}

export async function createInspection(
  companyId: string,
  request: InspectionPostRequest
) {
  const response = await inspectionApi.createInspection(companyId, request);
  return response.data;
}

export async function updateInspection(
  companyId: string,
  id: string,
  request: InspectionPutRequest
) {
  const response = await inspectionApi.updateInspection(companyId, id, request);
  return response.data;
}

export function sendInspectionSms(
  companyId: string,
  inspectionId: string,
  companyName: string
) {
  return inspectionApi.sendInspectionSMS(companyId, inspectionId, {
    companyName: companyName
  });
}

export function sendInspectionEmail(
  companyId: string,
  inspectionId: string,
  companyName: string
) {
  return inspectionApi.sendInspectionEmail(companyId, inspectionId, {
    companyName: companyName
  });
}

export async function archiveInspection(
  companyId: string,
  inspectionId: string
) {
  await inspectionApi.archiveInspection(companyId, inspectionId);
}
