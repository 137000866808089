import React, { ReactNode } from "react";
import { Card as RadixCard, Inset, ScrollArea, Text } from "@radix-ui/themes";
import classnames from "classnames";

type CardProps = {
  title?: string;
  subtitle?: ReactNode;
  trailing?: ReactNode;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  className?: string;
  button?: ReactNode;
  children: ReactNode;
  inset?: boolean;
  titleBadge?: ReactNode;
  maxHeight?: number;
};

export default function CardNew({
  title,
  subtitle,
  children,
  trailing,
  headerContent,
  footerContent,
  className,
  button,
  maxHeight,
  inset = false,
  titleBadge
}: CardProps) {
  const child = maxHeight ? (
    <ScrollArea style={{ height: maxHeight }}>{children}</ScrollArea>
  ) : (
    children
  );

  return (
    <RadixCard className={className} size={"2"}>
      {(title || trailing || subtitle) && (
        <Inset pb={"current"}>
          <div
            className={classnames(
              "items-center border-b border-b-radix-gray-6 px-4 py-4",
              {
                "mb-4": !headerContent && !inset
              }
            )}
          >
            <div className={"flex justify-between grow"}>
              <div className={"flex items-center gap-2"}>
                <Text as={"p"} size={"3"} weight={"bold"}>
                  {title}
                </Text>
                {titleBadge}
              </div>
              <div className={"flex items-center gap-2"}>{trailing}</div>
            </div>
            {subtitle && (
              <>
                {typeof subtitle === "string" && (
                  <Text as={"p"} className={"mt-2"} color={"gray"}>
                    {subtitle}
                  </Text>
                )}
                {typeof subtitle !== "string" && subtitle}
              </>
            )}
          </div>
        </Inset>
      )}
      {headerContent && (
        <Inset pb={"current"}>
          <div
            className={classnames(
              "flex px-4 py-3 border-b border-b-radix-gray-6 gap-2",
              {
                "mb-4": !inset
              }
            )}
          >
            {headerContent}
          </div>
        </Inset>
      )}
      {inset && <Inset pb={"0"}>{child}</Inset>}
      {!inset && child}
      {(button || footerContent) && (
        <Inset
          className={classnames(
            "mt-4 p-4 border-t border-t-radix-gray-6 rounded-none gap-2",
            {
              "flex justify-end": button
            }
          )}
        >
          {footerContent}
          {button}
        </Inset>
      )}
    </RadixCard>
  );
}
