import { createFileRoute, Link } from "@tanstack/react-router";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import { useTranslation } from "react-i18next";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import {
  calculateLineSum,
  DirectSale,
  DirectSaleSortField,
  useGetDirectSalesByCompanyId
} from "../../../../../service/api/DirectSaleApi";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useSort } from "../../../../../hooks/useSort";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import { keepPreviousData } from "@tanstack/react-query";
import React, { useState } from "react";
import DirectSalePreview from "./-components/DirectSalePreview";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import { searchBarSize } from "../../../../../components/ds/sizes";
import { useToast } from "../../../../../hooks/useToast";
import { formatCurrency } from "../../../../../utils/currencyFormatter";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/_layout/direct-sale"
)({
  component: DirectSalePage
});

function DirectSalePage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const { showSuccessToast } = useToast();
  const [selectedSale, setSelectedSale] = useState<DirectSale>();
  const {
    sortField,
    page,
    setPage,
    sortDirection,
    registerHeader,
    setQuery,
    debouncedQuery
  } = useSort<DirectSaleSortField>("SaleNumber", "directSaleSortKey");

  const saleQuery = useGetDirectSalesByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      path: { companyId },
      query: {
        query: debouncedQuery,
        sortField,
        page,
        sortDirection
      }
    }
  });

  const sales = saleQuery.data?.data ?? [];

  return (
    <PageLayout
      previewOffset={"regularWithTabBar"}
      preview={
        selectedSale && (
          <DirectSalePreview
            sale={selectedSale}
            onClosePreview={() => setSelectedSale(undefined)}
            onDeleted={() => {
              setSelectedSale(undefined);
              showSuccessToast(t("saleDeleted"));
              saleQuery.refetch();
            }}
          />
        )
      }
    >
      <CardNew
        title={t("directSale")}
        inset
        trailing={
          <ButtonNew size={"1"} icon={"add"} asChild>
            <Link to={"/dashboard/sales/direct-sale/new"}>{t("newSale")}</Link>
          </ButtonNew>
        }
        footerContent={
          <PaginationNew
            page={page}
            totalPages={saleQuery.data?.totalPages ?? 0}
            setPage={setPage}
          />
        }
        headerContent={
          <div className={"w-full flex justify-between"}>
            <TextFieldNew
              placeholder={t("searchForSaleNumberOrCustomer")}
              icon={"search"}
              className={searchBarSize}
              onChange={(e) => setQuery(e.target.value)}
              size={"2"}
            />
          </div>
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell sortFn={registerHeader("SaleNumber")}>
                {t("orderNumber")}
              </TableHeaderCell>
              <TableHeaderCell>{t("customer")}</TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("SaleNumber")}>
                {t("date")}
              </TableHeaderCell>
              <TableHeaderCell>{t("paymentMethod")}</TableHeaderCell>
              <TableHeaderCell>{t("productCount")}</TableHeaderCell>
              <TableHeaderCell>{t("sum")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sales.length === 0 && (
              <TableRow>
                <TableCell>{t("noSalesCreated")}</TableCell>
              </TableRow>
            )}
            {sales.map((sale) => {
              const totalProducts = sale.lines.reduce(
                (total, line) => total + line.quantity,
                0
              );

              const sum = sale.lines.reduce(
                (total, line) =>
                  total +
                  calculateLineSum(
                    line.price,
                    line.quantity,
                    line.discount,
                    line.vat
                  ),
                0
              );

              return (
                <TableRow key={sale.id}>
                  <TableCell>
                    <ButtonNew
                      variant={"ghost"}
                      highContrast
                      onClick={() => setSelectedSale(sale)}
                      className={"group-hover/tr:underline"}
                    >
                      #{sale.saleNumber}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{sale.customer.formattedCustomerName}</TableCell>
                  <TableCell>
                    {formatFullNumericDateString(sale.date)}
                  </TableCell>
                  <TableCell>{t(sale.paymentMethod)}</TableCell>
                  <TableCell>{totalProducts}</TableCell>
                  <TableCell>{formatCurrency(sum)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
