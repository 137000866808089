import {
  LocationApi,
  LocationPostRequest,
  LocationPutRequest
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const locationApi = new LocationApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetLocationByResourceId = createQuery({
  queryKey: ["getLocationByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await locationApi.getLocationByResourceId(
      variables.resourceId
    );
    return response.data;
  }
});

export async function createLocation(body: LocationPostRequest): Promise<void> {
  await locationApi.createLocation(body);
}

export async function updateLocation(body: LocationPutRequest): Promise<void> {
  await locationApi.updateLocation(body);
}

export async function deleteProjectLocation(
  locationId: string,
  projectId: string
): Promise<void> {
  await locationApi.deleteLocationForProject(projectId, locationId);
}

export async function deleteTaskLocation(
  taskId: string,
  locationId: string
): Promise<void> {
  await locationApi.deleteLocationForTask(taskId, locationId);
}
