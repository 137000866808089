export const validateOrgNumber = (value?: string): boolean => {
  const weights = [3, 2, 7, 6, 5, 4, 3, 2];
  if (!value) return false;
  if (value.length != 9) return false;

  let sum = 0;
  const controlDigit = parseInt(value[8]);
  const numbersToCheck = value.substr(0, 8);
  for (let i = 0; i < numbersToCheck.length; i++) {
    sum += parseInt(numbersToCheck[i]) * weights[i];
  }

  const remainder = sum % 11;
  return controlDigit === (remainder === 0 ? 0 : 11 - remainder);
};
