import {
  createFileRoute,
  useLoaderData,
  useNavigate
} from "@tanstack/react-router";
import React from "react";
import { UpsertProjectPhase } from "../../../../../pages/setting/project/phases/UpsertProjectPhase";
import { useGetProjectPhasesByCompanyId } from "../../../../../service/api/PhasesApi";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/project-phases/edit"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient, companyId } }) => {
    return queryClient.fetchQuery({
      ...useGetProjectPhasesByCompanyId.getOptions({ companyId })
    });
  },
  component: EditCompanyProjectPhasePage
});

function EditCompanyProjectPhasePage() {
  const navigate = useNavigate();
  const phases = useLoaderData({
    from: "/_protected/dashboard/settings/project-phases/edit"
  });

  async function afterSubmit() {
    await navigate({
      to: "/dashboard/settings",
      search: {
        tab: "project"
      }
    });
  }

  return (
    <UpsertProjectPhase existingPhases={phases} afterSubmit={afterSubmit} />
  );
}
