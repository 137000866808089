import { createFileRoute } from "@tanstack/react-router";
import { UpsertRoutinePage } from "./-components/UpsertInternalRoutinePage";

export const Route = createFileRoute(
  "/_protected/dashboard/internal-routines/new"
)({
  component: CreateRoutinePage
});

function CreateRoutinePage() {
  return <UpsertRoutinePage />;
}
