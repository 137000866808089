import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChecklistItemTemplateDetails from "../../../../../components/common/settings/ChecklistItemTemplateDetails";
import PageLayout from "../../../../../components/PageLayout";
import { useGetChecklistTemplatesByCompanyId } from "../../../../../service/api/ChecklistTemplateApi";
import {
  ChecklistItemTemplateTypeEnum,
  ChecklistTemplateDTO
} from "../../../../../.generated/api";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import TemplateGridLayout from "../../../../../pages/setting/TemplateGridLayout";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";
import Card from "../../../../../components/ds/Card";
import ChecklistTemplateDialog from "../../../../../components/common/settings/ChecklistTemplateDialog";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/checklist-template"
)({
  component: ChecklistTemplateSettingsPage
});

function ChecklistTemplateSettingsPage() {
  const { isOpen, onOpen, onClose } = useDialog();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const companyId = useCompanyId();
  const { t } = useTranslation();

  const checklistTemplateQuery = useGetChecklistTemplatesByCompanyId({
    variables: { companyId }
  });

  const [filteredTemplates, setFilteredTemplates] = useState<
    ChecklistTemplateDTO[]
  >([]);

  useEffect(() => {
    if (checklistTemplateQuery.data) {
      let templates = checklistTemplateQuery.data;
      templates = templates.filter((t) =>
        t.title.toLowerCase().includes(searchQuery)
      );
      const sorted = templates.sort((a, b) =>
        b.created.localeCompare(a.created)
      );
      setFilteredTemplates(sorted);
    }
  }, [searchQuery, checklistTemplateQuery.data]);

  return (
    <PageLayout>
      <ChecklistTemplateDialog
        companyId={companyId}
        onUpdate={() => checklistTemplateQuery.refetch()}
        open={isOpen}
        onClose={onClose}
      />
      <TemplateGridLayout
        primaryButtonText={t("Legg til ny sjekklistemal")}
        onPrimaryButtonClick={onOpen}
        searchPlaceHolder={t("Søk etter sjekklistemal")}
        onSearchUpdate={(v) => setSearchQuery(v)}
        leftSection={
          <NewDataFetcher
            query={checklistTemplateQuery}
            onData={() => (
              <div className={"overflow-y-scroll space-y-2"}>
                {filteredTemplates.map((template, index) => (
                  <Card
                    padding={"dense"}
                    key={template.id}
                    isSelected={selectedIndex === index}
                    onClick={() => setSelectedIndex(index)}
                  >
                    <div className={"flex items-center justify-between"}>
                      <span>
                        <h3>{template.title}</h3>
                        <p className={"text-system-neutral-90 mt-1"}>
                          {template.description}
                        </p>
                      </span>
                      <h5>
                        {
                          template.data.items?.filter(
                            (template) =>
                              template.type !==
                              ChecklistItemTemplateTypeEnum.Title
                          ).length
                        }{" "}
                        {t("punkter")}
                      </h5>
                    </div>
                  </Card>
                ))}
              </div>
            )}
          />
        }
        rightSection={
          <ChecklistItemTemplateDetails
            onCreated={() => checklistTemplateQuery.refetch()}
            companyId={companyId}
            template={filteredTemplates[selectedIndex]}
            onDeleted={() => {
              setSelectedIndex(0);
              checklistTemplateQuery.refetch();
            }}
          />
        }
      />
    </PageLayout>
  );
}
