import {
  HourlyRateApi,
  HourlyRatePostRequest,
  HourlyRatePutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const hourlyRateApi = new HourlyRateApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetHourlyRatesByCompanyId = createQuery({
  queryKey: ["getHourlyRatesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await hourlyRateApi.getHourlyRatesByCompanyId(
      variables.companyId
    );
    return response.data;
  }
});

export const useGetHourlyRatesByCustomerId = createQuery({
  queryKey: ["getHourlyRatesByCustomerId"],
  fetcher: async (variables: { companyId: string; customerId: string }) => {
    const response = await hourlyRateApi.getHourlyRateByCustomerId(
      variables.companyId,
      variables.customerId
    );
    return response.data;
  }
});

export async function createHourlyRate(
  companyId: string,
  request: HourlyRatePostRequest
) {
  const response = await hourlyRateApi.createHourlyRate(companyId, request);
  return response.data;
}

export async function setCustomerHourlyRate(
  companyId: string,
  rateId: string,
  customerId: string
) {
  await hourlyRateApi.addCustomerDefaultHourlyRate(
    companyId,
    rateId,
    customerId
  );
}

export async function updateHourlyRate(
  companyId: string,
  rateId: string,
  request: HourlyRatePutRequest
) {
  const response = await hourlyRateApi.updateHourlyRate(
    companyId,
    rateId,
    request
  );

  return response.data;
}

export async function deleteHourlyRate(companyId: string, rateId: string) {
  await hourlyRateApi.deleteHourlyRate(companyId, rateId);
}
