import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";
import InvoicePageOld from "../../../../../pages/timeInvoice/InvoicePageOld";
import NewInvoicePage from "../../../../../pages/invoice/NewInvoicePage";
import { useCheckIntegration } from "../../../../../service/api/IntegrationsApi";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/invoice"
)({
  component: InvoicePage
});

function InvoicePage() {
  const companyId = useCompanyId();
  const integrationQuery = useCheckIntegration({
    variables: { companyId }
  });

  return (
    <NewDataFetcher
      query={integrationQuery}
      onData={({ hasPoIntegration }) => {
        if (hasPoIntegration) {
          return <InvoicePageOld />;
        } else {
          return <NewInvoicePage />;
        }
      }}
    />
  );
}
