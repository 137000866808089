import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
  useRouter,
  useSearch
} from "@tanstack/react-router";
import { salesDetailsTabs } from "../../../../../router/Routes";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useToast } from "../../../../../hooks/useToast";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import useDialog from "../../../../../hooks/useDialog";
import {
  archiveSalesOppertunity,
  updateSalesOpportunity,
  useGetSalesOpportunityById
} from "../../../../../service/api/SalesOpportunityApi";
import { formatCurrency } from "../../../../../utils/currencyFormatter";
import {
  createCompanyOrder,
  getCompanyOrderStatuses
} from "../../../../../service/api/OrderApi";
import { createCustomerRelation } from "../../../../../service/api/CustomerRelationApiV3";
import PageTitle from "../../../../../components/common/PageTitle";
import DropDown from "../../../../../components/ds/Dropdown/Dropdown";
import classNames from "classnames";
import { getDsColorFromSalesOpportunityStatus } from "../../../../../utils/ConvertToDSColor";
import {
  getStatusLabel,
  SALES_OPPORTUNITY_STATUS_OPTIONS
} from "../../../../../pages/sales/utils";
import Icon from "../../../../../components/ds/Icon";
import DropdownItem from "../../../../../components/ds/Dropdown/DropdownItem";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import Statusline from "../../../../../pages/sales/salesOppertunity/details/StatusLine";
import { TypedTab } from "../../../../../components/ds/Tab";
import Card from "../../../../../components/ds/Card";
import IconWithText from "../../../../../components/IconWithText";
import { formatFullNumericDate } from "../../../../../utils/DateTimeFormatter";
import DeadlineComponent from "../../../../../pages/sales/salesOppertunity/details/DeadlineComponent";
import NoteComponent from "../../../../../components/NoteComponent";
import DocumentationTab from "../../../../../pages/documentation/DocumentationTab";
import InspectionTab from "../../../../../pages/sales/salesOppertunity/details/inspection/InspectionTab";
import OfferOnSalesOpportunityPage from "../../../../../pages/sales/salesOppertunity/details/offer/OfferPage";
import WonModal from "../../../../../pages/sales/salesOppertunity/details/WonModal";
import ConvertToProjectDialog from "./-components/ConvertToProjectDialog";
import { useGetCustomColumnsByCompanyId } from "../../../../../service/api/CustomColumnApi";
import { z } from "zod";

const salesDetailsTabSchema = z.object({
  tab: salesDetailsTabs.catch("overview").optional(),
  selectedOffer: z.string().optional(),
  selectedInspection: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/"
)({
  validateSearch: salesDetailsTabSchema,
  loader: ({
    context: { queryClient, companyId },
    params: { salesOpportunityId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetSalesOpportunityById.getOptions({
        salesOpportunityId,
        companyId
      })
    });
  },
  component: SalesOpportunityDetailsPage
});

function SalesOpportunityDetailsPage() {
  const salesOpportunity = useLoaderData({
    from: "/_protected/dashboard/sales/$salesOpportunityId/"
  });
  const { tab } = useSearch({
    from: "/_protected/dashboard/sales/$salesOpportunityId/"
  });
  const navigate = useNavigate();
  const router = useRouter();
  const companyId = useCompanyId();
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen
  } = useDialog();
  const convertToProjectDialog = useDialog();
  const economyData = useRef<
    | {
        isHourly?: boolean;
        estimatedValue?: string;
        estimatedHours?: string;
      }
    | undefined
  >(undefined);

  const {
    isOpen: isWonDialogOpen,
    onClose: onWonDialogClose,
    onOpen: onWonDialogOpen
  } = useDialog();

  const customColumnsQuery = useGetCustomColumnsByCompanyId({
    variables: { companyId }
  });

  const customColumns = customColumnsQuery.data ?? [];

  const {
    startDate,
    endDate,
    owner,
    description,
    address,
    status,
    customer,
    priority,
    leadSource,
    expectedCloseDate,
    title,
    id,
    archived,
    deliveryType,
    price,
    priceType,
    estimatedHours,
    customColumnOne,
    customColumnTwo
  } = salesOpportunity;

  economyData.current = {
    estimatedValue: salesOpportunity.price?.toString(),
    estimatedHours: salesOpportunity.estimatedHours?.toString(),
    isHourly: salesOpportunity.priceType === "hourly"
  };

  let priceText = formatCurrency(price);
  if (priceType === "hourly") {
    const total = price * estimatedHours;
    priceText = formatCurrency(total);

    priceText =
      formatCurrency(total) +
      ` (${estimatedHours} ${t(" timer à ")} ${formatCurrency(
        price
      )} ${t(" per hour")})`;
  }

  async function convertToOrder() {
    try {
      const orderStatuses = await getCompanyOrderStatuses(companyId);

      const status =
        orderStatuses.find((s) => s.title === "Ikke påbegynt") ??
        orderStatuses?.[0];

      const order = await createCompanyOrder(companyId, {
        companyId: companyId,
        address: customer.address,
        title: salesOpportunity.title,
        price: salesOpportunity.price,
        priceType: "Fixed",
        description: salesOpportunity.description ?? "",
        referencePersonName: customer.formattedCustomerName ?? "",
        referencePersonNumber: "",
        statusId: status.id,
        tagIds: [],
        salesOpportunityId: salesOpportunity.id
      });

      await createCustomerRelation(companyId, customer.id, order[0], "Order");
      await archiveSalesOppertunity(companyId, id);

      showSuccessToast("Salgsmulighet konvertert til ordre");
      await navigate({
        to: "/dashboard/orders/$orderId/overview",
        params: {
          orderId: order[0]
        }
      });
    } catch (_) {
      showErrorToast("Feil ved konvertering av salgsmulighet");
    }
  }

  return (
    <div className={"p-5"}>
      <ConvertToProjectDialog
        open={convertToProjectDialog.isOpen}
        onClose={convertToProjectDialog.onClose}
        salesOpportunity={salesOpportunity}
      />
      <PageTitle
        title={title}
        onBackClick={() =>
          navigate({
            to: "/dashboard/sales",
            search: {
              tab: "salesOpportunities"
            }
          })
        }
        actions={
          <div className={"flex items-center gap-2"}>
            {!archived && (
              <DropDown
                button={
                  <button
                    className={classNames(
                      "px-4 py-3 text-rg rounded-2 gap-2 flex items-center",
                      getDsColorFromSalesOpportunityStatus(status)
                    )}
                  >
                    <Trans>
                      {
                        SALES_OPPORTUNITY_STATUS_OPTIONS.find(
                          ({ value }) => value === status
                        )?.label
                      }
                    </Trans>
                    <Icon icon={"expand_more"} />
                  </button>
                }
              >
                {SALES_OPPORTUNITY_STATUS_OPTIONS.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={async () => {
                      await updateSalesOpportunity(companyId, id, {
                        status: item.value,
                        owner,
                        priority,
                        title,
                        address,
                        deliveryTypeId: deliveryType?.id,
                        description,
                        startDate,
                        endDate,
                        leadSource,
                        expectedCloseDate,
                        price,
                        priceType,
                        estimatedHours,
                        customColumnOne,
                        customColumnTwo
                      });

                      if (item.value === "Won") onWonDialogOpen();

                      await router.invalidate();
                    }}
                    label={t(getStatusLabel(item.value))}
                  />
                ))}
              </DropDown>
            )}
            {!archived && (
              <DropDown
                button={
                  <IconButton icon="more_vert" size={"4"} variant={"surface"} />
                }
              >
                <DropdownItem onClick={onArchiveOpen} label={t("Arkiver")} />
                <DropdownItem
                  onClick={convertToProjectDialog.onOpen}
                  label={t("convertToProject")}
                />
                <DropdownItem
                  onClick={() => convertToOrder()}
                  label={t("convertToOrder")}
                />
              </DropDown>
            )}
          </div>
        }
      />
      <div className="flex flex-col sm:flex-row justify-end sm:justify-between mb-6">
        <ConfirmationDialog
          title={t("Arkiver salgsmulighet")}
          message={t("Er du sikker på at du vil arkivere salgsmuligheten?")}
          confirmText={t("Arkiver")}
          onCancel={onArchiveClose}
          open={isArchiveOpen}
          onConfirm={() => {
            archiveSalesOppertunity(companyId, salesOpportunity.id, false)
              .then(() => {
                onArchiveClose();
                navigate({
                  to: "/dashboard/sales",
                  search: {
                    tab: "salesOpportunities"
                  }
                });
                showSuccessToast(t("Salgsmulighet arkivert"));
              })
              .catch(() => {
                showSuccessToast(t("Kunne ikke arkivere salgsmulighet"));
              });
          }}
        />
      </div>
      <div className="flex flex-col mb-6">
        <Statusline status={status} />
      </div>

      <TypedTab
        tabs={salesDetailsTabs.options}
        getLabel={(tab) => t(tab)}
        selected={tab}
        onChange={(tab) =>
          navigate({
            to: "/dashboard/sales/$salesOpportunityId",
            params: { salesOpportunityId: salesOpportunity.id },
            search: {
              tab: tab
            }
          })
        }
      >
        <div className="flex flex-col gap-8">
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 3xl:grid-cols-3">
            {/* Details card */}
            <Card
              isFullHeight
              title={t("details")}
              trailing={
                !archived && (
                  <IconButton asChild icon={"edit"} variant={"soft"}>
                    <Link
                      to={"/dashboard/sales/$salesOpportunityId/update"}
                      params={{
                        salesOpportunityId: salesOpportunity.id
                      }}
                    />
                  </IconButton>
                )
              }
            >
              <div className="flex flex-col gap-3">
                {owner && <IconWithText icon={"assignment_ind"} text={owner} />}
                {description && (
                  <IconWithText icon={"notes"} text={description} />
                )}
                {leadSource && <IconWithText icon={"link"} text={leadSource} />}
                {expectedCloseDate && (
                  <IconWithText
                    icon={"calendar_month"}
                    text={formatFullNumericDate(new Date(expectedCloseDate))}
                  />
                )}
                {address && (
                  <IconWithText icon={"location_on"} text={address} />
                )}
                {startDate && endDate && (
                  <IconWithText
                    icon={"timer"}
                    text={`${formatFullNumericDate(new Date(startDate))} -
                        ${formatFullNumericDate(new Date(endDate))}`}
                  />
                )}
                {priceText && (
                  <IconWithText icon={"payments"} text={priceText} />
                )}

                {customColumns.map((column, index) => {
                  const value = index === 0 ? customColumnOne : customColumnTwo;

                  if (!value) return;

                  return (
                    <div key={index} className="flex items-center gap-2">
                      <IconWithText
                        icon={"short_text"}
                        text={`${column.title}: `}
                      />
                      <p>{value}</p>
                    </div>
                  );
                })}
              </div>
            </Card>
            <DeadlineComponent
              resourceId={salesOpportunity.id}
              canAdd={!salesOpportunity.archived}
            />
            <Card>
              <div className="grid grid-cols-2 gap-3">
                {customer && (
                  <div className={"flex flex-col gap-3"}>
                    <h3 className={"font-bold"}>{t("customer") + ":"}</h3>
                    {customer.formattedCustomerName !== "" && (
                      <IconWithText
                        icon={
                          customer.type === "Corporate" ? "apartment" : "person"
                        }
                        text={customer.formattedCustomerName}
                      />
                    )}
                    <IconWithText
                      icon={"person"}
                      text={customer.firstName + " " + customer.lastName}
                    />
                    {customer.mobilePhone && (
                      <IconWithText
                        icon={"phone"}
                        text={customer.mobilePhone}
                      />
                    )}
                    {customer.workPhone && (
                      <IconWithText icon={"phone"} text={customer.workPhone} />
                    )}
                    {customer.email && (
                      <IconWithText icon={"email"} text={customer.email} />
                    )}
                  </div>
                )}
                {customer && (
                  <div className={"flex flex-col gap-3"}>
                    <h3 className={"font-bold"}>{t("contactPerson") + ":"}</h3>
                    {salesOpportunity.contactPerson && (
                      <IconWithText
                        icon={"person"}
                        text={
                          salesOpportunity.contactPerson.firstName +
                          " " +
                          salesOpportunity.contactPerson.lastName
                        }
                      />
                    )}
                    {salesOpportunity.contactPerson && (
                      <IconWithText
                        icon={"phone"}
                        text={salesOpportunity.contactPerson.mobilePhone}
                      />
                    )}
                    {salesOpportunity.contactPerson && (
                      <IconWithText
                        icon={"email"}
                        text={salesOpportunity.contactPerson.email}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>
          </div>
          <NoteComponent resourceId={salesOpportunity.id} />
        </div>

        <DocumentationTab
          resourceId={salesOpportunity.id}
          resourceName={salesOpportunity.title}
          isDisabled={salesOpportunity.archived}
        />

        <InspectionTab salesOpportunity={salesOpportunity} />

        <OfferOnSalesOpportunityPage salesOpportunity={salesOpportunity} />
      </TypedTab>

      <WonModal
        salesOpportunity={salesOpportunity}
        isOpen={isWonDialogOpen}
        onClose={onWonDialogClose}
        onConvertToProject={convertToProjectDialog.onOpen}
        refetch={router.invalidate}
      />
    </div>
  );
}
