import {
  createFileRoute,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import DocumentationTab from "../../../../../../pages/documentation/DocumentationTab";
import PageLayout from "../../../../../../components/PageLayout";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout/documentation"
)({
  component: OrderDocumentationPage
});

function OrderDocumentationPage() {
  const order = useLoaderData({
    from: "/_protected/dashboard/orders/$orderId/_layout"
  });
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/_layout/documentation"
  });

  return (
    <PageLayout>
      <DocumentationTab resourceId={orderId} resourceName={order.title} />
    </PageLayout>
  );
}
