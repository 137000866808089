import { createFileRoute, Link } from "@tanstack/react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginLayout from "../_unauthenticated/-components/LoginLayout";
import { Separator, Text } from "@radix-ui/themes";
import { CheckboxNew } from "../../components/ds/CheckboxNew";
import ButtonNew from "../../components/ds/ButtonNew";

export const Route = createFileRoute("/_protected/welcome")({
  component: WelcomePage
});

function WelcomePage() {
  const [selected, setSelected] = useState(false);
  const { t } = useTranslation();

  return (
    <LoginLayout title={t("welcomeToSkyworker")} withHeader>
      <Text className={"mt-6"} color={"gray"}>
        Din bruker er opprettet og du er klar til å sette i gang.
      </Text>
      <Text className={"mt-6"} color={"gray"}>
        Du har nå et prøveabonnement som varer i 14 dager. Du blir ikke
        fakturert for prøveperioden.
      </Text>
      <br />
      <Text color={"gray"}>
        Vi håper arbeidsdagen din blir genial og enkel med Skyworker!
      </Text>
      <br />
      <Text color={"gray"}>
        Obs: husk å sjekke søppelpost om du ikke har mottat mail fra oss!
      </Text>
      <Separator className={"w-full my-8"} />
      <CheckboxNew
        checked={selected}
        onChange={() => setSelected((previous) => !previous)}
        label={
          "Jeg vil gjerne at en Skyworker-ekspert tar kontakt for å gi meg en innføring"
        }
      />

      <ButtonNew size={"3"} asChild className={"mt-8"}>
        <Link to={"/dashboard/project/overview"}>{t("getStarted")}</Link>
      </ButtonNew>
    </LoginLayout>
  );
}
