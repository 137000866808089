import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import React from "react";
import { UpsertEhsPage } from "../-components/UpsertEhsPage";
import { z } from "zod";
import { ehsResourceType } from "../../../../../router/Routes";
import { useGetEhsById } from "../../../../../service/api/EhsApi";

const upsertEhsSchema = z.object({
  resourceType: ehsResourceType,
  resourceId: z.string()
});

export const Route = createFileRoute("/_protected/dashboard/ehs/$ehsId/update")(
  {
    validateSearch: upsertEhsSchema,
    preloadGcTime: 0,
    preloadStaleTime: 0,
    loader: async ({
      context: { queryClient, companyId },
      params: { ehsId }
    }) => {
      return await queryClient.fetchQuery({
        ...useGetEhsById.getOptions({ companyId, ehsId })
      });
    },
    component: UpdateEhsPage
  }
);

function UpdateEhsPage() {
  const { resourceId, resourceType } = useSearch({
    from: "/_protected/dashboard/ehs/$ehsId/update"
  });
  const ehsItem = useLoaderData({
    from: "/_protected/dashboard/ehs/$ehsId/update"
  });

  return (
    <UpsertEhsPage
      resourceType={resourceType}
      resourceId={resourceId}
      ehs={ehsItem}
    />
  );
}
