import { Text, Tooltip } from "@radix-ui/themes";
import { keepPreviousData } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeEntry } from "../../../../../.generated/api";
import PageLayout from "../../../../../components/PageLayout";
import ExportTimeEntryDialog from "../../../../../components/dialogs/ExportTimeEntryDialog";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import CardNew from "../../../../../components/ds/CardNew";
import { CheckboxNew } from "../../../../../components/ds/CheckboxNew";
import Combobox from "../../../../../components/ds/Combobox/Combobox";
import ComboboxMenu, { Item } from "../../../../../components/ds/ComboboxMenu";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TimeFilterSelector from "../../../../../components/ds/TimeFilterSelector";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import { searchBarSize } from "../../../../../components/ds/sizes";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDebounce from "../../../../../hooks/useDebounce";
import useDialog from "../../../../../hooks/useDialog";
import { useSelectedGeneric } from "../../../../../hooks/useSelected";
import { useSort } from "../../../../../hooks/useSort";
import { useTimeFilter } from "../../../../../hooks/useTimeFilter";
import { useToast } from "../../../../../hooks/useToast";
import TimeTableRow from "../../../../../pages/time/TimeTableRow";
import TimeEntryDialog from "../../../../../pages/timeInvoice/timeProject/TimeEntryDialog";
import { useSearchOrdersAndProjects } from "../../../../../service/api/OrderApi";
import { useGetTeamsByCompanyId } from "../../../../../service/api/TeamApi";
import {
  syncTimeEntries,
  TimeApprovalFilter,
  TimeEntrySortField,
  updateTimeEntryApproval,
  useGetPaginatedTimeEntriesByCompanyId
} from "../../../../../service/api/TimeEntryApi";
import { useGetUsersByCompanyId } from "../../../../../service/api/UserV2Api";

export const Route = createFileRoute(
  "/_protected/dashboard/economy/_layout/time-list"
)({
  component: TimeSheetPage
});

function TimeSheetPage() {
  const companyId = useCompanyId();
  const { selectedRange, setSelectedRange, period } = useTimeFilter("week");
  const [isSyncing, setIsSyncing] = useState(false);
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();

  const [resourceQuery, setResourceQuery] = useState("");
  const [selectedResource, setSelectedResource] = useState<string>();
  const debouncedResourceQuery = useDebounce(resourceQuery);
  const [selectedEmployee, setSelectedEmployee] = useState<Item>();
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const timeDialog = useDialog();
  const exportDialog = useDialog();
  const [selectedStatus, setSelectedStatus] =
    useState<Item<TimeApprovalFilter>>();

  const { selected, handleSelect, allSelected, handleSelectAll, isSelected } =
    useSelectedGeneric<TimeEntry>("id");

  const {
    sortField,
    page,
    setPage,
    sortDirection,
    registerHeader,
    pageSize,
    setPageSize
  } = useSort<TimeEntrySortField>("date", "timeList");

  const { resourceOptions, isFetching } = useSearchOrdersAndProjects(
    companyId,
    debouncedResourceQuery
  );

  const timeEntryQuery = useGetPaginatedTimeEntriesByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      sortDirection,
      sortField,
      page,
      approved: selectedStatus?.value,
      userId: employeeIds,
      resourceId: selectedResource,
      from: selectedRange.start,
      to: selectedRange.end,
      pageSize
    }
  });

  const userQuery = useGetUsersByCompanyId({
    variables: { companyId }
  });

  const teamsQuery = useGetTeamsByCompanyId({
    variables: { companyId }
  });

  const timeEntries = timeEntryQuery.data?.data || [];
  const users = userQuery.data || [];
  const teams = teamsQuery.data || [];

  async function handleApproval() {
    const filtered = selected.filter((s) => !s.approved);

    if (filtered.length > 0) {
      await updateTimeEntryApproval(companyId, {
        timeEntryIds: filtered.map((f) => f.id),
        approved: true
      });
    }

    showSuccessToast(t("timeEntryApproved"));
    timeEntryQuery.refetch();
  }

  function handleUserOrTeamSelect(item?: Item) {
    if (!item) return;
    const user = users.find((u) => u.id === item.value);
    if (user) {
      setSelectedEmployee(item);
      setEmployeeIds([item.value]);
      return;
    }

    const team = teams.find((t) => t.id === item.value);
    if (team) {
      setSelectedEmployee(item);
      setEmployeeIds(team.users.map((u) => u.id));
    }
  }

  async function handleSyncClick() {
    setIsSyncing(true);
    try {
      await syncTimeEntries(companyId);
      showSuccessToast(t("timeEntriesSynced"));
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.errorCode === "IizyConnectionNotFound") {
        showErrorToast(t("timeEntrySyncNotSetUp"));
      } else {
        showErrorToast(t("anErrorOccurred"));
      }
    } finally {
      setIsSyncing(false);
    }
  }

  return (
    <PageLayout>
      <ExportTimeEntryDialog
        isOpen={exportDialog.isOpen}
        onClose={exportDialog.onClose}
        entries={selected}
      />
      <TimeEntryDialog
        open={timeDialog.isOpen}
        onClose={timeDialog.onClose}
        resourceId={""}
        resourceType={"Project"}
        onUpdated={timeEntryQuery.refetch}
      />
      <CardNew
        inset
        title={t("timeList")}
        trailing={
          <TimeFilterSelector
            dates={selectedRange}
            period={period}
            onChange={setSelectedRange}
            periods={["week", "month", "custom"]}
          />
        }
        headerContent={
          <>
            <Combobox
              skipLocalFiltering
              loading={isFetching}
              className={searchBarSize}
              size={"2"}
              placeholder={t("searchForProjectOrWorkOrder")}
              options={resourceOptions}
              getLabel={(u) => u.title}
              getValue={(u) => u.id}
              onValueChange={setSelectedResource}
              value={selectedResource}
              onInputChange={setResourceQuery}
            />
            <ComboboxMenu<TimeApprovalFilter>
              label={"Status"}
              onChange={setSelectedStatus}
              value={selectedStatus}
              items={[
                {
                  value: "notApproved",
                  label: t("notApproved")
                },
                {
                  value: "approved",
                  label: t("approved")
                },
                {
                  value: "invoiced",
                  label: t("invoiced")
                },
                {
                  value: "notInvoiced",
                  label: t("notInvoiced")
                },
                {
                  value: "transferred",
                  label: t("transferred")
                },
                {
                  value: "notTransferred",
                  label: t("notTransferred")
                }
              ]}
            />

            <ComboboxMenu
              withSearch
              items={[
                ...users.map((u) => ({
                  label: u.name ?? "-",
                  value: u.id
                })),
                ...teams.map((t) => ({
                  label: t.name,
                  value: t.id
                }))
              ]}
              onChange={handleUserOrTeamSelect}
              value={selectedEmployee}
              label={t("employeeTeam")}
            />
            {(selectedEmployee || selectedStatus) && (
              <ButtonNew
                icon={"close"}
                color={"gray"}
                variant={"outline"}
                onClick={() => {
                  setSelectedEmployee(undefined);
                  setSelectedStatus(undefined);
                  setEmployeeIds([]);
                }}
              >
                {t("removeFilter")}
              </ButtonNew>
            )}
            <div className={"grow"} />
            {selected.length > 0 && (
              <>
                <Text as={"p"} className={"self-center"}>
                  {selected.length} {t("selected")}
                </Text>
                <ButtonNew
                  icon={"download"}
                  variant={"outline"}
                  color={"gray"}
                  onClick={exportDialog.onOpen}
                >
                  {t("export")}
                </ButtonNew>
                <ButtonNew variant={"surface"} onClick={handleApproval}>
                  {t("approveHours")}
                </ButtonNew>
              </>
            )}
            <ButtonNew
              icon={"sync"}
              loading={isSyncing}
              variant={"outline"}
              color={"gray"}
              onClick={handleSyncClick}
            >
              {t("transfer")}
            </ButtonNew>
            <ButtonNew icon={"add"} onClick={timeDialog.onOpen}>
              {t("addHours")}
            </ButtonNew>
          </>
        }
        footerContent={
          <PaginationNew
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={timeEntryQuery.data?.totalPages}
          />
        }
      >
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>
                <CheckboxNew
                  checked={allSelected(timeEntries)}
                  onClick={() => handleSelectAll(timeEntries)}
                />
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("userId")}>
                {t("user")}
              </TableHeaderCell>
              <TableHeaderCell sortFn={registerHeader("date")}>
                {t("date")}
              </TableHeaderCell>
              <TableHeaderCell>{t("value")}</TableHeaderCell>
              <TableHeaderCell>
                {t("customer") + "/" + t("project")}
              </TableHeaderCell>
              <TableHeaderCell>{t("activity")}</TableHeaderCell>
              <TableHeaderCell>{t("approved")}</TableHeaderCell>
              <TableHeaderCell>
                <div className={"flex gap-1 items-center"}>
                  {t("invoiced")}
                  <Tooltip content={t("invoicedEntryDescription")}>
                    <IconButton
                      icon={"info"}
                      variant={"ghost"}
                      color={"gray"}
                      highContrast
                    />
                  </Tooltip>
                </div>
              </TableHeaderCell>
              <TableHeaderCell>
                <div className={"flex gap-1 items-center"}>
                  {t("transferred")}
                  <Tooltip content={t("transferredEntryDescription")}>
                    <IconButton
                      icon={"info"}
                      variant={"ghost"}
                      color={"gray"}
                      highContrast
                    />
                  </Tooltip>
                </div>
              </TableHeaderCell>

              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {timeEntries.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>{t("noTimeEntriesAdded")}</TableCell>
              </TableRow>
            )}
            {timeEntries.map((entry) => (
              <TimeTableRow
                key={entry.id}
                entry={entry}
                companyId={companyId}
                onUpdated={timeEntryQuery.refetch}
                onCheck={() => handleSelect(entry)}
                checked={isSelected(entry)}
              />
            ))}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
