import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Link, Text } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/_protected/trial")({
  component: TrialPage
});

function TrialPage() {
  const { t } = useTranslation();

  return (
    <div className={"max-w-[600px] mx-auto pt-[100px]"}>
      <Outlet />
      <div className={"mt-4 max-w-[300px] mx-auto text-center"}>
        <Text as={"p"}>
          {t("freeTrialInfoText1")}{" "}
          <Link href="mailto:hei@skyworker.no?subject=Jeg ønsker å fortsette å bruke Skyworker">
            hei@skyworker.no
          </Link>{" "}
          {t("freeTrialInfoText2")} <Link href="tel:22151522">22 15 15 22</Link>
          .
        </Text>
      </div>
    </div>
  );
}
