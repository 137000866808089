import { createFileRoute, Link, useLoaderData } from "@tanstack/react-router";
import { useGetPublicOrderInfoByIds } from "../../../service/api/OrderApi";
import { useGetPublicProjectInfoByIds } from "../../../service/api/ProjectApiV2";
import NewPageTitle from "../../../components/common/NewPageTitle";
import PageLayout from "../../../components/PageLayout";
import { Text } from "@radix-ui/themes";
import CardNew from "../../../components/ds/CardNew";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "../../../components/ds/TableNew";
import Badge from "../../../components/ds/Badge";
import {
  dateFormatterShortMonth,
  formatHoursAndMinutes
} from "../../../utils/DateTimeFormatter";
import { TextFieldNew } from "../../../components/ds/TextFieldNew";
import Select from "../../../components/ds/NewSelect";
import { useEffect, useState } from "react";
import TextWithDetails from "../../../components/ds/TextWithDetails";
import LanguageSwitcherNew from "../../../components/common/LanguageSwitcherNew";
import { useTranslation } from "react-i18next";
import {
  useGetCompanyLogo,
  useGetCompanyPublicInfo
} from "../../../service/api/CompanyApi";
import { useQuery } from "@tanstack/react-query";
import { useGetCustomerPublicInfoById } from "../../../service/api/CustomerApiV3";

export const Route = createFileRoute("/guest/$customerId/")({
  loader: ({ context: { queryClient }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerPublicInfoById.getOptions({ customerId })
    });
  },
  component: CustomerGuestPage
});

function CustomerGuestPage() {
  const { t } = useTranslation();
  const customer = useLoaderData({ from: "/guest/$customerId/" });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState<"All" | "Project" | "Order">(
    "All"
  );
  const [hasContent, setHasContent] = useState(true);

  const companyQuery = useGetCompanyPublicInfo({
    variables: { companyId: customer.ownerCompanyId }
  });
  const company = companyQuery.data;

  const logoQuery = useGetCompanyLogo({
    variables: { companyId: customer.ownerCompanyId }
  });
  const companyLogoUrl = logoQuery.data?.url;

  const projectIds = customer.resources
    .filter(
      (resource) =>
        resource.resourceType === "Project" && searchType !== "Order"
    )
    .flatMap((resource) => resource.resourceId);
  const projectQuery = useQuery({
    ...useGetPublicProjectInfoByIds.getOptions({ projectIds })
  });
  const projects = projectQuery.data?.filter((project) =>
    project.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const orderIds = customer.resources
    .filter(
      (resource) =>
        resource.resourceType === "Order" && searchType !== "Project"
    )
    .flatMap((resource) => resource.resourceId);
  const orderQuery = useQuery({
    ...useGetPublicOrderInfoByIds.getOptions({ orderIds })
  });
  const orders = orderQuery.data?.filter((order) =>
    order.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formattedDateTime = (dateTime: Date) => {
    return `${dateFormatterShortMonth(dateTime)} - ${formatHoursAndMinutes(dateTime)}`;
  };

  useEffect(() => {
    setHasContent(
      ((projects && projects?.length > 0) || (orders && orders?.length > 0)) ??
        false
    );
  }, [projects, orders]);

  //TODO: New design?
  // biome-ignore lint/complexity/noUselessLoneBlockStatements: <explanation>
  {
    /*
  return (
    <div className={"max-w-[500px] mx-auto pt-10"}>
      <Heading className={"mb-10"}>Nytt og flott bygg AS</Heading>

      <Heading size={"3"} className={"ml-4 mb-2"}>
        Kundeportal
      </Heading>
      <CardNew>
        <DataList>
          <DataListItem label={t("customer")} value={""} />
        </DataList>
        <Separator className={"my-4 w-full"} />
        <DataList>
          <DataListItem label={t("supplier")} value={"Arne arnesen"} />
          <DataListItem
            label={t("orgNumber")}
            value={"Nytt og flott bygg as"}
          />
          <DataListItem
            label={t("contactPerson")}
            value={"Nytt og flott bygg as"}
          />
          <DataListItem label={t("email")} value={"Nytt og flott bygg as"} />
          <DataListItem label={t("phone")} value={"Nytt og flott bygg as"} />
        </DataList>
      </CardNew>
      <Heading as={"h5"} size={"3"} className={"py-10"}>
        Prosjekter
      </Heading>
      <Card asChild>
        <Link>asd</Link>
      </Card>
      <Link>
        <CardNew>asd</CardNew>
      </Link>
      <CardNew>
        Ingen prosjekter eller ordre er tilgjengelige for visning
      </CardNew>
      <p>asd</p>
    </div>
  );
  */
  }

  return (
    <>
      <NewPageTitle
        title={`${customer.companyName ?? customer.firstName + " " + customer.lastName}`}
        trailing={<LanguageSwitcherNew />}
        withNavigationGap={false}
      />
      <PageLayout
        preview={
          <div className={"flex flex-col gap-2"}>
            <Text size={"3"} weight={"bold"} mb={"3"} as={"p"}>
              {t("supplier")}
            </Text>
            <TextWithDetails
              title={t("supplier")}
              details={company?.name ?? "-"}
            />
            <TextWithDetails
              title={"Org. nr."}
              details={company?.orgNumber ?? "-"}
            />
            <TextWithDetails
              title={t("contactPerson")}
              details={company?.contactPerson ?? "-"}
            />
            <TextWithDetails
              title={t("email")}
              details={company?.contactEmail ?? "-"}
            />
            <TextWithDetails
              title={t("phone")}
              details={company?.contactPhone ?? "-"}
            />
            <div className={"flex flex-row justify-center mt-4"}>
              {companyLogoUrl && (
                <img
                  src={companyLogoUrl}
                  className={"w-3/4 h-3/4"}
                  alt={"company logo"}
                />
              )}
            </div>
          </div>
        }
      >
        {hasContent ? (
          <CardNew
            title={`${t("activeProjectsAndOrders")}`}
            titleBadge={
              <Badge size={"1"} color={"green"}>
                {customer.resources.length}
              </Badge>
            }
            headerContent={
              <div className={"flex flex-row gap-2"}>
                <TextFieldNew
                  size={"2"}
                  className={"w-[230px]"}
                  placeholder={t("searchForProjectOrOrder")}
                  icon={"search"}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <Select
                  options={[
                    { value: "All", label: t("all") },
                    { value: "Project", label: t("project") },
                    { value: "Order", label: t("order") }
                  ]}
                  onChange={(value) => {
                    setSearchType(value as "All" | "Project" | "Order");
                  }}
                  size={"2"}
                  placeholder={t("type")}
                />
              </div>
            }
          >
            {projects && projects.length > 0 && (
              <CardNew
                title={t("projects")}
                titleBadge={
                  <Badge size={"1"} color={"gray"}>
                    {projects.length}
                  </Badge>
                }
                className={"mb-4"}
                inset={true}
              >
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell className={"w-[140px]"}>
                        {t("projectNumber")}
                      </TableHeaderCell>
                      <TableHeaderCell>{t("title")}</TableHeaderCell>
                      <TableHeaderCell>{t("start")}</TableHeaderCell>
                      <TableHeaderCell>{t("end")}</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {projects.map((project) => {
                      return (
                        <TableRow key={project.id}>
                          <TableCell>
                            <Link
                              to={"/guest/project/$projectId"}
                              params={{
                                projectId: project.id
                              }}
                              className="flex items-center w-full h-full pr-1 group group-hover/tr:underline"
                            >
                              <Badge
                                color={"gray"}
                              >{`#${project.number}`}</Badge>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={"/guest/project/$projectId"}
                              params={{
                                projectId: project.id
                              }}
                              className="flex items-center w-full h-full pr-1 group group-hover/tr:underline"
                            >
                              <Text as={"p"} size={"2"}>
                                {project.title}
                              </Text>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Text as={"p"} size={"2"}>
                              {project.startDate
                                ? formattedDateTime(new Date(project.startDate))
                                : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text as={"p"} size={"2"}>
                              {project.endDate
                                ? formattedDateTime(new Date(project.endDate))
                                : "-"}
                            </Text>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardNew>
            )}
            {orders && orders.length > 0 && (
              <CardNew
                title={t("orders")}
                titleBadge={
                  <Badge size={"1"} color={"gray"}>
                    {orders.length}
                  </Badge>
                }
                inset={true}
              >
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell className={"w-[140px]"}>
                        {t("orderNumber")}
                      </TableHeaderCell>
                      <TableHeaderCell>{t("title")}</TableHeaderCell>
                      <TableHeaderCell>{t("start")}</TableHeaderCell>
                      <TableHeaderCell>{t("end")}</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {orders.map((order) => {
                      return (
                        <TableRow key={order.id}>
                          <TableCell>
                            <Link
                              to={"/guest/order/$orderId"}
                              params={{
                                orderId: order.id
                              }}
                              className="flex items-center w-full h-full pr-1 group group-hover/tr:underline"
                            >
                              <Badge color={"gray"}>{`#${order.number}`}</Badge>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={"/guest/order/$orderId"}
                              params={{
                                orderId: order.id
                              }}
                              className="flex items-center w-full h-full pr-1 group group-hover/tr:underline"
                            >
                              <Text as={"p"} size={"2"}>
                                {order.title}
                              </Text>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Text as={"p"} size={"2"}>
                              {order.startDate
                                ? formattedDateTime(new Date(order.startDate))
                                : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text as={"p"} size={"2"}>
                              {order.endDate
                                ? formattedDateTime(new Date(order.endDate))
                                : "-"}
                            </Text>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardNew>
            )}
          </CardNew>
        ) : (
          <CardNew title={t("nothingToShow")}>
            {
              <Text as={"p"} size={"2"}>
                {t("cPortalEmptyText")}
              </Text>
            }
          </CardNew>
        )}
      </PageLayout>
    </>
  );
}
