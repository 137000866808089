import {
  Combobox as AriaCombobox,
  ComboboxList,
  ComboboxProvider
} from "@ariakit/react";
import * as Popover from "@radix-ui/react-popover";
import { ScrollArea, Spinner, TextField, Theme } from "@radix-ui/themes";
import classNames from "classnames";
import classnames from "classnames";
import React, {
  InputHTMLAttributes,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWrapperNew, { InputWrapperProps } from "../InputWrapperNew";
import RadixIcon from "../RadixIcon";
import IconButton from "../buttons/IconButton";
import { pickInputWrapperProps } from "../utils";
import { ComboboxItem } from "./ComboboxItem";

type ControlledComboboxProps<T extends FieldValues, U> = {
  control: Control<T>;
  fieldName: FieldPath<T>;
  useStringAsValue?: boolean;
} & FormComboBoxProps<U>;

export function ControlledCombobox<T extends FieldValues, U>({
  fieldName,
  control,
  onValueChange,
  onInputChange,
  useStringAsValue = false,
  ...props
}: ControlledComboboxProps<T, U>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value, ref, name, onBlur } }) => (
        <FormCombobox
          {...props}
          value={value}
          customRef={ref}
          name={name}
          onBlur={onBlur}
          onInputChange={(val) => {
            if (useStringAsValue) onChange(val);
            onInputChange?.(val);
          }}
          onValueChange={(val) => {
            if (!useStringAsValue) onChange(val);
            onValueChange?.(val);
          }}
        />
      )}
    />
  );
}

type ComboboxProps<T> = {
  onValueChange?: (value: string | undefined) => void;
  onInputChange?: (value: string) => void;
  loading?: boolean;
  placeholder?: string;
  className?: string;
  options: T[];
  customRender?: (
    handleItemSelect: (value: string, label: string) => void
  ) => React.ReactNode;
  maxHeight?: string;
  value?: string;
  getLabel: (val: T) => string;
  getValue: (val: T) => string;
  getDisplayValue?: (val: T) => string;
  clearOnSelect?: boolean;
  skipLocalFiltering?: boolean;
  customRef?: Ref<HTMLInputElement>;
  size?: "2" | "3";
} & Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "size">;

type FormComboBoxProps<T> = InputWrapperProps & ComboboxProps<T>;

export function FormCombobox<T>({ ...props }: FormComboBoxProps<T>) {
  return (
    <InputWrapperNew {...pickInputWrapperProps(props)}>
      {(style) => (
        <Combobox {...props} className={classnames(style, props.className)} />
      )}
    </InputWrapperNew>
  );
}

export default function Combobox<T>({
  onValueChange,
  onInputChange,
  loading,
  placeholder,
  className,
  customRef,
  value,
  getLabel,
  getValue,
  options,
  customRender,
  maxHeight = "200px",
  clearOnSelect = false,
  skipLocalFiltering = false,
  size = "3",
  ...props
}: ComboboxProps<T>) {
  const { t } = useTranslation();
  const comboboxRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  useImperativeHandle(customRef, () => comboboxRef.current as HTMLInputElement);

  const filtered = skipLocalFiltering
    ? options
    : options.filter((o) =>
        getLabel(o).toLowerCase().includes(query.toLowerCase())
      );

  useEffect(() => {
    if (!value) {
      setQuery("");
    } else {
      const option = options.find((o) => getValue(o) === value);
      if (option) {
        setQuery(getLabel(option));
      } else {
        setQuery(value);
      }
    }
  }, [value]);

  function renderOptions() {
    if (filtered.length === 0) {
      return <div className="rt-BaseMenuItem">{t("noOptionsFound")}</div>;
    }
    return filtered.map((o, index) => {
      const value = getValue(o);
      const label = getLabel(o);
      return (
        <ComboboxItem
          value={value}
          key={value + index}
          onClick={() => handleItemSelect(value, label)}
        >
          {label}
        </ComboboxItem>
      );
    });
  }

  function handleItemSelect(value: string, label: string) {
    onValueChange?.(value);
    if (clearOnSelect) {
      setQuery("");
    } else {
      setQuery(label);
    }
  }

  function handleInputChange(value: string) {
    onInputChange?.(value);
    onValueChange?.(undefined);
    setQuery(value);
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal>
      <ComboboxProvider open={open} setOpen={setOpen} value={query}>
        <Popover.Anchor asChild>
          <div
            className={classNames(
              "rt-TextFieldRoot rt-variant-surface rt-color-red",
              className,
              {
                "rt-r-size-3": size === "3",
                "rt-r-size-2": size === "2"
              }
            )}
          >
            <TextField.Slot>
              <RadixIcon icon={"search"} size={size} />
            </TextField.Slot>
            <AriaCombobox
              ref={customRef}
              placeholder={placeholder}
              className={classNames("rt-reset rt-TextFieldInput")}
              {...props}
              onChange={(e) => {
                handleInputChange(e.target.value);
                props.onChange?.(e);
              }}
            />
            <TextField.Slot>{loading && <Spinner />}</TextField.Slot>
            {value && (
              <TextField.Slot>
                <IconButton
                  icon={"close"}
                  variant={"ghost"}
                  size={size === "3" ? "2" : "1"}
                  onClick={() => {
                    setQuery("");
                    onValueChange?.(undefined);
                    onInputChange?.("");
                    comboboxRef.current?.focus();
                  }}
                />
              </TextField.Slot>
            )}
          </div>
        </Popover.Anchor>

        <Popover.Portal>
          <Theme>
            <Popover.Content
              className={
                "radix-themes rt-PopperContent rt-BaseMenuContent rt-DropdownMenuContent rt-r-size-2 rt-variant-solid"
              }
              sideOffset={8}
              onOpenAutoFocus={(event) => event.preventDefault()}
              onInteractOutside={(event) => {
                const target = event.target as Element | null;
                const isCombobox = target === comboboxRef.current;
                const inListBox =
                  target && listBoxRef.current?.contains(target);
                if (isCombobox || inListBox) {
                  event.preventDefault();
                }
              }}
            >
              <ScrollArea
                type={"auto"}
                scrollbars={"vertical"}
                style={{ maxHeight: maxHeight }}
              >
                <ComboboxList
                  ref={listBoxRef}
                  role="listbox"
                  className="rt-BaseMenuViewport rt-DropdownMenuViewport listbox"
                  style={{ width: "var(--radix-popover-trigger-width)" }}
                >
                  {customRender?.(handleItemSelect) ?? renderOptions()}
                </ComboboxList>
              </ScrollArea>
            </Popover.Content>
          </Theme>
        </Popover.Portal>
      </ComboboxProvider>
    </Popover.Root>
  );
}
