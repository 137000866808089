import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as signOutFirebase,
  UserCredential
} from "firebase/auth";
import { QueryClient } from "@tanstack/react-query";
import { UseNavigateResult } from "@tanstack/react-router";

const providerMicrosoft = new OAuthProvider("microsoft.com");
const providerGoogle = new GoogleAuthProvider();
const providerApple = new OAuthProvider("apple.com");
providerApple.addScope("email");
providerApple.addScope("name");

export const signInMicrosoft = async (): Promise<UserCredential> => {
  return signInWithPopup(getAuth(), providerMicrosoft);
};

export const signInGoogle = async (): Promise<UserCredential> => {
  return signInWithPopup(getAuth(), providerGoogle);
};

export const signInApple = async (): Promise<UserCredential> => {
  return signInWithPopup(getAuth(), providerApple);
};

export const resetPassword = (email: string): Promise<void> => {
  return sendPasswordResetEmail(getAuth(), email);
};

export async function signOut(
  queryClient: QueryClient,
  navigate: UseNavigateResult<string>
) {
  await signOutFirebase(getAuth());
  queryClient.clear();
  await navigate({
    to: "/login",
    replace: true
  });
}

export const signInWithUsernameAndPass = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(getAuth(), email, password);

export const createAccount = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(getAuth(), email, password);

export const handleFirebaseError = (e: Record<string, unknown>): string => {
  switch (e.code) {
    case "auth/invalid-email":
      return "Ugyldig e-postadresse";
    case "auth/user-disabled":
      return "Kontoen er deaktivert";
    case "auth/user-not-found":
      return "Ingen konto funnet for denne e-postadressen";
    case "auth/wrong-password":
      return "Feil passord";
    case "auth/too-many-requests":
      return "For mange innloggingsforsøk. Prøv igjen senere";
    case "auth/account-exists-with-different-credential":
    case "auth/email-already-in-use":
      return "Det finnes allerede en konto for denne e-postadressen";
    case "auth/cancelled-popup-request":
      return "Kun ett innloggingsvindu kan være åpnet samtidig";
    case "auth/popup-blocked":
      return "Åpning av innloggingsvindu blokkert";
    case "auth/popup-closed-by-user":
      return "Innloggingsvindu ble lukket før fullført innlogging";
    case "auth/weak-password":
      return "Det valgte passordet er ikke sikkert nok";
    default: {
      const message = "Feil ved innlogging";
      if (e.code) {
        return `${message} (${e.code})`;
      }

      return message;
    }
  }
};
