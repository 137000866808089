import { createFileRoute } from "@tanstack/react-router";
import { useToast } from "../../hooks/useToast";
import { useForm } from "react-hook-form";
import {
  handleFirebaseError,
  resetPassword
} from "../../service/firebase/FirebaseService";
import LoginLayout from "./-components/LoginLayout";
import { t } from "i18next";
import { FormTextField } from "../../components/ds/TextFieldNew";
import ButtonNew from "../../components/ds/ButtonNew";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const Route = createFileRoute("/_unauthenticated/reset-password")({
  component: ResetPasswordPage
});

const resetPasswordSchema = z.object({
  email: z.string().email()
});

type ResetPasswordFormInput = z.infer<typeof resetPasswordSchema>;

function ResetPasswordPage() {
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<ResetPasswordFormInput>({
    resolver: zodResolver(resetPasswordSchema)
  });

  async function onSubmit(data: ResetPasswordFormInput) {
    try {
      await resetPassword(data.email);
      showSuccessToast(
        "En lenke for å tilbakestille passord har blitt sendt til deg på e-post"
      );
    } catch (e) {
      const error = handleFirebaseError(e as Record<string, unknown>);
      showErrorToast(error);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginLayout
        title={"Tilbakestill passord"}
        subtitle={
          "Skriv inn e-posten din så sender vi deg en lenke du kan trykke på for å lage et nytt passord"
        }
      >
        <FormTextField
          label={t("email")}
          placeholder={t("eksempel@bedrift.no")}
          errorMessage={errors.email?.message}
          {...register("email")}
        />
        <ButtonNew type={"submit"} size={"3"} loading={isSubmitting}>
          Tilbakestill
        </ButtonNew>
      </LoginLayout>
    </form>
  );
}
