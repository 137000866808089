import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../../components/ds/Card";
import TasksItemTemplateDetails from "../../../../../pages/setting/taskTemplate/TaskTemplateDetails";
import PageLayout from "../../../../../components/PageLayout";
import { useQuery } from "@tanstack/react-query";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import { TaskTemplateDTO } from "../../../../../.generated/api";
import { getTaskTemplates } from "../../../../../service/api/TaskTemplateApi";
import TaskTemplateDialog from "../../../../../pages/setting/taskTemplate/TaskTemplateDialog";
import TemplateGridLayout from "../../../../../pages/setting/TemplateGridLayout";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/task-template"
)({
  component: TaskTemplateSettingsPage
});

function TaskTemplateSettingsPage() {
  const companyId = useCompanyId();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDialog();
  const { t } = useTranslation();
  const [filteredTemplates, setFilteredTemplates] = useState<TaskTemplateDTO[]>(
    []
  );

  const taskTemplateQuery = useQuery({
    queryKey: ["taskTemplates", companyId],
    queryFn: () => getTaskTemplates(companyId)
  });

  useEffect(() => {
    if (taskTemplateQuery.data) {
      const sorted = taskTemplateQuery.data.sort((a, b) =>
        b.created.localeCompare(a.created)
      );
      const filtered = sorted.filter((t) =>
        t.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTemplates(filtered);
    }
  }, [taskTemplateQuery.data, searchQuery]);

  return (
    <PageLayout>
      <TaskTemplateDialog
        open={isOpen}
        onClose={onClose}
        companyId={companyId}
        onUpdated={() => {
          onClose();
          setSelectedIndex(0);
          taskTemplateQuery.refetch();
        }}
      />
      <TemplateGridLayout
        primaryButtonText={t("Legg til ny oppgavemal")}
        onPrimaryButtonClick={onOpen}
        searchPlaceHolder={t("searchForTaskTemplate")}
        onSearchUpdate={(q) => setSearchQuery(q)}
        leftSection={
          <NewDataFetcher
            query={taskTemplateQuery}
            onData={() => {
              return (
                <div className={"space-y-2"}>
                  {filteredTemplates.map((template, index) => (
                    <Card
                      key={template.id}
                      isSelected={selectedIndex === index}
                      onClick={() => setSelectedIndex(index)}
                      padding={"dense"}
                    >
                      <h3>{template.title}</h3>
                      <p className={"text-system-neutral-90 mt-1"}>
                        {template.description}
                      </p>
                    </Card>
                  ))}
                </div>
              );
            }}
          />
        }
        rightSection={
          <TasksItemTemplateDetails
            companyId={companyId}
            template={filteredTemplates[selectedIndex]}
            refetch={() => taskTemplateQuery.refetch()}
            onDeleted={() => {
              if (!taskTemplateQuery.data?.[selectedIndex + 1]) {
                setSelectedIndex(0);
              }
              taskTemplateQuery.refetch();
            }}
          />
        }
      />
    </PageLayout>
  );
}
