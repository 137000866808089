import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import React, { useState } from "react";
import useDialog from "../../../../../hooks/useDialog";
import { useTranslation } from "react-i18next";
import { FolderTemplateDTO } from "../../../../../.generated/api";
import { useGetDocumentationTemplatesByCompanyId } from "../../../../../service/api/DocumentationTemplateApi";
import DocumentationTemplateDialog from "../../../../../pages/documentation/DocumentationTemplateDialog";
import DocumentationTemplateDetails from "../../../../../pages/setting/documentationTemplate/DocumentationTemplateDetails";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import { searchBarSize } from "../../../../../components/ds/sizes";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import CenteredPageLayout from "../../../../../components/ds/CenteredPageLayout";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/documentation-template"
)({
  component: DocumentationTemplateSettingsPage
});

function DocumentationTemplateSettingsPage() {
  const companyId = useCompanyId();
  const [selectedTemplate, setSelectedTemplate] = useState<FolderTemplateDTO>();
  const [searchQuery, setSearchQuery] = useState("");
  const { isOpen, onOpen, onClose } = useDialog();
  const { t } = useTranslation();

  const templateQuery = useGetDocumentationTemplatesByCompanyId({
    variables: { companyId }
  });
  const templates = (templateQuery.data ?? []).filter((t) =>
    t.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  async function handleRefetch() {
    const res = await templateQuery.refetch();
    const template = res.data?.find((t) => t.id === selectedTemplate?.id);
    if (template) {
      setSelectedTemplate(template);
    }
  }

  return (
    <PageLayout
      previewOffset={"regularWithTabBar"}
      preview={
        selectedTemplate && (
          <DocumentationTemplateDetails
            template={selectedTemplate}
            companyId={companyId}
            onUpdate={handleRefetch}
            onClose={() => setSelectedTemplate(undefined)}
          />
        )
      }
    >
      <DocumentationTemplateDialog
        open={isOpen}
        onClose={onClose}
        currentCompanyId={companyId}
        onAddedTemplate={() => templateQuery.refetch()}
      />
      <CenteredPageLayout>
        <CardNew
          inset
          title={t("documentationTemplates")}
          trailing={
            <ButtonNew icon={"add"} size={"1"} onClick={onOpen}>
              {t("add")}
            </ButtonNew>
          }
          headerContent={
            <TextFieldNew
              icon={"search"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("searchForTemplate")}
              size={"2"}
              className={searchBarSize}
            />
          }
        >
          <Table variant={"ghost"}>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>{t("title")}</TableHeaderCell>
                <TableHeaderCell>{t("description")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {templates.length === 0 && (
                <TableRow>
                  <TableCell>{t("noTemplatesFound")}</TableCell>
                </TableRow>
              )}
              {templates.map((template) => (
                <TableRow key={template.id}>
                  <TableCell>
                    <ButtonNew
                      variant={"ghost"}
                      highContrast
                      className={"group-hover/tr:underline"}
                      onClick={() => setSelectedTemplate(template)}
                    >
                      {template.title}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>{template.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardNew>
      </CenteredPageLayout>
    </PageLayout>
  );
}
