import { createQuery } from "react-query-kit";
import {
  OrderBundleRequest,
  OrderProductApi,
  OrderProductRequest
} from "../../.generated/api";
import environment from "../../environment";
import { productsInstance } from "./ProductsModuleHttpBase";

const orderProductApi = new OrderProductApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export const useGetOrderProducts = createQuery({
  queryKey: ["getOrderProducts"],
  fetcher: async (variables: {
    companyId: string;
    orderId: string;
    page?: number;
  }) => {
    const response = await orderProductApi.getOrderProductsV2(
      variables.companyId,
      variables.orderId,
      undefined,
      undefined,
      variables.page
    );
    return response.data;
  }
});

export async function getOrderProducts(
  companyId: string,
  orderId: string,
  page?: number,
  limit?: number,
  search?: string
) {
  const {
    data: { body }
  } = await orderProductApi.getOrderProducts(
    companyId,
    orderId,
    undefined,
    search,
    page,
    limit
  );

  return body;
}

export async function getOrderProductsV2(
  companyId: string,
  orderId: string,
  page?: number,
  limit?: number,
  search?: string
) {
  const {
    data: { body }
  } = await orderProductApi.getOrderProductsV2(
    companyId,
    orderId,
    undefined,
    search,
    page,
    limit
  );

  return body;
}

export async function addOrderProducts(
  companyId: string,
  orderId: string,
  reqBody: OrderProductRequest[]
) {
  await orderProductApi.addOrderProducts(companyId, orderId, reqBody);
}

export async function updateOrderProduct(
  companyId: string,
  orderProductId: string,
  reqBody: OrderProductRequest
) {
  await orderProductApi.updateOrderProduct(companyId, orderProductId, reqBody);
}

export async function deleteOrderProduct(
  companyId: string,
  orderProductId: string
) {
  await orderProductApi.deleteOrderProduct(companyId, orderProductId);
}

export async function addOrderProductsFromBundle(
  companyId: string,
  orderId: string,
  request: OrderBundleRequest[]
) {
  await orderProductApi.addOrderProductsFromBundle(companyId, orderId, request);
}
