import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { UpsertProductPage } from "../../../../../pages/products/UpsertProductPage";
import { useGetProductById } from "../../../../../service/api/ProductApi";

export const Route = createFileRoute(
  "/_protected/dashboard/products/$productId/update"
)({
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { productId } }) => {
    return queryClient.fetchQuery({
      ...useGetProductById.getOptions({ companyId, productId })
    });
  },
  component: UpdateProductPage
});

function UpdateProductPage() {
  const product = useLoaderData({
    from: "/_protected/dashboard/products/$productId/update"
  });

  return <UpsertProductPage product={product} key={product.id} />;
}
