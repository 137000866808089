import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import UpsertOrderPage from "../../-components/UpsertOrderPage";
import { useGetOrderTemplateById } from "../../../../../../service/api/RecurringOrderApi";
import { useGetCustomerByResourceId } from "../../../../../../service/api/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/template/$templateId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { templateId }
  }) => {
    const template = await queryClient.fetchQuery(
      useGetOrderTemplateById.getOptions({ companyId, templateId })
    );

    const customer = await queryClient.fetchQuery(
      useGetCustomerByResourceId.getOptions({
        companyId,
        resourceId: templateId
      })
    );

    return {
      template,
      customer
    };
  },
  component: UpdateOrderTemplatePage
});

function UpdateOrderTemplatePage() {
  const { template, customer } = useLoaderData({
    from: "/_protected/dashboard/orders/template/$templateId/update"
  });

  return <UpsertOrderPage template={template} existingCustomer={customer} />;
}
