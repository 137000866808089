/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserActivity
 */
export interface UserActivity {
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'resourceId': string;
    /**
     * 
     * @type {number}
     * @memberof UserActivity
     */
    'activityNumber': number;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'type': UserActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'valueType': UserActivityValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'activityType': UserActivityActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'status': UserActivityStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserActivity
     */
    'isProjectDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserActivity
     */
    'externalReference'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserActivity
     */
    'isFavorite': boolean;
}

export const UserActivityTypeEnum = {
    Company: 'Company',
    Project: 'Project',
    Task: 'Task',
    Order: 'Order'
} as const;

export type UserActivityTypeEnum = typeof UserActivityTypeEnum[keyof typeof UserActivityTypeEnum];
export const UserActivityValueTypeEnum = {
    Kilometers: 'Kilometers',
    Hours: 'Hours'
} as const;

export type UserActivityValueTypeEnum = typeof UserActivityValueTypeEnum[keyof typeof UserActivityValueTypeEnum];
export const UserActivityActivityTypeEnum = {
    Billable: 'Billable',
    NonBillable: 'NonBillable',
    Absence: 'Absence',
    Other: 'Other'
} as const;

export type UserActivityActivityTypeEnum = typeof UserActivityActivityTypeEnum[keyof typeof UserActivityActivityTypeEnum];
export const UserActivityStatusEnum = {
    Active: 'Active',
    Deleted: 'Deleted'
} as const;

export type UserActivityStatusEnum = typeof UserActivityStatusEnum[keyof typeof UserActivityStatusEnum];


