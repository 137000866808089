import { createFileRoute, Outlet } from "@tanstack/react-router";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import { useTranslation } from "react-i18next";
import TabNav from "../../../../components/TabNav";
import { TabLink } from "../../../../components/TabLink";

export const Route = createFileRoute("/_protected/dashboard/economy/_layout")({
  component: EconomyLayoutPage
});

function EconomyLayoutPage() {
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("economy")} />
      <TabNav>
        <TabLink replace to={"/dashboard/economy/invoice"}>
          {t("invoicing")}
        </TabLink>
        <TabLink replace to={"/dashboard/economy/time-list"}>
          {t("timeList")}
        </TabLink>
        <TabLink replace to={"/dashboard/economy/time-report"}>
          {t("timeReport")}
        </TabLink>
        <TabLink replace to={"/dashboard/economy/activity"}>
          {t("timekeepingActivities")}
        </TabLink>
        <TabLink replace to={"/dashboard/economy/price-model"}>
          {t("priceModels")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
