import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import ChecklistPage from "../../../../../../pages/projectDetails/checklist/ChecklistPage";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/checklist"
)({
  component: ProjectChecklistPage
});

function ProjectChecklistPage() {
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  return <ChecklistPage resourceId={project.id} resourceType={"project"} />;
}
