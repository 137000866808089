import {
  createFileRoute,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import React from "react";
import { UpsertFileOfferPage } from "../-components/UpsertFileOfferPage";
import { useGetOfferById } from "../../../../../../../service/api/OfferApi";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { offerId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetOfferById.getOptions({ companyId, offerId })
    });
  },
  component: UpdateFileOffer
});

function UpdateFileOffer() {
  const { salesOpportunityId } = useParams({
    from: "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
  });
  const offer = useLoaderData({
    from: "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
  });
  return (
    <UpsertFileOfferPage
      salesOpportunityId={salesOpportunityId}
      offer={offer}
    />
  );
}
