import React, { ReactNode } from "react";
import { PreviewOffset, previewOffsets } from "./ds/sizes";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { ScrollArea } from "@radix-ui/themes";

type PreviewLayoutProps = {
  children: ReactNode;
  preview?: ReactNode;
  previewOffset?: PreviewOffset;
  withoutPadding?: boolean;
};

export default function PageLayout({
  children,
  preview,
  previewOffset = "regular",
  withoutPadding = false
}: PreviewLayoutProps) {
  return (
    <div className={"flex relative"}>
      <motion.div
        initial={false}
        className={classNames("grow", { "p-5": !withoutPadding })}
        transition={{
          ease: "easeInOut"
        }}
        animate={{
          marginRight: preview ? 378 : 0
        }}
      >
        {children}
      </motion.div>

      <AnimatePresence initial={false}>
        {preview && (
          <motion.div
            transition={{
              ease: "easeInOut"
            }}
            initial={{
              x: 378
            }}
            animate={{
              x: 0
            }}
            exit={{
              x: 378
            }}
            style={{ top: previewOffsets[previewOffset] }}
            className={
              "fixed bottom-0 bg-radix-panel-solid right-0 border-l border-l-radix-gray-6"
            }
          >
            <ScrollArea key={"preview"}>
              <div className={"w-[378px] p-5"}>{preview}</div>
            </ScrollArea>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
