import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import CardNew from "../../../components/ds/CardNew";
import { useTranslation } from "react-i18next";
import { Text } from "@radix-ui/themes";
import { FormTextField } from "../../../components/ds/TextFieldNew";
import { z } from "zod";
import { useAppStore } from "../../../state/app_store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import ButtonNew from "../../../components/ds/ButtonNew";
import { updateCompanyOnFreeTrialOrder } from "../../../service/api/CompanyApi";
import { useCompanyId } from "../../../hooks/useCompanyId";
import { useToast } from "../../../hooks/useToast";
import { refetchCompany } from "../../../hooks/useCompanyResolver";
import { useQueryClient } from "@tanstack/react-query";

export const Route = createFileRoute("/_protected/trial/subscribe")({
  component: FreeTrialSubscribePage
});

const subscribeSchema = z.object({
  contactPerson: z.string({ required_error: t("fieldRequired") }),
  phone: z.string({ required_error: t("fieldRequired") }),
  licenses: z.coerce
    .number({ message: t("mustBeNumberBetween1And100") })
    .min(1, { message: t("mustBeNumberBetween1And100") })
    .max(100, { message: t("mustBeNumberBetween1And100") }),
  email: z
    .string({ required_error: t("fieldRequired") })
    .email({ message: t("invalidEmail") })
});

type SubscribeFormData = z.infer<typeof subscribeSchema>;

function FreeTrialSubscribePage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const { showErrorToast } = useToast();
  const company = useAppStore((s) => s.selectedCompany);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<SubscribeFormData>({
    resolver: zodResolver(subscribeSchema),
    defaultValues: {
      contactPerson: company?.contactPerson ?? "",
      phone: company?.phone ?? "",
      email: company?.email,
      licenses: 5
    }
  });

  async function onSubmit(data: SubscribeFormData) {
    try {
      await updateCompanyOnFreeTrialOrder(companyId, {
        contactPerson: data.contactPerson,
        contactEmail: data.email,
        contactPhone: data.phone,
        licenceCount: data.licenses
      });

      await refetchCompany(queryClient);
      await navigate({
        to: "/trial/confirmation"
      });
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardNew
        title={t("reviewYourInformation")}
        button={
          <>
            <ButtonNew variant={"soft"} asChild>
              <Link to={"/trial/expired"}>{t("back")}</Link>
            </ButtonNew>
            <ButtonNew type={"submit"} loading={isSubmitting}>
              {t("confirmOrder")}
            </ButtonNew>
          </>
        }
      >
        <Text as={"p"} className={"mb-4"}>
          {t("reviewYourInformationInfoText")}
        </Text>
        <FormTextField
          disabled
          label={t("orgNumber")}
          value={company?.orgNumber}
        />
        <FormTextField
          disabled
          label={t("companyName")}
          value={company?.name}
        />
        <FormTextField
          label={t("contactPerson")}
          errorMessage={errors.contactPerson?.message}
          {...register("contactPerson")}
        />
        <FormTextField
          label={t("email")}
          errorMessage={errors.email?.message}
          {...register("email")}
        />
        <FormTextField
          label={t("phone")}
          errorMessage={errors.phone?.message}
          {...register("phone")}
        />
        <FormTextField
          label={t("numberOfLicenses")}
          errorMessage={errors.licenses?.message}
          type={"number"}
          placeholder={"1"}
          {...register("licenses")}
        />
      </CardNew>
    </form>
  );
}
