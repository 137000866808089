import {
  createFileRoute,
  Link,
  useNavigate,
  useParams,
  useRouter,
  useSearch
} from "@tanstack/react-router";
import { useToast } from "../../../../../hooks/useToast";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import { facilityDetailsTabs } from "../../../../../router/Routes";
import {
  archiveFacility,
  useGetFacilityById
} from "../../../../../service/api/FacilityApi";
import useDialog from "../../../../../hooks/useDialog";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import Button from "../../../../../components/ds/Button";
import { TypedTab } from "../../../../../components/ds/Tab";
import { ColumnLayout } from "../../../../../components/layouts/ColumnLayout";
import FacilityOverviewTab from "../../../../../pages/employeesPage/facility/FacilityOverviewTab";
import FacilityServiceTab from "../../../../../pages/employeesPage/facility/serviceContract/FacilityServiceTab";
import DocumentationTab from "../../../../../pages/documentation/DocumentationTab";
import FacilityOrdersTab from "../../../../../pages/employeesPage/facility/FacilityOrdersTab";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import React from "react";
import { z } from "zod";

const facilityDetailsSchema = z.object({
  tab: facilityDetailsTabs.catch("overview").optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/facility/$facilityId/details"
)({
  validateSearch: facilityDetailsSchema,
  component: FacilityDetailsPage
});

function FacilityDetailsPage() {
  const navigate = useNavigate();
  const router = useRouter();
  const { showSuccessToast } = useToast();
  const companyId = useCompanyId();
  const { t } = useTranslation();

  const { facilityId } = useParams({
    from: "/_protected/dashboard/facility/$facilityId/details"
  });
  const { tab } = useSearch({
    from: "/_protected/dashboard/facility/$facilityId/details"
  });

  const facilityQuery = useGetFacilityById({
    variables: { facilityId, companyId }
  });

  const {
    isOpen: archiveDialogOpen,
    onOpen: onArchiveDialogOpen,
    onClose: onArchiveDialogClose
  } = useDialog();

  return (
    <NewDataFetcher
      query={facilityQuery}
      onData={(facility) => (
        <div className="flex flex-col gap-4">
          <div className={"flex flex-col h-full items-start "}>
            <div
              className={
                "flex flex-col w-full items-start sm:flex-row my-3 justify-between gap-2"
              }
            >
              <div className={"flex justify-between items-baseline gap-4 "}>
                {/*TODO: Pagetitl?*/}
                <IconButton
                  onClick={() => router.history.back()}
                  icon={"arrow_back_ios_new"}
                  variant={"ghost"}
                  size={"3"}
                />
                <div className=" flex flex-col sm:flex-row gap-2">
                  <h1 className={"grow font-bold"}>{facility.name}</h1>
                  <p
                    className={
                      "text-md text-system-neutral-90 self-start sm:self-end"
                    }
                  >
                    #{facility.facilityNumber}
                  </p>
                </div>
              </div>
              <div className={"flex gap-4"}>
                {tab === "overview" ? (
                  <Button
                    isSmall={false}
                    icon="archive"
                    theme="red"
                    onClick={onArchiveDialogOpen}
                  >
                    {t("Arkiver anlegg")}
                  </Button>
                ) : tab === "serviceContracts" ? (
                  <Link
                    to={"/dashboard/service-contract/new"}
                    search={{
                      facilityId: facility.facilityId
                    }}
                  >
                    <Button isSmall={false} icon="add" theme="green">
                      {t("Opprett servicekontrakt")}
                    </Button>
                  </Link>
                ) : /*: selectedTab === 3 ? (
                  <Link
                    from={facilityDetailsRoute.to}
                    to={createOrderRoute.to}
                    params={{}}
                    search={{ facilityId: facility.facilityId }}
                  >
                    <Button isSmall={false} icon="add" theme="green" isLeftIcon>
                      Opprett ordre i anlegget
                    </Button>
                  </Link>
                )*/
                null}
              </div>
            </div>
            <TypedTab
              tabs={facilityDetailsTabs.options}
              getLabel={(tab) => t(tab)}
              selected={tab}
              onChange={(tab) =>
                navigate({
                  to: "/dashboard/facility/$facilityId/details",
                  params: {
                    facilityId
                  },
                  search: {
                    tab: tab
                  }
                })
              }
            >
              <ColumnLayout columns={1}>
                <FacilityOverviewTab facility={facility} />
              </ColumnLayout>
              <ColumnLayout columns={1}>
                <FacilityServiceTab facility={facility} />
              </ColumnLayout>
              <ColumnLayout columns={1}>
                <DocumentationTab
                  resourceId={facility.facilityId}
                  resourceName={facility.name}
                />
              </ColumnLayout>
              <ColumnLayout columns={1}>
                <FacilityOrdersTab facilityId={facility.facilityId} />
              </ColumnLayout>
            </TypedTab>
            <ConfirmationDialog
              title={t("Arkiver anlegg")}
              message={t(
                "Er du sikker på at du vil arkivere annlegget og alle tilhørende servicekontrakter?\n\n Eventuelle ordre som er lagt til på anlegget må slettes manuelt fra ordresiden."
              )}
              confirmText={t("Arkiver")}
              onCancel={onArchiveDialogClose}
              open={archiveDialogOpen}
              onConfirm={() => {
                archiveFacility(companyId, facility.facilityId).then(() => {
                  onArchiveDialogClose();
                  navigate({
                    to: "/dashboard/employees",
                    search: {
                      tab: "equipment"
                    }
                  });
                  showSuccessToast(t("Anlegg arkivert"));
                });
                //archiveAllServiceContracts();
              }}
            />
          </div>
        </div>
      )}
    />
  );
}
