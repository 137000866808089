import React, { ReactNode } from "react";
import { Dialog as RadixDialog } from "@radix-ui/themes";
import classNames from "classnames";
import IconButton from "./buttons/IconButton";
import ButtonNew from "./ButtonNew";
import { useTranslation } from "react-i18next";

type DialogProps = {
  open: boolean;
  onClose: VoidFunction;
  title: string;
  maxWidth?: "sm" | "md" | "lg" | "xl";
  description?: string;
  buttons?: ReactNode;
  children?: ReactNode;
  onSubmit?: VoidFunction;
  withCloseButton?: boolean;
};

export function DialogClose(props: RadixDialog.CloseProps) {
  return <RadixDialog.Close {...props} />;
}

export default function Dialog({
  open,
  onClose,
  title,
  children,
  description,
  buttons,
  onSubmit,
  withCloseButton = false,
  maxWidth = "sm"
}: DialogProps) {
  const { t } = useTranslation();

  return (
    <RadixDialog.Root open={open} onOpenChange={onClose}>
      <RadixDialog.Content
        className={classNames({
          "w-[400px] max-w-[400px]": maxWidth === "sm",
          "w-[500px] max-w-[500px]": maxWidth === "md",
          "w-[640px] max-w-[640px]": maxWidth === "lg",
          "w-[960px] max-w-[960px]": maxWidth === "xl"
        })}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit?.();
          }}
        >
          <div className={"flex justify-between"}>
            <RadixDialog.Title>{title}</RadixDialog.Title>

            <RadixDialog.Close>
              <IconButton icon={"close"} variant={"ghost"} size={"2"} />
            </RadixDialog.Close>
          </div>
          {!description && <RadixDialog.Description />}
          {description && (
            <RadixDialog.Description className={"mb-4"} size={"2"}>
              {description}
            </RadixDialog.Description>
          )}
          {children}
          {(buttons || withCloseButton) && (
            <div className={"flex justify-end gap-2 mt-4"}>
              {withCloseButton && (
                <DialogClose>
                  <ButtonNew variant={"soft"}>{t("close")}</ButtonNew>
                </DialogClose>
              )}
              {buttons}
            </div>
          )}
        </form>
      </RadixDialog.Content>
    </RadixDialog.Root>
  );
}
