import {
  createFileRoute,
  useLoaderData,
  useNavigate,
  useParams
} from "@tanstack/react-router";
import { UpsertProjectPhase } from "../../../../../../pages/setting/project/phases/UpsertProjectPhase";
import React from "react";
import { useGetProjectPhasesByProjectId } from "../../../../../../service/api/PhasesApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/phases/"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient }, params: { projectId } }) => {
    return queryClient.fetchQuery({
      ...useGetProjectPhasesByProjectId.getOptions({ projectId })
    });
  },
  component: EditProjectPhasePage
});

function EditProjectPhasePage() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/phases/"
  });
  const phases = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/phases/"
  });
  const navigate = useNavigate();

  async function afterSubmit() {
    await navigate({
      to: "/dashboard/project/$projectId/overview",
      params: {
        projectId: projectId
      }
    });
  }

  return (
    <UpsertProjectPhase
      existingPhases={phases}
      afterSubmit={afterSubmit}
      projectId={projectId}
    />
  );
}
