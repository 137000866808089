import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useToast } from "../../hooks/useToast";
import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { createCompany } from "../../service/api/CompanyApi";
import { t } from "i18next";
import { useGetUserProfile } from "../../service/api/UserApi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginLayout from "../_unauthenticated/-components/LoginLayout";
import { FormTextField } from "../../components/ds/TextFieldNew";
import ButtonNew from "../../components/ds/ButtonNew";
import { validateOrgNumber } from "../../utils/OrgNumberValidator";
import { ApiError } from "../../service/ErrorHandler";

const createCompanySchema = z.object({
  companyName: z.string().min(1),
  contactPerson: z.string().min(1),
  contactEmail: z.string().email(),
  contactPhone: z.string(),
  orgNumber: z.string().refine((val) => validateOrgNumber(val), {
    message: t("invalidOrganizationNumber")
  })
});

type CreateCompanyFormInputs = z.infer<typeof createCompanySchema>;

export const Route = createFileRoute("/_protected/create-company")({
  component: CreateCompanyPage
});

function CreateCompanyPage() {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const userQuery = useGetUserProfile();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, touchedFields }
  } = useForm<CreateCompanyFormInputs>({
    resolver: zodResolver(createCompanySchema)
  });

  useEffect(() => {
    if (touchedFields.contactEmail || touchedFields.contactPerson) return;
    if (userQuery.data) {
      setValue("contactEmail", userQuery.data.email ?? "");
      setValue("contactPerson", userQuery.data.name ?? "");
      setValue("contactPhone", userQuery.data.phoneNumber ?? "");
    }
  }, [userQuery.data]);

  async function onSubmit(data: CreateCompanyFormInputs) {
    try {
      await createCompany(data);
      await userQuery.refetch();
      await navigate({
        to: "/welcome"
      });
    } catch (e) {
      const error = e as ApiError;
      showErrorToast(error.message);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginLayout
        title={"Opprett bedrift"}
        subtitle={
          "Fyll inn informasjon om bedriften din for å koble den til Skyworker. Dette er et uforpliktende prøveabonnement."
        }
      >
        <FormTextField
          type={"number"}
          label={t("organizationNumber")}
          placeholder={"000000000"}
          errorMessage={errors.orgNumber?.message}
          {...register("orgNumber")}
        />
        <FormTextField
          label={t("companyName")}
          placeholder={"Bedrift AS"}
          errorMessage={errors.companyName?.message}
          {...register("companyName")}
        />
        <FormTextField
          label={t("contactPerson")}
          placeholder={"Ola Nordmann"}
          errorMessage={errors.contactPerson?.message}
          {...register("contactPerson")}
        />
        <FormTextField
          label={t("contactEmail")}
          placeholder={"eksempel@bedrift.no"}
          errorMessage={errors.contactEmail?.message}
          {...register("contactEmail")}
        />
        <FormTextField
          label={t("contactPhone")}
          placeholder={"eksempel@bedrift.no"}
          errorMessage={errors.contactPhone?.message}
          {...register("contactPhone")}
        />
        <ButtonNew size={"3"} type={"submit"} loading={isSubmitting}>
          {t("completeRegistration")}
        </ButtonNew>
      </LoginLayout>
    </form>
  );
}
