/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Facility } from '../model';
// @ts-ignore
import { FacilityCategory } from '../model';
// @ts-ignore
import { FacilityCategoryPostRequest } from '../model';
// @ts-ignore
import { FacilityCategoryPutRequest } from '../model';
// @ts-ignore
import { FacilityPostRequest } from '../model';
// @ts-ignore
import { FacilityPutRequest } from '../model';
// @ts-ignore
import { PaginatedResponseFacility } from '../model';
/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveFacility: async (facilityId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('archiveFacility', 'facilityId', facilityId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveFacility', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/facility/{facilityId}/archive`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create facilityCategory on company
         * @param {FacilityCategoryPostRequest} facilityCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityCategory: async (facilityCategoryPostRequest: FacilityCategoryPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityCategoryPostRequest' is not null or undefined
            assertParamExists('createFacilityCategory', 'facilityCategoryPostRequest', facilityCategoryPostRequest)
            const localVarPath = `/v1/facility/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityCategoryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete facilityCategory on company
         * @param {string} facilityCategoryId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacilityCategory: async (facilityCategoryId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityCategoryId' is not null or undefined
            assertParamExists('deleteFacilityCategory', 'facilityCategoryId', facilityCategoryId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteFacilityCategory', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/facility/category/{facilityCategoryId}`
                .replace(`{${"facilityCategoryId"}}`, encodeURIComponent(String(facilityCategoryId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facilities by ids, paginated
         * @param {string} companyId 
         * @param {Array<string>} facilityIds 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByIds: async (companyId: string, facilityIds: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllByIds', 'companyId', companyId)
            // verify required parameter 'facilityIds' is not null or undefined
            assertParamExists('getAllByIds', 'facilityIds', facilityIds)
            const localVarPath = `/v1/facility/company/{companyId}/ids`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facilityIds) {
                localVarQueryParameter['facilityIds'] = facilityIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all facilities on company, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyFacilities: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyFacilities', 'companyId', companyId)
            const localVarPath = `/v1/facility/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all facility categories on company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityCategoriesOnCompanyId: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getFacilityCategoriesOnCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/facility/{companyId}/categories`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facility on ID
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityOnId: async (companyId: string, facilityId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getFacilityOnId', 'companyId', companyId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getFacilityOnId', 'facilityId', facilityId)
            const localVarPath = `/v1/company/{companyId}/facility/{facilityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new facility
         * @param {FacilityPostRequest} facilityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFacility: async (facilityPostRequest: FacilityPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityPostRequest' is not null or undefined
            assertParamExists('postFacility', 'facilityPostRequest', facilityPostRequest)
            const localVarPath = `/v1/facility/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFacility: async (facilityId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('restoreFacility', 'facilityId', facilityId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('restoreFacility', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/facility/{facilityId}/restore`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update facility
         * @param {FacilityPutRequest} facilityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacility: async (facilityPutRequest: FacilityPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityPutRequest' is not null or undefined
            assertParamExists('updateFacility', 'facilityPutRequest', facilityPutRequest)
            const localVarPath = `/v1/facility/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update facilityCategory on company
         * @param {FacilityCategoryPutRequest} facilityCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilityCategory: async (facilityCategoryPutRequest: FacilityCategoryPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityCategoryPutRequest' is not null or undefined
            assertParamExists('updateFacilityCategory', 'facilityCategoryPutRequest', facilityCategoryPutRequest)
            const localVarPath = `/v1/facility/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityCategoryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveFacility(facilityId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveFacility(facilityId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create facilityCategory on company
         * @param {FacilityCategoryPostRequest} facilityCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFacilityCategory(facilityCategoryPostRequest: FacilityCategoryPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFacilityCategory(facilityCategoryPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete facilityCategory on company
         * @param {string} facilityCategoryId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFacilityCategory(facilityCategoryId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFacilityCategory(facilityCategoryId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get facilities by ids, paginated
         * @param {string} companyId 
         * @param {Array<string>} facilityIds 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByIds(companyId: string, facilityIds: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseFacility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByIds(companyId, facilityIds, page, sortDirection, sortField, query, pageSize, archived, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all facilities on company, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyFacilities(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseFacility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyFacilities(companyId, page, sortDirection, sortField, query, pageSize, archived, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all facility categories on company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilityCategoriesOnCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityCategoriesOnCompanyId(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get facility on ID
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilityOnId(companyId: string, facilityId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityOnId(companyId, facilityId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new facility
         * @param {FacilityPostRequest} facilityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFacility(facilityPostRequest: FacilityPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFacility(facilityPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreFacility(facilityId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreFacility(facilityId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update facility
         * @param {FacilityPutRequest} facilityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFacility(facilityPutRequest: FacilityPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFacility(facilityPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update facilityCategory on company
         * @param {FacilityCategoryPutRequest} facilityCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFacilityCategory(facilityCategoryPutRequest: FacilityCategoryPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFacilityCategory(facilityCategoryPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveFacility(facilityId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.archiveFacility(facilityId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create facilityCategory on company
         * @param {FacilityCategoryPostRequest} facilityCategoryPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityCategory(facilityCategoryPostRequest: FacilityCategoryPostRequest, authorization?: string, options?: any): AxiosPromise<FacilityCategory> {
            return localVarFp.createFacilityCategory(facilityCategoryPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete facilityCategory on company
         * @param {string} facilityCategoryId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacilityCategory(facilityCategoryId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFacilityCategory(facilityCategoryId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facilities by ids, paginated
         * @param {string} companyId 
         * @param {Array<string>} facilityIds 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByIds(companyId: string, facilityIds: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: any): AxiosPromise<PaginatedResponseFacility> {
            return localVarFp.getAllByIds(companyId, facilityIds, page, sortDirection, sortField, query, pageSize, archived, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all facilities on company, paginated
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'FacilityNumber' | 'Name'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyFacilities(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: any): AxiosPromise<PaginatedResponseFacility> {
            return localVarFp.getAllCompanyFacilities(companyId, page, sortDirection, sortField, query, pageSize, archived, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all facility categories on company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityCategoriesOnCompanyId(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<FacilityCategory>> {
            return localVarFp.getFacilityCategoriesOnCompanyId(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facility on ID
         * @param {string} companyId 
         * @param {string} facilityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityOnId(companyId: string, facilityId: string, authorization?: string, options?: any): AxiosPromise<Facility> {
            return localVarFp.getFacilityOnId(companyId, facilityId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new facility
         * @param {FacilityPostRequest} facilityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFacility(facilityPostRequest: FacilityPostRequest, authorization?: string, options?: any): AxiosPromise<Facility> {
            return localVarFp.postFacility(facilityPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore facility on ID
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFacility(facilityId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.restoreFacility(facilityId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update facility
         * @param {FacilityPutRequest} facilityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacility(facilityPutRequest: FacilityPutRequest, authorization?: string, options?: any): AxiosPromise<Facility> {
            return localVarFp.updateFacility(facilityPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update facilityCategory on company
         * @param {FacilityCategoryPutRequest} facilityCategoryPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilityCategory(facilityCategoryPutRequest: FacilityCategoryPutRequest, authorization?: string, options?: any): AxiosPromise<FacilityCategory> {
            return localVarFp.updateFacilityCategory(facilityCategoryPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
    /**
     * 
     * @summary Archive facility on ID
     * @param {string} facilityId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public archiveFacility(facilityId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).archiveFacility(facilityId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create facilityCategory on company
     * @param {FacilityCategoryPostRequest} facilityCategoryPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public createFacilityCategory(facilityCategoryPostRequest: FacilityCategoryPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).createFacilityCategory(facilityCategoryPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete facilityCategory on company
     * @param {string} facilityCategoryId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public deleteFacilityCategory(facilityCategoryId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).deleteFacilityCategory(facilityCategoryId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facilities by ids, paginated
     * @param {string} companyId 
     * @param {Array<string>} facilityIds 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'FacilityNumber' | 'Name'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {boolean} [archived] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getAllByIds(companyId: string, facilityIds: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getAllByIds(companyId, facilityIds, page, sortDirection, sortField, query, pageSize, archived, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all facilities on company, paginated
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'FacilityNumber' | 'Name'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {boolean} [archived] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getAllCompanyFacilities(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'FacilityNumber' | 'Name', query?: string, pageSize?: number, archived?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getAllCompanyFacilities(companyId, page, sortDirection, sortField, query, pageSize, archived, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all facility categories on company
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacilityCategoriesOnCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacilityCategoriesOnCompanyId(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facility on ID
     * @param {string} companyId 
     * @param {string} facilityId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacilityOnId(companyId: string, facilityId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacilityOnId(companyId, facilityId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new facility
     * @param {FacilityPostRequest} facilityPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public postFacility(facilityPostRequest: FacilityPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).postFacility(facilityPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore facility on ID
     * @param {string} facilityId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public restoreFacility(facilityId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).restoreFacility(facilityId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update facility
     * @param {FacilityPutRequest} facilityPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public updateFacility(facilityPutRequest: FacilityPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).updateFacility(facilityPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update facilityCategory on company
     * @param {FacilityCategoryPutRequest} facilityCategoryPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public updateFacilityCategory(facilityCategoryPutRequest: FacilityCategoryPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).updateFacilityCategory(facilityCategoryPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
