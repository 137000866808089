import { useEffect, useState } from "react";
import { useOfferStore } from "../-components/store";
import { OfferDetailsStatusEnum } from "../../../../.generated/api";

import SplashPage from "../-components/SplashPage";
import DetailsPage from "../-components/DetailsPage";
import ReceiptPage from "../-components/ReceiptPage";

import {
  createFileRoute,
  useNavigate,
  useParams
} from "@tanstack/react-router";

export const Route = createFileRoute("/_unauthenticated/offer/$pathUri/")({
  component: OfferPage
});

function OfferPage() {
  const { pathUri } = useParams({
    from: "/_unauthenticated/offer/$pathUri/"
  });

  const load = useOfferStore((state) => state.load);
  const offer = useOfferStore((state) => state.offer);
  const error = useOfferStore((state) => state.error);
  const isExpired = useOfferStore((state) => state.isExpired);
  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    load(pathUri);
  }, [pathUri, load]);

  useEffect(() => {
    if (offer?.status === OfferDetailsStatusEnum.Accepted) {
      setIsShowingDetails(false);
    }
  }, [offer, setIsShowingDetails]);

  if (error) {
    return <p>{error.message}</p>;
  }

  if (offer) {
    if (isExpired) {
      return <div>Fristen for tilbudet har utgått</div>;
    } else if (isShowingDetails) {
      return <DetailsPage />;
    } else {
      if (offer.status === OfferDetailsStatusEnum.Sent) {
        return (
          <SplashPage
            onShowDetails={() => {
              if (offer?.type === "Builder") {
                navigate({
                  to: "/offer/$pathUri/details",
                  params: {
                    pathUri: offer?.pathUri ?? ""
                  }
                });
              } else {
                window.open(offer.file?.downloadUrl);
              }
            }}
          />
        );
      } else if (offer.status === OfferDetailsStatusEnum.Accepted) {
        return <ReceiptPage />;
      }
    }

    return <div>DATA: {offer.status}</div>;
  } else {
    return <div>Loading...</div>;
  }
}
