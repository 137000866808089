import React from "react";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { useAppStore } from "../../state/app_store";
import { signOut } from "../../service/firebase/FirebaseService";
import { useQueryClient } from "@tanstack/react-query";
import { formatNickname } from "../../utils";
import { useNavigate } from "@tanstack/react-router";

export default function UserMenu() {
  const user = useAppStore((s) => s.user);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant={"soft"} className={"w-[40px] h-[40px]"}>
          {formatNickname(user.name ?? "-")}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item onClick={() => signOut(queryClient, navigate)}>
          Logg ut
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
