import { createFileRoute, Link } from "@tanstack/react-router";
import CardNew from "../../../components/ds/CardNew";
import { Text } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import ButtonNew from "../../../components/ds/ButtonNew";

export const Route = createFileRoute("/_protected/trial/confirmation")({
  component: FreeTrialConfirmationPage
});

function FreeTrialConfirmationPage() {
  const { t } = useTranslation();

  return (
    <CardNew
      title={t("orderRecieved")}
      button={
        <ButtonNew asChild>
          <Link to={"/dashboard/overview"}>{t("goToSkyworker")}</Link>
        </ButtonNew>
      }
    >
      <Text as={"p"} className={"mb-4"}>
        {t("freeTrialConfirmation1")}
      </Text>
      <Text as={"p"}>{t("freeTrialConfirmation2")}</Text>
    </CardNew>
  );
}
