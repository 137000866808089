import { createFileRoute, useParams } from "@tanstack/react-router";
import ProductConsumptionTab from "../../../../../../pages/projectDetails/projectProducts/ProductConsumptionTab";
import React from "react";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/product"
)({
  component: ProjectProductPage
});

function ProjectProductPage() {
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  return (
    <div className={"m-5"}>
      <ProductConsumptionTab resourceId={projectId} resourceType="project" />
    </div>
  );
}
