import { createFileRoute, useSearch } from "@tanstack/react-router";
import UpsertProjectPage from "./-components/UpsertProjectPage";
import { z } from "zod";

const CreateProjectPageSchema = z.object({
  parentProjectId: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/project/new")({
  component: CreateProjectPage,
  validateSearch: CreateProjectPageSchema
});

function CreateProjectPage() {
  const { parentProjectId } = useSearch({
    from: "/_protected/dashboard/project/new"
  });
  return <UpsertProjectPage parentProjectId={parentProjectId} />;
}
