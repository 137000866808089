import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import React from "react";
import { UpsertServiceContract } from "../-components/UpsertServiceContactPage";
import { z } from "zod";
import { useGetServiceContactById } from "../../../../../service/api/ServiceContractApi";

const updateServiceContractSchema = z.object({
  facilityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/service-contract/$serviceContractId/update"
)({
  validateSearch: updateServiceContractSchema,
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { serviceContractId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetServiceContactById.getOptions({ serviceContractId, companyId })
    });
  },
  component: UpdateServiceContract
});

function UpdateServiceContract() {
  const { facilityId } = useSearch({
    from: "/_protected/dashboard/service-contract/$serviceContractId/update"
  });

  const serviceContract = useLoaderData({
    from: "/_protected/dashboard/service-contract/$serviceContractId/update"
  });

  return (
    <UpsertServiceContract
      facilityId={facilityId}
      serviceContract={serviceContract}
    />
  );
}
