import { productsInstance } from "./ProductsModuleHttpBase";
import { BundleRequest, BundleApi } from "../../.generated/api";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const bundleApi = new BundleApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export const useGetProductBundlesByCompanyId = createQuery({
  queryKey: ["getProductBundlesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await bundleApi.getAllCompanyBundles(variables.companyId);
    return response.data.body!;
  }
});

export const useGetProductBundleById = createQuery({
  queryKey: ["getProductBundleById"],
  fetcher: async (variables: { bundleId: string; companyId: string }) => {
    const response = await bundleApi.getBundleById(
      variables.companyId,
      variables.bundleId
    );
    return response.data.body;
  }
});

export async function createBundle(companyId: string, reqBody: BundleRequest) {
  return bundleApi.createBundle(companyId, reqBody);
}

export async function deleteBundle(companyId: string, bundleId: string) {
  await bundleApi.deleteBundle(companyId, bundleId);
}

export async function updateBundle(
  companyId: string,
  bundleId: string,
  reqBody: BundleRequest
) {
  return await bundleApi.updateBundle(companyId, bundleId, reqBody);
}
