import { createFileRoute, useParams } from "@tanstack/react-router";
import { useToast } from "../../../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import {
  updateProjectEconomy,
  useGetProjectEconomy
} from "../../../../../../../service/api/ProjectEconomyApi";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import CardNew from "../../../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import { ControlledSelect } from "../../../../../../../components/ds/NewSelect";
import { FormTextField } from "../../../../../../../components/ds/TextFieldNew";
import { z } from "zod";

const economyBudgetFormSchema = z.object({
  isHourly: z.union([z.literal("fixed"), z.literal("hourly")]),
  invoiceInfo: z.string(),
  estimatedContractValue: z.coerce.number().optional(),
  estimatedSubcontractorCost: z.coerce.number().optional(),
  estimatedMaterialCost: z.coerce.number().optional(),
  estimatedHours: z.coerce.number().optional(),
  estimatedHoursAdditionalWork: z.coerce.number().optional(),
  subcontractorSurcharge: z.coerce.number().optional(),
  materialSurcharge: z.coerce.number().optional(),
  estimatedHourCost: z.coerce.number().optional(),
  sendHourNotification: z.boolean()
});

type EconomyBudgetFormData = z.infer<typeof economyBudgetFormSchema>;

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget"
)({
  component: ProjectEconomyBudgetPage
});

function ProjectEconomyBudgetPage() {
  const { showSuccessToast, showErrorToast } = useToast();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const { t } = useTranslation();

  const projectEconomyQuery = useGetProjectEconomy({
    variables: { projectId }
  });

  const economy = projectEconomyQuery.data;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<EconomyBudgetFormData>({
    resolver: zodResolver(economyBudgetFormSchema),
    defaultValues: {
      sendHourNotification: economy?.sendHourNotification,
      isHourly: economy?.isHourly ? "hourly" : "fixed",
      invoiceInfo: economy?.invoiceInfo,
      estimatedSubcontractorCost:
        economy?.estimatedSubcontractorCost || undefined,
      estimatedHoursAdditionalWork:
        economy?.estimatedHoursAdditionalWork || undefined,
      estimatedMaterialCost: economy?.estimatedMaterialCost || undefined,
      estimatedContractValue: economy?.estimatedContractValue || undefined,
      estimatedHours: economy?.estimatedHours || undefined,
      materialSurcharge: economy?.materialSurcharge || undefined,
      subcontractorSurcharge: economy?.subcontractorSurcharge || undefined,
      estimatedHourCost: economy?.estimatedHourCost || undefined
    }
  });

  useEffect(() => {
    if (economy) {
      reset({
        estimatedContractValue: economy.estimatedContractValue || undefined,
        invoiceInfo: economy.invoiceInfo,
        sendHourNotification: economy.sendHourNotification,
        isHourly: economy.isHourly ? "hourly" : "fixed",
        estimatedSubcontractorCost:
          economy.estimatedSubcontractorCost || undefined,
        estimatedMaterialCost: economy.estimatedMaterialCost || undefined,
        estimatedHours: economy.estimatedHours || undefined,
        estimatedHoursAdditionalWork:
          economy.estimatedHoursAdditionalWork || undefined,
        materialSurcharge: economy.materialSurcharge || undefined,
        subcontractorSurcharge: economy.subcontractorSurcharge || undefined,
        estimatedHourCost: economy?.estimatedHourCost || undefined
      });
    }
  }, [economy]);

  async function onSubmit(data: EconomyBudgetFormData) {
    try {
      await updateProjectEconomy(projectId, {
        isHourly: data.isHourly === "hourly",
        estimatedHours: data.estimatedHours,
        estimatedHoursAdditionalWork: data.estimatedHoursAdditionalWork,
        estimatedMaterialCost: data.estimatedMaterialCost,
        estimatedRevenue: data.estimatedContractValue,
        estimatedSubcontractorCost: data.estimatedSubcontractorCost,
        invoiceInfo: data.invoiceInfo,
        sendHourNotification: data.sendHourNotification,
        materialSurcharge: data.materialSurcharge,
        subcontractorSurcharge: data.subcontractorSurcharge,
        estimatedHourCost: data.estimatedHourCost
      });
      showSuccessToast(t("projectEconomyUpdated"));
      projectEconomyQuery.refetch();
    } catch (_) {
      showErrorToast(t("anErrorOccurred"));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardNew
        title={t("budget")}
        subtitle={t("budgetText")}
        button={
          <ButtonNew type={"submit"} loading={isSubmitting}>
            {t("save")}
          </ButtonNew>
        }
      >
        <div className={"grid grid-cols-2 gap-x-4"}>
          <ControlledSelect
            className={"w-full"}
            control={control}
            fieldName={"isHourly"}
            label={t("priceType")}
            options={[
              {
                label: t("fixedPrice"),

                value: "fixed"
              },
              {
                label: t("hourly"),
                value: "hourly"
              }
            ]}
          />
          <FormTextField
            label={t("invoiceInformation")}
            {...register("invoiceInfo")}
          />

          <FormTextField
            label={t("estimatedRevenue")}
            errorMessage={errors.estimatedContractValue?.message}
            {...register("estimatedContractValue")}
          />
          <FormTextField
            label={t("estimatedHoursAdditionalWork")}
            errorMessage={errors.estimatedHoursAdditionalWork?.message}
            {...register("estimatedHoursAdditionalWork")}
          />

          <FormTextField
            label={t("estimatedSubcontractorCost")}
            errorMessage={errors.estimatedSubcontractorCost?.message}
            {...register("estimatedSubcontractorCost")}
          />
          <FormTextField
            label={t("estimatedMaterialCost")}
            errorMessage={errors.estimatedMaterialCost?.message}
            {...register("estimatedMaterialCost")}
          />
          <FormTextField
            label={t("estimatedHours")}
            errorMessage={errors.estimatedHours?.message}
            {...register("estimatedHours")}
          />
          <FormTextField
            label={t("estimatedHourCost")}
            errorMessage={errors.estimatedHourCost?.message}
            {...register("estimatedHourCost")}
          />

          <FormTextField
            label={t("materialSurcharge") + " (%)"}
            errorMessage={errors.materialSurcharge?.message}
            {...register("materialSurcharge")}
          />
          <FormTextField
            label={t("subcontractorSurcharge") + " (%)"}
            errorMessage={errors.subcontractorSurcharge?.message}
            {...register("subcontractorSurcharge")}
          />
        </div>
      </CardNew>
    </form>
  );
}
