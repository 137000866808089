import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import CardNew from "../../../components/ds/CardNew";
import { useTranslation } from "react-i18next";
import { Text } from "@radix-ui/themes";
import ButtonNew from "../../../components/ds/ButtonNew";
import { useAppStore } from "../../../state/app_store";
import {
  getActiveConnections,
  mapCompanyData
} from "../../../hooks/useCompanyResolver";
import { FormSelect } from "../../../components/ds/NewSelect";
import { signOut } from "../../../service/firebase/FirebaseService";
import { useQueryClient } from "@tanstack/react-query";

export const Route = createFileRoute("/_protected/trial/expired")({
  component: FreeTrialExpiredPage
});

function FreeTrialExpiredPage() {
  const { t } = useTranslation();
  const user = useAppStore((s) => s.user);
  const selectedCompany = useAppStore((s) => s.selectedCompany);
  const activeConnections = getActiveConnections(user.companyConnections) ?? [];
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isExpired = selectedCompany?.subscription === "EXPIRED";

  async function handleCompanyChange(companyId: string) {
    const company = activeConnections.find((c) => c.companyId === companyId);
    if (!company) return;

    useAppStore.setState({ selectedCompany: mapCompanyData(company) });
    await navigate({
      to: "/dashboard/overview",
      replace: true
    });
  }

  async function handleSignOut() {
    await signOut(queryClient, navigate);
  }

  return (
    <CardNew
      title={isExpired ? t("trialPeriodExpired") : t("upgradeSubscription")}
      button={
        <>
          <ButtonNew variant={"soft"} onClick={handleSignOut}>
            {t("signOut")}
          </ButtonNew>
          <ButtonNew asChild>
            <Link to={"/trial/subscribe"}>{t("yesIWouldLikeToContinue")}</Link>
          </ButtonNew>
        </>
      }
    >
      <Text as={"p"}>
        {isExpired
          ? t("trialPeriodExpiredInfoText")
          : t("upgradeSubscriptionInfoText")}
      </Text>

      {activeConnections.length > 1 && (
        <div className={"mt-4"}>
          <FormSelect
            size={"2"}
            label={t("changeToAnotherCompany")}
            noBottomPadding
            value={selectedCompany?.id}
            onChange={handleCompanyChange}
            options={activeConnections.map((c) => ({
              label: c.companyName,
              value: c.companyId
            }))}
          />
        </div>
      )}
    </CardNew>
  );
}
