import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import { PageSize, useAppStore } from "../state/app_store";

export type SortDirection = "ASC" | "DESC";

export type SortState<T> = {
  direction: SortDirection;
  column: T;
};

export type RegisterHeaderProps = {
  isSelected: boolean;
  isAscending: boolean;
  onChange: VoidFunction;
};

export type UseSortProps<T> = {
  sortField: T;
  sortDirection: SortDirection;
  registerHeader(t: T): RegisterHeaderProps;
  page: number;
  setPage(page: number): void;
  query: string;
  debouncedQuery: string;
  setQuery(query: string): void;
  pageSize: PageSize;
  setPageSize(pageSize: PageSize): void;
};

export function useSort<T>(
  value: T,
  tableKey: string,
  savedIsValid?: (value: T) => boolean
): UseSortProps<T> {
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query);
  const [pageSize, setPageSize] = useAppStore((s) => [
    s.pageSize,
    s.setPageSize
  ]);

  const getDefaultSortState = () => ({
    direction: "ASC" as SortDirection,
    column: value
  });

  const [sortState, setSortState] = useState<SortState<T>>(() => {
    const storedSortState = localStorage.getItem(tableKey);

    try {
      const parsedSortState = JSON.parse(storedSortState ?? "");
      if (savedIsValid && !savedIsValid(parsedSortState.column)) {
        return getDefaultSortState();
      }

      return {
        direction: parsedSortState.direction,
        column: parsedSortState.column
      };
    } catch (_) {
      return getDefaultSortState();
    }
  });

  useEffect(() => {
    localStorage.setItem(tableKey, JSON.stringify(sortState));
  }, [sortState, tableKey]);

  return {
    page: page,
    setPage: setPage,
    pageSize: pageSize,
    setPageSize: setPageSize,
    query: query,
    debouncedQuery: debouncedQuery,
    setQuery: (query: string) => {
      setQuery(query);
      if (page > 0) setPage(0);
    },
    sortField: sortState.column,
    sortDirection: sortState.direction,
    registerHeader: (field) => ({
      isSelected: sortState.column === field,
      isAscending: sortState.direction === "ASC",
      onChange: () => {
        if (sortState.column === field) {
          setSortState((prev) => ({
            direction: prev.direction === "ASC" ? "DESC" : "ASC",
            column: prev.column
          }));
        } else {
          setSortState({
            column: field,
            direction: "DESC"
          });
        }
      }
    })
  };
}
