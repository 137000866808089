import React, { forwardRef, ReactNode } from "react";
import { TextField as RadixTextField } from "@radix-ui/themes";
import RadixIcon from "./RadixIcon";
import InputWrapperNew, { InputWrapperProps } from "./InputWrapperNew";
import classnames from "classnames";
import { pickInputWrapperProps } from "./utils";

type TextFieldProps = RadixTextField.RootProps & {
  icon?: string;
  trailing?: ReactNode;
};

export type FormTextFieldProps = InputWrapperProps & TextFieldProps;

export const FormTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  ({ ...props }, ref) => {
    return (
      <InputWrapperNew {...pickInputWrapperProps(props)}>
        {(style) => (
          <TextFieldNew
            {...props}
            className={classnames(style, props.className)}
            ref={ref}
          />
        )}
      </InputWrapperNew>
    );
  }
);

export const TextFieldNew = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ icon, trailing, size = "3", ...props }, ref) => {
    return (
      <RadixTextField.Root size={size} {...props} ref={ref}>
        {icon && (
          <RadixTextField.Slot>
            <RadixIcon icon={icon} size={size} />
          </RadixTextField.Slot>
        )}
        {trailing && icon && (
          <RadixTextField.Slot>{trailing}</RadixTextField.Slot>
        )}
        {trailing && !icon && (
          <>
            <RadixTextField.Slot pl={"0"} />
            <RadixTextField.Slot>{trailing}</RadixTextField.Slot>
          </>
        )}
      </RadixTextField.Root>
    );
  }
);
