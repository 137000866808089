import { createFileRoute } from "@tanstack/react-router";
import { UpsertEquipment } from "./-components/UpsertEquipment";

export const Route = createFileRoute("/_protected/dashboard/equipment/new")({
  component: CreateEquipment
});

function CreateEquipment() {
  return <UpsertEquipment />;
}
