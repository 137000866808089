import React, { ReactNode } from "react";
import classNames from "classnames";
type PageSectionProps = {
  size?: "md" | "lg" | "xl";
  children: ReactNode;
};
export default function PageSection({
  size = "md",
  children
}: PageSectionProps) {
  return (
    <div
      className={classNames("mx-auto mb-6", {
        "max-w-[720px]": size === "md",
        "max-w-[1100px]": size === "lg",
        "max-w-[1300px]": size === "xl"
      })}
    >
      {children}
    </div>
  );
}
