import { createFileRoute, Link } from "@tanstack/react-router";
import { DropdownMenu, SegmentedControl } from "@radix-ui/themes";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import CardNew from "../../../../../components/ds/CardNew";
import { useTranslation } from "react-i18next";
import PageLayout from "../../../../../components/PageLayout";
import useDialog from "../../../../../hooks/useDialog";
import { TextFieldNew } from "../../../../../components/ds/TextFieldNew";
import {
  checkboxCellSize,
  searchBarSize
} from "../../../../../components/ds/sizes";
import Select from "../../../../../components/ds/NewSelect";
import React, { useState } from "react";
import { useSort } from "../../../../../hooks/useSort";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import { useSearchCompanyProjects } from "../../../../../service/api/ProjectApiV2";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import ProjectTableRow from "./-components/ProjectTableRow";
import Table from "../../../../../components/ds/RadixTable/Table";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import { restoreProject } from "../../../../../service/api/ProjectApi";
import { useToast } from "../../../../../hooks/useToast";
import CopyProjectDialog from "../../../../../components/dialogs/CopyProjectDialog";
import { useSelectedGeneric } from "../../../../../hooks/useSelected";
import { ProjectV2 } from "../../../../../.generated/api";
import { CheckboxNew } from "../../../../../components/ds/CheckboxNew";
import { exportProjects } from "../../../../../utils/exportUtils";

type ProjectFilter = "all" | "mine";
type SelectedView = "list" | "archive";

export const Route = createFileRoute(
  "/_protected/dashboard/project/_layout/overview"
)({
  component: ProjectOverviewPage
});

function ProjectOverviewPage() {
  const { onClose, onOpen, isOpen } = useDialog();
  const companyId = useCompanyId();
  const [selectedFilter, setSelectedFilter] = useState<ProjectFilter>("all");
  const [selectedView, setSelectedView] = useState<SelectedView>("list");
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selected, handleSelectAll, handleSelect, allSelected, isSelected } =
    useSelectedGeneric<ProjectV2>("id");

  const {
    sortField,
    sortDirection,
    registerHeader,
    query,
    setQuery,
    page,
    setPage,
    debouncedQuery,
    pageSize,
    setPageSize
  } = useSort<"ProjectNumber" | "Title" | "Created" | "PhaseTitle">(
    "Title",
    "ProjectListView"
  );

  const projectQuery = useSearchCompanyProjects({
    placeholderData: keepPreviousData,
    variables: {
      companyId,
      page,
      sortDirection,
      sortField,
      pageSize,
      status: selectedView === "list" ? "active" : "deleted",
      fetchOnlyAssigned: selectedFilter === "mine",
      query: debouncedQuery
    }
  });

  const projects = projectQuery.data?.data ?? [];

  async function handleRestore(projectId: string) {
    try {
      await restoreProject(projectId);
      showSuccessToast(t("Prosjekt gjenopprettet"));
      await projectQuery.refetch();
    } catch (_) {
      showErrorToast("Kunne ikke gjenopprette prosjekt");
    }
  }

  return (
    <PageLayout>
      <CopyProjectDialog
        open={isOpen}
        onClose={onClose}
        onCopied={() => projectQuery.refetch()}
      />
      <CardNew
        inset
        title={t("projects")}
        trailing={
          <>
            {selected.length > 0 && (
              <ButtonNew
                variant={"outline"}
                icon={"download"}
                onClick={() => exportProjects(selected, queryClient)}
              >
                {t("export")}
              </ButtonNew>
            )}
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <ButtonNew trailingIcon={"keyboard_arrow_down"}>
                  {t("createProject")}
                </ButtonNew>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item asChild>
                  <Link to={"/dashboard/project/new"}>{t("newProject")}</Link>
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={onOpen}>
                  {t("copyProject")}
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </>
        }
        headerContent={
          <div className={"flex justify-between w-full"}>
            <div className={"flex gap-2"}>
              <TextFieldNew
                icon={"search"}
                size={"2"}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("searchForTitleOrProjectNumber")}
                className={searchBarSize}
              />
              <Select<ProjectFilter>
                size={"2"}
                value={selectedFilter}
                onChange={setSelectedFilter}
                options={[
                  { label: t("allProjects"), value: "all" },
                  { label: t("myProjects"), value: "mine" }
                ]}
              />
            </div>
            <SegmentedControl.Root value={selectedView}>
              <SegmentedControl.Item
                value={"list"}
                onClick={() => setSelectedView("list")}
              >
                {t("list")}
              </SegmentedControl.Item>
              <SegmentedControl.Item
                value={"archive"}
                onClick={() => setSelectedView("archive")}
              >
                {t("archive")}
              </SegmentedControl.Item>
            </SegmentedControl.Root>
          </div>
        }
        footerContent={
          <PaginationNew
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPage={setPage}
            totalPages={projectQuery.data?.totalPages}
          />
        }
      >
        {selectedView === "list" && (
          <Table variant={"ghost"}>
            <TableHeader>
              <TableRow>
                <TableHeaderCell className={checkboxCellSize}>
                  <CheckboxNew
                    checked={allSelected(projects)}
                    onClick={() => handleSelectAll(projects)}
                  />
                </TableHeaderCell>
                <TableHeaderCell sortFn={registerHeader("ProjectNumber")}>
                  #
                </TableHeaderCell>
                <TableHeaderCell />
                <TableHeaderCell sortFn={registerHeader("Created")}>
                  {t("created")}
                </TableHeaderCell>
                <TableHeaderCell sortFn={registerHeader("Title")}>
                  {t("title")}
                </TableHeaderCell>
                <TableHeaderCell>{t("customer")}</TableHeaderCell>
                <TableHeaderCell>{t("projectManager")}</TableHeaderCell>
                <TableHeaderCell>{t("start")}</TableHeaderCell>
                <TableHeaderCell>{t("end")}</TableHeaderCell>
                <TableHeaderCell sortFn={registerHeader("PhaseTitle")}>
                  {t("phase")}
                </TableHeaderCell>
                <TableHeaderCell>{t("contractValue")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {projects.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>{t("noProjectsFound")}</TableCell>
                </TableRow>
              )}
              {projects.map((project) => (
                <ProjectTableRow
                  key={project.id}
                  project={project}
                  checked={isSelected(project)}
                  onCheck={() => handleSelect(project)}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {selectedView === "archive" && (
          <Table variant={"ghost"}>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>{t("title")}</TableHeaderCell>
                <TableHeaderCell>{t("start")}</TableHeaderCell>
                <TableHeaderCell>{t("end")}</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {projects.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>{t("noProjectsFound")}</TableCell>
                </TableRow>
              )}
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>#{project.prefixedProjectNumber}</TableCell>
                  <TableCell>{project.title}</TableCell>
                  <TableCell>
                    {formatFullNumericDateString(project.from)}
                  </TableCell>
                  <TableCell>
                    {formatFullNumericDateString(project.to)}
                  </TableCell>
                  <TableCell
                    py={"0"}
                    className={"align-middle"}
                    align={"right"}
                  >
                    <IconButton
                      icon={"restore_from_trash"}
                      onClick={() => handleRestore(project.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardNew>
    </PageLayout>
  );
}
