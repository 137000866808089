import { createFileRoute, useParams } from "@tanstack/react-router";
import React from "react";
import { UpsertLocationPage } from "../-components/UpsertLocationPage";

export const Route = createFileRoute(
  "/_protected/dashboard/location/$resourceId/new"
)({
  component: CreateLocationPage
});

function CreateLocationPage() {
  const { resourceId } = useParams({
    from: "/_protected/dashboard/location/$resourceId/new"
  });
  return <UpsertLocationPage resourceId={resourceId} />;
}
