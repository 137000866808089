import {
  createFileRoute,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import React from "react";
import { UpsertOfferPage } from "../../../../../../../pages/sales/salesOppertunity/details/offer/UpsertOfferPage";
import { useGetOfferById } from "../../../../../../../service/api/OfferApi";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { offerId } }) => {
    return queryClient.fetchQuery({
      ...useGetOfferById.getOptions({ offerId, companyId })
    });
  },
  component: UpdateOfferPage
});

function UpdateOfferPage() {
  const { salesOpportunityId } = useParams({
    from: "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
  });
  const offer = useLoaderData({
    from: "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
  });

  return (
    <UpsertOfferPage salesOpportunityId={salesOpportunityId} offer={offer} />
  );
}
