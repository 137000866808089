import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProgressPlanByCompanyId } from "../../../../../service/api/ProgressPlanApi";
import CardV3 from "../../../../../components/ds/CardV3";
import Toggle from "../../../../../components/ds/Toggle";
import Pagination from "../../../../../components/ds/Pagination";
import ProgressPlanItemCard from "../../../../../pages/resourceplanner/ProgressPlanItemCard";
import PageLayout from "../../../../../components/PageLayout";

export const Route = createFileRoute(
  "/_protected/dashboard/project/_layout/progress-plan"
)({
  component: RouteComponent
});

function RouteComponent() {
  const companyId = useCompanyId();
  const [searchType, setSearchType] = useState<"all" | "active">("active");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const progressPlanQuery = useGetProgressPlanByCompanyId({
    variables: {
      companyId: companyId,
      active: searchType === "active",
      page: page
    }
  });

  return (
    <PageLayout>
      <CardV3
        title={t("progressPlan")}
        trailing={
          <Toggle
            isSmall
            options={["active", "all"]}
            getLabel={(option) => {
              if (option === "active") {
                return t("activeProgressPlans");
              } else {
                return t("allProgressPlans");
              }
            }}
            selectedOption={searchType}
            setSelectedOption={(option) => {
              setSearchType(option);
              setPage(0);
            }}
          />
        }
        footerContent={
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={progressPlanQuery.data?.totalPages ?? 0}
          />
        }
      >
        <div className={"flex flex-col gap-4"}>
          {progressPlanQuery.data?.data?.map((plan) => (
            <ProgressPlanItemCard item={plan} key={plan.planId} />
          ))}
        </div>
      </CardV3>
    </PageLayout>
  );
}
