import {
  TextArea as RadixTextArea,
  TextAreaProps as RadixTextAreaProps
} from "@radix-ui/themes";
import classnames from "classnames";
import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef
} from "react";
import InputWrapperNew, { InputWrapperProps } from "./InputWrapperNew";
import { pickInputWrapperProps } from "./utils";

type FormTextAreaProps = InputWrapperProps & TextAreaProps;

type TextAreaProps = RadixTextAreaProps;

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ ...props }, ref) => {
    return (
      <InputWrapperNew {...pickInputWrapperProps(props)}>
        {(style) => (
          <TextArea
            {...props}
            className={classnames(props.className, style)}
            ref={ref}
          />
        )}
      </InputWrapperNew>
    );
  }
);

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ size = "3", ...props }, ref) => {
    const textboxRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textboxRef.current as HTMLTextAreaElement);

    function adjustHeight() {
      if (textboxRef.current) {
        textboxRef.current.style.overflow = "hidden";
        textboxRef.current.style.height = "0px";
        textboxRef.current.style.height = `${textboxRef.current.scrollHeight}px`;
      }
    }

    useLayoutEffect(adjustHeight, []);

    return (
      <RadixTextArea
        ref={textboxRef}
        size={size}
        {...props}
        className={classnames(props.className, {
          "min-h-0": props.rows === 1
        })}
        onChange={(e) => {
          adjustHeight();
          props.onChange?.(e);
        }}
      />
    );
  }
);
