import { createQuery } from "react-query-kit";
import {
  ProjectBundleRequest,
  ProjectProductApi,
  ProjectProductRequest
} from "../../.generated/api";
import environment from "../../environment";
import { productsInstance } from "./ProductsModuleHttpBase";

const projectProductApi = new ProjectProductApi(
  undefined,
  environment.skwApiNewBaseUrl,
  productsInstance
);

export async function getProjectProductsV2(
  companyId: string,
  projectId: string,
  page?: number,
  limit?: number,
  sort?: string,
  search?: string
) {
  const {
    data: { body }
  } = await projectProductApi.getProjectProductsV2(
    companyId,
    projectId,
    undefined,
    search,
    page,
    limit,
    sort
  );

  return body;
}

export async function getTaskProductsV2(
  companyId: string,
  taskId: string,
  page?: number,
  limit?: number,
  search?: string
) {
  const {
    data: { body }
  } = await projectProductApi.getTaskProductsV2(
    companyId,
    taskId,
    undefined,
    search,
    page,
    limit
  );

  return body;
}

export const useGetProjectProducts = createQuery({
  queryKey: ["getProjectProducts"],
  fetcher: async (variables: {
    companyId: string;
    projectId: string;
    page?: number;
  }) => {
    const response = await projectProductApi.getProjectProductsV2(
      variables.companyId,
      variables.projectId,
      undefined,
      undefined,
      variables.page
    );
    return response.data;
  }
});

export async function getProjectProducts(
  companyId: string,
  projectId: string,
  page?: number,
  limit?: number,
  search?: string
) {
  const {
    data: { body }
  } = await projectProductApi.getProjectProducts(
    companyId,
    projectId,
    undefined,
    search,
    page,
    limit
  );

  return body;
}

export async function addProjectProducts(
  companyId: string,
  projectId: string,
  reqBody: ProjectProductRequest[]
) {
  await projectProductApi.addProjectProducts(companyId, projectId, reqBody);
}

export async function addTaskProducts(
  companyId: string,
  projectId: string,
  taskId: string,
  reqBody: ProjectProductRequest[]
) {
  await projectProductApi.addTaskProducts(
    companyId,
    projectId,
    taskId,
    reqBody
  );
}

export async function updateProjectProduct(
  companyId: string,
  projectProductId: string,
  reqBody: ProjectProductRequest
) {
  await projectProductApi.updateProjectProduct(
    companyId,
    projectProductId,
    reqBody
  );
}

export async function addProjectProductsFromBundle(
  companyId: string,
  projectId: string,
  reqBody: ProjectBundleRequest[]
) {
  await projectProductApi.addProjectProductsFromBundle(
    companyId,
    projectId,
    reqBody
  );
}

export async function addTaskProductsFromBundle(
  companyId: string,
  projectId: string,
  taskId: string,
  reqBody: ProjectBundleRequest[]
) {
  await projectProductApi.addTaskProductsFromBundle(
    companyId,
    projectId,
    taskId,
    reqBody
  );
}

export async function deleteProjectProduct(
  companyId: string,
  projectProductId: string
) {
  await projectProductApi.deleteProjectProduct(companyId, projectProductId);
}
