/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { OrderBundleRequest } from '../model';
// @ts-ignore
import { OrderProductRequest } from '../model';
// @ts-ignore
import { ResponseDTOPaginatedProductConsumptionOrder } from '../model';
// @ts-ignore
import { ResponseDTOPaginatedProductConsumptionOrderV2 } from '../model';
// @ts-ignore
import { ResponseDTOString } from '../model';
/**
 * OrderProductApi - axios parameter creator
 * @export
 */
export const OrderProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add products and the quantity used to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderProductRequest>} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderProducts: async (companyId: string, orderId: string, orderProductRequest: Array<OrderProductRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addOrderProducts', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('addOrderProducts', 'orderId', orderId)
            // verify required parameter 'orderProductRequest' is not null or undefined
            assertParamExists('addOrderProducts', 'orderProductRequest', orderProductRequest)
            const localVarPath = `/v1/company/{companyId}/order/{orderId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add bundles of products to an order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderBundleRequest>} orderBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderProductsFromBundle: async (companyId: string, orderId: string, orderBundleRequest: Array<OrderBundleRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addOrderProductsFromBundle', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('addOrderProductsFromBundle', 'orderId', orderId)
            // verify required parameter 'orderBundleRequest' is not null or undefined
            assertParamExists('addOrderProductsFromBundle', 'orderBundleRequest', orderBundleRequest)
            const localVarPath = `/v1/company/{companyId}/order/{orderId}/products/bundle`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderBundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a product from an order or task
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderProduct: async (companyId: string, orderProductId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteOrderProduct', 'companyId', companyId)
            // verify required parameter 'orderProductId' is not null or undefined
            assertParamExists('deleteOrderProduct', 'orderProductId', orderProductId)
            const localVarPath = `/v1/company/{companyId}/order/products/{orderProductId}/archive`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderProductId"}}`, encodeURIComponent(String(orderProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderProducts: async (companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOrderProducts', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderProducts', 'orderId', orderId)
            const localVarPath = `/v1/company/{companyId}/order/{orderId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderProductsV2: async (companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getOrderProductsV2', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderProductsV2', 'orderId', orderId)
            const localVarPath = `/v1/company/{companyId}/order/{orderId}/productsV2`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a order product
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {OrderProductRequest} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProduct: async (companyId: string, orderProductId: string, orderProductRequest: OrderProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateOrderProduct', 'companyId', companyId)
            // verify required parameter 'orderProductId' is not null or undefined
            assertParamExists('updateOrderProduct', 'orderProductId', orderProductId)
            // verify required parameter 'orderProductRequest' is not null or undefined
            assertParamExists('updateOrderProduct', 'orderProductRequest', orderProductRequest)
            const localVarPath = `/v1/company/{companyId}/order/products/{orderProductId}/update`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderProductId"}}`, encodeURIComponent(String(orderProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderProductApi - functional programming interface
 * @export
 */
export const OrderProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add products and the quantity used to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderProductRequest>} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrderProducts(companyId: string, orderId: string, orderProductRequest: Array<OrderProductRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrderProducts(companyId, orderId, orderProductRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add bundles of products to an order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderBundleRequest>} orderBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrderProductsFromBundle(companyId: string, orderId: string, orderBundleRequest: Array<OrderBundleRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrderProductsFromBundle(companyId, orderId, orderBundleRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a product from an order or task
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderProduct(companyId: string, orderProductId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderProduct(companyId, orderProductId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderProducts(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumptionOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderProducts(companyId, orderId, authorization, search, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderProductsV2(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOPaginatedProductConsumptionOrderV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderProductsV2(companyId, orderId, authorization, search, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a order product
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {OrderProductRequest} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderProduct(companyId: string, orderProductId: string, orderProductRequest: OrderProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderProduct(companyId, orderProductId, orderProductRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderProductApi - factory interface
 * @export
 */
export const OrderProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Add products and the quantity used to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderProductRequest>} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderProducts(companyId: string, orderId: string, orderProductRequest: Array<OrderProductRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addOrderProducts(companyId, orderId, orderProductRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add bundles of products to an order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {Array<OrderBundleRequest>} orderBundleRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderProductsFromBundle(companyId: string, orderId: string, orderBundleRequest: Array<OrderBundleRequest>, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.addOrderProductsFromBundle(companyId, orderId, orderBundleRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a product from an order or task
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderProduct(companyId: string, orderProductId: string, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.deleteOrderProduct(companyId, orderProductId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderProducts(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumptionOrder> {
            return localVarFp.getOrderProducts(companyId, orderId, authorization, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products belonging to a order
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderProductsV2(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<ResponseDTOPaginatedProductConsumptionOrderV2> {
            return localVarFp.getOrderProductsV2(companyId, orderId, authorization, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a order product
         * @param {string} companyId 
         * @param {string} orderProductId 
         * @param {OrderProductRequest} orderProductRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProduct(companyId: string, orderProductId: string, orderProductRequest: OrderProductRequest, authorization?: string, options?: any): AxiosPromise<ResponseDTOString> {
            return localVarFp.updateOrderProduct(companyId, orderProductId, orderProductRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderProductApi - object-oriented interface
 * @export
 * @class OrderProductApi
 * @extends {BaseAPI}
 */
export class OrderProductApi extends BaseAPI {
    /**
     * 
     * @summary Add products and the quantity used to a order
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {Array<OrderProductRequest>} orderProductRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public addOrderProducts(companyId: string, orderId: string, orderProductRequest: Array<OrderProductRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).addOrderProducts(companyId, orderId, orderProductRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add bundles of products to an order
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {Array<OrderBundleRequest>} orderBundleRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public addOrderProductsFromBundle(companyId: string, orderId: string, orderBundleRequest: Array<OrderBundleRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).addOrderProductsFromBundle(companyId, orderId, orderBundleRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a product from an order or task
     * @param {string} companyId 
     * @param {string} orderProductId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public deleteOrderProduct(companyId: string, orderProductId: string, authorization?: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).deleteOrderProduct(companyId, orderProductId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a order
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public getOrderProducts(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).getOrderProducts(companyId, orderId, authorization, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products belonging to a order
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {string} [authorization] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public getOrderProductsV2(companyId: string, orderId: string, authorization?: string, search?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).getOrderProductsV2(companyId, orderId, authorization, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a order product
     * @param {string} companyId 
     * @param {string} orderProductId 
     * @param {OrderProductRequest} orderProductRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public updateOrderProduct(companyId: string, orderProductId: string, orderProductRequest: OrderProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).updateOrderProduct(companyId, orderProductId, orderProductRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
