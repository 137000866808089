import { createFileRoute, Outlet } from "@tanstack/react-router";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import { useTranslation } from "react-i18next";
import TabNav from "../../../../components/TabNav";
import { TabLink } from "../../../../components/TabLink";

export const Route = createFileRoute("/_protected/dashboard/sales/_layout")({
  component: SalesLayoutPage
});

function SalesLayoutPage() {
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("sales")} />
      <TabNav>
        <TabLink to={"/dashboard/sales/sales-opportunity"}>
          {t("salesOpportunity")}
        </TabLink>
        <TabLink to={"/dashboard/sales/direct-sale"}>{t("directSale")}</TabLink>
        <TabLink to={"/dashboard/sales/report"}>{t("salesReports")}</TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
