import { createFileRoute, useParams } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Offer } from "../../../../../../../.generated/api";
import { useGetOffersByProjectId } from "../../../../../../../service/api/OfferApi";
import PageLayout from "../../../../../../../components/PageLayout";
import ChangeOrderPreview from "./-components/ChangeOrderPreview";
import CardNew from "../../../../../../../components/ds/CardNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../../../components/ds/RadixTable/TableCell";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import { formatFullNumericDateString } from "../../../../../../../utils/DateTimeFormatter";
import Badge from "../../../../../../../components/ds/Badge";
import {
  resolveOfferStatus,
  resolveOfferStatusColor
} from "../../../../../../../utils/offerUtils";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order"
)({
  component: ProjectEconomyChangeOrderPage
});

function ProjectEconomyChangeOrderPage() {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const [selectedOffer, setSelectedOffer] = useState<Offer>();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  const offerQuery = useGetOffersByProjectId({
    variables: {
      projectId,
      companyId
    }
  });

  return (
    <PageLayout
      previewOffset={"regularWithTabBar"}
      withoutPadding
      preview={
        selectedOffer && (
          <ChangeOrderPreview
            offerId={selectedOffer.id}
            onClose={() => setSelectedOffer(undefined)}
          />
        )
      }
    >
      <CardNew title={t("changeOrder")} inset>
        <Table variant={"ghost"}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>{t("title")}</TableHeaderCell>
              <TableHeaderCell>{t("reported")}</TableHeaderCell>
              <TableHeaderCell>{t("responder")}</TableHeaderCell>
              <TableHeaderCell>{t("status")}</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {offerQuery.data?.map((offer) => {
              return (
                <TableRow key={offer.id}>
                  <TableCell>
                    <ButtonNew
                      highContrast
                      variant={"ghost"}
                      className={"underline"}
                      onClick={() => setSelectedOffer(offer)}
                    >
                      {offer.name}
                    </ButtonNew>
                  </TableCell>
                  <TableCell>
                    {formatFullNumericDateString(offer.updatedAt)}
                  </TableCell>
                  <TableCell>{offer.contactName}</TableCell>
                  <TableCell>
                    <Badge color={resolveOfferStatusColor(offer.status)}>
                      {resolveOfferStatus(offer.status)}
                    </Badge>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </PageLayout>
  );
}
