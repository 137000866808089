import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import {
  handleFirebaseError,
  signInApple,
  signInGoogle,
  signInMicrosoft,
  signInWithUsernameAndPass
} from "../../service/firebase/FirebaseService";
import { t } from "i18next";
import SkyworkerLogo from "../../static/logos/skyworker.svg";
import {
  IconButton,
  Link as RadixLink,
  Separator,
  Text
} from "@radix-ui/themes";
import { FormTextField } from "../../components/ds/TextFieldNew";
import GoogleLogo from "../../static/logos/sso/google.svg";
import AppleLogoLight from "../../static/logos/sso/apple_light.svg";
import AppleLogoDark from "../../static/logos/sso/apple_dark.svg";
import MicrosoftLogo from "../../static/logos/sso/microsoft.svg";
import ButtonNew from "../../components/ds/ButtonNew";
import useTheme from "../../hooks/useTheme";
import classNames from "classnames";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../../hooks/useToast";
import LoginLayout from "./-components/LoginLayout";

export const Route = createFileRoute("/_unauthenticated/login")({
  component: LoginPage
});

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1)
});

type LoginFormInput = z.infer<typeof loginSchema>;

function LoginPage() {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const { theme } = useTheme();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<LoginFormInput>({
    resolver: zodResolver(loginSchema)
  });

  function handleError(e: unknown) {
    const error = handleFirebaseError(e as Record<string, unknown>);
    showErrorToast(error);
  }

  const onSubmit = async (data: LoginFormInput) => {
    try {
      await signInWithUsernameAndPass(data.email, data.password);
      await navigate({
        to: "/dashboard/overview"
      });
    } catch (e) {
      handleError(e);
    }
  };

  async function signInWithMicrosoft() {
    try {
      await signInMicrosoft();
      await navigate({
        to: "/dashboard/project/overview"
      });
    } catch (e) {
      handleError(e);
    }
  }

  async function signInWithGoogle() {
    try {
      await signInGoogle();
      await navigate({
        to: "/dashboard/project/overview"
      });
    } catch (e) {
      handleError(e);
    }
  }

  async function signInWithApple() {
    try {
      await signInApple();
      await navigate({
        to: "/dashboard/project/overview"
      });
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginLayout
        title={"Velkommen tilbake!"}
        subtitle={"Skriv inn e-post og passord for å logge inn"}
        leading={
          <img
            src={SkyworkerLogo}
            className={"h-[40px] mb-12"}
            alt={"Skyworker logo"}
          />
        }
      >
        <FormTextField
          label={t("email")}
          placeholder={t("eksempel@bedrift.no")}
          errorMessage={errors.email?.message}
          {...register("email")}
        />
        <FormTextField
          label={t("password")}
          type={"password"}
          errorMessage={errors.password?.message}
          placeholder={t("password")}
          {...register("password")}
        />
        <RadixLink asChild className={"mb-4 self-end"}>
          <Link to={"/reset-password"}>{t("forgotPassword")}</Link>
        </RadixLink>
        <ButtonNew size={"3"} type={"submit"} loading={isSubmitting}>
          {t("signIn")}
        </ButtonNew>
        <div className={"flex items-center gap-4 mb-4 mt-8"}>
          <Separator className={"grow"} />
          <Text color={"gray"} size={"2"}>
            {t("orSignInWith")}
          </Text>
          <Separator className={"grow"} />
        </div>
        <div className={"flex gap-4 justify-center"}>
          <IconButton
            size={"3"}
            type={"button"}
            onClick={signInWithGoogle}
            className={classNames("p-2.5", {
              "bg-white": theme === "light",
              "bg-[#131313]": theme === "dark"
            })}
          >
            <img alt={"Google logo"} src={GoogleLogo} />
          </IconButton>
          <IconButton
            size={"3"}
            type={"button"}
            onClick={signInWithApple}
            className={classNames("p-2.5", {
              "bg-white": theme === "dark",
              "bg-black": theme === "light"
            })}
          >
            <img
              alt={"Apple logo"}
              src={theme === "light" ? AppleLogoDark : AppleLogoLight}
            />
          </IconButton>
          <IconButton
            type={"button"}
            size={"3"}
            onClick={signInWithMicrosoft}
            className={classNames(" p-2.5", {
              "bg-white": theme === "light",
              "bg-[#131313]": theme === "dark"
            })}
          >
            <img alt={"Microsoft logo"} src={MicrosoftLogo} />
          </IconButton>
        </div>
        <div className={"flex justify-center mt-6 gap-2"}>
          <Text weight={"medium"}>Har du ikke en konto?</Text>
          <RadixLink asChild>
            <Link to={"/create-account"}>{t("registerHere")}</Link>
          </RadixLink>
        </div>
      </LoginLayout>
    </form>
  );
}
