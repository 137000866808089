import {
  FacilityApi,
  FacilityPostRequest,
  FacilityPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const facilityApi = new FacilityApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetFacilityById = createQuery({
  queryKey: ["getFacilityById"],
  fetcher: async (variables: { companyId: string; facilityId: string }) => {
    const response = await facilityApi.getFacilityOnId(
      variables.companyId,
      variables.facilityId
    );
    return response.data;
  }
});

export const useGetFacilityByIds = createQuery({
  queryKey: ["getFacilityByIds"],
  fetcher: async (variables: {
    companyId: string;
    facilityIds: string[];
    page?: number;
    query?: string;
    archived?: boolean;
  }) => {
    const response = await facilityApi.getAllByIds(
      variables.companyId,
      variables.facilityIds,
      variables.page,
      undefined,
      undefined,
      variables.query,
      undefined,
      variables.archived ?? false
    );

    return response.data;
  }
});

export const useGetFacilityByCompanyId = createQuery({
  queryKey: ["getFacilityByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    query: string;
    page?: number;
    archived?: boolean;
  }) => {
    const response = await facilityApi.getAllCompanyFacilities(
      variables.companyId,
      variables.page,
      "DESC",
      "FacilityNumber",
      variables.query,
      undefined,
      variables.archived ?? false
    );
    return response.data;
  }
});

export async function createFacility(request: FacilityPostRequest) {
  const { data } = await facilityApi.postFacility(request);
  return data;
}

export async function updateFacility(request: FacilityPutRequest) {
  const { data } = await facilityApi.updateFacility(request);
  return data;
}

export async function archiveFacility(companyId: string, facilityId: string) {
  const { data } = await facilityApi.archiveFacility(facilityId, companyId);
  return data;
}

export async function restoreFacility(companyId: string, facilityId: string) {
  await facilityApi.restoreFacility(facilityId, companyId);
  // return data;
}
