export type PageTitleSize = "regular";
export type TabBarSize = "regular";
export type PreviewOffset = "regular" | "regularWithTabBar";

export const searchBarSize = "w-[300px]";
export const checkboxCellSize = "w-[44px]";

export const pageTitleSizes = {
  regular: 89
} satisfies Record<PageTitleSize, number>;

export const tabBarSizes = {
  regular: 40
} satisfies Record<TabBarSize, number>;

export const previewOffsets = {
  regular: pageTitleSizes.regular,
  regularWithTabBar: pageTitleSizes.regular + tabBarSizes.regular
} satisfies Record<PreviewOffset, number>;
