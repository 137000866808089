import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import UpsertOrderPage from "../-components/UpsertOrderPage";
import { useGetOrderById } from "../../../../../service/api/OrderApi";
import { useGetCustomerByResourceId } from "../../../../../service/api/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { orderId }
  }) => {
    const order = await queryClient.fetchQuery(
      useGetOrderById.getOptions({ companyId, orderId })
    );

    const customer = await queryClient.fetchQuery(
      useGetCustomerByResourceId.getOptions({
        companyId,
        resourceId: order.id
      })
    );

    return {
      order,
      customer
    };
  },
  component: UpdateOrderPage
});

function UpdateOrderPage() {
  const { order, customer } = useLoaderData({
    from: "/_protected/dashboard/orders/$orderId/update"
  });

  return <UpsertOrderPage order={order} existingCustomer={customer} />;
}
