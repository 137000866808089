/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Inspection } from '../model';
// @ts-ignore
import { InspectionCommentPutRequest } from '../model';
// @ts-ignore
import { InspectionDetails } from '../model';
// @ts-ignore
import { InspectionPostRequest } from '../model';
// @ts-ignore
import { InspectionPutRequest } from '../model';
// @ts-ignore
import { MailAndSmsPostRequest } from '../model';
// @ts-ignore
import { SubmitInspectionPutRequest } from '../model';
/**
 * InspectionApi - axios parameter creator
 * @export
 */
export const InspectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive inspection
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveInspection: async (companyId: string, inspectionId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveInspection', 'companyId', companyId)
            // verify required parameter 'inspectionId' is not null or undefined
            assertParamExists('archiveInspection', 'inspectionId', inspectionId)
            const localVarPath = `/v1/company/{companyId}/inspection/{inspectionId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"inspectionId"}}`, encodeURIComponent(String(inspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new inspection
         * @param {string} companyId 
         * @param {InspectionPostRequest} inspectionPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInspection: async (companyId: string, inspectionPostRequest: InspectionPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createInspection', 'companyId', companyId)
            // verify required parameter 'inspectionPostRequest' is not null or undefined
            assertParamExists('createInspection', 'inspectionPostRequest', inspectionPostRequest)
            const localVarPath = `/v1/company/{companyId}/inspection`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inspectionPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get inspection by id
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionById: async (companyId: string, inspectionId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInspectionById', 'companyId', companyId)
            // verify required parameter 'inspectionId' is not null or undefined
            assertParamExists('getInspectionById', 'inspectionId', inspectionId)
            const localVarPath = `/v1/company/{companyId}/inspection/{inspectionId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"inspectionId"}}`, encodeURIComponent(String(inspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionByPathUri: async (pathUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pathUri' is not null or undefined
            assertParamExists('getInspectionByPathUri', 'pathUri', pathUri)
            const localVarPath = `/v1/unauthenticated/inspection/path_uri/{pathUri}`
                .replace(`{${"pathUri"}}`, encodeURIComponent(String(pathUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get inspections by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsByCompanyId: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInspectionsByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/inspection`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get inspections by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsByCustomerId: async (companyId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInspectionsByCustomerId', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getInspectionsByCustomerId', 'customerId', customerId)
            const localVarPath = `/v1/company/{companyId}/inspection/customer/{customerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get inspections by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsBySalesOpportunityId: async (companyId: string, salesOpportunityId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInspectionsBySalesOpportunityId', 'companyId', companyId)
            // verify required parameter 'salesOpportunityId' is not null or undefined
            assertParamExists('getInspectionsBySalesOpportunityId', 'salesOpportunityId', salesOpportunityId)
            const localVarPath = `/v1/company/{companyId}/inspection/sales-opportunity/{salesOpportunityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"salesOpportunityId"}}`, encodeURIComponent(String(salesOpportunityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send inspection email to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInspectionEmail: async (companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('sendInspectionEmail', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInspectionEmail', 'id', id)
            // verify required parameter 'mailAndSmsPostRequest' is not null or undefined
            assertParamExists('sendInspectionEmail', 'mailAndSmsPostRequest', mailAndSmsPostRequest)
            const localVarPath = `/v1/company/{companyId}/inspection/{id}/email`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailAndSmsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send inspection SMS to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInspectionSMS: async (companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('sendInspectionSMS', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInspectionSMS', 'id', id)
            // verify required parameter 'mailAndSmsPostRequest' is not null or undefined
            assertParamExists('sendInspectionSMS', 'mailAndSmsPostRequest', mailAndSmsPostRequest)
            const localVarPath = `/v1/company/{companyId}/inspection/{id}/sms`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailAndSmsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInspectionByPathUri: async (pathUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pathUri' is not null or undefined
            assertParamExists('submitInspectionByPathUri', 'pathUri', pathUri)
            const localVarPath = `/v1/unauthenticated/inspection/path_uri/{pathUri}`
                .replace(`{${"pathUri"}}`, encodeURIComponent(String(pathUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit inspection response
         * @param {string} companyId 
         * @param {string} id 
         * @param {SubmitInspectionPutRequest} submitInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInspectionResponse: async (companyId: string, id: string, submitInspectionPutRequest: SubmitInspectionPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('submitInspectionResponse', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitInspectionResponse', 'id', id)
            // verify required parameter 'submitInspectionPutRequest' is not null or undefined
            assertParamExists('submitInspectionResponse', 'submitInspectionPutRequest', submitInspectionPutRequest)
            const localVarPath = `/v1/company/{companyId}/inspection/{id}/submit`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitInspectionPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update comment on inspection by path URI
         * @param {string} pathUri 
         * @param {InspectionCommentPutRequest} inspectionCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentOnInspection: async (pathUri: string, inspectionCommentPutRequest: InspectionCommentPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pathUri' is not null or undefined
            assertParamExists('updateCommentOnInspection', 'pathUri', pathUri)
            // verify required parameter 'inspectionCommentPutRequest' is not null or undefined
            assertParamExists('updateCommentOnInspection', 'inspectionCommentPutRequest', inspectionCommentPutRequest)
            const localVarPath = `/v1/unauthenticated/inspection/path_uri/{pathUri}/comment`
                .replace(`{${"pathUri"}}`, encodeURIComponent(String(pathUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inspectionCommentPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update inspection
         * @param {string} companyId 
         * @param {string} id 
         * @param {InspectionPutRequest} inspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInspection: async (companyId: string, id: string, inspectionPutRequest: InspectionPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateInspection', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInspection', 'id', id)
            // verify required parameter 'inspectionPutRequest' is not null or undefined
            assertParamExists('updateInspection', 'inspectionPutRequest', inspectionPutRequest)
            const localVarPath = `/v1/company/{companyId}/inspection/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inspectionPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InspectionApi - functional programming interface
 * @export
 */
export const InspectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InspectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive inspection
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveInspection(companyId: string, inspectionId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveInspection(companyId, inspectionId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new inspection
         * @param {string} companyId 
         * @param {InspectionPostRequest} inspectionPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInspection(companyId: string, inspectionPostRequest: InspectionPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inspection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInspection(companyId, inspectionPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get inspection by id
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInspectionById(companyId: string, inspectionId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InspectionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInspectionById(companyId, inspectionId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInspectionByPathUri(pathUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InspectionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInspectionByPathUri(pathUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get inspections by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInspectionsByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inspection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInspectionsByCompanyId(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get inspections by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInspectionsByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inspection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInspectionsByCustomerId(companyId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get inspections by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInspectionsBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inspection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInspectionsBySalesOpportunityId(companyId, salesOpportunityId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send inspection email to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInspectionEmail(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInspectionEmail(companyId, id, mailAndSmsPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send inspection SMS to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInspectionSMS(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInspectionSMS(companyId, id, mailAndSmsPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitInspectionByPathUri(pathUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitInspectionByPathUri(pathUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit inspection response
         * @param {string} companyId 
         * @param {string} id 
         * @param {SubmitInspectionPutRequest} submitInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitInspectionResponse(companyId: string, id: string, submitInspectionPutRequest: SubmitInspectionPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inspection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitInspectionResponse(companyId, id, submitInspectionPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update comment on inspection by path URI
         * @param {string} pathUri 
         * @param {InspectionCommentPutRequest} inspectionCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommentOnInspection(pathUri: string, inspectionCommentPutRequest: InspectionCommentPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommentOnInspection(pathUri, inspectionCommentPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update inspection
         * @param {string} companyId 
         * @param {string} id 
         * @param {InspectionPutRequest} inspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInspection(companyId: string, id: string, inspectionPutRequest: InspectionPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inspection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInspection(companyId, id, inspectionPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InspectionApi - factory interface
 * @export
 */
export const InspectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InspectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive inspection
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveInspection(companyId: string, inspectionId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.archiveInspection(companyId, inspectionId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new inspection
         * @param {string} companyId 
         * @param {InspectionPostRequest} inspectionPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInspection(companyId: string, inspectionPostRequest: InspectionPostRequest, authorization?: string, options?: any): AxiosPromise<Inspection> {
            return localVarFp.createInspection(companyId, inspectionPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get inspection by id
         * @param {string} companyId 
         * @param {string} inspectionId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionById(companyId: string, inspectionId: string, authorization?: string, options?: any): AxiosPromise<InspectionDetails> {
            return localVarFp.getInspectionById(companyId, inspectionId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionByPathUri(pathUri: string, options?: any): AxiosPromise<InspectionDetails> {
            return localVarFp.getInspectionByPathUri(pathUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get inspections by company id
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsByCompanyId(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Inspection>> {
            return localVarFp.getInspectionsByCompanyId(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get inspections by customer id
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsByCustomerId(companyId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<Array<Inspection>> {
            return localVarFp.getInspectionsByCustomerId(companyId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get inspections by sales opportunity id
         * @param {string} companyId 
         * @param {string} salesOpportunityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInspectionsBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: any): AxiosPromise<Array<Inspection>> {
            return localVarFp.getInspectionsBySalesOpportunityId(companyId, salesOpportunityId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send inspection email to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInspectionEmail(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.sendInspectionEmail(companyId, id, mailAndSmsPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send inspection SMS to customer
         * @param {string} companyId 
         * @param {string} id 
         * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInspectionSMS(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.sendInspectionSMS(companyId, id, mailAndSmsPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit inspection by path URI
         * @param {string} pathUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInspectionByPathUri(pathUri: string, options?: any): AxiosPromise<object> {
            return localVarFp.submitInspectionByPathUri(pathUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit inspection response
         * @param {string} companyId 
         * @param {string} id 
         * @param {SubmitInspectionPutRequest} submitInspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInspectionResponse(companyId: string, id: string, submitInspectionPutRequest: SubmitInspectionPutRequest, authorization?: string, options?: any): AxiosPromise<Inspection> {
            return localVarFp.submitInspectionResponse(companyId, id, submitInspectionPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update comment on inspection by path URI
         * @param {string} pathUri 
         * @param {InspectionCommentPutRequest} inspectionCommentPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentOnInspection(pathUri: string, inspectionCommentPutRequest: InspectionCommentPutRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateCommentOnInspection(pathUri, inspectionCommentPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update inspection
         * @param {string} companyId 
         * @param {string} id 
         * @param {InspectionPutRequest} inspectionPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInspection(companyId: string, id: string, inspectionPutRequest: InspectionPutRequest, authorization?: string, options?: any): AxiosPromise<Inspection> {
            return localVarFp.updateInspection(companyId, id, inspectionPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InspectionApi - object-oriented interface
 * @export
 * @class InspectionApi
 * @extends {BaseAPI}
 */
export class InspectionApi extends BaseAPI {
    /**
     * 
     * @summary Archive inspection
     * @param {string} companyId 
     * @param {string} inspectionId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public archiveInspection(companyId: string, inspectionId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).archiveInspection(companyId, inspectionId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new inspection
     * @param {string} companyId 
     * @param {InspectionPostRequest} inspectionPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public createInspection(companyId: string, inspectionPostRequest: InspectionPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).createInspection(companyId, inspectionPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get inspection by id
     * @param {string} companyId 
     * @param {string} inspectionId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public getInspectionById(companyId: string, inspectionId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).getInspectionById(companyId, inspectionId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get inspection by path URI
     * @param {string} pathUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public getInspectionByPathUri(pathUri: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).getInspectionByPathUri(pathUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get inspections by company id
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public getInspectionsByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).getInspectionsByCompanyId(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get inspections by customer id
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public getInspectionsByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).getInspectionsByCustomerId(companyId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get inspections by sales opportunity id
     * @param {string} companyId 
     * @param {string} salesOpportunityId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public getInspectionsBySalesOpportunityId(companyId: string, salesOpportunityId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).getInspectionsBySalesOpportunityId(companyId, salesOpportunityId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send inspection email to customer
     * @param {string} companyId 
     * @param {string} id 
     * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public sendInspectionEmail(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).sendInspectionEmail(companyId, id, mailAndSmsPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send inspection SMS to customer
     * @param {string} companyId 
     * @param {string} id 
     * @param {MailAndSmsPostRequest} mailAndSmsPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public sendInspectionSMS(companyId: string, id: string, mailAndSmsPostRequest: MailAndSmsPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).sendInspectionSMS(companyId, id, mailAndSmsPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit inspection by path URI
     * @param {string} pathUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public submitInspectionByPathUri(pathUri: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).submitInspectionByPathUri(pathUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit inspection response
     * @param {string} companyId 
     * @param {string} id 
     * @param {SubmitInspectionPutRequest} submitInspectionPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public submitInspectionResponse(companyId: string, id: string, submitInspectionPutRequest: SubmitInspectionPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).submitInspectionResponse(companyId, id, submitInspectionPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update comment on inspection by path URI
     * @param {string} pathUri 
     * @param {InspectionCommentPutRequest} inspectionCommentPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public updateCommentOnInspection(pathUri: string, inspectionCommentPutRequest: InspectionCommentPutRequest, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).updateCommentOnInspection(pathUri, inspectionCommentPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update inspection
     * @param {string} companyId 
     * @param {string} id 
     * @param {InspectionPutRequest} inspectionPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public updateInspection(companyId: string, id: string, inspectionPutRequest: InspectionPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).updateInspection(companyId, id, inspectionPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
