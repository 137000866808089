import { createRouter, Navigate } from "@tanstack/react-router";
import ErrorPage from "../pages/errorBoundary/ErrorPage";
import { routeTree } from "../routeTree.gen";
import { SuspenseFallback } from "../pages/fallbacks/SuspenseFallback";
import { queryClient } from "./QueryClient";

export const AppRouter = createRouter({
  defaultPreload: "intent",
  routeTree: routeTree,
  defaultErrorComponent: ({ error }) => <ErrorPage error={error} />,
  defaultPendingComponent: () => <SuspenseFallback />,
  defaultNotFoundComponent: () => (
    <Navigate to={"/dashboard/project/overview"} />
  ),
  context: {
    queryClient: queryClient
  }
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof AppRouter;
  }
}
