import {
  createFileRoute,
  useLoaderData,
  useNavigate,
  useParams,
  useRouter
} from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useToast } from "../../../../../hooks/useToast";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { locationSchema, MarkerOption } from "../../../../../components/utils";
import {
  getRoleName,
  useGetRolesByCompanyId
} from "../../../../../service/api/RoleApi";
import { useTranslation } from "react-i18next";
import {
  addUserToTeam,
  removeUserFromTeam,
  useGetTeamsByCompanyId
} from "../../../../../service/api/TeamApi";
import { useGetCertificationsByUserId } from "../../../../../service/api/UserCertificationApi";
import {
  CompanyConnectionEmploymentTypeEnum,
  CompanyUserSalaryTypeEnum,
  EmergencyContact,
  Team,
  UserCertificationTypeEnum
} from "../../../../../.generated/api";
import useDialog from "../../../../../hooks/useDialog";
import {
  createTimekeepingLocation,
  useGetTimekeepingLocationsByResourceId
} from "../../../../../service/api/UserLocationApi";
import React, { useEffect } from "react";
import {
  addUserEmergencyContact,
  updateUserCompanyInfo,
  useGetUsersByCompanyId
} from "../../../../../service/api/UserV2Api";
import { ApiError } from "../../../../../service/ErrorHandler";
import TeamDialog from "../../../../../pages/employeesPage/TeamDialog";
import PageTitle from "../../../../../components/common/PageTitle";
import Button from "../../../../../components/ds/Button";
import Card from "../../../../../components/ds/Card";
import TextField from "../../../../../components/ds/TextField";
import Select from "../../../../../components/ds/Select";
import { PopoverButton } from "../../../../../components/ds/PopoverItem";
import EmployeeFileUpload from "../../../../../pages/employeesPage/EmployeeFileUpload";
import CertificationsTable from "../../../../../pages/employeesPage/CertificationsTable";
import TimekeepingLocationComponent from "../../../../../components/TimekeepingLocationComponent";

export type UpsertEmployeeFormInputs = {
  employeeNumber: number;
  title?: string;
  role?: string;
  salaryType: string;
  associatedCompany?: string;
  locations: MarkerOption[];
  team?: Team;
  emergencyContact?: EmergencyContact;
};

export const Route = createFileRoute(
  "/_protected/dashboard/resources/$employeeId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId } }) => {
    return queryClient.fetchQuery({
      ...useGetUsersByCompanyId.getOptions({ companyId })
    });
  },
  component: UpdateEmployeePage
});

function UpdateEmployeePage() {
  const router = useRouter();
  const { employeeId } = useParams({
    from: "/_protected/dashboard/resources/$employeeId/update"
  });
  const employees = useLoaderData({
    from: "/_protected/dashboard/resources/$employeeId/update"
  });
  const employee = employees.find((e) => e.id === employeeId);
  const companyId = useCompanyId();
  const navigate = useNavigate();

  const { showErrorToast } = useToast();

  const methods = useForm<UpsertEmployeeFormInputs>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(locationSchema)
  });

  const roleQuery = useGetRolesByCompanyId({
    variables: { companyId, scope: "company" }
  });

  const roles = roleQuery.data ?? [];
  const { t } = useTranslation();

  const teams = useGetTeamsByCompanyId({
    variables: { companyId }
  });

  const certificationQuery = useGetCertificationsByUserId({
    variables: {
      userId: employeeId,
      companyId: companyId
    }
  });

  const courses =
    certificationQuery.data?.filter(
      (c) => c.type === UserCertificationTypeEnum.Course
    ) ?? [];

  const certifications =
    certificationQuery.data?.filter(
      (c) => c.type === UserCertificationTypeEnum.Certification
    ) ?? [];

  const roleOptions = roles
    .filter(({ name }) => name)
    .map(({ name, roleId }) => ({
      label: name as string,
      value: roleId
    }));

  function getDefaultValues(): UpsertEmployeeFormInputs {
    return {
      employeeNumber: employee?.employeeNumber ?? 0,
      role: employee ? employee.role?.roleId : undefined,
      title: employee ? employee.title : undefined,
      salaryType: employee
        ? employee.salaryType
        : CompanyUserSalaryTypeEnum.Fixed,
      associatedCompany: employee ? employee.associatedCompany : undefined,
      locations: [],
      team: employee?.team ?? undefined,
      emergencyContact: employee?.emergencyContact ?? undefined
    };
  }

  const salaryTypes = Object.values(CompanyUserSalaryTypeEnum);
  const salaryOptions = [
    ...salaryTypes.map((salary) => ({
      label:
        salary == CompanyUserSalaryTypeEnum.Fixed
          ? t("Fast")
          : t("Timesbetalt"),
      value: salary
    }))
  ];

  const formValues = methods.watch();
  const { isOpen, onOpen, onClose } = useDialog();
  const userLocationQuery = useGetTimekeepingLocationsByResourceId({
    variables: {
      companyId,
      resourceId: employeeId,
      resourceType: "User"
    }
  });

  useEffect(() => {
    if (userLocationQuery.data) {
      methods.setValue("locations", userLocationQuery.data);
    }
  }, [userLocationQuery.data]);

  async function onSubmit(data: UpsertEmployeeFormInputs) {
    try {
      if (employee) {
        await updateUserCompanyInfo(companyId, employee.id, {
          employeeNumber: data.employeeNumber,
          salaryType: data.salaryType as CompanyUserSalaryTypeEnum,
          title: data.title,
          roleId: data.role,
          associatedCompany: data.associatedCompany
        });

        if (data.team?.id !== employee.team?.id) {
          if (data.team) {
            await addUserToTeam(companyId, employee.id, data.team.id);
          } else {
            await removeUserFromTeam(
              companyId,
              employee.id,
              employee.team?.id ?? ""
            );
          }
        }

        if (data.emergencyContact) {
          await addUserEmergencyContact(
            companyId,
            employee.id,
            data.emergencyContact
          );
        }

        await createTimekeepingLocation(companyId, {
          resourceId: employee.id,
          resourceType: "User",
          locations: data.locations.map((loc) => ({
            title: loc.title,
            location: loc.location,
            radius: loc.radius,
            isPrimary: loc.isPrimary
          }))
        });

        await navigate({
          to: "/dashboard/employees"
        });
      }
    } catch (e) {
      const error = e as ApiError;
      showErrorToast(error.message);
    }
  }

  return (
    <div className={"p-5"}>
      <TeamDialog
        isOpen={isOpen}
        onClose={onClose}
        teams={teams.data ?? []}
        onUpdated={teams.refetch}
      />
      <PageTitle
        title={t("Rediger ansatt")}
        actions={
          <div className="flex items-center justify-center gap-4">
            <Button
              isLoading={methods.formState.isSubmitting}
              theme={"green"}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("update")}
            </Button>
            <Button theme={"white"} onClick={() => router.history.back()}>
              {t("cancel")}
            </Button>
          </div>
        }
      />
      <FormProvider {...methods}>
        <form>
          <Card>
            <h6 className={"font-bold my-8"}>{employee?.name}</h6>
            <div className="sm:grid sm:grid-cols-2 gap-x-6">
              <TextField
                name="associatedCompany"
                label={t("company")}
                register={methods.register("associatedCompany")}
              />
              {/*<TextField label="Mobil" />
              <TextField label="E-post" />*/}
              <TextField
                name="title"
                label={t("Yrke/tittel")}
                register={methods.register("title")}
              />

              <Controller
                control={methods.control}
                name="role"
                render={() => (
                  <Select
                    name="role"
                    label={t("role")}
                    value={formValues.role}
                    onChange={(selectedOption) => {
                      if (!selectedOption) return;
                      methods.setValue("role", selectedOption);
                    }}
                    options={roleOptions.map((role) => ({
                      ...role,
                      label: t(getRoleName(role.label))
                    }))}
                  />
                )}
              />
              <Controller
                control={methods.control}
                name="salaryType"
                render={() => (
                  <Select
                    name="salaryType"
                    label={t("Lønnstype")}
                    value={formValues.salaryType}
                    onChange={(selectedOption) => {
                      if (!selectedOption) return;
                      methods.setValue("salaryType", selectedOption);
                    }}
                    options={salaryOptions}
                  />
                )}
              />
              <Controller
                control={methods.control}
                name={"team"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select
                      value={value}
                      label={t("Team")}
                      compareValueId={(v) => v?.id}
                      noOptionsText={t("Ingen team funnet")}
                      options={(teams.data ?? []).map((team) => ({
                        label: team.name,
                        value: team
                      }))}
                      onChange={onChange}
                      placeholder={t("Søk etter team")}
                      trailing={
                        <PopoverButton
                          title={t("Rediger teams")}
                          onClick={() => {
                            onOpen();
                          }}
                        />
                      }
                    />
                  );
                }}
              />
              <TextField
                name="employeeNumber"
                label={t("Ansattnummer")}
                placeholder={t("Ansattnummer")}
                register={methods.register("employeeNumber")}
              />

              {employee &&
                employee.employmentType ==
                  CompanyConnectionEmploymentTypeEnum.Subcontractor && (
                  <TextField
                    name="associatedCompany"
                    label="Bedrift"
                    register={methods.register("associatedCompany")}
                  />
                )}
            </div>
            <h3 className="font-bold mt-6">{t("HR-relatert informasjon")}</h3>
            <h6 className={"font-bold my-8"}>{t("Pårørende")}</h6>
            <div className="sm:grid sm:grid-cols-2 gap-x-6">
              <TextField
                name="emergencyFirstName"
                label={t("firstName")}
                register={methods.register("emergencyContact.firstName")}
              />
              <TextField
                name="emergencyLastName"
                label={t("lastName")}
                register={methods.register("emergencyContact.lastName")}
              />
              <TextField
                name="emergencyPhoneNumber"
                label={t("Telefonnummer")}
                register={methods.register("emergencyContact.phoneNumber")}
              />
            </div>
            {employee && (
              <div>
                <h6 className={"font-bold my-8"}>
                  {t("Last opp kontrakt, CV, HMS-dokumenter o.l.")}
                </h6>
                <EmployeeFileUpload userCompanyId={employee.userCompanyId} />
              </div>
            )}
            <h6 className={"font-bold my-8"}>{t("Kurs")}</h6>
            <CertificationsTable
              type="Course"
              certifications={courses}
              refetch={certificationQuery.refetch}
              userId={employeeId}
            />
            <h6 className={"font-bold my-8"}>{t("Sertifiseringer")}</h6>
            <CertificationsTable
              type="Certification"
              certifications={certifications}
              refetch={certificationQuery.refetch}
              userId={employeeId}
            />
            <TimekeepingLocationComponent />
          </Card>
        </form>
      </FormProvider>
    </div>
  );
}
