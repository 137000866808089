import { createFileRoute, useSearch } from "@tanstack/react-router";
import React from "react";
import { UpsertDeviationPage } from "./-components/UpsertDeviationPage";
import { z } from "zod";

const createDeviationSchema = z.object({
  resourceType: z.enum(["task", "project", "order"]),
  resourceId: z.string(),
  projectId: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/deviation/new")({
  validateSearch: createDeviationSchema,
  component: CreateDeviationPage
});

function CreateDeviationPage() {
  const { resourceType, resourceId, projectId } = useSearch({
    from: "/_protected/dashboard/deviation/new"
  });
  return (
    <UpsertDeviationPage
      resourceId={resourceId}
      resourceType={resourceType}
      projectId={projectId}
    />
  );
}
