import React, { forwardRef } from "react";
import { Table } from "@radix-ui/themes";
import classnames from "classnames";

const TableRow = forwardRef<HTMLTableRowElement, Table.RowProps>(
  ({ ...props }, ref) => {
    return (
      <Table.Row
        {...props}
        className={classnames("group/tr", props.className)}
        ref={ref}
      />
    );
  }
);
export default TableRow;
