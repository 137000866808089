import React, { useState } from "react";
import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useQuery } from "@tanstack/react-query";
import ValidatedIntegrationProvider from "../../../../../providers/SuccessfulIntegrationProvider";
import { useToast } from "../../../../../hooks/useToast";
import useDialog from "../../../../../hooks/useDialog";
import useValidatedIntegration from "../../../../../hooks/useSuccessfulIntegration";
import Card from "../../../../../components/ds/Card";
import PowerOfficeLogo from "../../../../../static/images/poweroffice/poweroffice_logo.png";
import Button from "../../../../../components/ds/Button";
import CustomDialog from "../../../../../components/common/CustomDialog";
import PageLayout from "../../../../../components/PageLayout";
import { t } from "i18next";
import Integrations from "../../../../../static/images/integrasjoner.png";
import CenteredPageLayout from "../../../../../components/ds/CenteredPageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import { Link, Text } from "@radix-ui/themes";
import {
  checkExistingIntegrations,
  removeExistingIntegration,
  synchronizeIntegration
} from "../../../../../service/api/IntegrationsApi";
import NewDataFetcher from "../../../../../components/common/NewDataFetcher";

type ActiveIntegrationProps = {
  refetch: VoidFunction;
};

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/integration"
)({
  component: IntegrationSettingsPage
});

const ActiveIntegration = ({ refetch }: ActiveIntegrationProps) => {
  const companyId = useCompanyId();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isSyncing, setIsSyncing] = useState(false);

  function syncData() {
    if (!isSyncing) {
      setIsSyncing(true);
      synchronizeIntegration(companyId)
        .then(() => {
          showSuccessToast("Data synkronisert til PowerOffice");
        })
        .catch(() => {
          showErrorToast("Feil ved synkronisering av data");
        })
        .finally(() => {
          setIsSyncing(false);
        });
    }
  }

  const { isOpen, onClose, onOpen } = useDialog();

  const { setIsClientKeyValidated, setIsIntegrationSynced } =
    useValidatedIntegration();

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex w-full justify-between items-start">
          <div>
            <h1 className="text-secondary mb-4">PowerOffice</h1>
            <p className=" h-14">Din integrasjon mot PowerOffice er aktiv.</p>
          </div>
          <img src={PowerOfficeLogo} alt="" className="w-56 object-contain" />
        </div>
        <div className={"flex justify-between"}>
          <Button theme="white" onClick={syncData} isLoading={isSyncing}>
            Synkroniser data
          </Button>
          <Button onClick={onOpen}>Avinstaller integrasjon</Button>
        </div>
      </div>
      <CustomDialog
        title="Avinstaller PowerOffice"
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex flex-col gap-8 mt-4">
          <p>Er du sikker på at du ønsker å avinstallere PowerOffice?</p>
          <div>
            <Button
              theme="red"
              onClick={async () => {
                await removeExistingIntegration(companyId);
                setIsClientKeyValidated(false);
                setIsIntegrationSynced(false);
                refetch();
              }}
            >
              Avinstaller
            </Button>
          </div>
        </div>
      </CustomDialog>
    </Card>
  );
};

function IntegrationSettingsPage() {
  const companyId = useCompanyId();

  const existingIntegrationQuery = useQuery({
    queryKey: ["existingIntegration", companyId],
    queryFn: () => checkExistingIntegrations(companyId)
  });

  const { refetch } = existingIntegrationQuery;

  return (
    <PageLayout>
      <ValidatedIntegrationProvider>
        <NewDataFetcher
          query={existingIntegrationQuery}
          onData={({ integrationType, isSynchronized }) => {
            if (!isSynchronized)
              return (
                <CenteredPageLayout>
                  <CardNew title={t("integrations")}>
                    <Text as={"p"}>{t("integrationText")}</Text>
                    <Text size={"2"}>{t("contactUsAt")}</Text>{" "}
                    <Link size={"2"} href={"mailto:hei@skyworker.no"}>
                      hei@skyworker.no
                    </Link>{" "}
                    <Text size={"2"}>{t("toGetStarted")}</Text>
                    <img
                      className={"mt-4 mx-auto rounded-2"}
                      src={Integrations}
                      alt={"Logo til forskjellige regnskapssystemer"}
                    />
                  </CardNew>
                </CenteredPageLayout>
              );

            if (integrationType === 1)
              return <ActiveIntegration refetch={refetch} />;

            return <div>Synchronized & integration is not poweroffice</div>;
          }}
        />
      </ValidatedIntegrationProvider>
    </PageLayout>
  );
}
