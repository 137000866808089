import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  SortableData,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { Callout } from "@radix-ui/themes";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useSearch
} from "@tanstack/react-router";
import { addDays } from "date-fns";
import { Ref, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import PageLayout from "../../../../components/PageLayout";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import ButtonNew from "../../../../components/ds/ButtonNew";
import CardNew from "../../../../components/ds/CardNew";
import { ControlledCheckbox } from "../../../../components/ds/CheckboxNew";
import Combobox, {
  ControlledCombobox
} from "../../../../components/ds/Combobox/Combobox";
import { ControlledDatePicker } from "../../../../components/ds/DatePicker/DatePickerNew";
import PageSection from "../../../../components/ds/PageSection";
import RadixIcon from "../../../../components/ds/RadixIcon";
import Table from "../../../../components/ds/RadixTable/Table";
import TableBody from "../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../components/ds/RadixTable/TableCell";
import TableHeader from "../../../../components/ds/RadixTable/TableHeader";
import TableHeaderCell from "../../../../components/ds/RadixTable/TableHeaderCell";
import { TableRow } from "../../../../components/ds/TableNew";
import { FormTextArea } from "../../../../components/ds/TextArea";
import { FormTextField } from "../../../../components/ds/TextFieldNew";
import { useCompanyId } from "../../../../hooks/useCompanyId";
import useDebounce from "../../../../hooks/useDebounce";
import useDialog from "../../../../hooks/useDialog";
import { useToast } from "../../../../hooks/useToast";
import {
  useGetCustomerById,
  useGetCustomerByResourceId
} from "../../../../service/api/CustomerApiV3";
import { useGetDirectSaleById } from "../../../../service/api/DirectSaleApi";
import {
  createInvoice,
  useGetInvoiceById
} from "../../../../service/api/InvoiceApi";
import { useGetOfferById } from "../../../../service/api/OfferApi";
import {
  useGetOrderById,
  useSearchOrdersAndProjects
} from "../../../../service/api/OrderApi";
import { useGetProductsByCompanyId } from "../../../../service/api/ProductApi";
import { useGetProjectById } from "../../../../service/api/ProjectApiV2";
import { markTimeEntriesAsInvoiced } from "../../../../service/api/TimeEntryApi";
import { formatCurrency } from "../../../../utils/currencyFormatter";
import InvoiceDialog from "./-components/InvoiceDialog";
import SortableInvoiceLine from "./-components/SortableInvoiceLine";
import { mapOfferLines } from "./-components/invoiceUtils";

const createInvoiceSchema = z.object({
  projectId: z.string().optional(),
  orderId: z.string().optional(),
  offerId: z.string().optional(),
  invoiceId: z.string().optional(),
  directSaleId: z.string().optional()
});

const invoiceLineSchema = z.object({
  productNumber: z.string().min(1),
  description: z.string().min(1),
  count: z.coerce.number(),
  unit: z.string().min(1),
  price: z.coerce.number(),
  discount: z.coerce.number(),
  vat: z.enum(["25", "15", "12", "0"]),
  isFreeText: z.boolean(),
  resource: z
    .object({
      id: z.string(),
      type: z.enum(["TimeEntry", "Product"])
    })
    .optional()
});

const invoiceSchema = z.object({
  resourceId: z.string().min(1),
  resourceType: z.enum(["Project", "Order", "Other"]),
  customerId: z.string().min(1),
  poNumber: z.string(),
  deliveryDate: z.date(),
  dueDate: z.date(),
  title: z.string().min(1),
  description: z.string(),
  updateAllPrices: z.boolean(),
  lines: z.array(invoiceLineSchema)
});

const vatMap: Record<string, "0" | "12" | "15" | "25"> = {
  vat0: "0",
  vat12: "12",
  vat15: "15",
  vat25: "25"
};

type VatAmount = {
  amount: number;
  vatAmount: number;
};

export type InvoiceLineData = z.infer<typeof invoiceLineSchema>;
export type InvoiceFormData = z.infer<typeof invoiceSchema>;

export const Route = createFileRoute(
  "/_protected/dashboard/economy/invoice/new"
)({
  validateSearch: createInvoiceSchema,
  component: CreateInvoicePage
});

export function CreateInvoicePage() {
  const companyId = useCompanyId();
  const { showSuccessToast, showErrorToast } = useToast();
  const queryClient = useQueryClient();
  const [resourceSearchQuery, setResourceSearchQuery] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const invoiceDialog = useDialog();
  const debouncedProductSearch = useDebounce(productSearchQuery, 500);
  const debouncedResourceSearch = useDebounce(resourceSearchQuery, 500);
  const { t } = useTranslation();

  const { projectId, orderId, offerId, directSaleId, invoiceId } = useSearch({
    from: "/_protected/dashboard/economy/invoice/new"
  });
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting }
  } = useForm<InvoiceFormData>({
    resolver: zodResolver(invoiceSchema),
    defaultValues: {
      resourceId: "",
      title: "",
      description: "",
      updateAllPrices: false,
      resourceType: "Other",
      poNumber: "",
      customerId: "",
      deliveryDate: new Date(),
      dueDate: addDays(new Date(), 14),
      lines: []
    }
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "lines"
  });

  async function handleCopyInvoice(invoiceId: string) {
    const invoice = await queryClient.fetchQuery(
      useGetInvoiceById.getOptions({ invoiceId, companyId })
    );

    const resourceId = invoice.orderId || invoice.projectId;
    if (!resourceId) {
      return;
    }
  }

  async function init() {
    if (invoiceId) {
      handleCopyInvoice(invoiceId);
      return;
    }

    const resourceId = orderId || projectId;
    if (resourceId) {
      const customer = await queryClient.fetchQuery(
        useGetCustomerByResourceId.getOptions({
          companyId,
          resourceId
        })
      );

      setValue("resourceId", resourceId);
      setValue("resourceType", orderId ? "Order" : "Project");
      setValue("customerId", customer?.id ?? "");

      if (!customer?.id) {
        return;
      }

      if (orderId) {
        const order = await queryClient.fetchQuery(
          useGetOrderById.getOptions({ companyId, orderId: resourceId })
        );

        if (order.priceType === "Fixed") {
          append({
            productNumber: "999",
            description: t("fixedPrice"),
            count: 1,
            price: order.price,
            unit: "stk",
            discount: 0,
            vat: "25",
            isFreeText: false
          });
        }
      }

      invoiceDialog.onOpen();
    } else if (offerId) {
      const offer = await queryClient.fetchQuery({
        ...useGetOfferById.getOptions({ offerId, companyId })
      });

      setValue("title", offer.name);
      setValue("resourceId", "Tilbud");
      setValue("customerId", offer.customer.id);
      const lines = mapOfferLines(offer.sections);
      append(lines);
    } else if (directSaleId) {
      const sale = await queryClient.fetchQuery(
        useGetDirectSaleById.getOptions({
          companyId,
          saleId: directSaleId
        })
      );

      setValue("resourceId", "Direktesalg");
      setValue("customerId", sale.customer.id);
      sale.lines.forEach((line) => {
        append({
          productNumber: "999",
          price: line.price,
          count: line.quantity,
          description: line.name,
          unit: "stk.",
          vat: vatMap[line.vat],
          discount: line.discount,
          isFreeText: false
        });
      });
    }
  }

  useEffect(() => {
    init();
  }, []);

  const lines = watch("lines");
  const watchedCustomerId = watch("customerId");
  const watchedResourceId = watch("resourceId");
  const watchedResourceType = watch("resourceType");
  const watchedUpdateAllPrices = watch("updateAllPrices");

  const { isFetching, resourceOptions } = useSearchOrdersAndProjects(
    companyId,
    debouncedResourceSearch
  );

  const productQuery = useGetProductsByCompanyId({
    placeholderData: keepPreviousData,
    variables: {
      companyId: companyId,
      query: debouncedProductSearch,
      archived: false
    }
  });

  const products = productQuery.data?.products ?? [];

  async function onSubmit(data: InvoiceFormData) {
    if (lines.length === 0) {
      showErrorToast(t("atLeastOneLineMustBeAdded"));
      return;
    }

    const customer = await queryClient.ensureQueryData(
      useGetCustomerById.getOptions({
        customerId: data.customerId,
        companyId
      })
    );

    let orderId;
    let projectId;
    let projectNumber;
    if (data.resourceType === "Order") {
      orderId = data.resourceId;
    } else if (data.resourceType === "Project") {
      const project = await queryClient.ensureQueryData(
        useGetProjectById.getOptions({
          projectId: data.resourceId
        })
      );
      projectId = project.id;
      projectNumber = project.prefixedProjectNumber;
    }

    try {
      await createInvoice(companyId, {
        title: data.title,
        description: data.description,
        dueDate: data.dueDate.toISOString(),
        deliveryDate: data.deliveryDate.toISOString(),
        poNumber: data.poNumber,
        orderId: orderId,
        projectId: projectId,
        prefixedProjectNumber: projectNumber,
        lines: data.lines.map((line) => ({
          productNumber: line.productNumber,
          description: line.description,
          price: line.price,
          count: line.count,
          unit: line.unit,
          discountPercent: line.discount,
          vatPercent: +line.vat
        })),
        customer: {
          id: customer.id,
          email: customer.primaryContact?.email,
          address: customer.primaryAddress?.address,
          mobilePhone: customer.primaryContact?.mobilePhone,
          postalCode: customer.primaryAddress?.postalCode,
          postalArea: customer.primaryAddress?.postalArea,
          firstName: customer.primaryContact?.firstName ?? "",
          lastName: customer.primaryContact?.lastName ?? "",
          workPhone: customer.primaryContact?.workPhone,
          customerNumber: customer.customerNumber ?? 0,
          company:
            customer.orgNumber && customer.companyName
              ? {
                  companyName: customer.companyName,
                  orgNumber: customer.orgNumber
                }
              : undefined
        }
      });

      const timeEntryIds = data.lines.flatMap((line) =>
        line.resource?.type === "TimeEntry" ? [line.resource.id] : []
      );
      if (timeEntryIds.length > 0) {
        await markTimeEntriesAsInvoiced(companyId, timeEntryIds);
      }

      showSuccessToast(t("Faktura opprettet"));
      await navigate({
        to: "/dashboard/economy/invoice"
      });
    } catch (_) {}
  }

  function handlePriceChange(productNumber: string, price: number) {
    if (!watchedUpdateAllPrices || productNumber === "") return;

    lines.forEach((line, index) => {
      if (line.productNumber === productNumber) {
        setValue(`lines.${index}.price`, price);
      }
    });
  }

  function calculateLinePrice(line: InvoiceLineData) {
    return (line.price - (line.discount / 100) * line.price) * line.count;
  }

  const vatRates = new Map<string, VatAmount>();

  const netTotal =
    lines?.reduce((acc, line) => {
      const linePrice = calculateLinePrice(line);
      const vatAmount = (+line.vat / 100) * linePrice;

      vatRates.set(line.vat, {
        vatAmount: (vatRates.get(line.vat)?.vatAmount || 0) + vatAmount,
        amount: (vatRates.get(line.vat)?.amount || 0) + linePrice
      });

      return acc + linePrice;
    }, 0) || 0;

  const vatTotal = Array.from(vatRates.values()).reduce(
    (acc, { vatAmount }) => acc + vatAmount,
    0
  );

  const grandTotal = netTotal + vatTotal;

  function onDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fromData = active.data.current as SortableData;
      const toData = over.data.current as SortableData;
      move(fromData.sortable.index, toData.sortable.index);
    }
  }

  const isLocked =
    Boolean(projectId) ||
    Boolean(orderId) ||
    Boolean(directSaleId) ||
    Boolean(offerId);

  const isMissingCustomer = !watchedCustomerId && Boolean(watchedResourceId);

  const canFetchData =
    Boolean(watchedResourceId) &&
    Boolean(watchedCustomerId) &&
    watchedResourceType !== "Other";

  return (
    <>
      {canFetchData && (
        <InvoiceDialog
          customerId={watchedCustomerId}
          open={invoiceDialog.isOpen}
          onClose={invoiceDialog.onClose}
          resourceId={watchedResourceId}
          resourceType={watchedResourceType}
          onAdd={(lines) => {
            append(lines);
          }}
        />
      )}
      <NewPageTitle title={t("createInvoice")} withBackButton />
      <PageLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageSection>
            <CardNew title={t("invoice")}>
              <ControlledCombobox
                required
                disabled={isLocked}
                label={t("projectOrOrder")}
                errorMessage={errors.resourceId?.message}
                loading={isFetching}
                placeholder={t("searchForProjectOrOrder")}
                control={control}
                fieldName={"resourceId"}
                options={resourceOptions}
                onInputChange={setResourceSearchQuery}
                getLabel={(r) => r.title}
                getValue={(r) => r.id}
                onValueChange={(v) => {
                  if (v) {
                    const resource = resourceOptions.find((r) => r.id === v);
                    if (!resource) return;
                    setValue("resourceType", resource.type);
                    setValue("customerId", resource.customerId ?? "");
                  } else {
                    setValue("resourceType", "Other");
                    setValue("customerId", "");
                  }
                }}
              />
              {isMissingCustomer && (
                <Callout.Root color={"amber"} className={"mb-4"} size={"1"}>
                  <Callout.Icon>
                    <RadixIcon icon={"warning"} />
                  </Callout.Icon>
                  <Callout.Text>{t("cusotmerIsMissing")}</Callout.Text>
                </Callout.Root>
              )}
              <ButtonNew
                variant={"soft"}
                className={"mb-4"}
                disabled={!canFetchData}
                onClick={invoiceDialog.onOpen}
              >
                {t("fetchInvoiceData")}
              </ButtonNew>
              <div className={"grid grid-cols-2 gap-x-4"}>
                <FormTextField
                  {...register("title")}
                  label={t("title")}
                  required
                  errorMessage={errors.title?.message}
                />
                <FormTextField
                  {...register("poNumber")}
                  label={t("invoiceReference")}
                />
                <ControlledDatePicker
                  type={"single"}
                  control={control}
                  fieldName={"deliveryDate"}
                  trigger={(ref, value) => (
                    <FormTextField
                      icon={"calendar_today"}
                      wrapperClassName={"grow"}
                      label={t("deliveryDate")}
                      ref={ref as Ref<HTMLInputElement>}
                      value={value}
                      className={"withoutReadonlyStyles"}
                    />
                  )}
                />
                <ControlledDatePicker
                  type={"single"}
                  control={control}
                  fieldName={"dueDate"}
                  trigger={(ref, value) => (
                    <FormTextField
                      wrapperClassName={"grow"}
                      icon={"calendar_today"}
                      label={t("dueDate")}
                      ref={ref as Ref<HTMLInputElement>}
                      value={value}
                      className={"withoutReadonlyStyles"}
                    />
                  )}
                />
              </div>
              <FormTextArea
                {...register("description")}
                label={t("description")}
              />
            </CardNew>
          </PageSection>
          <PageSection size={"xl"}>
            <CardNew
              inset
              title={t("productLines")}
              button={
                <ButtonNew type={"submit"} loading={isSubmitting}>
                  {t("createInvoice")}
                </ButtonNew>
              }
              headerContent={
                <Combobox
                  size={"2"}
                  skipLocalFiltering
                  clearOnSelect
                  className={"w-[400px]"}
                  placeholder={t("searchForProduct")}
                  onInputChange={setProductSearchQuery}
                  options={products}
                  getLabel={(p) => `${p.name} - (${p.item_number})`}
                  getValue={(p) => p.id}
                  onValueChange={(id) => {
                    const product = products.find((p) => p.id === id);
                    if (!product) return;
                    append({
                      productNumber: product.item_number,
                      description: product.name,
                      price: product.price,
                      unit: product.unit.name,
                      discount: 0,
                      count: 1,
                      vat: "25",
                      isFreeText: false
                    });
                  }}
                />
              }
            >
              <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                  items={fields}
                  strategy={verticalListSortingStrategy}
                >
                  <Table variant={"ghost"}>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell>{t("product")}</TableHeaderCell>
                        <TableHeaderCell>{t("description")}</TableHeaderCell>
                        <TableHeaderCell>{t("count")}</TableHeaderCell>
                        <TableHeaderCell>{t("unit")}</TableHeaderCell>
                        <TableHeaderCell>{t("price")}</TableHeaderCell>
                        <TableHeaderCell>{t("discount")}</TableHeaderCell>
                        <TableHeaderCell>{t("vat")}</TableHeaderCell>
                        <TableHeaderCell align={"right"}>
                          {t("netAmount")}
                        </TableHeaderCell>
                        <TableHeaderCell />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {fields.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={9}>{t("noLinesAdded")}</TableCell>
                        </TableRow>
                      )}
                      {fields.map((line, index) => (
                        <SortableInvoiceLine
                          key={line.id}
                          id={line.id}
                          isFreeText={line.isFreeText}
                          index={index}
                          register={register}
                          errors={errors}
                          control={control}
                          onRemove={() => remove(index)}
                          linePrice={calculateLinePrice(lines[index])}
                          onPriceChange={(price) =>
                            handlePriceChange(lines[index].productNumber, price)
                          }
                        />
                      ))}
                      <TableRow className={"bg-radix-gray-a2"}>
                        <TableCell colSpan={10}>
                          <div className={"flex items-center gap-4"}>
                            <ButtonNew
                              icon={"add"}
                              variant={"soft"}
                              onClick={() =>
                                append(
                                  {
                                    productNumber: "999",
                                    description: "",
                                    unit: "",
                                    vat: "25",
                                    discount: 0,
                                    price: 100,
                                    count: 1,
                                    isFreeText: true
                                  },
                                  {
                                    focusName: `lines.${fields.length}.description`
                                  }
                                )
                              }
                            >
                              {t("newFreeTextLine")}
                            </ButtonNew>
                            <ControlledCheckbox
                              control={control}
                              fieldName={"updateAllPrices"}
                              label={t("updatePricesOnAllLines")}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow className={"bg-radix-gray-a2"}>
                        <TableCell colSpan={8}>{t("netAmount")}</TableCell>
                        <TableCell align={"right"}>
                          {formatCurrency(netTotal)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {Array.from(vatRates).map(([vatRate, vat]) => (
                        <TableRow className={"bg-radix-gray-a2"} key={vatRate}>
                          <TableCell colSpan={8}>
                            {vatRate}% {t("mva av")}{" "}
                            {formatCurrency(vat.amount)}
                          </TableCell>
                          <TableCell align={"right"}>
                            <div>{formatCurrency(vat.vatAmount)}</div>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={8} className={"font-bold"}>
                          {t("toPay")}
                        </TableCell>
                        <TableCell align={"right"}>
                          {formatCurrency(grandTotal)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </SortableContext>
              </DndContext>
            </CardNew>
          </PageSection>
        </form>
      </PageLayout>
    </>
  );
}
