/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Deviation } from '../model';
// @ts-ignore
import { DeviationPostRequest } from '../model';
// @ts-ignore
import { DeviationPutRequest } from '../model';
// @ts-ignore
import { DeviationStatusRequest } from '../model';
// @ts-ignore
import { PaginatedResponseDeviation } from '../model';
// @ts-ignore
import { PaginatedResponseDeviationWithResource } from '../model';
/**
 * DeviationApi - axios parameter creator
 * @export
 */
export const DeviationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the specified deviation
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviation: async (deviationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('deleteDeviation', 'deviationId', deviationId)
            const localVarPath = `/v1/deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all deviations assigned to a user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeviationsByAssignedUser: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllDeviationsByAssignedUser', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/deviation/assigned`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public deviations by resource ID
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicDeviationsByProjectId: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAllPublicDeviationsByProjectId', 'projectId', projectId)
            const localVarPath = `/v1/open/deviations/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deviation by ID
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationById: async (deviationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('getDeviationById', 'deviationId', deviationId)
            const localVarPath = `/v1/deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deviations on a project and all its tasks
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationsByProjectId: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDeviationsByProjectId', 'projectId', projectId)
            const localVarPath = `/v1/project/{projectId}/deviation`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deviations on a task or project
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationsOnResource: async (resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getDeviationsOnResource', 'resourceId', resourceId)
            const localVarPath = `/v1/deviation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all deviations for a company (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'order' | 'project'} [resourceType] 
         * @param {'open' | 'resolved'} [deviationStatus] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDeviationsOnCompany: async (companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, resourceType?: 'order' | 'project', deviationStatus?: 'open' | 'resolved', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedDeviationsOnCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/deviation/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }

            if (deviationStatus !== undefined) {
                localVarQueryParameter['deviationStatus'] = deviationStatus;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all deviations on a resource (paginated)
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Low' | 'Medium' | 'High'} [priority] 
         * @param {'open' | 'resolved'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDeviationsOnResource: async (resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, priority?: 'Low' | 'Medium' | 'High', status?: 'open' | 'resolved', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getPaginatedDeviationsOnResource', 'resourceId', resourceId)
            const localVarPath = `/v1/resource/{resourceId}/deviation`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public deviation by ID
         * @param {string} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDeviationById: async (deviationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('getPublicDeviationById', 'deviationId', deviationId)
            const localVarPath = `/v1/open/deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the specified deviation
         * @param {string} deviationId 
         * @param {DeviationPutRequest} deviationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviation: async (deviationId: string, deviationPutRequest: DeviationPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('patchDeviation', 'deviationId', deviationId)
            // verify required parameter 'deviationPutRequest' is not null or undefined
            assertParamExists('patchDeviation', 'deviationPutRequest', deviationPutRequest)
            const localVarPath = `/v1/deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviationPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the deviation status of  the specified deviation
         * @param {string} deviationId 
         * @param {DeviationStatusRequest} deviationStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviationStatus: async (deviationId: string, deviationStatusRequest: DeviationStatusRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('patchDeviationStatus', 'deviationId', deviationId)
            // verify required parameter 'deviationStatusRequest' is not null or undefined
            assertParamExists('patchDeviationStatus', 'deviationStatusRequest', deviationStatusRequest)
            const localVarPath = `/v1/deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviationStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new deviation on a project or task
         * @param {DeviationPostRequest} deviationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviation: async (deviationPostRequest: DeviationPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationPostRequest' is not null or undefined
            assertParamExists('postDeviation', 'deviationPostRequest', deviationPostRequest)
            const localVarPath = `/v1/deviation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviationPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviationApi - functional programming interface
 * @export
 */
export const DeviationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the specified deviation
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviation(deviationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviation(deviationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all deviations assigned to a user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDeviationsByAssignedUser(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deviation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDeviationsByAssignedUser(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all public deviations by resource ID
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublicDeviationsByProjectId(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deviation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublicDeviationsByProjectId(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deviation by ID
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviationById(deviationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deviation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviationById(deviationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deviations on a project and all its tasks
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviationsByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deviation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviationsByProjectId(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deviations on a task or project
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviationsOnResource(resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deviation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviationsOnResource(resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all deviations for a company (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'order' | 'project'} [resourceType] 
         * @param {'open' | 'resolved'} [deviationStatus] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedDeviationsOnCompany(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, resourceType?: 'order' | 'project', deviationStatus?: 'open' | 'resolved', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseDeviationWithResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedDeviationsOnCompany(companyId, page, sortDirection, sortField, query, pageSize, resourceType, deviationStatus, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all deviations on a resource (paginated)
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Low' | 'Medium' | 'High'} [priority] 
         * @param {'open' | 'resolved'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedDeviationsOnResource(resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, priority?: 'Low' | 'Medium' | 'High', status?: 'open' | 'resolved', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseDeviation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedDeviationsOnResource(resourceId, page, sortDirection, sortField, query, pageSize, priority, status, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public deviation by ID
         * @param {string} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicDeviationById(deviationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deviation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicDeviationById(deviationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the specified deviation
         * @param {string} deviationId 
         * @param {DeviationPutRequest} deviationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDeviation(deviationId: string, deviationPutRequest: DeviationPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deviation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDeviation(deviationId, deviationPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the deviation status of  the specified deviation
         * @param {string} deviationId 
         * @param {DeviationStatusRequest} deviationStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDeviationStatus(deviationId: string, deviationStatusRequest: DeviationStatusRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDeviationStatus(deviationId, deviationStatusRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new deviation on a project or task
         * @param {DeviationPostRequest} deviationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviation(deviationPostRequest: DeviationPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deviation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviation(deviationPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviationApi - factory interface
 * @export
 */
export const DeviationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviationApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the specified deviation
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviation(deviationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDeviation(deviationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all deviations assigned to a user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeviationsByAssignedUser(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<Deviation>> {
            return localVarFp.getAllDeviationsByAssignedUser(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public deviations by resource ID
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicDeviationsByProjectId(projectId: string, options?: any): AxiosPromise<Array<Deviation>> {
            return localVarFp.getAllPublicDeviationsByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deviation by ID
         * @param {string} deviationId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationById(deviationId: string, authorization?: string, options?: any): AxiosPromise<Deviation> {
            return localVarFp.getDeviationById(deviationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deviations on a project and all its tasks
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationsByProjectId(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<Deviation>> {
            return localVarFp.getDeviationsByProjectId(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deviations on a task or project
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviationsOnResource(resourceId: string, authorization?: string, options?: any): AxiosPromise<Array<Deviation>> {
            return localVarFp.getDeviationsOnResource(resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all deviations for a company (paginated)
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'order' | 'project'} [resourceType] 
         * @param {'open' | 'resolved'} [deviationStatus] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDeviationsOnCompany(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, resourceType?: 'order' | 'project', deviationStatus?: 'open' | 'resolved', authorization?: string, options?: any): AxiosPromise<PaginatedResponseDeviationWithResource> {
            return localVarFp.getPaginatedDeviationsOnCompany(companyId, page, sortDirection, sortField, query, pageSize, resourceType, deviationStatus, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all deviations on a resource (paginated)
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
         * @param {string} [query] 
         * @param {number} [pageSize] 
         * @param {'Low' | 'Medium' | 'High'} [priority] 
         * @param {'open' | 'resolved'} [status] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDeviationsOnResource(resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, priority?: 'Low' | 'Medium' | 'High', status?: 'open' | 'resolved', authorization?: string, options?: any): AxiosPromise<PaginatedResponseDeviation> {
            return localVarFp.getPaginatedDeviationsOnResource(resourceId, page, sortDirection, sortField, query, pageSize, priority, status, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public deviation by ID
         * @param {string} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDeviationById(deviationId: string, options?: any): AxiosPromise<Deviation> {
            return localVarFp.getPublicDeviationById(deviationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the specified deviation
         * @param {string} deviationId 
         * @param {DeviationPutRequest} deviationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviation(deviationId: string, deviationPutRequest: DeviationPutRequest, authorization?: string, options?: any): AxiosPromise<Deviation> {
            return localVarFp.patchDeviation(deviationId, deviationPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the deviation status of  the specified deviation
         * @param {string} deviationId 
         * @param {DeviationStatusRequest} deviationStatusRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviationStatus(deviationId: string, deviationStatusRequest: DeviationStatusRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.patchDeviationStatus(deviationId, deviationStatusRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new deviation on a project or task
         * @param {DeviationPostRequest} deviationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviation(deviationPostRequest: DeviationPostRequest, authorization?: string, options?: any): AxiosPromise<Deviation> {
            return localVarFp.postDeviation(deviationPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviationApi - object-oriented interface
 * @export
 * @class DeviationApi
 * @extends {BaseAPI}
 */
export class DeviationApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the specified deviation
     * @param {string} deviationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public deleteDeviation(deviationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).deleteDeviation(deviationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all deviations assigned to a user
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getAllDeviationsByAssignedUser(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getAllDeviationsByAssignedUser(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public deviations by resource ID
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getAllPublicDeviationsByProjectId(projectId: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getAllPublicDeviationsByProjectId(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deviation by ID
     * @param {string} deviationId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getDeviationById(deviationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getDeviationById(deviationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deviations on a project and all its tasks
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getDeviationsByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getDeviationsByProjectId(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deviations on a task or project
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getDeviationsOnResource(resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getDeviationsOnResource(resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all deviations for a company (paginated)
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {'order' | 'project'} [resourceType] 
     * @param {'open' | 'resolved'} [deviationStatus] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getPaginatedDeviationsOnCompany(companyId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, resourceType?: 'order' | 'project', deviationStatus?: 'open' | 'resolved', authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getPaginatedDeviationsOnCompany(companyId, page, sortDirection, sortField, query, pageSize, resourceType, deviationStatus, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all deviations on a resource (paginated)
     * @param {string} resourceId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority'} [sortField] 
     * @param {string} [query] 
     * @param {number} [pageSize] 
     * @param {'Low' | 'Medium' | 'High'} [priority] 
     * @param {'open' | 'resolved'} [status] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getPaginatedDeviationsOnResource(resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'DeviationNumber' | 'Title' | 'Created' | 'Type' | 'CreatedBy' | 'Cost' | 'Status' | 'Priority', query?: string, pageSize?: number, priority?: 'Low' | 'Medium' | 'High', status?: 'open' | 'resolved', authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getPaginatedDeviationsOnResource(resourceId, page, sortDirection, sortField, query, pageSize, priority, status, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public deviation by ID
     * @param {string} deviationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public getPublicDeviationById(deviationId: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).getPublicDeviationById(deviationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the specified deviation
     * @param {string} deviationId 
     * @param {DeviationPutRequest} deviationPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public patchDeviation(deviationId: string, deviationPutRequest: DeviationPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).patchDeviation(deviationId, deviationPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the deviation status of  the specified deviation
     * @param {string} deviationId 
     * @param {DeviationStatusRequest} deviationStatusRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public patchDeviationStatus(deviationId: string, deviationStatusRequest: DeviationStatusRequest, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).patchDeviationStatus(deviationId, deviationStatusRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new deviation on a project or task
     * @param {DeviationPostRequest} deviationPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationApi
     */
    public postDeviation(deviationPostRequest: DeviationPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return DeviationApiFp(this.configuration).postDeviation(deviationPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
