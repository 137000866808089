import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import DeviationReportPage from "../../../../../pages/timeInvoice/DeviationReportPage";
import { z } from "zod";
import PageLayout from "../../../../../components/PageLayout";

const projectDeviationReportSchema = z.object({
  deviationStatus: z.enum(["open", "resolved"]).optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/_layout/deviation-report"
)({
  validateSearch: projectDeviationReportSchema,
  component: ProjectDeviationReportPage
});

function ProjectDeviationReportPage() {
  return (
    <PageLayout>
      <DeviationReportPage resourceType={"project"} />
    </PageLayout>
  );
}
