import * as Sentry from "@sentry/react";
import axios from "axios";
import { getAuth } from "firebase/auth";

import environment from "../../environment";
import { handleAxiosError } from "../ErrorHandler";

export const instance = axios.create({
  baseURL: environment.skwApiNewBaseUrl
});

instance.interceptors.request.use(async (req) => {
  const token = await getAuth().currentUser?.getIdToken();
  req.headers["Content-Type"] = "application/json";
  req.headers.Authorization = `Bearer ${token}`;

  return req;
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    Sentry.captureException(err);
    return handleAxiosError(err);
  }
);
