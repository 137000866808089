import { operations, paths } from "../../.generated/api-ts";
import createClient, { Middleware } from "openapi-fetch";
import environment from "../../environment";
import { getAuth } from "firebase/auth";

export type Merged<T extends keyof operations> = {
  query: operations[T]["parameters"]["query"];
  path: operations[T]["parameters"]["path"];
};

const middleware: Middleware = {
  async onRequest({ request }) {
    const token = await getAuth().currentUser?.getIdToken();
    request.headers.set("Authorization", `Bearer ${token}`);
    return request;
  }
};

export const client = createClient<paths>({
  baseUrl: environment.skwApiNewBaseUrl
});

client.use(middleware);
