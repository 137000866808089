import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import UpsertCustomerPage from "../-components/UpsertCustomerPage";
import { useGetCustomerById } from "../../../../../service/api/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/edit"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerById.getOptions({ customerId, companyId })
    });
  },
  component: UpdateCustomerPage
});

function UpdateCustomerPage() {
  const customer = useLoaderData({
    from: "/_protected/dashboard/customers/$customerId/edit"
  });
  return <UpsertCustomerPage customer={customer} />;
}
