import { useOfferStore } from "./-components/store";
import { useEffect } from "react";
import DetailsPage from "./-components/DetailsPage";
import { z } from "zod";

import { createFileRoute, useSearch } from "@tanstack/react-router";

const offerPreviewSchema = z.object({
  offerData: z.string()
});

export const Route = createFileRoute("/_unauthenticated/offer/preview")({
  validateSearch: offerPreviewSchema,
  component: () => OfferPreviewPage
});

function OfferPreviewPage() {
  const { offerData } = useSearch({
    from: "/_unauthenticated/offer/preview"
  });

  const initializeAsPreview = useOfferStore(
    (state) => state.initializeAsPreview
  );
  const offer = useOfferStore((state) => state.offer);
  const error = useOfferStore((state) => state.error);

  useEffect(() => {
    initializeAsPreview(offerData as string);
  }, [initializeAsPreview, offerData]);

  if (offer) {
    return <DetailsPage />;
  } else if (error) {
    return <p>{error.message}</p>;
  } else {
    return <p>En feil oppstod</p>;
  }
}
