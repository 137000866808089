import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import useDialog from "../../../../../hooks/useDialog";
import React, { useState } from "react";
import { OrderStatus, OrderTag } from "../../../../../.generated/api";
import { useToast } from "../../../../../hooks/useToast";
import {
  deleteCompanyOrderStatus,
  deleteCompanyOrdertag,
  StaticOrderStatuses,
  useGetOrderStatusesByCompanyId,
  useGetOrderTagsByCompanyId
} from "../../../../../service/api/OrderApi";
import OrderStatusDialog from "./-components/OrderStatusDialog";
import OrderTagDialog from "./-components/OrderTagDialog";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import { Tooltip } from "@radix-ui/themes";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import DeleteBlock from "../../../../../components/buttons/DeleteBlock";
import PageLayout from "../../../../../components/PageLayout";
import FormSection from "../../../../../components/ds/layout/FormSection";
import FormContainer from "../../../../../components/ds/layout/FormContainer";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/order"
)({
  component: OrderSettingsPage
});

function OrderSettingsPage() {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const statusDialog = useDialog();
  const tagDialog = useDialog();
  const [selectedStatus, setSelectedStatus] = useState<OrderStatus>();
  const [selectedTag, setSelectedTag] = useState<OrderTag>();

  const { showSuccessToast, showErrorToast } = useToast();

  const orderStatusQuery = useGetOrderStatusesByCompanyId({
    variables: { companyId }
  });

  const orderTagsQuery = useGetOrderTagsByCompanyId({
    variables: { companyId }
  });

  const orderStatuses = orderStatusQuery.data ?? [];
  const orderTags = orderTagsQuery.data ?? [];

  return (
    <PageLayout>
      <OrderStatusDialog
        onClose={statusDialog.onClose}
        open={statusDialog.isOpen}
        selectedStatus={selectedStatus}
        onUpdated={() => {
          orderStatusQuery.refetch();
          setSelectedStatus(undefined);
        }}
      />
      <OrderTagDialog
        onUpdated={orderTagsQuery.refetch}
        onClose={tagDialog.onClose}
        open={tagDialog.isOpen}
        selectedOrderTag={selectedTag}
      />
      <FormContainer>
        <FormSection
          withSeparator
          title={t("orderStatuses")}
          description={t("orderStatusDescription")}
          content={
            <CardNew
              inset
              trailing={
                <ButtonNew
                  size={"1"}
                  icon={"add"}
                  onClick={statusDialog.onOpen}
                >
                  {t("add")}
                </ButtonNew>
              }
            >
              <Table variant={"ghost"}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t("title")}</TableHeaderCell>
                    <TableHeaderCell>{t("description")}</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orderStatuses.map((status) => (
                    <TableRow key={status.id}>
                      <TableCell>{status.title}</TableCell>
                      <TableCell>{status.description}</TableCell>
                      <TableCell
                        align={"right"}
                        py={"0"}
                        className={"align-middle"}
                      >
                        <div
                          className={
                            "invisible group-hover/tr:visible flex gap-4 justify-end"
                          }
                        >
                          {StaticOrderStatuses.includes(status.title) && (
                            <Tooltip content={t("cannotDeleteSystemStatus")}>
                              <IconButton
                                icon={"delete"}
                                disabled
                                variant={"ghost"}
                              />
                            </Tooltip>
                          )}
                          {!StaticOrderStatuses.includes(status.title) && (
                            <>
                              <IconButton
                                icon="edit"
                                variant={"ghost"}
                                onClick={() => {
                                  setSelectedStatus(status);
                                  statusDialog.onOpen();
                                }}
                              />
                              <DeleteBlock
                                title={t("deleteOrderStatus")}
                                confirmationText={t(
                                  "deleteOrderStatusConfirmation"
                                )}
                                deleteFunction={async () => {
                                  try {
                                    await deleteCompanyOrderStatus(
                                      status.id,
                                      companyId
                                    );
                                  } catch (_) {
                                    showErrorToast(t("statusInUse"));
                                  }
                                }}
                                onDeleted={() => {
                                  orderStatusQuery.refetch();
                                  showSuccessToast(t("statusDeleted"));
                                }}
                                render={(onClick) => (
                                  <IconButton
                                    icon="delete"
                                    onClick={onClick}
                                    variant={"ghost"}
                                  />
                                )}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardNew>
          }
        />
        <FormSection
          title={t("orderTags")}
          description={t("orderTagsDescription")}
          content={
            <CardNew
              inset
              className={"mt-6"}
              trailing={
                <ButtonNew
                  icon={"add"}
                  onClick={() => tagDialog.onOpen()}
                  size={"1"}
                >
                  {t("add")}
                </ButtonNew>
              }
            >
              <Table variant={"ghost"}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t("title")}</TableHeaderCell>
                    <TableHeaderCell>{t("description")}</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orderTags.length === 0 && (
                    <TableRow>
                      <TableCell>{t("noTagsAdded")}</TableCell>
                    </TableRow>
                  )}
                  {orderTags.map((tag) => (
                    <TableRow key={tag.id}>
                      <TableCell>{tag.title}</TableCell>
                      <TableCell>{tag.description}</TableCell>
                      <TableCell py={"0"} className={"align-middle"}>
                        <div
                          className={
                            "invisible group-hover/tr:visible flex gap-4 justify-end"
                          }
                        >
                          <IconButton
                            icon="edit"
                            variant={"ghost"}
                            onClick={() => {
                              setSelectedTag(tag);
                              tagDialog.onOpen();
                            }}
                          />
                          <DeleteBlock
                            title={t("deleteTag")}
                            deleteFunction={() =>
                              deleteCompanyOrdertag(tag.id, companyId)
                            }
                            confirmationText={t("deleteTagConfirmation")}
                            onDeleted={() => {
                              orderTagsQuery.refetch();
                              showSuccessToast(t("tagDeleted"));
                            }}
                            render={(onClick) => (
                              <IconButton
                                icon="delete"
                                variant={"ghost"}
                                onClick={onClick}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardNew>
          }
        />
      </FormContainer>
    </PageLayout>
  );
}
