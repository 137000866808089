import { createFileRoute } from "@tanstack/react-router";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import useDialog from "../../../../../hooks/useDialog";
import { useState } from "react";
import { EquipmentCategory } from "../../../../../.generated/api";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/useToast";
import {
  deleteEquipmentCategory,
  useGetEquipmentCategoriesByCompanyId
} from "../../../../../service/api/EquipmentApi";
import EquipmentCategoryDialog from "./-components/EquipmentCategoryDialog";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import IconButton from "../../../../../components/ds/buttons/IconButton";
import DeleteBlock from "../../../../../components/buttons/DeleteBlock";
import { ApiError } from "../../../../../service/ErrorHandler";
import PageLayout from "../../../../../components/PageLayout";
import FormContainer from "../../../../../components/ds/layout/FormContainer";
import FormSection from "../../../../../components/ds/layout/FormSection";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/equipment"
)({
  component: EquipmentSettingsPage
});

function EquipmentSettingsPage() {
  const companyId = useCompanyId();
  const { onOpen, onClose, isOpen } = useDialog();
  const [selectedCategory, setSelectedCategory] = useState<EquipmentCategory>();
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToast();

  const equipmentCategoriesQuery = useGetEquipmentCategoriesByCompanyId({
    variables: { companyId }
  });
  const equipmentCategories = equipmentCategoriesQuery.data ?? [];

  return (
    <PageLayout>
      <EquipmentCategoryDialog
        onClose={onClose}
        selectedCategory={selectedCategory}
        open={isOpen}
        onUpdated={() => {
          setSelectedCategory(undefined);
          equipmentCategoriesQuery.refetch();
        }}
      />
      <FormContainer>
        <FormSection
          title={t("equipmentCategories")}
          description={t("equipmentCategoriesDescription")}
          content={
            <CardNew
              inset
              trailing={
                <ButtonNew
                  icon="add"
                  size={"1"}
                  onClick={() => {
                    setSelectedCategory(undefined);
                    onOpen();
                  }}
                >
                  {t("add")}
                </ButtonNew>
              }
            >
              <Table variant={"ghost"}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t("title")}</TableHeaderCell>
                    <TableHeaderCell>{t("description")}</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {equipmentCategories.map((category) => (
                    <TableRow key={category.id}>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>{category.description}</TableCell>
                      <TableCell>
                        <div
                          className={
                            "flex gap-4 justify-end invisible group-hover/tr:visible"
                          }
                        >
                          <IconButton
                            icon="edit"
                            variant={"ghost"}
                            onClick={() => {
                              setSelectedCategory(category);
                              onOpen();
                            }}
                          />
                          <DeleteBlock
                            title={t("deleteCategory")}
                            confirmationText={t("deleteCategoryConfirmation")}
                            deleteFunction={async () => {
                              try {
                                await deleteEquipmentCategory(
                                  companyId,
                                  category.id
                                );
                              } catch (e) {
                                if (
                                  (e as ApiError).errorCode ===
                                  "EquipmentCategoryInUse"
                                ) {
                                  showErrorToast(
                                    t("cannotDeleteCategoryInUse")
                                  );
                                  return;
                                }
                              }
                            }}
                            onDeleted={() => {
                              equipmentCategoriesQuery.refetch();
                              showSuccessToast(t("categoryDeleted"));
                            }}
                            render={(onClick) => (
                              <IconButton
                                icon="delete"
                                variant={"ghost"}
                                onClick={onClick}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardNew>
          }
        />
      </FormContainer>
    </PageLayout>
  );
}
