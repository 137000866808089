import { createFileRoute, Outlet } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import NewPageTitle from "../../../../components/common/NewPageTitle";
import TabNav from "../../../../components/TabNav";
import { TabLink } from "../../../../components/TabLink";

export const Route = createFileRoute("/_protected/dashboard/project/_layout")({
  component: RouteComponent
});

function RouteComponent() {
  const { t } = useTranslation();

  return (
    <>
      <NewPageTitle title={t("projects")} />
      <TabNav>
        <TabLink to={"/dashboard/project/overview"} replace>
          {t("projectOverview")}
        </TabLink>
        <TabLink to={"/dashboard/project/task"} replace>
          {t("tasks")}
        </TabLink>
        <TabLink to={"/dashboard/project/progress-plan"} replace>
          {t("progressPlan")}
        </TabLink>
        <TabLink to={"/dashboard/project/deviation-report"} replace>
          {t("deviationReport")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
