import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { t } from "i18next";
import CenteredPageLayout from "../../../../../components/ds/CenteredPageLayout";
import PageLayout from "../../../../../components/PageLayout";
import CardNew from "../../../../../components/ds/CardNew";
import ButtonNew from "../../../../../components/ds/ButtonNew";
import { Text } from "@radix-ui/themes";

type VideoCardProps = {
  title: string;
  subtitle: string;
  link: string;
};

export const Route = createFileRoute(
  "/_protected/dashboard/settings/_layout/video-guide"
)({
  component: VideoGuideSettingsPage
});

function VideoCard({ link, subtitle, title }: VideoCardProps) {
  return (
    <CardNew>
      <div className={"flex justify-between items-center gap-4"}>
        <div className={"flex flex-col gap-1"}>
          <Text as={"p"} weight={"bold"}>
            {title}
          </Text>
          <Text as={"p"} color={"gray"} className={"max-w-[400px]"}>
            {subtitle}
          </Text>
        </div>
        <ButtonNew asChild variant={"soft"} trailingIcon={"open_in_new"}>
          <a href={link} target={"_blank"} rel="noreferrer">
            Se video
          </a>
        </ButtonNew>
      </div>
    </CardNew>
  );
}

function VideoGuideSettingsPage() {
  return (
    <PageLayout>
      <CenteredPageLayout>
        <CardNew title={t("skyworkerVideoGuide")}>
          <div className={"flex flex-col gap-4"}>
            <VideoCard
              title={t("Velkommen til Skyworker!")}
              subtitle={"Intro til Skyworker"}
              link={
                "https://www.youtube.com/embed/weH51ctMtLQ?si=MD1o1-HreXr_xNwZ"
              }
            />
            <VideoCard
              title={t("getStarted")}
              subtitle={t("Tips og triks for å komme i gang.")}
              link={
                "https://www.youtube.com/embed/A_mRozZJ9NE?si=EOBZ1ZzNr4p1c1AX"
              }
            />
            <VideoCard
              title={t("sales")}
              subtitle={t(
                "Lær hvordan du kan jobbe med salg og digitale tilbud."
              )}
              link={
                "https://www.youtube.com/embed/kGIAEEi6nO8?si=_8ue1rb-zQamKKf6"
              }
            />
            <VideoCard
              title={t("resources")}
              subtitle={t("Inviter dine ansatte.")}
              link={
                "https://www.youtube.com/embed/_Pzvh1hgekg?si=A_5CFYlemCd05amF"
              }
            />
            <VideoCard
              title={t("products")}
              subtitle={t("Bygg din egen produktdatabase i Skyworker.")}
              link={
                "https://www.youtube.com/embed/SVe0W4o9yAc?si=6N_mcOfPjGwjuXGR"
              }
            />
            <VideoCard
              title={t("projects")}
              subtitle={t("Alt du trenger å vite om Skyworker prosjektmodul.")}
              link={
                "https://www.youtube.com/embed/hTeGGZ5T-Fw?si=tlBOo3RgdkpgpDzQ"
              }
            />
            <VideoCard
              title={t("workOrders")}
              subtitle={t("Arbeidsordre - perfekt for mindre oppdrag.")}
              link={
                "https://www.youtube.com/embed/Vcwrn6q3fZc?si=CxuNyDi99hbdDLKA"
              }
            />
            <VideoCard
              title={t("app")}
              subtitle={t(
                "Lær hvordan dine ansatte kan bruke Skyworker effektivt fra vår mobil-applikasjon."
              )}
              link={
                "https://www.youtube.com/embed/kwAqVP_LWlk?si=FbPh7TxXJ0KEfOv7"
              }
            />
            <VideoCard
              title={t("settings")}
              subtitle={t(
                "Definer maler og andre innstillinger for din bedrift."
              )}
              link={
                "https://www.youtube.com/embed/v5gu2fNa628?si=00bMUTk5kRfJXeoA"
              }
            />
          </div>
        </CardNew>
      </CenteredPageLayout>
    </PageLayout>
  );
}
