import { ScrollArea } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import {
  ProductConsumptionOrderV2,
  ProductConsumptionV2
} from "../../../../../.generated/api";
import { CheckboxNew } from "../../../../../components/ds/CheckboxNew";
import PaginationNew from "../../../../../components/ds/PaginationNew";
import Table from "../../../../../components/ds/RadixTable/Table";
import TableBody from "../../../../../components/ds/RadixTable/TableBody";
import TableCell from "../../../../../components/ds/RadixTable/TableCell";
import TableHeader from "../../../../../components/ds/RadixTable/TableHeader";
import TableHeaderCell from "../../../../../components/ds/RadixTable/TableHeaderCell";
import TableRow from "../../../../../components/ds/RadixTable/TableRow";
import { checkboxCellSize } from "../../../../../components/ds/sizes";
import { useCompanyId } from "../../../../../hooks/useCompanyId";
import { UseSelectedReturn } from "../../../../../hooks/useSelected";
import { useSort } from "../../../../../hooks/useSort";
import { useGetOrderProducts } from "../../../../../service/api/OrderProductApi";
import { useGetProjectProducts } from "../../../../../service/api/ProjectProductApi";
import { formatFullNumericDateString } from "../../../../../utils/DateTimeFormatter";
import { formatCurrency } from "../../../../../utils/currencyFormatter";

type InvoiceDialogProductsProps = {
  selection: UseSelectedReturn<GenericProductConsumption>;
  resourceId: string;
  resourceType: "Order" | "Project" | "Other";
};

export type GenericProductConsumption = {
  entryId: string;
  productId: string;
  productName: string;
  productNumber: string;
  unit: string;
  quantity: number;
  price: number;
  added: string;
};

function mapProductConsumption(products?: ProductConsumptionV2[]) {
  if (!products) return [];
  return products.flatMap((product) => {
    const separateEntries = product.project_products ?? [];
    return separateEntries.map((entry) => ({
      entryId: entry.id,
      productId: product.product_number,
      productName: product.product_name,
      productNumber: product.product_number,
      unit: product.unit_name,
      quantity: entry.quantity_used,
      price: entry.product_price,
      added: entry.added_at
    }));
  }) satisfies GenericProductConsumption[];
}

function mapOrderProducts(
  products?: ProductConsumptionOrderV2[]
): GenericProductConsumption[] {
  if (!products) return [];

  return products.flatMap((product) => {
    const separateEntries = product.order_products ?? [];
    return separateEntries.map((entry) => ({
      entryId: entry.id,
      productId: product.product_number,
      productName: product.product_name,
      productNumber: product.product_number,
      unit: product.unit_name,
      quantity: entry.quantity_used,
      price: entry.product_price,
      added: entry.added_at
    }));
  }) satisfies GenericProductConsumption[];
}

export default function InvoiceDialogProducts({
  selection,
  resourceId,
  resourceType
}: InvoiceDialogProductsProps) {
  const companyId = useCompanyId();
  const { t } = useTranslation();
  const { handleSelectAll, handleSelect, allSelected, isSelected } = selection;
  const { page, setPage } = useSort("", "");

  const orderProductQuery = useGetOrderProducts({
    enabled: resourceType === "Order",
    variables: {
      companyId,
      orderId: resourceId,
      page: page
    }
  });

  const projectProductQuery = useGetProjectProducts({
    enabled: resourceType === "Project",
    variables: {
      companyId,
      projectId: resourceId,
      page: page
    }
  });

  const orderProducts = mapOrderProducts(
    orderProductQuery.data?.body?.product_consumption
  );
  const projectProducts = mapProductConsumption(
    projectProductQuery.data?.body?.product_consumption
  );

  const totalPages =
    resourceType === "Order"
      ? orderProductQuery.data?.body?.pagination.total_pages
      : projectProductQuery.data?.body?.pagination.total_pages;
  const products = resourceType === "Order" ? orderProducts : projectProducts;

  return (
    <>
      <ScrollArea className={"min-h-[536px] h-536p mb-4"}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>
                <CheckboxNew
                  checked={allSelected(products)}
                  onClick={() => handleSelectAll(products)}
                />
              </TableHeaderCell>
              <TableHeaderCell>{t("productNumber")}</TableHeaderCell>
              <TableHeaderCell>{t("dateAdded")}</TableHeaderCell>
              <TableHeaderCell>{t("product")}</TableHeaderCell>
              <TableHeaderCell>{t("count")}</TableHeaderCell>
              <TableHeaderCell>{t("unit")}</TableHeaderCell>
              <TableHeaderCell align={"right"}>
                {t("unitPrice")}
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {products.length === 0 && (
              <TableRow>
                <TableCell>{t("noProductsAdded")}</TableCell>
              </TableRow>
            )}
            {products.map((product) => (
              <TableRow key={product.entryId}>
                <TableCell className={checkboxCellSize}>
                  <CheckboxNew
                    checked={isSelected(product)}
                    onClick={() => handleSelect(product)}
                  />
                </TableCell>
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>
                  {formatFullNumericDateString(product.added)}
                </TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.unit}</TableCell>
                <TableCell align={"right"}>
                  {formatCurrency(product.price)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
      <PaginationNew page={page} setPage={setPage} totalPages={totalPages} />
    </>
  );
}
