import {
  createFileRoute,
  useNavigate,
  useParams
} from "@tanstack/react-router";
import { useCompanyId } from "../../../../../../hooks/useCompanyId";
import { useEffect, useState } from "react";
import { useToast } from "../../../../../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProjectById } from "../../../../../../service/api/ProjectApiV2";
import { CrewMembers } from "../../../../../../service/export/ExportApi";
import { exportCrewListPdf } from "../../../../../../utils/exportPdf";
import NewDataFetcher from "../../../../../../components/common/NewDataFetcher";
import PageTitle from "../../../../../../components/common/PageTitle";
import Button from "../../../../../../components/ds/Button";
import { ColumnLayout } from "../../../../../../components/layouts/ColumnLayout";
import Card from "../../../../../../components/ds/Card";
import DatePicker from "../../../../../../components/ds/DatePicker/DatePicker";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from "../../../../../../components/ds/TableNew";
import TableHeaderCell from "../../../../../../components/ds/Table/Table.HeaderCell";
import { getRoleName } from "../../../../../../service/api/RoleApi";
import { useGetCrewListByResourceId } from "../../../../../../service/api/WorkAssignmentApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/workers/"
)({
  component: ProjectWorkersPage
});

function ProjectWorkersPage() {
  const companyId = useCompanyId();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/workers/"
  });
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const { showSuccessToast, showErrorToast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => getProjectById(projectId)
  });

  const workersQuery = useGetCrewListByResourceId({
    variables: {
      companyId,
      resourceId: projectId,
      resourceType: "Project",
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString()
    }
  });

  useEffect(() => {
    workersQuery.refetch();
  }, [toDate, fromDate]);

  function onSelectDate(date: Date, type: "from" | "to") {
    const dateWithTime = date;
    if (type === "from") {
      dateWithTime.setHours(0, 0, 0, 0);
      setFromDate(dateWithTime);
    } else {
      dateWithTime.setHours(23, 59, 59, 999);
      setToDate(dateWithTime);
    }
  }

  function onShowAll() {
    setFromDate(undefined);
    setToDate(undefined);
  }

  async function onExportPdf() {
    if (!workersQuery.data) return;
    try {
      const crewMembers: CrewMembers = workersQuery.data.map((worker) => {
        return {
          name: worker.name ?? "-",
          role: worker.role?.name,
          email: worker.email,
          phoneNumber: worker.phoneNumber,
          companyName: worker.associatedCompany,
          ehsCardNumber: ""
        };
      });
      exportCrewListPdf({
        crewMembers,
        fromDate,
        toDate,
        title: data?.title ?? "",
        companyId,
        language: t("no"),
        queryClient
      });
      showSuccessToast(t("Mannskapslisten ble lastet ned"));
    } catch (_) {
      showErrorToast(t("Kunne ikke eksportere pdf"));
    }
  }

  return (
    <NewDataFetcher
      query={workersQuery}
      onData={(workers) => {
        return (
          <div className={"flex flex-col h-full w-full"}>
            <PageTitle
              title={data?.title ?? t("project")}
              onBackClick={() => {
                navigate({
                  to: "/dashboard/project/$projectId/overview",
                  params: { projectId }
                });
              }}
              actions={
                <Button
                  isLeftIcon
                  icon={"download"}
                  theme={"white"}
                  onClick={onExportPdf}
                >
                  {t("Eksporter pdf")}
                </Button>
              }
            />
            <ColumnLayout columns={1}>
              <Card
                title={t("Mannskapsliste")}
                isFullHeight
                headerContent={
                  <>
                    <div className="flex space-x-4">
                      <DatePicker
                        label={t("Velg periode - fra dato")}
                        value={fromDate}
                        mode={"single"}
                        onSelect={(date) => {
                          onSelectDate(date, "from");
                        }}
                      />
                      <DatePicker
                        label={t("Til dato")}
                        value={toDate}
                        mode={"single"}
                        onSelect={(date) => {
                          onSelectDate(date, "to");
                        }}
                      />
                    </div>
                    <Button onClick={() => onShowAll()} isSmall theme={"white"}>
                      {t("showAll")}
                    </Button>
                  </>
                }
              >
                {workers.length > 0 ? (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>{t("name")}</TableHeaderCell>
                        <TableHeaderCell>{t("role")}</TableHeaderCell>
                        <TableHeaderCell>{t("email")}</TableHeaderCell>
                        <TableHeaderCell>{t("phone")}</TableHeaderCell>
                        <TableHeaderCell>{t("company")}</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {workers.map((worker) => (
                        <TableRow key={worker.id}>
                          <TableCell>{worker.name}</TableCell>
                          <TableCell>
                            {t(getRoleName(worker.role?.name))}
                          </TableCell>
                          <TableCell>{worker.email}</TableCell>
                          <TableCell>{worker.phoneNumber}</TableCell>
                          <TableCell>{worker.associatedCompany}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <p>{t("Ingen arbeidere i denne perioden")}</p>
                )}
              </Card>
            </ColumnLayout>
          </div>
        );
      }}
    />
  );
}
