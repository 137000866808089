import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import React from "react";
import { UpsertFacilityPage } from "../-components/UpsertFacilityPage";
import { z } from "zod";
import { useGetFacilityById } from "../../../../../service/api/FacilityApi";

const updateFacilitySchema = z.object({
  customerId: z.string()
});

export const Route = createFileRoute(
  "/_protected/dashboard/facility/$facilityId/update"
)({
  validateSearch: updateFacilitySchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { facilityId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetFacilityById.getOptions({ facilityId, companyId })
    });
  },
  component: UpdateFacilityPage
});

function UpdateFacilityPage() {
  const facility = useLoaderData({
    from: "/_protected/dashboard/facility/$facilityId/update"
  });
  const { customerId } = useSearch({
    from: "/_protected/dashboard/facility/$facilityId/update"
  });

  return <UpsertFacilityPage facility={facility} customerId={customerId} />;
}
