import {
  createFileRoute,
  useLoaderData,
  useSearch
} from "@tanstack/react-router";
import React from "react";
import UpsertInspectionPage from "../-components/UpsertInspectionPage";
import { z } from "zod";
import { inspectionType } from "../../../../../../router/Routes";
import { useGetCustomerById } from "../../../../../../service/api/CustomerApiV3";

const createInspectionSchema = z.object({
  inspectionType: inspectionType,
  salesOpportunityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/sales/inspection/$customerId/new"
)({
  validateSearch: createInspectionSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { customerId }
  }) => {
    return await queryClient.ensureQueryData({
      ...useGetCustomerById.getOptions({ companyId, customerId })
    });
  },
  component: CreateInspectionPage
});

function CreateInspectionPage() {
  const { inspectionType, salesOpportunityId } = useSearch({
    from: "/_protected/dashboard/sales/inspection/$customerId/new"
  });
  const customer = useLoaderData({
    from: "/_protected/dashboard/sales/inspection/$customerId/new"
  });

  return (
    <UpsertInspectionPage
      inspectionType={inspectionType}
      salesOpportunityId={salesOpportunityId}
      customer={customer}
    />
  );
}
