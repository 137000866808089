import { createFileRoute, useSearch } from "@tanstack/react-router";
import React from "react";
import UpsertChecklistPage from "./-components/UpsertChecklistPage";
import { z } from "zod";

const checklistSchema = z.object({
  resourceId: z.string(),
  parentId: z.string().optional(),
  resourceType: z.enum([
    "Project",
    "Task",
    "Order",
    "Equipment",
    "EhsInspection"
  ])
});

export const Route = createFileRoute("/_protected/dashboard/checklist/new")({
  validateSearch: checklistSchema,
  component: CreateChecklistPage
});

function CreateChecklistPage() {
  const { resourceId, resourceType } = useSearch({
    from: "/_protected/dashboard/checklist/new"
  });

  return (
    <UpsertChecklistPage resourceId={resourceId} resourceType={resourceType} />
  );
}
