import { createFileRoute, useParams } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../../../../../../hooks/useCompanyId";
import useDialog from "../../../../../../../hooks/useDialog";
import { useGetTimeEntriesByResourceId } from "../../../../../../../service/api/TimeEntryApi";
import TimeEntryDialog from "../../../../../../../pages/timeInvoice/timeProject/TimeEntryDialog";
import CardNew from "../../../../../../../components/ds/CardNew";
import { getFormattedHoursAndMinutesOnTimeSubmits } from "../../../../../../../service/api/utils";
import ButtonNew from "../../../../../../../components/ds/ButtonNew";
import Table from "../../../../../../../components/ds/RadixTable/Table";
import TableHeader from "../../../../../../../components/ds/RadixTable/TableHeader";
import TableRow from "../../../../../../../components/ds/RadixTable/TableRow";
import TableHeaderCell from "../../../../../../../components/ds/RadixTable/TableHeaderCell";
import TableBody from "../../../../../../../components/ds/RadixTable/TableBody";
import React from "react";
import HourCard from "./-components/HourCard";
import ProjectEconomyTimeTableRow from "./-components/ProjectEconomyTimeTableRow";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time"
)({
  component: ProjectEconomyTimePage
});

function ProjectEconomyTimePage() {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const { onClose, onOpen, isOpen } = useDialog();
  const { projectId } = useParams({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });

  const timeEntryQuery = useGetTimeEntriesByResourceId({
    variables: { companyId: companyId, resourceId: projectId }
  });

  const timeEntries =
    timeEntryQuery.data?.sort((a, b) => b.date.localeCompare(a.date)) ?? [];

  const billable = timeEntries.filter((t) => t.activityType === "Billable");
  const nonBillable = timeEntries.filter(
    (t) => t.activityType === "NonBillable"
  );

  return (
    <>
      <TimeEntryDialog
        open={isOpen}
        onClose={onClose}
        resourceId={projectId}
        resourceType={"Project"}
        onUpdated={() => {
          onClose();
          timeEntryQuery.refetch();
        }}
      />
      <CardNew title={t("hourOverview")} inset className={"mb-5"}>
        <div className={"grid grid-cols-3 divide-x divide-radix-gray-6"}>
          <HourCard
            label={t("total")}
            value={getFormattedHoursAndMinutesOnTimeSubmits(timeEntries)}
          />
          <HourCard
            label={t("billable")}
            value={getFormattedHoursAndMinutesOnTimeSubmits(billable)}
          />
          <HourCard
            label={t("nonBillable")}
            value={getFormattedHoursAndMinutesOnTimeSubmits(nonBillable)}
          />
        </div>
      </CardNew>
      <CardNew
        inset
        title={t("hours")}
        trailing={
          <ButtonNew size={"1"} variant={"soft"} onClick={onOpen}>
            {t("addHours")}
          </ButtonNew>
        }
      >
        <Table variant={"ghost"}>
          <TableHeader className={"bg-radix-gray-a2"}>
            <TableRow>
              <TableHeaderCell>{t("user")}</TableHeaderCell>
              <TableHeaderCell>{t("date")}</TableHeaderCell>
              <TableHeaderCell>{t("activity")}</TableHeaderCell>
              <TableHeaderCell>{t("value")}</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {timeEntries.map((entry) => {
              return (
                <ProjectEconomyTimeTableRow
                  entry={entry}
                  key={entry.id}
                  onUpdated={timeEntryQuery.refetch}
                />
              );
            })}
          </TableBody>
        </Table>
      </CardNew>
    </>
  );
}
